import { ReactComponent as ArrowRightIcon } from "../../../../assets/images/svg/arrow-right.svg";
import { ReactComponent as ThreatsBlockerIcon } from "../../../../assets/images/svg/threats-blocker-icon.svg";
import { ReactComponent as VPNIcon } from "../../../../assets/images/svg/vpn-icon.svg";
import { ReactComponent as HotspotIcon } from "../../../../assets/images/svg/web-access-icon.svg";
import { ReactComponent as AdBlockerIcon } from "../../../../assets/images/svg/ad-blocker-icon.svg";
import { ReactComponent as HideIdentityIcon } from "../../../../assets/images/svg/hide-identity-icon.svg";
import { ReactComponent as TransactionValidatorIcon } from "../../../../assets/images/svg/transaction-validator-icon.svg";

export const guardianFeaturesData = [
  {
    uppertitle: "THREATS BLOCKER",
    svgProp: <ThreatsBlockerIcon />,
    description: "Secures every connected device in the network",
    bg: "bg1",
  },
  {
    uppertitle: "TRANSACTION VALIDATOR",
    svgProp: <TransactionValidatorIcon />,
    description: "Validates that transactions are signed by pre-identified devices",
    bg: "bg6",
  },
  {
    uppertitle: "VPN",
    svgProp: <VPNIcon />,
    description: "VPN",
    bg: "bg2",
  },
  {
    uppertitle: "AD BLOCKER",
    description: "Ad Blocker",
    bg: "bg4",
    svgProp: <AdBlockerIcon />,
  },
  {
    uppertitle: "COMPUTING POWER",
    description: "Computing resources for DApps development",
    bg: "bg7",
    svgProp: <ThreatsBlockerIcon />,
  },
  {
    uppertitle: "5G HOTSPOT",
    description: "5G HOTSPOT",
    bg: "bg3",
    svgProp: <HotspotIcon />,
  },
  {
    uppertitle: "PROTECT MY IDENTITY",
    description: "Remain anonymous online",
    bg: "bg5",
    svgProp: <HideIdentityIcon />,
  },
  {
    uppertitle: "LOW ENERGY",
    description: (
      <div className="font-size-80 font-pnb">
        <span className="low-energy">Low energy</span> <br></br>
        <span style={{ color: "black" }}>Plant a </span>
        <span style={{ color: "#20deb0" }}>tree.</span>
      </div>
    ),
    getStarted: (
      <div className="get-started-mobile">
        <button className="font-size-20 plant-link font-pnsb " onClick={() => window.open("https://ethernytree.com/")}>
          Get started
          <ArrowRightIcon className="ms-2" />
        </button>
      </div>
    ),

    bg: "bg8",
  },
];
