import React from "react";
import "./Steps.scss";
import { Col, Row } from "react-bootstrap";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";

import NeonCube from "../../../assets/images/png/meta-realm-page/slider-img-4.png";
// import ActivateCardImg from "../../../assets/images/png/meta-realm-page/activate-card-img.png";
import { ReactComponent as BlueCoin } from "../../../assets/images/png/meta-realm-page/blue-coin.svg";
import { ReactComponent as ActivateCardImg } from "../../../assets/images/png/meta-realm-page/activate-card-img.svg";
import CommunityCardImg from "../../../assets/images/png/meta-realm-page/community-card-img.png";
import CommunityCardImgMobile from "../../../assets/images/png/meta-realm-page/community-card-img-mobile.png";
import ProtectedCardImg from "../../../assets/images/png/meta-realm-page/firewall.png";
import RewardsCardImg from "../../../assets/images/png/meta-realm-page/rewards-card-img.png";
// import WithdrawImg from "../../../assets/images/png/meta-realm-page/poker-chip.png";
import CubeImg from "../../../assets/images/png/meta-realm-page/cube-png.png";

import { ReactComponent as StakeImg } from "../../../assets/images/png/meta-realm-page/stake-img.svg";

const Steps = () => {
  return (
    <section id="meta-realm-steps" className="page-section">
      <div className="container-main">
        <Uppertitle title={`STEPS`} />
        <h2 className="mb-3 font-size-80 white-color font-pnb">
          Steps
          <span className="purple-color">.</span>
        </h2>

        {/* configure */}
        <Row className="d-flex justify-content-end align-items-center">
          <Col>
            <div className="column-wrapper configure-card d-flex justify-content-between">
              <div className="h-100 d-flex flex-column align-items-start justify-content-center inner-configure-cards">
                <img src={CubeImg} alt="cube" className="img-fluid neon-cube mb-4" />
                <div className="">
                  <p className="font-pnb font-size-64 mb-0 line-height-1">Configure</p>
                  <p className="font-pnb font-size-64 mb-0 line-height-1"> Your</p>
                  <p className="font-pnb font-size-64 mb-0 line-height-1"> Realm</p>
                  <p className="font-pnb font-size-64 mb-0 line-height-1"> Metanode.</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="rand d-flex justify-content-end align-items-center">
          {/* active realm */}
          <Col sm={6}>
            <div className="column-wrapper p-4 p-lg-5 activate-card d-flex flex-column justify-content-between">
              <div className="h-100 d-flex flex-column align-items-start justify-content-center">
                <BlueCoin className="img-fluid neon-cube py-sm-0 py-4" />
                {/* <img src={ActivateCardImg} alt="cube" className="img-fluid neon-cube" /> */}
                <div className=" ">
                  <p className="font-pnb font-size-64 mb-0 line-height-11">To activate</p>
                  <p className="font-pnb font-size-64 mb-0 line-height-11"> your Realm</p>
                  <p className="font-pnb font-size-64 mb-0 line-height-11">Metanode.</p>
                </div>
              </div>
            </div>
          </Col>

          {/* stake */}
          <Col sm={6}>
            <div className="column-wrapper p-4 p-lg-5 stake-card d-flex flex-column justify-content-between">
              <div className="h-100 d-flex flex-column align-items-start justify-content-center">
                <div className="text-center w-100 pb-4">
                  <p className="font-pnb font-size-56 mb-0 line-height-11">Stake</p>
                </div>
                <div className="stake-img text-center w-100">
                  <div className="stake-img-overlay">
                    {" "}
                    <p className="stake-coming-soon font-pnr font-size-20 w-100">Coming soon</p>
                  </div>

                  <StakeImg className="img-fluid mx-auto" />
                </div>
              </div>
            </div>
          </Col>
        </Row>

        {/* realm metanode */}
        <Row className="d-flex justify-content-end align-items-center">
          <Col>
            <div className="column-wrapper p-4 p-lg-5 community-card d-flex justify-content-between">
              <div className="h-100 d-flex flex-column align-items-start justify-content-center">
                <img src={CommunityCardImg} alt="cube" className="img-fluid neon-cube steps-card-icon py-sm-0 py-4" />
                <div className="pt-4">
                  <p className="font-pnb font-size-64 mb-0 line-height-11">Create your own</p>
                  <p className="font-pnb font-size-64 mb-0 line-height-11"> rules to promote</p>
                  <p className="font-pnb font-size-64 line-height-11">your Realm Metanode.</p>
                  <p className="font-pnb font-size-32 mb-0 line-height-11 community-gradient">
                    Gain a bigger community to connect more pools.
                  </p>
                </div>
                <img
                  src={CommunityCardImgMobile}
                  alt="cube"
                  className="img-fluid d-sm-none d-block pt-4 community-card-img-mobile"
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row className="rand d-flex justify-content-end align-items-center">
          {/* community */}
          <Col sm={6}>
            <div className="column-wrapper p-4 p-lg-5 protected-card d-flex flex-column justify-content-between">
              <div className="h-100 d-flex flex-sm-column flex-column-reverse align-items-start justify-content-center">
                <div className="pb-md-5 pb-4">
                  <div className="">
                    <p className="font-pnb protected-gradient font-size-64 mb-0 line-height-11">Community</p>
                    <p className="font-pnb protected-gradient font-size-64 line-height-11"> is protected</p>
                  </div>

                  <p className="font-pnb font-size-32 mb-0 line-height-11">The Realm Metanode</p>
                  <p className="font-pnb font-size-32 mb-0 line-height-11 light-red-txt-color">Owner cannot access</p>
                  <p className="font-pnb font-size-32 mb-0 line-height-11">the users staked coin.</p>
                </div>
                <img src={ProtectedCardImg} alt="cube" className="img-fluid steps-card-icon py-sm-0 py-4" />
              </div>
            </div>
          </Col>

          {/* rewards */}
          <Col sm={6}>
            <div className="column-wrapper p-4 p-lg-5 rewards-card d-flex flex-column justify-content-between">
              <div className="h-100 d-flex flex-column align-items-start justify-content-center">
                <img src={RewardsCardImg} alt="cube" className="img-fluid steps-card-icon pt-sm-0 pt-4" />
                <div className="pt-5">
                  <p className="font-pnb font-size-64 mb-0 line-height-11">More</p>
                  <p className="font-pnb font-size-64 mb-0 line-height-11"> staked</p>
                  <div className="">
                    <p className="font-pnb rewards-gradient font-size-64 mb-0 line-height-11">Mooore</p>
                    <p className="font-pnb rewards-gradient font-size-64 line-height-11"> Rewards.</p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="d-flex justify-content-end align-items-center">
          {/* withdraw */}
          <Col>
            <div className="column-wrapper p-4 p-lg-5 withdraw-card d-flex justify-content-between">
              <div className="h-100 d-flex flex-column align-items-start justify-content-center">
                {/* <img src={WithdrawImg} alt="cube" className="img-fluid d-md-none d-block py-4 poker-chip-img" /> */}
                <ActivateCardImg className="img-fluid d-md-none d-block py-4 poker-chip-img" />
                <p className="font-pnb font-size-120 mb-0 line-height-11">Withdraw</p>
                <p className="font-pnb font-size-120 light-green-txt-color line-height-11"> XDEN.</p>
                <p className="font-pnb font-size-32 mb-0 line-height-11 community-gradient">
                  You have <span className="withdraw-gradient">full control</span> <br className="br-withdraw" /> on your
                  earnings.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Steps;
