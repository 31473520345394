import React from "react";
import "./Benefits.scss";
import { Col, Row, Button } from "react-bootstrap";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import { ReactComponent as ArrowRight } from "../../../assets//images/svg/arrow-right.svg";
import { ReactComponent as Communications } from "../../../assets/images/svg/EcosystemPage/benefits-atom.svg";
import { ReactComponent as Anonim } from "../../../assets/images/svg/EcosystemPage/benefits-padlock.svg";
import { ReactComponent as Vms } from "../../../assets/images/svg/EcosystemPage/benefits-bitcoin2.svg";
import { ReactComponent as Uptime } from "../../../assets/images/svg/EcosystemPage/benefits-network.svg";
import { ReactComponent as Passiveincome } from "../../../assets/images/svg/EcosystemPage/benefits-wallet.svg";
import { ReactComponent as Storage } from "../../../assets/images/svg/EcosystemPage/benefits-bitcoin.svg";
import { ReactComponent as Scissor } from "../../../assets/images/svg/EcosystemPage/benefits-scissors.svg";
import { ReactComponent as Security } from "../../../assets/images/svg/EcosystemPage/benefits-shield.svg";
import { ReactComponent as Connectivity } from "../../../assets/images/svg/EcosystemPage/benefits-bitcoin3.svg";
import { ReactComponent as Computing } from "../../../assets/images/svg/EcosystemPage/benefits-cpu.svg";

const Benefits = () => {
  return (
    <section id="benefits-ecosystem" className="page-section">
      <div className="container-main d-flex flex-column">
        <Row className="title align-items-start flex-column">
          <Uppertitle title={"BENEFITS"} />
          <h2 className="title font-size-80 white-color font-pnb">
            Xiden benefits
            <span className="purple-color">.</span>
          </h2>

          <h4 className="font-pnb gradient-subtitle font-size-56 pt-1 pb-lg-4 pb-3">Unrestricted internet.</h4>
          <p className="font-pnr mb-4 font-size-24 white-color title-description">
            We’ve created a Global Network of Decentralized Private Networks that facilitates connection for all types of devices
            anytime and anywhere.
          </p>
        </Row>
        <Row className="mt-4 mt-lg-5 cards-row align-items-center">
          <Col xl md={4} sm={6}>
            <div className="column-wrapper pe-2 d-flex flex-column  align-items-start">
              <div className="logo-container mb-3 d-flex justify-content-center align-items-center align-content-center">
                {/* <img src={Scissor} alt="scissor" className="img-fluid" /> */}
                <Scissor />
              </div>
              <div className="container-content">
                <h4 className="font-pnb font-size-32 white-color">
                  Reduced costs
                  <span className="purple-color">.</span>
                </h4>
                <p className="font-pnr font-size-18 lightgrey-color">
                  Reduced fees for transactions and no costs for integrating devices that share their resources with the network.
                </p>
              </div>
            </div>
          </Col>
          <Col xl md={4} sm={6}>
            <div className="column-wrapper pe-2 d-flex flex-column  align-items-start">
              <div className="logo-container mb-3 d-flex justify-content-center align-items-center align-content-center">
                {/* <img src={Connectivity} alt="Connectivity" className="img-fluid" /> */}
                <Connectivity />
              </div>
              <div className="container-content">
                <h4 className="font-pnb font-size-32 white-color">
                  Connectivity
                  <span className="purple-color">.</span>
                </h4>
                <p className="font-pnr font-size-18 lightgrey-color">
                  Free, secure, and unrestricted internet connection provided by a decentralized network of connectivity points
                  around the world.
                </p>
              </div>
            </div>
          </Col>
          <Col xl md={4} sm={6}>
            <div className="column-wrapper pe-2 d-flex flex-column  align-items-start">
              <div className="logo-container mb-3 d-flex justify-content-center align-items-center align-content-center">
                {/* <img src={Security} alt="security" className="img-fluid" /> */}
                <Security />
              </div>
              <div className="container-content">
                <h4 className="font-pnb font-size-32 white-color">
                  Security
                  <span className="purple-color">.</span>
                </h4>
                <p className="font-pnr font-size-18 lightgrey-color">
                  The network’s architecture uses Voice Over Blockchain Protocol (VOBP) and cryptographic algorithms to secure the
                  connections between devices, routers, and peers.
                </p>
              </div>
            </div>
          </Col>
          <Col xl md={4} sm={6}>
            <div className="column-wrapper pe-2 d-flex flex-column  align-items-start">
              <div className="logo-container mb-3 d-flex justify-content-center align-items-center align-content-center">
                {/* <img src={Anonimity} alt="Anonymity" className="img-fluid" /> */}
                <Anonim />
              </div>
              <div className="container-content">
                <h4 className="font-pnb font-size-32 white-color">
                  Anonymity
                  <span className="purple-color">.</span>
                </h4>
                <p className="font-pnr font-size-18 lightgrey-color">
                  The network nodes ensure secure communication gates to anonymize the identity of users, transferred packages,
                  and connected devices.
                </p>
              </div>
            </div>
          </Col>
          <Col xl md={4} sm={6}>
            <div className="column-wrapper pe-2 d-flex flex-column  align-items-start">
              <div className="logo-container mb-3 d-flex justify-content-center align-items-center align-content-center">
                {/* <img src={Passiveincome} alt="Passive income" className="img-fluid" /> */}
                <Passiveincome />
              </div>
              <div className="container-content">
                <h4 className="font-pnb font-size-32 white-color">
                  Passive Income
                  <span className="purple-color">.</span>
                </h4>
                <p className="font-pnr font-size-18 lightgrey-color">
                  The more devices you connect, the more your earnings will increase. Making available for the network the
                  resources of your smart devices will ensure a high passive income for you.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="title align-items-start flex-column">
          <h4 className="font-pnb gradient-subtitle font-size-56 pt-lg-5 pt-4 pb-lg-4 pb-3">IoT resources.</h4>
          <p className="font-pnr mb-4 font-size-24 white-color title-description">
            Connected devices share the computing and storage resources so the apps developers can build any imagined app using
            all the resources needed.
          </p>
        </Row>
        <Row className="mt-4 mt-lg-5 cards-row align-items-center">
          <Col xl md={4} sm={6}>
            <div className="column-wrapper pe-2 d-flex flex-column  align-items-start">
              <div className="logo-container mb-3 d-flex justify-content-center align-items-center align-content-center">
                {/* <img src={Storage} alt="scissor" className="img-fluid" /> */}
                <Storage />
              </div>
              <div className="container-content">
                <h4 className="font-pnb font-size-32 white-color">
                  Storage
                  <span className="purple-color">.</span>
                </h4>
                <p className="font-pnr pt-4 font-size-18 lightgrey-color">
                  More integrated smart devices into the network means more overall storage capacity.
                </p>
              </div>
            </div>
          </Col>
          <Col xl md={4} sm={6}>
            <div className="column-wrapper pe-2 d-flex flex-column  align-items-start">
              <div className="logo-container mb-3 d-flex justify-content-center align-items-center align-content-center">
                {/* <img src={Computing} alt="Connectivity" className="img-fluid" /> */}
                <Computing />
              </div>
              <div className="container-content">
                <h4 className="font-pnb font-size-32 white-color">
                  Computing
                  <span className="purple-color">.</span>
                </h4>
                <p className="font-pnr pt-4 font-size-18 lightgrey-color">
                  A higher number of validator nodes will ensure a higher computing power for the network.
                </p>
              </div>
            </div>
          </Col>
          <Col xl md={4} sm={6}>
            <div className="column-wrapper pe-2 d-flex flex-column  align-items-start">
              <div className="logo-container mb-3 d-flex justify-content-center align-items-center align-content-center">
                {/* <img src={Communications} alt="security" className="img-fluid" /> */}
                <Communications />
              </div>
              <div className="container-content">
                <h4 className="font-pnb font-size-32 white-color">
                  Communications
                  <span className="purple-color">.</span>
                </h4>
                <p className="font-pnr pt-4 font-size-18 lightgrey-color">
                  Encrypted and secure communication channels for the devices integrated within the network.
                </p>
              </div>
            </div>
          </Col>
          <Col xl md={4} sm={6}>
            <div className="column-wrapper pe-2 d-flex flex-column  align-items-start">
              <div className="logo-container mb-3 d-flex justify-content-center align-items-center align-content-center">
                {/* <img src={Uptime} alt="Anonymity" className="img-fluid" /> */}
                <Uptime />
              </div>
              <div className="container-content">
                <h4 className="font-pnb font-size-32 white-color">
                  Uptime
                  <span className="purple-color">.</span>
                </h4>
                <p className="font-pnr pt-4 font-size-18 lightgrey-color">
                  The devices with extended operation time will earn a higher passive income.
                </p>
              </div>
            </div>
          </Col>
          <Col xl md={4} sm={6}>
            <div className="column-wrapper pe-2 d-flex flex-column  align-items-start">
              <div className="logo-container mb-3 d-flex justify-content-center align-items-center align-content-center">
                {/* <img src={Vms} alt="Passive income" className="img-fluid" /> */}
                <Vms />
              </div>
              <div className="container-content">
                <h4 className="font-pnb font-size-32 white-color">
                  VMS
                  <span className="purple-color">.</span>
                </h4>
                <p className="font-pnr pt-4 font-size-18 lightgrey-color">
                  Provides access to a vast volume of resources thanks to all the virtual machines integrated into the network.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};
export default Benefits;
