import React from "react";
import "./RouterFunctions.scss";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import GuardianImg from "../../../assets/images/png/guardian-node-guardian-node.png";
import Validator from "../../../assets/images/png/guardian-node-page/validator-guardian-node.png";
import RouterImg from "../../../assets/images/png/guardian-node-secured-router.png";
import RouterImg2 from "../../../assets/images/png/validator-router.png";

const RouterFunctions = () => {
  return (
    <section id="router-functions-section">
      <div className="page-section">
        <div className="container-main">
          <div className="main-col">
            <Uppertitle title={`ROUTER`} />
            <h2 className="mb-0 font-size-80 white-color font-pnb line-height-11">A router with</h2>
            <h2 className="mb-2 font-size-80 white-color font-pnb line-height-11">
              multiple functions<span className="purple-color">.</span>
            </h2>
            <div className="description description-991">
              {/* <p className="font-family-pnr font-size-24 lightgrey-color line-height-11">
                Ocean fearful derive self reason pious insofar god will pious horror. Christian aversion war evil faith abstract victorious.
              </p> */}
            </div>
          </div>

          <div className="router-functions-container">
            <div className="description-col">
              <div className="image-container">
                <img src={RouterImg2} loading="lazy" alt="guardian node" className="col-image guardian-image" />
              </div>
              <h4 className="text-white font-size-40 font-pnb box-title">
                Guardian node<span className="purple-color">.</span>
              </h4>
              <p className="font-family-pnr font-size-24 lightgrey-color line-height-11 router-functions-description ">
                Validates the other devices registered within the network including configured Decentralized Private Networks.
              </p>
            </div>

            <div className="description-col">
              <div className="image-container">
                <img src={RouterImg} loading="lazy" alt="connectivity" className="col-image router-image" />
              </div>
              <h4 className="text-white font-size-40 font-pnb box-title">
                Secured router<span className="purple-color">.</span>
              </h4>
              <p className="font-family-pnr font-size-24 lightgrey-color line-height-11 router-functions-description">
                Provides a free Internet connection and access in a secure, private, and unrestricted manner.
              </p>
            </div>

            <div className="description-col">
              <div className="image-container">
                <img src={Validator} loading="lazy" alt="validator" className="col-image validator-image" />
              </div>
              <h4 className="text-white font-size-40 font-pnb box-title">
                Validator<span className="purple-color">.</span>
              </h4>
              <p className="font-family-pnr font-size-24 lightgrey-color line-height-11 router-functions-description">
                Facilitates the opening of a Krater Pool with a predetermined amount of XDEN that can be unlocked through a mining
                process.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RouterFunctions;
