import FiveKrater from '../SupportKraterSteps/assets-steps/krater/5.png';
import SuccessfullStakeKrater from "../SupportKraterSteps/assets-steps/krater/successfull-stake.jpeg";
import ConnectMetamask from "../SupportKraterSteps/assets-steps/krater/connect-metamask.png";
import XidenNetwork from "../SupportKraterSteps/assets-steps/krater/xiden-network-img.png";
import RedeemPool from "../SupportKraterSteps/assets-steps/redeem-pool.PNG";
import ConfirmNetwork from "../SupportKraterSteps/assets-steps/connect-button.PNG";
import RedeemSuccess from "../SupportKraterSteps/assets-steps/redeem-success.PNG";
import StakeNowImg from "../SupportKraterSteps/assets-steps/stake-now-image.PNG";
import GasFee2 from "../SupportKraterSteps/assets-steps/gas-fee-screen2.PNG";
import ConfirmGasFee from "../SupportKraterSteps/assets-steps/confirm-gas-fee.PNG";
import PoolCongrats from "../SupportKraterSteps/assets-steps/my-pool-congrats.PNG";
import ThirdKrater from '../SupportKraterSteps/assets-steps/krater/gas-fee-screen.jpg';
import { TiWarning as WarningSign } from "react-icons/ti";
import { AiFillInfoCircle as InfoSign } from "react-icons/ai";

export const getLockedXdenSteps = [
    {
        uppertitle: "STEP 01",
        title: "Connect your Metamask",
        punctuation: ".",
        content: <>
            <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                You can <a className="blue-color tutorial-link" href="https://xiden.com/support-metamask" target="_blank">check this tutorial</a> to learn how to set up Metamask.
            </p>
        </>,
        image: ConnectMetamask
    },
    {
        uppertitle: "STEP 02",
        title: "Select Xiden Network",
        punctuation: ".",
        content: <>
            <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 ">
                Select Xiden Network and your wallet.
            </p>
            <p className="font-family-pnr font-size-18 lightgrey-color line-height-11 ">
                <InfoSign className="info-svg me-2" /><a className="blue-color tutorial-link" href="https://xiden.com/support-metamask" target="_blank">Check how</a> to add Xiden network into your Metamask.
            </p>
            <p className="font-family-pnr font-size-18 lightgrey-color line-height-11 ">

                <WarningSign className="warning-svg me-2" />If you already redeemed your locked XDEN and you want to redeem for a new Minter into the same Metamask wallet you must create a new Xiden account. <br />
                <a className="blue-color tutorial-link" href="https://metamask.zendesk.com/hc/en-us/articles/360015289452-How-to-create-an-additional-account-in-your-wallet" target="_blank">Check how </a>
                to add a new account in the same Metamask wallet.
            </p>
            <p className="font-family-pnr font-size-18 lightgrey-color line-height-11 ">
                <WarningSign className="warning-svg me-2" />Redeem is available one time for each Minter and one single account.
            </p>
        </>,
        image: XidenNetwork
    },
    {
        uppertitle: "STEP 03",
        title: "Connect Metamask to Xiden blockchain",
        punctuation: ".",
        content: <>
            <p className="pb-2 font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                Select your account from your wallet to connect to Xiden blockchain.
            </p>
        </>,
        image: RedeemPool
    },
    {
        uppertitle: "STEP 04",
        title: "Connect with blockchain to access 1000 locked XDEN",
        punctuation: ".",
        content: <>
            <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 ">
                Press on "Connect" on the Metamask window.
            </p>
        </>,
        image: ConfirmNetwork
    },
    {
        uppertitle: "STEP 05",
        title: "Redeem your 1000 locked XDEN",
        punctuation: ".",
        content: <>
            <p className="pb-2 font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                Click on "Redeem" to redeem your 1000 locked XDEN.
            </p>
            <p className="font-family-pnr font-size-18 lightgrey-color line-height-11 text-start">
                <InfoSign className="info-svg me-2" /> You must wait 30 seconds for the operation to be processed.
            </p>
        </>,
        image: RedeemSuccess
    },
    {
        uppertitle: "STEP 06",
        title: "Stake 1000 locked XDEN",
        punctuation: ".",
        content: <>
            <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                Press "Stake now" button in Xiden Pools window to activate your pool to be able to validate.
            </p>
        </>,
        image: StakeNowImg
    },
    {
        uppertitle: "STEP 07",
        title: "Edit Gas Fee for staking transaction",
        punctuation: ".",
        content: <>
            <p className="pb-2 font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                Click on the Edit Transaction Fee.
            </p>
            <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                <WarningSign className="warning-svg me-2" /> You must have XDEN in your account to be able to send locked XDEN into staking address.
            </p>
        </>,
        image: ThirdKrater
    },
    {
        uppertitle: "STEP 08",
        title: "Set Gas Fee to 20 GWEI",
        punctuation: ".",
        content: <>
            <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                Set Gas Fee to minimum 20 GWEI and press the "Save" button.
            </p>
        </>,
        image: GasFee2
    },
    {
        uppertitle: "STEP 09",
        title: "Confirm your transaction",
        punctuation: ".",
        content: <>
            <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                Click on "Confirm" to confirm your transaction to stake 1000 locked XDEN.
            </p>
        </>,
        image: ConfirmGasFee
    },
    {
        uppertitle: "STEP 10",
        title: "Congratulations",
        punctuation: "!",
        content: <>
            <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                Congratulations! You staked 1000 XDEN succesfully! Your pool is now active. All mining activities are enabled.
            </p>
        </>,
        image: SuccessfullStakeKrater
    },
    {
        uppertitle: "STEP 11",
        title: "Keep Guardian node online",
        punctuation: ".",
        content: <>
            <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                Ensure your Minter Guardian node is always connected and verifies the conformity of other devices and data in the system.
            </p>
        </>,
        image: FiveKrater
    },
    {
        uppertitle: "STEP 12",
        title: "Check your validating power",
        punctuation: ".",
        content: <>
            <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                In Xiden Pools web application check your validating power and stats.
            </p>
        </>,
        image: PoolCongrats
    }];