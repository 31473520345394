import React from "react";
import "./DeviceCard.scss";

const DeviceCard = ({ cardData, color, pointer, marginX, devicesCards, toggleDevice, deviceType, activeDevice }) => {
  const matchCardColors = (color) => {
    if (devicesCards) {
      if (activeDevice) {
        return { background: "#22aaff", color: "#070713" };
      } else {
        return { background: "#161616", color: "#ffffff" };
      }
    } else {
      switch (color) {
        case "blue":
          return { background: "#22aaff", color: "#070713" };

        case "dark":
          return { background: "#161616", color: "#ffffff" };

        case "yellow":
          return { background: "#fda544", color: "#070713" };

        default:
          return { background: "#161616", color: "#ffffff" };
      }
    }
  };

  const handleDevice = () => {
    if (devicesCards) {
      if (deviceType === "impulse") {
        toggleDevice("impulse");
      }
      if (deviceType === "basm") {
        toggleDevice("basm");
      }
      if (deviceType === "hidr") {
        toggleDevice("hidr");
      }
    }
  };

  const handleIcon = () => {
    if (devicesCards) {
      if (activeDevice) {
        return cardData.deviceIcon[0];
      } else {
        return cardData.deviceIcon[1];
      }
    } else {
      return cardData.deviceIcon;
    }
  };

  return (
    <div
      className={`device-card ${color === "yellow" && "computing-card"} ${pointer && "pointer"} ${marginX && "marginX"} ${
        devicesCards && "devicesCardSizes"
      }`}
      style={matchCardColors(color)}
      onClick={() => handleDevice()}
    >
      <div className="me-3 d-flex flex-column">
        <span className={`upperTitleSize font-pnr ${devicesCards && "devicesUpperTitleSize"}`}>{cardData.upperTitle}</span>
        <h5 className={`mb-0 font-pnsb titleSize ${devicesCards && "devicesTitleSize"}`}>{cardData.title}</h5>
      </div>
      <div className={`icon-container ${devicesCards && "devicesIcon"}`}>{handleIcon()}</div>
    </div>
  );
};

export default DeviceCard;
