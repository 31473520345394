import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { changeLogDataArr } from '../ChangeLogData/ChangeLogData'
import VersionCard from '../VersionCard/VersionCard'
import { ReactComponent as ArrowRight } from '../../../assets/images/svg/arrow-right.svg'

import './ChangeLogCards.scss'

const ChangeLogCards = ({ isHomepage }) => {
  const [visibile, setVisibile] = useState(3)
  const [changelogPools, setChangeLogPools] = useState([{}])
  const [changelogImpulse, setChangeLogImpulse] = useState([{}])
  const [changelogMinter, setChangeLogMinter] = useState([{}])
  const [changelogHidr, setChangeLogHidr] = useState([{}])
  const [changelogBasm, setChangeLogBasm] = useState([{}])
  const [changelog, setChangeLog] = useState([{}])
  const [holder, setHolder] = useState(1)

  const handleLoadMore = () => {
    setVisibile(prevState => prevState + 2)
  }

  useEffect(async () => {
    const fetchDataImpulse = async () => {
      try {
        const response = await axios.get(
          'https://visibility.cryptodata.com/updates/custom/xiden/impulse'
        )
        if (response.status === 200) {
          setChangeLogImpulse(response.data)
        }
      } catch (error) {
        console.log(error)
      }
    }

    const fetchDataPools = async () => {
      try {
        const response = await axios.get(
          'https://visibility.cryptodata.com/updates/custom/xiden/pools'
        )
        if (response.status === 200) {
          setChangeLogPools(response.data)
        }
      } catch (error) {
        console.log(error)
      }
    }

    const fetchDataMinter = async () => {
      try {
        const response = await axios.get(
          'https://visibility.cryptodata.com/updates/custom/xiden/minter'
        )
        if (response.status === 200) {
          setChangeLogMinter(response.data)
        }
      } catch (error) {
        console.log(error)
      }
    }
    const fetchDataHidr = async () => {
      try {
        const response = await axios.get(
          'https://visibility.cryptodata.com/updates/custom/xiden/hidr'
        )
        if (response.status === 200) {
          setChangeLogHidr(response.data)
        }
      } catch (error) {
        console.log(error)
      }
    }
    const fetchDataBasm = async () => {
      try {
        const response = await axios.get(
          'https://visibility.cryptodata.com/updates/custom/xiden/basm'
        )
        if (response.status === 200) {
          setChangeLogBasm(response.data)
        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchDataPools()
    fetchDataImpulse()
    fetchDataHidr()
    fetchDataMinter()
    fetchDataBasm()
  }, [])

  useEffect(() => {
    setChangeLog([
      ...changelogImpulse,
      ...changelogPools,
      ...changelogMinter,
      ...changelogHidr,
      ...changelogBasm,
    ])
  }, [
    changelogImpulse,
    changelogPools,
    changelogMinter,
    changelogHidr,
    changelogBasm,
  ])

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  // sort changelog by date
  const sortedChangeLog = changelog.sort((a, b) => {
    return new Date(b.date) - new Date(a.date)
  })

  return (
    <>
      {isHomepage ? (
        <>
          {' '}
          <section
            // id="cl-cards"
            className='d-flex align-items-center justify-content-center flex-column'
          >
            <VersionCard
              updateType={changelog[0].type}
              isHomepage={isHomepage}
              version={changelog[0]?.version}
              date={changelog[0]?.date}
              device={changelog[0]?.device}
              updates={changelog[0]?.updates?.slice(0, holder)}
              holder={holder}
              setHolder={setHolder}
            />

            {!(changelog.length === changelog.length) && (
              <button
                className='load-more-btn font-pnr white-color font-size-24'
                onClick={handleLoadMore}
              >
                Load more
                <ArrowRight className='ms-2' />
              </button>
            )}
          </section>
        </>
      ) : (
        <>
          {' '}
          <section
            id='cl-cards'
            className='d-flex align-items-center justify-content-center flex-column'
          >
            {/* Sort changelog by date  */}
            {changelog
              .sort((a, b) => {
                return new Date(b.date) - new Date(a.date)
              })

              .map((version, index) => {
                return (
                  <VersionCard
                    updateType={version.type}
                    key={index}
                    isHomepage={isHomepage}
                    version={version.version}
                    date={version.date}
                    device={version.device}
                    updates={version.updates}
                    // titleFix={version.titleFix}
                  ></VersionCard>
                )
              })}

            {!(changelog.length === changelog.length) && (
              <button
                className='load-more-btn font-pnr white-color font-size-24'
                onClick={handleLoadMore}
              >
                Load more
                <ArrowRight className='ms-2' />
              </button>
            )}

            {changelog.length === changelog.length && changelog.length > 3 && (
              <>
                <p className='no-more-items-to-show font-pnr white-color font-size-24'>
                  No more items to show
                </p>
                <button
                  className='load-more-btn font-pnr white-color font-size-24'
                  onClick={scrollToTop}
                >
                  Scroll to top
                </button>
              </>
            )}
          </section>
        </>
      )}
    </>
  )
}

export default ChangeLogCards
