import React from "react";
// style
import "./HeroSection.scss";

import { Row, Col } from "react-bootstrap";
//sections
import HeroImg from "./hero.png";
// import {ReactComponent as PlusIco} from "../../../assets/images/png/SupportMetamask/plus-button.svg";
import { Link as ScrollLink } from "react-scroll";
import XidenRouter from "./xiden-router.png";

const HeroSection = () => {
  return (
    <section id="metamask-support-hero-page" className="page-section minter-support-section">
      <div className="container-main d-flex flex-column justify-content-start align-items-start">
        <Row className="hero-row w-100">
          <Col
            lg={7}
            className="content-col d-flex flex-column align-items-start justify-content-center"
          >
            <img src={XidenRouter} alt="minter router" className="img-fluid router-img" />
            <h2 className="title-hero font-size-64 font-pnb white-color">
              How to download and install<br />
              Krater App on your BASM<span className="purple-color">?</span>
            </h2>

            <Row xs={1} sm={1} md={2} lg={2} xl={1} xxl={2} className="w-100">
              <Col>
                <ScrollLink
                  className="buttonOutlineBlue btn-1 mt-2 mt-lg-4 mx-0 d-flex align-items-center justify-content-center text-center"
                  to="how-to-login-cryptodata-os"
                  offset={-90}
                  spy={true}
                >
                  <span className="font-size-20 font-pnb"> How to login to CryptoDATA OS? </span>
                </ScrollLink>
              </Col>
              <Col>
                <ScrollLink
                  className="buttonOutlineBlue btn-2 mt-2 mt-lg-4 mx-0 d-flex align-items-center justify-content-center text-center"
                  to="how-to-download-krater-app"
                  offset={-90}
                  spy={true}
                >
                  <span className="font-size-20 font-pnb"> How to install Krater App? </span>
                </ScrollLink>
              </Col>
            </Row>
            {/* <Row xs={1} sm={1} md={2} lg={2} xl={1} xxl={2}  className="w-100">
              <Col>
                <ScrollLink
                  className="buttonOutlineBlue btn-3 mt-2 mt-lg-4 me-0 d-flex align-items-center justify-content-center text-center"
                  to="how-to-link-minter"
                  offset={-90}
                  spy={true}
                >
                  <span className="font-size-20 font-pnb"> How to link Minter with Pool? </span>
                </ScrollLink>
              </Col>
              <Col>
                <ScrollLink
                  className="buttonOutlineBlue btn-4 mt-2 mt-lg-4 me-0 d-flex align-items-center justify-content-center text-center"
                  to="how-to-get-1000-xden"
                  offset={-90}
                  spy={true}
                >
                  <span className="font-size-20 font-pnb"> How to get the 1000 locked XDEN? </span>
                </ScrollLink>
              </Col>
            </Row> */}
          </Col>

          <Col
            lg={5}
            className="mobile-col d-flex align-items-center"
          >
            <img
              src={HeroImg}
              alt="Xiden setup in Metamask"
              className="img-fluid hero-img m-auto"
            />
          </Col>
        </Row>
      </div>
    </section >
  );
};

export default HeroSection;
