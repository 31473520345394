import Uppertitle from '../../../components/Uppertitle/Uppertitle'
import iconWarning from './assets/icon-dreapta.png'

import './PrivateWithdraw.scss'
const PrivateWithdraw = () => {
  return (
    <div className='private-withdraw text-white pb-5'>
      <div className='container-main'>
        <div className='private-title pb-lg-5 pb-4 pt-5 pt-lg-0'>
          <h2 className='font-pnb font-size-80  white-color  lh-1'>
            Private withdraw
          </h2>
          <h2 className='font-pnb font-size-80  medium-green-text lh-1'>
            is now available.
          </h2>
        </div>
        <div className='row g-4'>
          <div className='col-lg-8'>
            <div className='column-wrapper buy-minter-1 d-flex flex-column justify-content-start p-4 p-lg-5 lh-1'>
              <Uppertitle title={'PRIVATE WITHDRAW'} />
              <h4 className='font-pnb font-size-56 big-bang-section-title lh-1 my-auto'>
                You can now withdraw <br /> your
                <span className='blue-color'> XDEN</span> unlocked <br />
                through Private Mining
                <span className='purple-color'>.</span>
              </h4>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='column-wrapper warning-please p-4 p-lg-5 d-flex flex-column'>
              <Uppertitle title={'STAKE'} />
              <div className='font-pnb font-size-48 lh-1 text-center my-auto'>
                <img
                  src={iconWarning}
                  alt=''
                  className='img-fluid mb-4'
                  width='100px'
                />
                <div className='px-xxl-4 '>
                  Please be aware that <br />
                  <span className='font-pnb font-size-48 xiden-warning'>
                    you cannot mine without 1.000 XDEN{' '}
                  </span>
                  <br />
                  in staking.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default PrivateWithdraw
