import React from "react";
import "./Announcement.scss";
import { Col, Row } from "react-bootstrap";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import CubePurple from "../../../assets/images/png/stake-support-validators-cooldown.png";

const Announcement = () => {


    return (
        <section id="stake-support-announcement" className="page-section">
            <div className="container-main">
                <h2 className="font-size-80 pb-xl-5 pb-4 white-color font-pnb">
                    Announcement
                    <span className="purple-color">.</span>
                </h2>
                <Row>
                    <Col>
                        <div className="column-wrapper contract-card p-4 p-xl-5  d-flex align-items-center justify-content-center">
                            <div className="prop-feature-info h-100">
                                <Uppertitle title={"WITHDRAW"} />
                                <h4 className="font-pnb py-lg-0 py-4 font-size-56 white-color proprietary-title-main-card">
                                    When you <span className="stake-support-red-color">stake </span> or <br />
                                    <span className="stake-support-purple-color">unstake</span>, <span className="darkgrey-color">validators <br />
                                        will enter in a</span> <br />
                                    cooldown period <span className="darkgrey-color"> <br />
                                        for 7.776.000 blocks</span>
                                    <span className="purple-color">.</span>
                                </h4>
                            </div>
                            <div className="prop-image-col align-self-end position-relative">
                                <img src={CubePurple} loading="lazy" alt="purple cube w-100" className="prop-cube-img" />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    );
};

export default Announcement;
