import React, { useState } from "react";
import "./SupportGuideSteps.scss";
import LeftSide from "../StepsComponents/LeftSide";
import RightSide from "../StepsComponents/RightSide";

import { accessHidrSteps } from "../../SupportKraterInstallation/StepsData/hidrData";

const SupportGuideSteps = () => {
  return (
    <section id="guardian-steps-section" className="krater-guide-steps-section krater-app-steps-section">
      <div className="page-section text-white">
        <div className="container-main">
          <div className="mt-5">
            <h4 className="scroll-anchor display-none"></h4>
            {accessHidrSteps.map((step, index) => {
              return index % 2 === 0 ? (
                <LeftSide
                  uppertitle={step.uppertitle}
                  title={step.title}
                  punctuation={step.punctuation}
                  content={step.content}
                  image={step.image}
                  modal={step.modal}
                  key={step.title}
                />
              ) : (
                <RightSide
                  uppertitle={step.uppertitle}
                  title={step.title}
                  punctuation={step.punctuation}
                  content={step.content}
                  image={step.image}
                  modal={step.modal}
                  key={step.title}
                />
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SupportGuideSteps;
