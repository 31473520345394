import React from "react";
import { Row, Col } from "react-bootstrap";
import TransparentCard from "../../../../components/TransparentCard/TransparentCard";
import { useEffect, useState } from "react";
import axios from "axios";

const HidrCardsList = () => {
  const [hidrCardsData, setHidrCardsData] = useState([
    {
      id: 1,
      upperTitle: `VALIDATING POWER`,
      title: `54 cycles`,
    },
    {
      id: 2,
      upperTitle: "PRIVATE DIFFICULTY",
      title: `1`,
    },
    {
      id: 3,
      upperTitle: "PROFIT PER DAY",
      title: "1 XDEN",
    },
    {
      id: 4,
      upperTitle: "PROFIT PER MONTH",
      title: "1 XDEN",
    },
    {
      id: 5,
      upperTitle: "PROFIT PER YEAR",
      title: "1 XDEN",
    },
    {
      id: 6,
      upperTitle: "PUBLIC DIFFICULTY",
      title: "1 T",
    },
    {
      id: 7,
      upperTitle: "PUBLIC DIFFICULTY",
      title: "1 T",
    },
    {
      id: 8,
      upperTitle: "PROFIT PER DAY",
      title: "1 XDEN",
    },
    {
      id: 9,
      upperTitle: "PROFIT PER MONTH",
      title: "1 XDEN",
    },
    {
      id: 10,
      upperTitle: "PROFIT PER YEAR",
      title: "1 XDEN",
    },
  ]);

  const [hidrCardsData2, setHidrCardsData2] = useState([
    {
      id: 1,
      upperTitle: `VALIDATING POWER`,
      title: `54 cycles`,
    },
    {
      id: 2,
      upperTitle: "PRIVATE DIFFICULTY",
      title: `1`,
    },
    {
      id: 3,
      upperTitle: "PROFIT PER DAY",
      title: "1 XDEN",
    },
    {
      id: 4,
      upperTitle: "PROFIT PER MONTH",
      title: "1 XDEN",
    },
    {
      id: 5,
      upperTitle: "PROFIT PER YEAR",
      title: "1 XDEN",
    },
    {
      id: 6,
      upperTitle: "PUBLIC DIFFICULTY",
      title: "1 T",
    },
    {
      id: 7,
      upperTitle: "PUBLIC DIFFICULTY",
      title: "1 T",
    },
    {
      id: 8,
      upperTitle: "PROFIT PER DAY",
      title: "1 XDEN",
    },
    {
      id: 9,
      upperTitle: "PROFIT PER MONTH",
      title: "1 XDEN",
    },
    {
      id: 10,
      upperTitle: "PROFIT PER YEAR",
      title: "1 XDEN",
    },
  ]);

  useEffect(() => {
    var raw = {
      toggleDifficulty: false,
      size: 1,
      basmInput: 0,
      hidrInput: 1,
      minterInput: 0,
      activeDevices: 0,
    };
    var raw2 = {
      toggleDifficulty: true,
      size: 1,
      basmInput: 0,
      hidrInput: 1,
      minterInput: 0,
      activeDevices: 0,
    };

    const fetchDataPrivate = async () => {
      try {
        const checkReq = await axios.post("https://api.heimdall.ro/calculatorFunction", raw);
        if (checkReq.status === 200) {
          setHidrCardsData([
            {
              id: 1,
              upperTitle: `VALIDATING POWER`,
              title: `54 cycles`,
            },
            {
              id: 2,
              upperTitle: "PRIVATE DIFFICULTY",
              title: `${checkReq.data.privateDifficulty} `,
            },
            {
              id: 3,
              upperTitle: "PROFIT PER DAY",
              title: `${checkReq.data.dailyInput} XDEN`,
            },
            {
              id: 4,
              upperTitle: "PROFIT PER MONTH",
              title: `${checkReq.data.monthlyInput} XDEN`,
            },
            {
              id: 5,
              upperTitle: "PROFIT PER YEAR",
              title: `${checkReq.data.yearlyInput} XDEN`,
            },
            {
              id: 6,
              upperTitle: "PUBLIC DIFFICULTY",
              title: `${0}`,
            },
            {
              id: 7,
              upperTitle: "PUBLIC DIFFICULTY",
              title: `${0} `,
            },
            {
              id: 8,
              upperTitle: "PROFIT PER DAY",
              title: `${0}   XDEN`,
            },
            {
              id: 9,
              upperTitle: "PROFIT PER MONTH",
              title: `${0} XDEN`,
            },
            {
              id: 10,
              upperTitle: "PROFIT PER YEAR",
              title: `${0} XDEN`,
            },
          ]);
        }
      } catch (err) {
        // setPriceAvailable(false);
        if (err.response) {
          switch (err.response.status) {
            case 404:
              console.log("404 Not found.");
              return;
            default:
              console.log("Something is temporarily wrong.");
              return;
          }
        }
      }
    };
    const fetchDataPublic = async () => {
      try {
        const checkReq = await axios.post("https://api.heimdall.ro/calculatorFunction", raw2);
        if (checkReq.status === 200) {
          setHidrCardsData2([
            {
              id: 1,
              upperTitle: `VALIDATING POWER`,
              title: `54 cycles`,
            },
            {
              id: 2,
              upperTitle: "PRIVATE DIFFICULTY",
              title: `${checkReq.data.privateDifficulty} `,
            },
            {
              id: 3,
              upperTitle: "PROFIT PER DAY",
              title: `${checkReq.data.dailyInput} XDEN`,
            },
            {
              id: 4,
              upperTitle: "PROFIT PER MONTH",
              title: `${checkReq.data.monthlyInput} XDEN`,
            },
            {
              id: 5,
              upperTitle: "PROFIT PER YEAR",
              title: `${checkReq.data.yearlyInput} XDEN`,
            },
            {
              id: 6,
              upperTitle: "PUBLIC DIFFICULTY",
              title: `${checkReq.data.publicDifficulty}`,
            },
            {
              id: 7,
              upperTitle: "PUBLIC DIFFICULTY",
              title: `${checkReq.data.publicDifficulty} `,
            },
            {
              id: 8,
              upperTitle: "PROFIT PER DAY",
              title: `${checkReq.data.dailyInput} XDEN`,
            },
            {
              id: 9,
              upperTitle: "PROFIT PER MONTH",
              title: `${checkReq.data.monthlyInput} XDEN`,
            },
            {
              id: 10,
              upperTitle: "PROFIT PER YEAR",
              title: `${checkReq.data.yearlyInput} XDEN`,
            },
          ]);
        }
      } catch (err) {
        // setPriceAvailable(false);
        if (err.response) {
          switch (err.response.status) {
            case 404:
              console.log("404 Not found.");
              return;
            default:
              console.log("Something is temporarily wrong.");
              return;
          }
        }
      }
    };

    fetchDataPrivate();
    fetchDataPublic();
  }, []);

  return (
    <>
      {/* DESKTOP VERSION */}

      <div className="d-flex custom-cards-container d-none d-lg-flex">
        <div className="custom-first-card">
          <TransparentCard color="white" cardData={hidrCardsData[0]} />
        </div>
        <div className="w-80">
          <div className="d-flex transparent-cards-first-row">
            {hidrCardsData.map((cardItem) => {
              if (cardItem.id > 1 && cardItem.id <= 5) {
                return (
                  <div
                    key={cardItem.id}
                    className={`customColPading custom-transparent-card ${cardItem.id === 2 ? "padding-start-custom" : ""} ${
                      cardItem.id === 5 ? "padding-end-custom" : ""
                    } ${cardItem.id % 2 ? "padding-odd" : "padding-even"}`}
                  >
                    <>
                      <TransparentCard color="blue" cardData={cardItem} />
                    </>
                  </div>
                );
              }
            })}
          </div>
          <div className="d-flex transparent-cards-second-row">
            {hidrCardsData2.map((cardItem) => {
              if (cardItem.id > 6 && cardItem.id <= 11) {
                return (
                  <div
                    key={cardItem.id}
                    className={`customColPading custom-transparent-card ${cardItem.id === 7 ? "padding-start-custom" : ""} ${
                      cardItem.id === 10 ? "padding-end-custom" : ""
                    } ${cardItem.id % 2 ? "padding-even" : "padding-odd"}`}
                  >
                    <TransparentCard color="blue" cardData={cardItem} />
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>

      {/* MOBILE VERSION */}

      <div className="d-flex custom-cards-container d-flex d-lg-none">
        <div className="custom-first-card">
          <TransparentCard color="white" cardData={hidrCardsData[0]} />
        </div>
        <div className="w-80">
          <div className="d-flex align-items-center justify-content-between data-container gap-2 flex-wrap">
            {hidrCardsData.map((cardItem) => {
              if (cardItem.id > 1 && cardItem.id <= 5) {
                return (
                  <>
                    <div
                      key={cardItem.id}
                      className="private-container text-center d-flex align-items-center justify-content-between flex-column"
                    >
                      <div className="font-pnr uppertitle-text-size"> {cardItem.upperTitle}</div>
                      <div className="font-pnsb profit-text-size" style={{ color: " rgb(34, 170, 255)" }}>
                        {cardItem.title}
                      </div>
                    </div>
                  </>
                );
              }
            })}
          </div>
          <div className="d-flex align-items-center justify-content-between data-container gap-2 flex-wrap">
            {hidrCardsData2.map((cardItem) => {
              if (cardItem.id > 6 && cardItem.id <= 11) {
                return (
                  <>
                    <div
                      key={cardItem.id}
                      className="private-container text-center d-flex align-items-center justify-content-between flex-column"
                    >
                      <div className="font-pnr uppertitle-text-size"> {cardItem.upperTitle}</div>
                      <div className="font-pnsb profit-text-size" style={{ color: " rgb(34, 170, 255)" }}>
                        {cardItem.title}
                      </div>
                    </div>
                  </>
                );
              }
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default HidrCardsList;
