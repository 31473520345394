import React from "react";
// style
import "./Steps.scss";

import { Container, Row, Col } from "react-bootstrap";
//sections

import StepImg1 from "../../../assets/images/png/SupportMetamask/metamask-2.jpg";
import StepImg2 from "../../../assets/images/png/SupportMetamask/metamask-3.jpg";
import StepImg3 from "../../../assets/images/png/SupportMetamask/metamask-4.jpg";
import StepImg4 from "../../../assets/images/png/SupportMetamask/metamask-5.jpg";
import StepImg5 from "../../../assets/images/png/SupportMetamask/metamask-6.jpg";
import StepImg6 from "../../../assets/images/png/SupportMetamask/metamask-7.jpg";
import StepImg7 from "../../../assets/images/png/SupportMetamask/metamask-8.jpg";
import StepImg8 from "../../../assets/images/png/SupportMetamask/metamask-9.jpg";
import StepImg9 from "../../../assets/images/png/SupportMetamask/metamask-10.jpg";
import StepImg10 from "../../../assets/images/png/SupportMetamask/metamask-11.jpg";
import StepImg11 from "../../../assets/images/png/SupportMetamask/metamask-12.jpg";
import StepImg12 from "../../../assets/images/png/SupportMetamask/metamask-15.jpg";
import StepImg13 from "../../../assets/images/png/SupportMetamask/metamask-14.jpg";
import StepImg14 from "../../../assets/images/png/SupportMetamask/metamask-16.JPG";
import StepImg15 from "../../../assets/images/png/SupportMetamask/metamask-17.jpg";
// import { ReactComponent as WarningLogo } from "../../../assets/SupportMetamask/svg/Icon-material-warning.svg"
// import { Element } from "react-scroll";

const Steps = () => {
    return (

        <section id="metamask-support-steps-page" className="pt-5 page-section">
            <div className="container-main d-flex flex-column justify-content-start align-items-start">

                {/* <h2 className="font-size-32 font-pnb">
                    Vault
                </h2>
                <p className="font-size-18 paragraph-content font-pnr">
                    An app with sensitive security settings specially developed for configuration of user Private Keys, storing values and creating digital identities.
                </p> */}
                <h2 className="font-size-32 mb-5 font-pnb">
                    Steps to get started
                </h2>

                {/* STEPS */}
                <Row className="w-100  py-5 border-top">
                    <Col lg={6} className="d-flex content-col align-items-start justify-content-start px-0">
                        <div className="d-flex align-items-start justify-content-start purple-color font-size-80 me-3 line-height-11 font-pnb">1</div>
                        <div className="column-container d-flex flex-column line-height-11">
                            <span className="font-size-32 font-pnb">
                                Step 1
                            </span>
                            <span className="font-size-18 mt-4 font-pnr">
                                Open your browser and go to <span onClick={() => window.open("https://metamask.io/download/")} className="purple-color ext-link">https://metamask.io<br className="d-none" />/download/</span>
                            </span>
                        </div>
                    </Col>

                    <Col lg={6} className="mobile-col d-flex aling-items-center justify-content-end px-0">
                        <img src={StepImg1} alt="step 1 open vault" className="step-img img-fluid" />
                    </Col>
                </Row>
                <Row className="w-100 py-5 border-top">
                    <Col lg={6} className="d-flex content-col align-items-start justify-content-start px-0">
                        <div className="d-flex align-items-start justify-content-start purple-color font-size-80 me-3 line-height-11 font-pnb">2</div>
                        <div className="column-container d-flex flex-column line-height-11">
                            <span className="font-size-32 font-pnb">
                                Step 2
                            </span>
                            <span className="font-size-18 mt-4 font-pnr">
                                Select 'Firefox' and click on the 'Add to Firefox' button.
                            </span>
                            <span className="font-size-18 mt-4 font-pnr">
                                <span className="purple-color">Note:</span> Metamask is available for iOS and Android devices via AppStore and GooglePlay and also for Google Chrome browser.
                            </span>
                            {/* <span className="font-size-18 mt-4 font-pnr">
                                <WarningLogo /> In case of losing your Private Key or resetting your Vault password, your data will be safely retrieved.
                            </span> */}
                        </div>
                    </Col>

                    <Col lg={6} className="mobile-col d-flex aling-items-center justify-content-end px-0">
                        <img src={StepImg2} alt="step 1 open vault" className="step-img img-fluid" />
                    </Col>
                </Row>
                <Row className="w-100 py-5 border-top">
                    <Col lg={6} className="d-flex content-col align-items-start justify-content-start px-0">
                        <div className="d-flex align-items-start justify-content-start purple-color font-size-80 me-3 line-height-11 font-pnb">3</div>
                        <div className="column-container d-flex flex-column line-height-11">
                            <span className="font-size-32 font-pnb">
                                Step 3
                            </span>
                            <span className="font-size-18 mt-4 font-pnr">
                                Click on the Add to Firefox button and click on Add when the extension pop-up appears.
                            </span>
                        </div>
                    </Col>

                    <Col lg={6} className="mobile-col d-flex aling-items-center justify-content-end px-0">
                        <img src={StepImg3} alt="step 1 open vault" className="step-img img-fluid" />
                    </Col>
                </Row>
                <Row className="w-100 py-5 border-top">
                    <Col lg={6} className="d-flex content-col align-items-start justify-content-start px-0">
                        <div className="d-flex align-items-start justify-content-start purple-color font-size-80 me-3 line-height-11 font-pnb">4</div>
                        <div className="column-container d-flex flex-column line-height-11">
                            <span className="font-size-32 font-pnb">
                                Step 4
                            </span>
                            <span className="font-size-18 mt-4 font-pnr">
                                Your extension is now activated! Click on 'Get Started'.
                            </span>
                        </div>
                    </Col>

                    <Col lg={6} className="mobile-col d-flex aling-items-center justify-content-end px-0">
                        <img src={StepImg4} alt="step 1 open vault" className="step-img img-fluid" />
                    </Col>
                </Row>
                <Row className="w-100 py-5 border-top">
                    <Col lg={6} className="d-flex content-col align-items-start justify-content-start px-0">
                        <div className="d-flex align-items-start justify-content-start purple-color font-size-80 me-3 line-height-11 font-pnb">5</div>
                        <div className="column-container d-flex flex-column line-height-11">
                            <span className="font-size-32 font-pnb">
                                Step 5
                            </span>
                            <span className="font-size-18 mt-4 font-pnr">
                                a<span className="purple-color">)</span> If you have never used MetaMask before, click on 'Create a Wallet'.
                            </span>
                            <span className="font-size-18 mt-3 font-pnr">
                                b<span className="purple-color">)</span> If you already have a wallet, click on 'Import Wallet".
                            </span>
                        </div>
                    </Col>

                    <Col lg={6} className="mobile-col d-flex aling-items-center justify-content-end px-0">
                        <img src={StepImg5} alt="step 1 open vault" className="step-img img-fluid" />
                    </Col>
                </Row>
                <Row className="w-100 py-5 border-top">
                    <Col lg={6} className="d-flex content-col align-items-start justify-content-start px-0">
                        <div className="d-flex align-items-start justify-content-start purple-color font-size-80 me-3 line-height-11 font-pnb">6</div>
                        <div className="column-container d-flex flex-column line-height-11">
                            <span className="font-size-32 font-pnb">
                                Step 6
                            </span>
                            <span className="font-size-18 mt-4 font-pnr">
                                Select whether you would like to share some of your data with MetaMask or not. We recommend you select 'No Thanks'.
                            </span>
                        </div>
                    </Col>

                    <Col lg={6} className="mobile-col d-flex aling-items-center justify-content-end px-0">
                        <img src={StepImg6} alt="step 1 open vault" className="step-img img-fluid" />
                    </Col>
                </Row>
                <Row className="w-100 py-5 border-top">
                    <Col lg={6} className="d-flex content-col align-items-start justify-content-start px-0">
                        <div className="d-flex align-items-start justify-content-start purple-color font-size-80 me-3 line-height-11 font-pnb">7</div>
                        <div className="column-container d-flex flex-column line-height-11">
                            <span className="font-size-32 font-pnb">
                                Step 7
                            </span>
                            <span className="font-size-18 mt-4 font-pnr">
                                a<span className="purple-color">)</span> Configure your password
                            </span>
                            <span className="font-size-18 mt-3 font-pnr">
                                b<span className="purple-color">)</span> Select the complexity of the recovery phrase from the drop-down list and insert your secret words. Then, configure your password. Congratulations! You are already done!
                            </span>
                        </div>
                    </Col>

                    <Col lg={6} className="mobile-col d-flex aling-items-center justify-content-end px-0">
                        <img src={StepImg7} alt="step 1 open vault" className="step-img img-fluid" />
                    </Col>
                </Row>
                <Row className="w-100 py-5 border-top">
                    <Col lg={6} className="d-flex content-col align-items-start justify-content-start px-0">
                        <div className="d-flex align-items-start justify-content-start purple-color font-size-80 me-3 line-height-11 font-pnb">8</div>
                        <div className="column-container d-flex flex-column line-height-11">
                            <span className="font-size-32 font-pnb">
                                Step 8
                            </span>
                            <span className="font-size-18 mt-4 font-pnr">
                                a<span className="purple-color">)</span> Watch the MetaMask tutorial to learn more about the secret recovery phrase (optional). When ready, click 'Next'.
                            </span>
                        </div>
                    </Col>

                    <Col lg={6} className="mobile-col d-flex aling-items-center justify-content-end px-0">
                        <img src={StepImg8} alt="step 1 open vault" className="step-img img-fluid" />
                    </Col>
                </Row>
                <Row className="w-100 py-5 border-top">
                    <Col lg={6} className="d-flex content-col align-items-start justify-content-start px-0">
                        <div className="d-flex align-items-start justify-content-start purple-color font-size-80 me-3 line-height-11 font-pnb">9</div>
                        <div className="column-container d-flex flex-column line-height-11">
                            <span className="font-size-32 font-pnb">
                                Step 9
                            </span>
                            <span className="font-size-18 mt-4 font-pnr">
                                a<span className="purple-color">)</span> Save your secret recovery phrase in a secure manner. Take note of the Tips and Warnings provided by MetaMask in order to ensure the security of your digital assets.
                            </span>
                        </div>
                    </Col>

                    <Col lg={6} className="mobile-col d-flex aling-items-center justify-content-end px-0">
                        <img src={StepImg9} alt="step 1 open vault" className="step-img img-fluid" />
                    </Col>
                </Row>
                <Row className="w-100 py-5 border-top">
                    <Col lg={6} className="d-flex content-col align-items-start justify-content-start px-0">
                        <div className="d-flex align-items-start justify-content-start purple-color font-size-80 me-3 line-height-11 font-pnb">10</div>
                        <div className="column-container d-flex flex-column line-height-11">
                            <span className="font-size-32 font-pnb">
                                Step 10
                            </span>
                            <span className="font-size-18 mt-4 font-pnr">
                                a<span className="purple-color">)</span> Select each word in the right order (reference your saved note). Click on 'Confirm'.
                            </span>
                        </div>
                    </Col>

                    <Col lg={6} className="mobile-col d-flex aling-items-center justify-content-end px-0">
                        <img src={StepImg10} alt="step 1 open vault" className="step-img img-fluid" />
                    </Col>
                </Row>
                <Row className="w-100 py-5 border-top">
                    <Col lg={6} className="d-flex content-col align-items-start justify-content-start px-0">
                        <div className="d-flex align-items-start justify-content-start purple-color font-size-80 me-3 line-height-11 font-pnb">11</div>
                        <div className="column-container d-flex flex-column line-height-11">
                            <span className="font-size-32 font-pnb">
                                Step 11
                            </span>
                            <span className="font-size-18 mt-4 font-pnr">
                                Press on "Ethereum Mainnet"  and select "Add Network".
                            </span>
                        </div>
                    </Col>

                    <Col lg={6} className="mobile-col d-flex aling-items-center justify-content-end px-0">
                        <img src={StepImg12} alt="step 1 open vault" className="step-img img-fluid" />
                    </Col>
                </Row>
                <Row className="w-100 py-5 border-top">
                    <Col lg={6} className="d-flex content-col align-items-start justify-content-start px-0">
                        <div className="d-flex align-items-start justify-content-start purple-color font-size-80 me-3 line-height-11 font-pnb">12</div>
                        <div className="column-container d-flex flex-column line-height-11">
                            <span className="font-size-32 font-pnb">
                                Step 12
                            </span>
                            <span className="font-size-18 mt-4 font-pnr">
                                In the next tab complete with the following information:<br />
                            </span>
                            <span className="font-size-18 mt-2 font-pnr">
                                <span className="purple-color"> •</span> Network name<span className="purple-color">:</span> Xiden <br />
                                <span className="purple-color"> •</span> New RPC URL<span className="purple-color">:</span> https://rpc.xiden.com <br />
                                <span className="purple-color"> •</span> Chain ID<span className="purple-color">:</span> 5381 <br />
                                <span className="purple-color"> •</span> Currency Symbol<span className="purple-color">:</span> XDEN <br />
                            </span>
                            <span className="font-size-18 mt-2 font-pnr">
                                Make sure that the informations are correct and press 'Save'.
                            </span>
                        </div>
                    </Col>

                    <Col lg={6} className="mobile-col d-flex aling-items-center justify-content-end px-0">
                        <img src={StepImg13} alt="step 1 open vault" className="step-img img-fluid" />
                    </Col>
                </Row>
                <Row className="w-100 py-5 border-top">
                    <Col lg={6} className="d-flex content-col align-items-start justify-content-start px-0">
                        <div className="d-flex align-items-start justify-content-start purple-color font-size-80 me-3 line-height-11 font-pnb">13</div>
                        <div className="column-container d-flex flex-column line-height-11">
                            <span className="font-size-32 font-pnb">
                                Step 13
                            </span>
                            <span className="font-size-18 mt-4 font-pnr">
                                Press "Import Tokens"
                            </span>
                        </div>
                    </Col>

                    <Col lg={6} className="mobile-col d-flex aling-items-center justify-content-end px-0">
                        <img src={StepImg14} alt="step 1 open vault" className="step-img img-fluid" />
                    </Col>
                </Row>
                {/* <Row className="w-100 py-5 border-top">
                    <Col lg={6} className="d-flex content-col align-items-start justify-content-start px-0">
                        <div className="d-flex align-items-start justify-content-start purple-color font-size-80 me-3 line-height-11 font-pnb">14</div>
                        <div className="column-container d-flex flex-column line-height-11">
                            <span className="font-size-32 font-pnb">
                                Step 14
                            </span>
                            <span className="font-size-18 mt-4 font-pnr">
                                In the next tab complete "Token Contract Address" with the following adress:
                                <input type="text" disabled className="my-3 w-100 p-2" value="0x9f682C94fCc7cBc5408d72C21B90E220242b5591"
                                    style={{ backgroundColor: "#2B0966", color: "white", border: "none" }} />
                                - This will allow you to see your XDEN that you can redeem when activating a Pool the first time.<br />
                            </span>
                            <span className="font-size-18 mt-4 font-pnr">And press "Add custom token".</span>
                        </div>
                    </Col>

                    <Col lg={6} className="mobile-col d-flex aling-items-center justify-content-end px-0">
                        <img src={StepImg15} alt="step 1 open vault" className="step-img img-fluid" />
                    </Col>
                </Row> */}
                <Row className="w-100 py-5 border-top-bottom">
                    <Col lg={6} className="d-flex content-col align-items-start justify-content-start px-0">
                        <div className="column-container d-flex flex-column line-height-11">
                            <span className="font-size-32 font-pnb">
                                Final step
                            </span>
                            <span className="font-size-20 mt-5 font-pnr">
                                Congratulations<span className="purple-color">!</span> You are all done<span className="purple-color">!</span>
                            </span>
                        </div>
                    </Col>

                    <Col lg={6} className="mobile-col d-flex aling-items-center justify-content-end px-0">
                        {/* <img src={StepImg11} alt="step 1 open vault" className="step-img img-fluid"/> */}
                    </Col>
                </Row>
            </div>
        </section>
    );
};

export default Steps;
