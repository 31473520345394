import React from "react";
import "./Ecosystem.scss";
// import NavBar from "../../components/Navbar/NavBar";
import MainView from "./MainView/MainView";
import Howit from "./Howit/Howit";
import XidenFeatures from "./XidenFeatures/XidenFeatures";
import Blockchain from "./Blockchain/Blockchain";
import Sector from "./Sector/Sector";
import Consensus from "./Consensus/Consensus";
import EcoSection from "./EcoSection/EcoSection";
import CorneredCards from "../OLD_Ecosystem/CorneredCards/CorneredCards";
import RewardsSection from "./RewardsSection/RewardsSection";
import Benefits from "../OLD_Ecosystem/Benefits/Benefits";
import Miners from "./Miners/Miners";
import { Helmet } from "react-helmet";
import SchemaTemplate from "../../SEO/SchemaTemplate.json";

export default function Xiden() {
   return (
      <div className="xiden-page">
         {/* seo */}
         <Helmet>
            <title>Xiden | Ecosystem</title>
            <meta name="title" content="Xiden | Ecosystem" />
            <meta name="description" content="Discover the features of the Xiden decentralized network providing free, unrestricted, and permanent internet connection regardless of user location or device." />
            <link rel="canonical" href="" />
            {/* Open Graph Facebook */}
            <meta property="og:title" content="Xiden | Ecosystem" />
            <meta property="og:description" content="Discover the features of the Xiden decentralized network providing free, unrestricted, and permanent internet connection regardless of user location or device." />
            <meta property="og:type" content="website" />
            <meta property="og:image" content="" />
            <meta property="og:url" content="" />
            {/* Open Graph Twitter  */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="" />
            <meta property="twitter:title" content="Xiden | Ecosystem" />
            <meta property="twitter:description" content="Discover the features of the Xiden decentralized network providing free, unrestricted, and permanent internet connection regardless of user location or device." />
            <meta property="twitter:image" content="" />
            {/* Structured Data */}
            <script>{JSON.stringify(SchemaTemplate)}</script>
         </Helmet>

         {/* sections */}
         <MainView />
         <Blockchain />
         <Consensus />
         <Howit />
         <EcoSection />
         <CorneredCards />
         <Miners />
         <RewardsSection />
         <XidenFeatures />
         <Benefits />
         <Sector />
      </div>
   );
}
