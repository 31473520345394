import React from "react";
import "../GuardianFeatures.scss";
import Slider from "../../../../components/Slider/Slider";
import GuardianFeaturesCard from "./GuardianFeaturesCard";
import { guardianFeaturesData } from "./guardianFeaturesData";

const GuardianFeaturesMobile = () => {
  return (
    <div className="d-flex d-lg-none">
      <div className="w-100 guardian-features-slider">
        <Slider>
          {guardianFeaturesData.map((card, index) => {
            return (
              <GuardianFeaturesCard
                key={index}
                uppertitle={card.uppertitle}
                description={card.description}
                svgProp={card.svgProp}
                bg={card.bg}
                getStarted={card.getStarted}
              />
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default GuardianFeaturesMobile;
