import React from "react";
import { Col, Row } from "react-bootstrap";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import "./HeroSection.scss";
import HeroImg from "../../../assets/images/png/meta-realm-page/realm-hero-img.png";
import MetaRealmVideo from "../../../assets/videos/metarealm-page/meta-realm-video.mp4";
import { AiOutlineArrowRight as ArrowRight } from "react-icons/ai";
import { FiArrowUpRight as ArrowRightUp } from "react-icons/fi";
import { toast } from "react-toastify";

const HeroSection = () => {
  return (
    <section id="meta-realm-hero" className="page-section">
      <div className="container-main d-flex">
        <Row className="flex-lg-row flex-column-reverse">
          <Col className="d-flex justify-content-center align-items-center " lg={5}>
            <div className="pe-lg-0 pe-4">
              <div className="metarealm-uppertitle-container">
                <Uppertitle title={"MINTER"} />
              </div>
              <h2 className="font-size-80 white-color font-pnb line-height-11 mb-0 text-center-991">
                Meta Realm
                <span className="purple-color">.</span>
              </h2>

              <p className="font-family-pnr font-size-24 mb-0 lightgrey-color line-height-11 py-lg-5 py-4 description-991 text-center-991">
                Every active validator node is rewarded by the Xiden Network based on jobs collected and validated in order to
                ensure the availability and the functionality of the system.
              </p>

              <div className="buttons-flex-direction d-flex justify-content-start align-items-center ">
                <button className="buttonBlue" onClick={() => toast.info("Coming soon...", { toastId: "meta-realm-buy" })}>
                  <span className="me-2">Buy realm</span>
                  <ArrowRight />
                </button>

                <button
                  className="buttonOutlineBlue"
                  onClick={() => toast.info("Coming soon...", { toastId: "meta-realm-read" })}
                >
                  <span className="me-2">Read more</span>
                  <ArrowRightUp />
                </button>
              </div>
            </div>
          </Col>
          <Col lg={7} className="d-flex justify-content-start align-items-center">
            {/* <img src={HeroImg} alt="hero-img" className="hero-img ps-lg-5 ps-0" /> */}
            <video
              src={MetaRealmVideo}
              autoPlay
              playsInline
              muted
              loop={true}
              className="hero-img meta-hero-video ps-lg-5 ps-0"
            />
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default HeroSection;
