import React, { useEffect, useState } from "react";
import axios from "axios";
import "./HeroSection.scss";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import { ReactComponent as ArrowRight } from "../../../assets//images/svg/arrow-right.svg";
import { ReactComponent as ArrowRightUp } from "../../../assets//images/svg/arrow-right-up.svg";
import XidCoinImage from "../../../assets/images/png/XID-features-page/XID-hero-coin.png";
import { toast } from "react-toastify";
import { Link as ScrollLink } from "react-scroll";
import MyCountdown from "react-countdown";
import CountdownService from "../../Homepage/Countdown/CountdownService";
// import LightPaper from "../../../assets/documents/WhitePaper-XIDEN BlockchainV1.1.pdf";
import loop1 from "../../../assets/videos/XDEN/animatie_token_1.mp4";
import { Accordion } from "react-bootstrap";
import { ReactComponent as Plus } from "../../../components/Assets/images/png/test-plus.svg";

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <CountdownService countdownData={{ days: 0, hours: 0, minutes: 0, seconds: 0 }} />;
  } else {
    return <CountdownService countdownData={{ days: days, hours: hours, minutes: minutes, seconds: seconds }} />;
  }
};

const HeroSection = () => {
  const [XDENPrice, setXDENPrice] = useState(() => "Loading...");
  const [totalSupply, setTotalSupply] = useState(() => "Loading...");
  // const [mintedData, setMintedData] = useState("Loading...");
  const [loading, setLoading] = useState(() => true);
  const [loadingTotalSupply, setLoadingTotalSupply] = useState(() => true);

  const [circulatingSupply, setCirculatingSupply] = useState("Loading...");

  const getCirculatingSupply = async () => {
    try {
      const checkReq = await axios.get("https://xiden.com/supply/");
      if (checkReq.status === 200) {
        setCirculatingSupply((+checkReq.data).toFixed(2));
      }
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 404:
            console.log("404 Not found.");
            return;
          default:
            console.log("Something is temporarily wrong.");
            return;
        }
      }
    } finally {
      setLoadingTotalSupply(false);
    }
  };

  const getPrice = async () => {
    try {
      const checkReq = await axios.get(`https://pools.xiden.com/api/price`);
      if (checkReq.status === 200) {
        setXDENPrice((+checkReq.data.price).toFixed(2) + " USDT");
      }
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 404:
            console.log("404 Not found.");
            return;
          default:
            console.log("Something is temporarily wrong.");
            return;
        }
      }
    } finally {
      setLoading(false);
    }
  };

  // const getTotalSupply = async () => {
  //   try {
  //     const checkReq = await axios.get(`https://explorer.xiden.com/api?module=stats&action=coinsupply`);
  //     if (checkReq.status === 200) {
  //       setTotalSupply((+checkReq.data).toFixed(2));
  //     }
  //   } catch (err) {
  //     if (err.response) {
  //       switch (err.response.status) {
  //         case 404:
  //           console.log("404 Not found.");
  //           return;
  //         default:
  //           console.log("Something is temporarily wrong.");
  //           return;
  //       }
  //     }
  //   } finally {
  //     setLoadingTotalSupply(false);
  //   }
  // };

  useEffect(async () => {
    await getPrice();
    // await getTotalSupply();
    await getCirculatingSupply();
  }, []);

  return (
    <section id="xiden-features-hero">
      <div className="page-section xid-hero">
        <div className="container-main">
          <div className="main-col">
            <div className="xden-uppertitle-container">
              <Uppertitle title={`XDEN`} />
            </div>
            <h2 className="mb-0 font-size-80 white-color font-pnb">Digital</h2>
            <h2 className="mb-0 font-size-80 white-color font-pnb">Transferable</h2>
            <h2 className="font-size-80 white-color font-pnb">
              Asset
              <span className="purple-color">.</span>
            </h2>
            <div className="my-4 paragraph-container">
              <p className="font-family-pnr font-size-24 lightgrey-color">
                The asset that defines the value of the apps, resources and services from the blockchain.
              </p>
            </div>
            <div className="hero-btn-container">
              <a className="buttonBlue me-0" href={"https://xiden.com/whitepaper/"} target="_blank">
                <span className="me-2">Whitepaper</span>
                <ArrowRightUp />
              </a>

              {/* <ScrollLink className="buttonOutlineBlue" to="xid-page-features" spy={true}>
                        <span className="me-2">Get started</span>
                        <ArrowRight />
                     </ScrollLink> */}
            </div>
          </div>

          <div className="section-image-col">
            {/* <img src={XidCoinImage} loading="lazy" alt="xid coin" className="section-image" /> */}
            <video src={loop1} autoPlay playsInline muted loop={true} className="section-image" />
          </div>
        </div>

        <div className="banner  d-none d-lg-flex mt-5">
          {/* <div className="banner-overlay font-size-48"><MyCountdown date={new Date(2022, 2, 30, 0, 0, 0)} renderer={renderer} /></div> */}
          <div className="banner-column d-flex justify-content-between w-33">
            <div className="banner-item">
              <div className="d-flex flex-column font-size-20 font-pnl">
                <span>XDEN</span>
                <span>price</span>
              </div>

              <span className="font-pnb number-span">{loading ? "Loading..." : XDENPrice}</span>
            </div>

            {/* <div className="banner-item">
                     <div className="d-flex flex-column font-size-20 font-pnl">
                        <span>Staking</span>
                        <span>supply</span>
                     </div>

                     <span className="font-pnb number-span">123.485</span>
                  </div> */}
          </div>

          <div className="banner-column d-flex justify-content-between w-33">
            <div className="banner-item">
              <div className="d-flex flex-column font-size-20 font-pnl">
                <span>Circulating</span>
                <span>supply</span>
              </div>

              <span className="font-pnb number-span">{loadingTotalSupply ? "Loading..." : circulatingSupply + " XDEN"}</span>
            </div>
          </div>
          <div className="banner-column d-flex justify-content-between w-33">
            <div className="banner-item">
              <div className="d-flex flex-column font-size-20 font-pnl">
                <span>Total</span>
                <span>supply</span>
              </div>

              {/* <span className="font-pnb number-span">{loadingTotalSupply ? "Loading..." : totalSupply + " XDEN"}</span> */}
              <span className="font-pnb number-span">300M XDEN</span>
            </div>
          </div>
        </div>

        <div className="banners-mobile banner mt-5 mb-1 d-flex d-lg-none">
          <Accordion defaultActiveKey={0}>
            <Accordion.Item eventKey={0} key={0}>
              <Accordion.Header>
                <div className="banner-column d-flex justify-content-between w-100">
                  <div className="banner-item">
                    <div className="d-flex flex-column font-size-20 font-pnl">
                      <span>XDEN</span>
                      <span>price</span>
                    </div>
                    <div className="acordeon-plus d-flex align-items-center justicy-content-center">
                      <span className="font-pnb number-span">{loading ? "Loading..." : XDENPrice}</span>
                      <Plus className="button-transition ms-3" />
                    </div>
                  </div>
                </div>
              </Accordion.Header>

              <Accordion.Body>
                <div className="banner-column d-flex justify-content-between w-100">
                  <div className="banner-item">
                    <div className="d-flex flex-column font-size-20 font-pnl">
                      <span>Circulating</span>
                      <span>supply</span>
                    </div>

                    <span className="font-pnb number-span">
                      {loadingTotalSupply ? "Loading..." : circulatingSupply + " XDEN"}
                    </span>
                  </div>
                </div>
                <div className="banner-column d-flex justify-content-between w-100">
                  <div className="banner-item">
                    <div className="d-flex flex-column font-size-20 font-pnl">
                      <span>Total</span>
                      <span>supply</span>
                    </div>

                    {/* <span className="font-pnb number-span">{loadingTotalSupply ? "Loading..." : totalSupply + " XDEN"}</span> */}
                    <span className="font-pnb number-span">300M XDEN</span>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
