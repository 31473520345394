import React, { useState, createContext, useEffect } from "react";
import "./WhatIsXiden.scss";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
// import { AiOutlineArrowRight as ArrowRight } from "react-icons/ai";
import { FiArrowUpRight as ArrowRightUp } from "react-icons/fi";
// import BlueCubes from "../../../assets/images/png/Graphic-Fees-Blue.png";
// import SlickGreyCubes from "../../../assets/images/png/slick-grey-cubes.png";
import SlickGreyCubes from "../../../assets/images/png/router-icon.png";
import ServicesSlider from "./ServicesSlider";
// import HomePageCoin from "../../../assets/images/png/homepage-coin.png";
import HomePageCoin from "../../../assets/images/png/homePage/homepage-slider-img-coin.png";
import HomePageCubes from "../../../assets/images/png/homePage/homepage-slider-img-blockchain.png";
import SpectralisImg from "../../../assets/images/png/homePage/spectralis-img.png";
import GNodeSliderImg from "../../../assets/images/png/homePage/guardian-node-slider.png";
import ValidatorImg from "../../../assets/images/png/homePage/validators-slider.png";
import SdrImg from "../../../assets/images/png/homePage/sdr-slider.png";
import RouterImg from "../../../assets/images/png/validator-router.png";
import NewRouter from "../../../assets/images/png/homePage/new-validator-asset.png";
// import { Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../Utils/routes";

const contentContext = createContext();

//TODO: change SDR Link in slider din string in obiect

const WhatIsXiden = () => {
   const sliderData = [
      {
         id: 1,
         image: <img src={HomePageCubes} loading="lazy" alt="blockchain representation" className="slide-image blockchain-img" />,
         title: "Blockchain",
      },
      {
         id: 2,
         image: <img src={SpectralisImg} loading="lazy" alt="Hotspot" className="slide-image hotspot-image" />,
         title: "Spectralis",
      },
      {
         id: 3,
         image: <img src={HomePageCoin} loading="lazy" alt="XID coin" className="slide-image coin-image" />,
         title: "XDEN",
      },
      {
         id: 4,
         image: <img src={SdrImg} alt="service" className="slide-image sdr-img" />,
         title: "SDR",
      },
      {
         id: 5,
         image: <img src={RouterImg} alt="service" className="slide-image hotspot-image router-image" />,
         title: "Guardian Node",
      },
      {
         id: 6,
         image: <img src={NewRouter} alt="service" className="slide-image new-validator " />,
         title: "Validator",
      },
   ];

   const contentData = [
      {
         uperTitle: "XIDEN",
         title: "Introducing Xiden",
         punctuation: ".",
         paragraph: `A decentralized network that provides users with the opportunity to have a free, unrestricted, and permanent internet connection regardless of user location or device. By decentralizing the Internet, the user is no longer conditioned by affiliation or association with an internet service provider, no longer has limited access to certain parts of the Internet, and its identity will be permanently protected and secured.`,
         buttonBlue: (
            <button className="buttonBlue" onClick={() => navigate(routes.xiden)}>
               <span className="me-2">Read more</span>
               <ArrowRightUp />
            </button>
         ),
      },
      {
         uperTitle: "XIDEN",
         title: "Spectralis",
         punctuation: ".",
         paragraph: `A user-owned private network that combines elements of technologies such as TOR, VPN, and VOBP to secure connections, traffic, communication sessions, data transfers, and devices.`,
         buttonBlue: (
            <button className="buttonBlue" onClick={() => navigate(routes.privateNetwork)}>
               <span className="me-2">Read more</span>
               <ArrowRightUp />
            </button>
         ),
      },
      {
         uperTitle: "XIDEN",
         title: "Introducing XDEN",
         punctuation: ".",
         paragraph: `The Xiden Blockchain uses XDEN Digital Transferable Asset to ensure operational efficiency. XDEN is the measurement unit in which the devices’ ‘work’ or ‘service’ is quantified. The network’s members will receive XDEN for making their resources available based on the online time of their devices.`,
         buttonBlue: (
            <button className="buttonBlue" onClick={() => navigate(routes.xidenFeatures)}>
               <span className="me-2">Read more</span>
               <ArrowRightUp />
            </button>
         ),
      },
      {
         uperTitle: "XIDEN",
         title: "Smart Distributed Resources (SDR)",
         punctuation: ".",
         paragraph: `SDR is a utility layer integrated into the Xiden blockchain that collects all the resources distributed by the network’s registered devices and makes them available for all users, in a decentralized manner, according to their needs.`,
         buttonBlue: (
            <button className="buttonBlue" onClick={() => navigate("/sdr-layer")}>
               <span className="me-2">Read more</span>
               <ArrowRightUp />
            </button>
         ),
      },
      {
         uperTitle: "XIDEN",
         title: "Minter Guardian",
         punctuation: ".",
         paragraph: `A light validation node that verifies the conformity of both data and the system’s integrated devices, contributing to the network’s operation and providing resources for decentralized computing and storage power. It uses Delegated Staking and it allows miners to register devices in a Krater Pool to increase their total validating power.`,
         buttonBlue: (
            <button className="buttonBlue" onClick={() => navigate(routes.guardianNode)}>
               <span className="me-2">Read more</span>
               <ArrowRightUp />
            </button>
         ),
      },
      {
         uperTitle: "XIDEN",
         title: "Validator",
         punctuation: ".",
         paragraph: `CryptoDATA devices have validator roles assigned in the Xiden consensus. Also every validator can be connected to the SDR layer through the KrateR Pool and will distribute their resources with the network.`,
         buttonBlue: (
            <button className="buttonBlue" onClick={() => navigate(routes.validator)}>
               <span className="me-2">Read more</span>
               <ArrowRightUp />
            </button>
         ),
      },
   ];

   const navigate = useNavigate();

   const [contentIndex, setContentIndex] = useState(0);

   const [animate, setAnimate] = useState(false);

   const value = { contentIndex, setContentIndex }; //pentru context provider ca sa pot schimba indexul din slider

   const [displayData, setDisplayData] = useState(contentData[contentIndex]);

   useEffect(() => {
      setDisplayData(contentData[contentIndex]);
      setAnimate(true);
      setTimeout(() => setAnimate(false), 500);
   }, [contentIndex]); //aici fac rerender in parent pe baza modificarii indexului din child

   return (
      <contentContext.Provider value={value}>
         <section id="what-is-xiden" className="page-section what-section">
            <div className="container-main what-contianer">
               <div className="row">
                  <div className="col-lg-6 col-12 order-lg-1 order-2 d-flex flex-column justify-content-center align-items-start">
                     <Uppertitle title={displayData.uperTitle} />
                     <h2 className="mb-4 font-size-80 white-color font-pnb">
                        {displayData.title}
                        <span className="purple-color">{displayData.punctuation}</span>
                     </h2>
                     <div className={`description description-991 ${animate && "apply-effect"}`}>
                        <p className="font-family-pnr font-size-24 lightgrey-color line-height-11">
                           {displayData.paragraph}
                        </p>
                     </div>

                     <div
                        className={`buttons-flex-direction d-flex justify-content-start align-items-start pt-lg-5 pt-4 ${animate && "apply-effect"
                           }`}
                     >
                        {displayData.buttonBlue}

                        {/* <button className="buttonOutlineBlue">
                     <span className="me-2">See video</span>
                     <ArrowRight />
                  </button> */}
                     </div>
                  </div>

                  {/* <ServicesSlider data={sliderData} /> */}
                  <div className="col-lg-6 col-12 order-lg-2 order-1 position-relative slider-col">
                     <div className="pos-absolute-slider">
                        <ServicesSlider data={sliderData} />
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </contentContext.Provider>
   );
};

export { contentContext };
export default WhatIsXiden;
