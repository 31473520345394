import Step1 from "../../SupportKraterInstallation/SupportKraterInstallationSteps/assets-steps/HidR/step1.png";
import Step2 from "../../SupportKraterInstallation/SupportKraterInstallationSteps/assets-steps/HidR/step2.png";
import Step3 from "../../SupportKraterInstallation/SupportKraterInstallationSteps/assets-steps/HidR/step3.png";
import Step4 from "../../SupportKraterInstallation/SupportKraterInstallationSteps/assets-steps/HidR/step4.png";
import Step5 from "../../SupportKraterInstallation/SupportKraterInstallationSteps/assets-steps/HidR/step5.png";
import Step6 from "../../SupportKraterInstallation/SupportKraterInstallationSteps/assets-steps/HidR/step6.png";
import Step7 from "../../SupportKraterInstallation/SupportKraterInstallationSteps/assets-steps/HidR/step7.png";
import { ReactComponent as Lock } from "./assets/lock.svg";
import "./hidrData.scss";
import { useNavigate } from "react-router-dom";

export const accessHidrSteps = [
  {
    uppertitle: "STEP 01",
    title: "Configure your HidR",
    punctuation: ".",
    content: (
      <>
        <p className="font-pnr font-size-20 lightgrey-color line-height-11 text-start scroll-p-krater">
          Follow the instructions available on the HidR configuration page:
        </p>
        <div className="cryptodata-setup d-flex align-items-center justify-content-center flex-row">
          <Lock className="me-2 lock-svg-krater" />
          <a href="https://cryptodata.com/setup-hidr" target="_blank" className="crypto-setup-hidr-link font-pnr font-size-16">
            {" "}
            https://cryptodata.com/setup-hidr
          </a>
        </div>
      </>
    ),
    image: Step1,
    modal: true,
  },
  {
    uppertitle: "STEP 02",
    title: "Access HidR’s secure Play Store",
    punctuation: ".",
    content: (
      <>
        <p className="font-pnr font-size-20 lightgrey-color line-height-11 text-start">
          On your HidR’s lock screen tap on secure Play Store icon.
        </p>
      </>
    ),
    image: Step2,
    modal: true,
  },
  {
    uppertitle: "STEP 03",
    title: "Locate Krater Validator app",
    punctuation: ".",
    content: (
      <>
        <p className="font-pnr font-size-20 lightgrey-color line-height-11 text-start">
          Locate and Tap on the Krater Validator app to open the installer
        </p>
        <br />
      </>
    ),
    image: Step3,
    modal: true,
  },
  {
    uppertitle: "STEP 04",
    title: "Install Krater Validator app",
    punctuation: ".",
    content: (
      <>
        <p className="font-pnr font-size-20 lightgrey-color line-height-11 text-start">Tap on the Install button to start the installer.</p>
      </>
    ),
    image: Step4,
    modal: true,
  },
  {
    uppertitle: "STEP 05",
    title: "Open Krater Validator app",
    punctuation: ".",
    content: (
      <>
        <p className="font-pnr font-size-20 lightgrey-color line-height-11 text-start">
          Locate and Tap on the Krater Validator app on your lock screen
        </p>
      </>
    ),
    image: Step5,
    modal: true,
  },
  {
    uppertitle: "STEP 06",
    title: "Link validator with KrateR Pool",
    punctuation: ".",
    content: (
      <>
        <p className="font-pnr font-size-20 lightgrey-color line-height-11 text-start">
          To successfully add your miner, open the “Mining” app pre-installed on your HiDR or BASM devices, insert the 12 mnemonic words in
          the exact sequence, and click on “Link” to successfully complete the process.
        </p>
        <br></br>
        <a
          href="https://xiden.com/support-validator"
          target="_blank"
          className="setup-validator-link font-pnr font-size-20 lightgrey-color line-height-11 text-start"
        >
          Read how to <span className="setup-validator-color">setup your validator</span>.
        </a>
      </>
    ),
    image: Step6,
    modal: true,
  },
  {
    uppertitle: "STEP 07",
    title: "Activate your HidR Validator",
    punctuation: ".",
    content: (
      <>
        <p className="font-pnr font-size-20 lightgrey-color line-height-11 text-start">
          Press the ON/OFF button to change your HidR Validator <br />
          status from <span className="inactive-color-krater-steps">Inactive</span> to{" "}
          <span className="active-color-krater-steps">Active.</span>
        </p>
      </>
    ),
    image: Step7,
    modal: true,
  },
];
