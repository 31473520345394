import SupportSteps from './components/SupportStakeSteps/SupportSteps'
import heroImg from './assets/hero.png'

import { Col, Row } from 'react-bootstrap'

import './SupportUnstake.scss'
const SupportUnstake = () => {
  return (
    <section className='support-unstake'>
      <div className='hero-support-unstake container'>
        <div className='row'>
          <div className='col-md-6 h-100 my-auto'>
            <div className='d-flex align-items-center justify-content-center'>
              <h4 className='font-size-64 font-pnb line-height-11 text-white'>
                How to Unstake <span className='purple-color'>?</span>
              </h4>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='d-flex justify-content-center align-items-center'>
              <img src={heroImg} alt='' className='img-fluid' />
            </div>
          </div>
        </div>
      </div>

      <SupportSteps />
    </section>
  )
}
export default SupportUnstake
