import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import "./RewardCalculatorLong.scss";
import ImpulseIcon from "../../assets/images/svg/hidr-ico.svg";
import MinterIcon from "../../assets/images/svg/minter-guardian-icon.svg";
import BasmIcon from "../../assets/images/svg/basm-ico.svg";
import CustomToggleButton from "../ToggleButton/CustomToggleButton";
import { ReactComponent as FireIcon } from "../../assets/images/svg/fire.svg";
import { Button, Overlay, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ReactComponent as ArrowRight } from "../../assets//images/svg/arrow-right.svg";
import { ReactComponent as RefreshLogo } from "../../assets//images/svg/refresh-ico.svg";
import { ReactComponent as ArrowUp } from "../../assets/images/svg/calculator-arrow-up.svg";
import { ReactComponent as ArrowDown } from "../../assets/images/svg/calculator-arrow-down.svg";
import { RiErrorWarningLine as WarningSign } from "react-icons/ri";
import { toast } from "react-toastify";

const RewardCalculatorLong = () => {
  const [toggleDifficulty, setToggleDifficulty] = useState(() => false);
  const [warningTooltipMessage, setWarningTooltipMessage] = useState("");

  const target = useRef(null);

  const [showTooltip, setShowTooltip] = useState(false);

  const [refreshDisable, setRefreshDisable] = useState(false);

  const [showMinterOverlay, setShowMinterOverlay] = useState(() => false);
  const [showBasmOverlay, setShowBasmOverlay] = useState(() => false);
  const [showHidrOverlay, setShowHidrOverlay] = useState(() => false);
  const [showDevicesOverlay, setShowDevicesOverlay] = useState(() => false);
  const [showRewards, setShowRewards] = useState(() => false);

  const [privateDifficulty, setPrivateDifficulty] = useState(
    () => 0
  );
  const [publicDifficulty, setPublicDifficulty] = useState(
    () => 0
  );

  const handleToggleDifficulty = () => setToggleDifficulty(!toggleDifficulty);

  const [devicesInput, setDevicesInput] = useState(() => 0);
  const [minterInput, setMinterInput] = useState(() => 0);
  const [basmInput, setBasmInput] = useState(() => 0);
  const [hidrInput, setHidrInput] = useState(() => 0);
  const [computingInput, setComputingInput] = useState(() => "");
  const [computingDevicesInput, setComputingDevicesInput] = useState(() => "");
  const [computingTotalInput, setComputingTotalInput] = useState(() => "");

  const [dailyInput, setDailyInput] = useState(() => "0 XDEN");
  const [weeklyInput, setWeeklyInput] = useState(() => "0 XDEN");
  const [monthlyInput, setMonthlyInput] = useState(() => "0 XDEN");

  const getPublicPrivateDifficulty = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "toggleDifficulty": toggleDifficulty,
      "size": 0,
      "basmInput": basmInput,
      "hidrInput": hidrInput,
      "minterInput": minterInput,
      "devicesInput": devicesInput
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    try {
      const checkReq = await axios.post('https://api.heimdall.ro/calculatorFunction', requestOptions);
      if (checkReq.status === 200) {

        setPrivateDifficulty(checkReq.data.privateDifficulty);
        setPublicDifficulty(checkReq.data.publicDifficulty);

      }
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 404:
            console.log("404 Not found.");
            return;
          default:
            console.log("Something is temporarily wrong.");
            return;
        }
      }
    }
  }
  const getPublicPrivateRewards = async (toggleDifficulty) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw ={
      toggleDifficulty: toggleDifficulty,
      size: 0,
      basmInput: parseInt(basmInput),
      hidrInput: parseInt(hidrInput),
      minterInput: parseInt(minterInput),
      activeDevices: parseInt(devicesInput)
    };

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    try {
      const checkReq = await axios.post('https://api.heimdall.ro/calculatorFunction', raw);
      if (checkReq.status === 200) {
        setDailyInput(checkReq.data.dailyInput)
        setWeeklyInput(checkReq.data.weeklyInput)
        setMonthlyInput(checkReq.data.monthlyInput)
      }
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 404:
            console.log("404 Not found.");
            return;
          default:
            console.log("Something is temporarily wrong.");
            return;
        }
      }
    }
  }

  useEffect(() => {
    getPublicPrivateDifficulty()
  }, [])


  const myCalculatorFunction = async () => {
    await getPublicPrivateRewards(toggleDifficulty);
  };

  const handleComputingDevices = () => {
    let result = Math.round(+devicesInput * 0.01 * computingInput * 100) / 100;
    setComputingDevicesInput(result);
  };

  const handleComputingTotal = () => {
    let result;
    result = Math.round((+computingInput + +computingDevicesInput) * 100) / 100;
    setComputingTotalInput(result);
  };

  const handleDevicesChange = () => {
    let result = +basmInput * 83 + +hidrInput * 54 + +minterInput * 49;
    setComputingInput(result);
  };

  useEffect(() => {
    if (minterInput === 0) setDevicesInput(0);
    handleDevicesChange();
    handleComputingDevices();
    handleComputingTotal();
  }, [
    toggleDifficulty,
    computingInput,
    basmInput,
    hidrInput,
    minterInput,
    devicesInput,
    computingDevicesInput,
    computingTotalInput,
  ]);

  const removeZeros = (myInput) => {
    if (+myInput !== 0) {
      let aux = myInput.toString();

      while (aux[0] === "0") {
        aux = aux.substring(1);
      }
      return aux.split(".")[0];
    } else {
      return myInput;
    }
  };

  const handleImpulse = (event) => {
    let aux = minterInput;
    let myMaxDevicesNumber = +basmInput + +hidrInput + +event.target.value;
    if (myMaxDevicesNumber > 10 || +event.target.value < 0) {
      setShowBasmOverlay(false);
      setShowHidrOverlay(false);
      setShowMinterOverlay(true);
      setShowDevicesOverlay(false);
      setWarningTooltipMessage(
        "You will exeed the maximum number of 10 devices!"
      );
      setMinterInput(aux);
      setTimeout(() => {
        setShowMinterOverlay(false);
      }, 3000);
    } else {
      setShowMinterOverlay(false);
      setMinterInput(removeZeros(+event.target.value).toString());
    }

    if (+event.target.value === 0) setDevicesInput(0);
  };

  const handleBasm = (event) => {
    let aux = basmInput;
    let myMaxDevicesNumber = +event.target.value + +hidrInput + +minterInput;

    if (inputValidator(myMaxDevicesNumber, +event.target.value)) {
      setShowBasmOverlay(true);
      setShowHidrOverlay(false);
      setShowMinterOverlay(false);
      setShowDevicesOverlay(false);
      setWarningTooltipMessage(
        "You will exeed the maximum number of 10 devices!"
      );
      setBasmInput(aux);
      setTimeout(() => {
        setShowBasmOverlay(false);
      }, 3000);
    } else {
      setShowBasmOverlay(false);
      setBasmInput(removeZeros(+event.target.value).toString());
    }
  };

  const inputValidator = (myMaxDevicesNumber, actualNumber) => {
    if (myMaxDevicesNumber > 10 || actualNumber < 0) return true;
    else return false;
  };

  const handleHidr = (event) => {
    let aux = hidrInput;
    let myMaxDevicesNumber = +basmInput + +event.target.value + +minterInput;

    if (inputValidator(myMaxDevicesNumber, +event.target.value)) {
      setShowBasmOverlay(false);
      setShowHidrOverlay(true);
      setShowMinterOverlay(false);
      setShowDevicesOverlay(false);
      setWarningTooltipMessage(
        "You will exeed the maximum number of 10 devices!"
      );
      setHidrInput(aux);
      setTimeout(() => {
        setShowHidrOverlay(false);
      }, 3000);
    } else {
      setShowHidrOverlay(false);
      setHidrInput(removeZeros(+event.target.value).toString());
    }
  };

  const handleConnectedDevices = (event) => {
    if (minterInput) {
      let aux = devicesInput;

      if (+event.target.value > 20 || +event.target.value < 0) {
        setShowDevicesOverlay(true);
        setShowBasmOverlay(false);
        setShowHidrOverlay(false);
        setShowMinterOverlay(false);
        setWarningTooltipMessage("You will exeed the maximum number of 20 devices!")
        setDevicesInput(aux);
        setTimeout(() => {
          setShowDevicesOverlay(false);
        }, 3000);
      } else {
        setShowDevicesOverlay(false);
        setDevicesInput(removeZeros(+event.target.value).toString());
      }
    } else {
      setDevicesInput(0);
    }
  };

  const handleDaily = (event) => setDailyInput(event.target.value);
  const handleWeekly = (event) => setWeeklyInput(event.target.value);
  const handleMonthly = (event) => setMonthlyInput(event.target.value);

  const handleArrows = (myInput, type, deviceType) => {
    let aux = +myInput;
    let myMaxDevicesNumber = +basmInput + +hidrInput + +minterInput;

    if (type === "plus") myMaxDevicesNumber++;
    if (type === "minus") myMaxDevicesNumber--;
    if (
      inputValidator(myMaxDevicesNumber, type === "plus" ? aux + 1 : aux - 1)
    ) {
      if (deviceType === "basm") {
        setShowBasmOverlay(true);
        setShowHidrOverlay(false);
        setShowMinterOverlay(false);
        setShowDevicesOverlay(false);
        setBasmInput(aux);
        setTimeout(() => {
          setShowBasmOverlay(false);
        }, 3000);
      }
      if (deviceType === "router") {
        setShowBasmOverlay(false);
        setShowHidrOverlay(false);
        setShowMinterOverlay(true);
        setShowDevicesOverlay(false);
        setMinterInput(aux);
        // if(+aux === 0) setDevicesInput(0)
        setTimeout(() => {
          setShowMinterOverlay(false);
        }, 3000);
      }
      if (deviceType === "hidr") {
        setShowBasmOverlay(false);
        setShowHidrOverlay(true);
        setShowMinterOverlay(false);
        setShowDevicesOverlay(false);
        setHidrInput(aux);
        setTimeout(() => {
          setShowHidrOverlay(false);
        }, 3000);
      }

      if (myMaxDevicesNumber === 11)
        setWarningTooltipMessage(
          "You will exeed the maximum number of 10 devices!"
        );
      if (type === "minus" && aux === 0)
        setWarningTooltipMessage("You can't go below 0 devices!");
    } else {
      if (type === "plus") {
        aux = aux + 1;
      }
      if (type === "minus") {
        aux = aux - 1;
      }
      if (deviceType === "basm") {
        setShowBasmOverlay(false);
        setBasmInput(removeZeros(aux).toString());
      }
      if (deviceType === "router") {
        setShowMinterOverlay(false);
        if (aux === 0) setDevicesInput(0);
        setMinterInput(removeZeros(aux).toString());
      }
      if (deviceType === "hidr") {
        setShowHidrOverlay(false);
        setHidrInput(removeZeros(aux).toString());
      }
    }
  };

  return (
    <div className="text-white reward-calculator-long position-relative">
      <div className="reward-calculator-content">
        <div className="px-xl-5 px-sm-4 px-3 pt-xl-5 pt-sm-4 pt-3 pb-4">
          <h4 className="mb-5 font-size-40 text-center font-pnb calc-heading">
            Calculate reward
          </h4>
          <h4 className="mb-4 font-size-24 font-pnb calc-heading">
            Validators for Krater Pool
          </h4>

          <div className="mb-4 w-100 d-flex justify-content-between middle-inputs">
            <div className="me-2 mi-flex-row d-flex flex-column">
              <div className="mb-2 mi-flex-row-div d-flex align-items-center">
                <img src={MinterIcon} alt="impulse" />
                <span className="ms-2 mi-flex-row-div-span font-size-24 font-pnr setting-item">
                  Router
                </span>
              </div>
              <OverlayTrigger
                show={showMinterOverlay}
                placement="bottom"
                overlay={
                  <Tooltip id="tooltip-disabled">
                    {warningTooltipMessage}
                  </Tooltip>
                }
              >
                <div className="custom-input-container position-relative">
                  <input
                    type="number"
                    step="1"
                    pattern="\d*"
                    className="calculateInputs with-arrows"
                    value={minterInput}
                    onChange={handleImpulse}
                  />
                  <div className="position-absolute arrows d-flex flex-column justify-content-center">
                    <ArrowUp
                      className="arrows-inputs mt-1"
                      onClick={() =>
                        handleArrows(minterInput, "plus", "router")
                      }
                    />
                    <ArrowDown
                      className="arrows-inputs mb-1"
                      onClick={() =>
                        handleArrows(minterInput, "minus", "router")
                      }
                    />
                  </div>
                </div>
              </OverlayTrigger>
            </div>

            <div className="me-2 mi-flex-row d-flex flex-column">
              <div className="mb-2 mi-flex-row-div d-flex align-items-center">
                <img src={BasmIcon} alt="impulse" />
                <span className="ms-2 mi-flex-row-div-span font-size-24 font-pnr setting-item">
                  BASM
                </span>
              </div>
              <OverlayTrigger
                show={showBasmOverlay}
                placement="bottom"
                overlay={
                  <Tooltip id="tooltip-disabled">
                    {warningTooltipMessage}
                  </Tooltip>
                }
              >
                <div className="custom-input-container position-relative">
                  <input
                    type="number"
                    className="calculateInputs with-arrows"
                    value={basmInput}
                    onChange={handleBasm}
                  />
                  <div className="position-absolute arrows d-flex flex-column justify-content-center">
                    <ArrowUp
                      className="arrows-inputs mt-1"
                      onClick={() => handleArrows(basmInput, "plus", "basm")}
                    />
                    <ArrowDown
                      className="arrows-inputs mb-1"
                      onClick={() => handleArrows(basmInput, "minus", "basm")}
                    />
                  </div>
                </div>
              </OverlayTrigger>
            </div>

            <div className="mi-flex-row d-flex flex-column">
              <div className="mb-2 mi-flex-row-div d-flex align-items-center">
                <img src={ImpulseIcon} alt="impulse" />
                <span className="ms-2 mi-flex-row-div-span font-size-24 font-pnr setting-item">
                  HidR
                </span>
              </div>
              <OverlayTrigger
                show={showHidrOverlay}
                placement="bottom"
                overlay={
                  <Tooltip id="tooltip-disabled">
                    {warningTooltipMessage}
                  </Tooltip>
                }
              >
                <div className="custom-input-container position-relative">
                  <input
                    type="number"
                    className="calculateInputs with-arrows"
                    value={hidrInput}
                    onChange={handleHidr}
                  />
                  <div className="position-absolute arrows d-flex flex-column justify-content-center">
                    <ArrowUp
                      className="arrows-inputs mt-1"
                      onClick={() => handleArrows(hidrInput, "plus", "hidr")}
                    />
                    <ArrowDown
                      className="arrows-inputs mb-1"
                      onClick={() => handleArrows(hidrInput, "minus", "hidr")}
                    />
                  </div>
                </div>
              </OverlayTrigger>
            </div>
          </div>

          <h4 className="mb-4 font-size-24 font-pnb calc-heading">
            Devices connected to Router
          </h4>
          <div className="mt-sm-4 mt-3 w-100 d-flex align-items-center justify-content-between">
            <span className="font-size-24 font-pnr setting-item">Devices</span>
            <OverlayTrigger
              show={showDevicesOverlay}
              placement="bottom"
              overlay={
                <Tooltip id="tooltip-disabled">{warningTooltipMessage}</Tooltip>
              }
            >
              <div className="custom-input-container position-relative">
                <input
                  type="number"
                  disabled={+minterInput === 0}
                  className="calculateInputs with-arrows"
                  value={devicesInput}
                  onChange={handleConnectedDevices}
                />
                <div className="position-absolute arrows d-flex flex-column justify-content-center">
                  <ArrowUp
                    className="arrows-inputs mt-1"
                    onClick={() => {
                      if (+minterInput) {
                        let aux = devicesInput;

                        if (+aux + 1 > 20 || +(+aux + 1) < 0) {
                          setShowDevicesOverlay(true);
                          setShowBasmOverlay(false);
                          setShowHidrOverlay(false);
                          setShowMinterOverlay(false);
                          setDevicesInput(aux);
                          if (+aux + 1 === 21)
                            setWarningTooltipMessage(
                              "You will exeed the maximum number of 20 devices!"
                            );
                          if (+aux - 1 === -1)
                            setWarningTooltipMessage(
                              "You can't go below 0 devices!"
                            );
                          setTimeout(() => {
                            setShowDevicesOverlay(false);
                          }, 3000);
                        } else {
                          setShowDevicesOverlay(false);
                          setDevicesInput(removeZeros(+aux + 1).toString());
                        }
                      } else {
                        setDevicesInput(0);
                      }
                    }}
                  />
                  <ArrowDown
                    className="arrows-inputs mb-1"
                    onClick={() => {
                      if (+minterInput) {
                        let aux = devicesInput;

                        if (+aux - 1 > 20 || +(+aux - 1) < 0) {
                          setShowDevicesOverlay(true);
                          setShowBasmOverlay(false);
                          setShowHidrOverlay(false);
                          setShowMinterOverlay(false);
                          setDevicesInput(aux);
                          if (+aux + 1 === 21)
                            setWarningTooltipMessage(
                              "You will exeed the maximum number of 20 devices!"
                            );
                          if (+aux - 1 === -1)
                            setWarningTooltipMessage(
                              "You can't go below 0 devices!"
                            );
                          setTimeout(() => {
                            setShowDevicesOverlay(false);
                          }, 3000);
                        } else {
                          setShowDevicesOverlay(false);
                          setDevicesInput(removeZeros(+aux - 1).toString());
                        }
                      } else {
                        setDevicesInput(0);
                      }
                    }}
                  />
                </div>
              </div>
            </OverlayTrigger>
          </div>
        </div>

        <div className="calculate-reward-separator-middle"></div>

        <div className="px-xl-5 px-sm-4 px-3 py-4">
          <div className="w-100 d-flex justify-content-between align-items-center toggle-container">
            <div className="d-flex flex-column align-items-center justify-content-center me-3  private-public-container">
              <span
                className={` font-size-24 font-pnr toggle-txt setting-item ${toggleDifficulty ? "darkgrey-color" : "text-white"
                  }`}
              >
                Private <br className="d-none"></br>difficulty
              </span>
              <input
                disabled
                type="text"
                value={privateDifficulty}
                className={` calculateInputs font-size-24 font-pnr toggle-txt setting-item private-difficulty ${toggleDifficulty ? "darkgrey-color" : "text-white"
                  }`}
              />
            </div>

            <CustomToggleButton
              checkState={toggleDifficulty}
              toggle={handleToggleDifficulty}
            />

            <div className="d-flex flex-column align-items-center justify-content-center ms-3 private-public-container">
              <span
                className={`font-size-24 font-pnr toggle-txt setting-item public-number ${toggleDifficulty ? "text-white" : "darkgrey-color"
                  }`}
              >
                Public <br className="d-none"></br>difficulty
              </span>
              <input
                disabled
                type="text"
                value={publicDifficulty}
                className={` calculateInputs font-size-24 font-pnr toggle-txt setting-item ${toggleDifficulty ? "text-white" : "darkgrey-color"
                  }`}
              />
            </div>
          </div>
        </div>

        <div className="calculate-reward-separator-middle"></div>

        <div className="px-xl-5 px-sm-4 px-3 py-4">
          <div className="mb-sm-4 mb-3 w-100 d-flex align-items-center justify-content-between">
            <span className="font-size-24 font-pnr setting-item">
              Validator power
            </span>
            <input
              type="text"
              disabled
              placeholder="0"
              className="calculateInputs"
              value={computingInput === 0 ? "cycles" : computingInput}
            />
          </div>
          <div className="mb-sm-4 mb-3 w-100 d-flex align-items-center justify-content-between">
            <span className="font-size-24 font-pnr setting-item">
              Booster power
            </span>
            <input
              type="text"
              disabled
              placeholder="0"
              className="calculateInputs"
              value={
                computingDevicesInput === 0 ? "cycles" : computingDevicesInput
              }
            />
          </div>
          <div className="w-100 d-flex align-items-center justify-content-between">
            <span className="font-size-24 font-pnr setting-item">
              Total power
            </span>
            <input
              type="text"
              disabled
              placeholder="0"
              className="calculateInputs"
              value={computingTotalInput === 0 ? "cycles" : computingTotalInput}
            />
          </div>
          <div className="mt-sm-5 mb-sm-3 mt-4 mb-3 w-100 d-flex align-items-center justify-content-start">
            <WarningSign className="warning-svg me-2" />
            <span className="font-size-18 font-pnr setting-item">All calculations are made based on the network difficulty.</span>
          </div>
          <button
            className={`mt-sm-3 mt-3 w-100 buttonBlue custom-button font-size-24 font-pnsb ${showRewards ? "d-none" : ""
              }`}
            onClick={() => {
              myCalculatorFunction();
              setShowRewards(true);
              setRefreshDisable(false)

            }}
          >
            Calculate reward
            <ArrowRight className="ms-2" />
          </button>
        </div>

        <div className={showRewards ? "" : "d-none"}>
          <div className="calculate-reward-separator"></div>

          <div className="px-xl-5 px-sm-4 px-3 pb-xl-5 pb-sm-4 pb-3 pt-4">
            <div className="d-flex justify-content-start align-items-center mb-3 position-relative">
              <FireIcon className="me-2" />
              <p className="font-size-40 font-pnb mb-0 total-txt line-height-11">
                Total reward earned:
              </p>
              {/* <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-disabled">Calculate again</Tooltip>}>
                        <RefreshLogo className="ms-auto" onClick={() => setShowRewards(false)} />
                     </OverlayTrigger> */}
              <Button
                disabled={refreshDisable}
                ref={target}
                className="ms-auto my-refresh-button"
                onClick={() => {
                  setRefreshDisable(true);
                  setTimeout(() => {
                    setShowRewards(false);
                  }, 300);
                }}
                onMouseEnter={() => {
                  setShowTooltip(!showTooltip);
                }}
                onMouseLeave={() => setShowTooltip(false)}
              >
                <RefreshLogo />
              </Button>
              <Overlay
                placement="bottom"
                show={showTooltip}
                target={target.current}
              >
                {(props) => (
                  <Tooltip
                    {...props}
                    id="tooltip-disabled"
                    className="d-lg-block d-none"
                  >
                    Calculate again
                  </Tooltip>
                )}
              </Overlay>
            </div>

            <div className="mb-4 w-100 d-flex justify-content-between middle-inputs">
              <div className="me-2 mi-flex-row d-flex flex-column">
                <div className="mb-2 d-flex align-items-center">
                  <span className="ms-2 font-size-24 font-pnr setting-item">
                    Daily
                  </span>
                </div>
                <input
                  type="text"
                  disabled
                  className="calculateInputs"
                  value={dailyInput + "XDEN"}
                  onChange={handleDaily}
                />
              </div>

              <div className=" me-2 mi-flex-row d-flex flex-column">
                <div className="mb-2 d-flex align-items-center">
                  <span className="ms-2 font-size-24 font-pnr setting-item">
                    Weekly
                  </span>
                </div>
                <input
                  type="text"
                  disabled
                  className="calculateInputs"
                  value={weeklyInput + "XDEN"}
                  onChange={handleWeekly}
                />
              </div>

              <div className="mi-flex-row d-flex flex-column">
                <div className="mb-2 d-flex align-items-center">
                  <span className="ms-2 font-size-24 font-pnr setting-item">
                    Monthly
                  </span>
                </div>
                <input
                  type="text"
                  disabled
                  className="calculateInputs"
                  value={monthlyInput + "XDEN"}
                  onChange={handleMonthly}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RewardCalculatorLong;
