import React from "react";
import "./KraterValidator.scss";
import { ReactComponent as ButtonIco } from "./assets/button-ico.svg";
import ValidatorApp from "./assets/app.png";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../Utils/routes";

const KraterValidator = () => {
  const navigate = useNavigate();

  return (
    <section id="krater-validator" className="page-section">
      <div className="container-main position-relative krater-app-section d-flex align-items-center justify-content-center flex-column">
        <img src={ValidatorApp} alt="validator application" className="img-fluid validator-app-img mb-3 mb-lg-0" />

        <h2 className="font-pnb font-size-80 text-center">
          Krater <br />
          Validator<span className="purple-color">.</span>
        </h2>

        <span className="font-pnr font-size-24 lightgrey-color text-center mt-3">Download BASM Krater Validator App</span>

        <button
          className="mt-3 d-flex align-items-center justify-content-center button-download font-pnsb font-size-20"
          onClick={() => navigate(routes.supportKraterApp)}
        >
          Download app
          <ButtonIco className="ms-2" />
        </button>
      </div>
    </section>
  );
};

export default KraterValidator;
