import React from "react";
import HeroSection from "./HeroSection/HeroSection";
import DownloadApp from "./DownloadApp/DownloadApp";
import NetworkFeatures from "./NetworkFeatures/NetworkFeatures";
import Features from "./Features/Features";
import Booster from "./Booster/Booster";
import { Helmet } from "react-helmet";
import SchemaTemplate from "../../SEO/SchemaTemplate.json";
import Spectralis from "./Spectralis/Spectralis";
import WhatIsSpectralis from "./WhatIsSpectralis/WhatIsSpectralis";
import HumanCards from "./HumanCards/HumanCards";
import ANetworkBuilt from "./ANetworkBuilt/ANetworkBuilt";

const PrivateNetwork = () => {
  return (
    <div className="private-network-page">
      {/* seo */}
      <Helmet>
        <title>Xiden | Spectralis Private Network</title>
        <meta name="title" content="Xiden | Spectralis Private Network" />
        <meta
          name="description"
          content="A hotspot unlocked by a Router that can open a pool through which smart devices can connect freely in order to access an anonymous internet network."
        />
        <link rel="canonical" href="" />
        {/* Open Graph Facebook */}
        <meta property="og:title" content="Xiden | Spectralis Private Network" />
        <meta
          property="og:description"
          content="A hotspot unlocked by a Router that can open a pool through which smart devices can connect freely in order to access an anonymous internet network."
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        {/* Open Graph Twitter  */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="" />
        <meta property="twitter:title" content="Xiden | Spectralis Private Network" />
        <meta
          property="twitter:description"
          content="A hotspot unlocked by a Router that can open a pool through which smart devices can connect freely in order to access an anonymous internet network."
        />
        <meta property="twitter:image" content="" />
        {/* Structured Data */}
        <script>{JSON.stringify(SchemaTemplate)}</script>
      </Helmet>

      {/* sections */}
      <HeroSection />
      <Spectralis />
      <WhatIsSpectralis />
      <HumanCards />
      <ANetworkBuilt />
      {/* <Features />
         <Booster />
         <DownloadApp />
         <NetworkFeatures /> */}
    </div>
  );
};

export default PrivateNetwork;
