import React from "react";
import "./HeroSection.scss";
import StakingImage from "../../../assets/images/png/staking.png";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import { AiOutlineArrowRight as ArrowRight } from "react-icons/ai";
import { Row, Col } from "react-bootstrap";
import { Link as ScrollLink } from "react-scroll";
import { toast } from "react-toastify";
import HeroVideo from "../../../assets/videos/consensus-page/consensus-hero-v2.mp4";

const HeroSection = () => {
  return (
    <section id="hero-section-staking">
      <div className="page-section position-relative">
        <div className="container-main hero-section-container d-flex flex-column">
          <Row className="flex-lg-row flex-column-reverse z-index-1">
            <Col lg={6}>
              <div className="highlight-content h-100 d-flex flex-column justify-content-center align-items-lg-start align-items-center">
                <div className="consensus-uppertitle-container">
                  <Uppertitle title={`CONSENSUS`} />
                </div>
                <h2 className="mb-0 font-size-80 white-color mb-0 font-pnb text-center-991">The mechanism</h2>
                <h2 className="mb-0 font-size-80 white-color mb-0 font-pnb text-center-991">that governs</h2>
                <h2 className="font-size-80 white-color mb-0 font-pnb text-center-991">
                  Xiden Blockchain
                  <span className="purple-color">.</span>
                </h2>
                <div className="py-lg-5 py-4 paragraph-container">
                  <p className="font-family-pnr font-size-24 lightgrey-color text-center-991 line-height-11">
                    The Xiden blockchain's consensus layer is built on three different protocols that redefine the concept of
                    Proof of Work and ensures scalability and sustainable and efficient development. It is primarily low-energy
                    and eco-friendly as the integrated devices are used by their owners regularly for their daily activities.
                  </p>
                </div>
                <div className="buttons-flex-direction w-100 d-flex justify-content-lg-start justify-content-center align-items-center">
                  <button
                    className="buttonBlue"
                    onClick={() => window.open("https://xiden.com/whitepaper/xiden-layers/consensus/", "_blank")}
                  >
                    <span className="me-2">Learn more</span>
                    <ArrowRight />
                  </button>
                </div>
              </div>
            </Col>
            <Col lg={6} className="d-flex justify-content-center align-items-center">
              {/* <img src={StakingImage} loading="lazy" alt="xid coin" className="img-fluid staking-hero-img pb-lg-0 pb-4" /> */}
            </Col>
          </Row>
          <div className="video-container d-flex align-items-center justify-content-end sdr-hero-video">
            <video src={HeroVideo} autoPlay playsInline muted loop={true} className="staking-hero-video position-absolute" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
