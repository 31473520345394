import React from "react";
import Slider from "../../../components/Slider/Slider";
import FeaturesCard from "./FeaturesCard";
import ConnectedDevices from "../../../assets/images/png/validator-page/ConnectedDevices.png";

import { ReactComponent as Decentralized } from "../../../assets/images/svg/ValidatorPage/decentralized.svg";
import { ReactComponent as Rewards } from "../../../assets/images/svg/ValidatorPage/get-rewards.svg";
import { ReactComponent as MoreRewards } from "../../../assets/images/svg/ValidatorPage/more-rewards.svg";

const FeaturesMobile = () => {
  return (
    <div className="d-block d-md-none features-mobile-slider-validator">
      <Slider>
        <FeaturesCard
          uppertitle="REQUIRED"
          imgUrl={ConnectedDevices}
          description={"Connect all the devices in the system to share all the available resources & get more rewards"}
          bg="main-bg"
        />
        <FeaturesCard
          uppertitle="GET REWARDED"
          svgProp={<Rewards className="card-logo" />}
          description={"Get rewarded for verifying the system’s data integrity"}
          bg="bg2"
        />
        <FeaturesCard
          uppertitle="DECENTRALIZED"
          svgProp={<Decentralized className="card-logo" />}
          description={"Benefit of decentralized environment"}
          bg="bg1"
        />
        <FeaturesCard
          uppertitle="MORE REWARDS"
          description={"Use validator as normal device"}
          bg="bg3"
          svgProp={<MoreRewards className="card-logo" />}
        />
        <FeaturesCard
          uppertitle="LOW ENERGY"
          description={
            <div className="font-size-80">
              <span className="low-energy">Low Energy</span> <br></br>
              <span style={{ color: "black" }}>Plant a </span>
              <span className="tree-color">tree.</span>
            </div>
          }
          bg="nice-bg"
        />
      </Slider>
    </div>
  );
};

export default FeaturesMobile;
