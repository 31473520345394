import React from "react";
import { Col, Row, Button } from "react-bootstrap";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import "./Hero.scss";
// import HeroImg from "../../../assets/images/png/bew1.png";
import { AiOutlineArrowRight as ArrowRight } from "react-icons/ai";
import { FiArrowUpRight as ArrowRightUp, FiTrendingUp } from "react-icons/fi";
import { toast } from "react-toastify";
// import Footer from "../../../components/Footer/Footer";
// import Consensus from "../Consensus/Consensus";
// import Newsletter from "../../../components/Newsletter/Newsletter";
// import LightPaper from "../../../assets/documents/WhitePaper-XIDEN BlockchainV1.1.pdf";
import loop1 from "../../../assets/videos/homepage/HARTA.mp4";
import axios from "axios";
import { useEffect, useState } from "react";
// import Developers from "../../../components/Developers/Developers";

// import { useNavigate } from "react-router-dom";

// import { Link as ScrollLink } from "react-scroll";
import { routes } from "../../../Utils/routes";
import { ReactComponent as Dolar } from "../../../assets/images/svg/005-dollar.svg";
import { ReactComponent as Cube } from "../../../assets/images/svg/003-cube.svg";

import { ReactComponent as Circle } from "../../../assets/images/svg/circle.svg";

import { ReactComponent as Total } from "../../../assets/images/svg/total-supply.svg";
import { ReactComponent as Dif } from "../../../assets/images/svg/difficulty.svg";
import { ReactComponent as Private } from "../../../assets/images/svg/private-difficulty.svg";

import { ReactComponent as Plus } from "../../../components/Assets/images/png/test-plus.svg";
import { Accordion } from "react-bootstrap";

import XidenLightpaper from "../../../assets/pdf/Lightpaper Xiden V1.2.pdf";
const Hero = () => {
  const [XDENPrice, setXDENPrice] = useState(() => "Loading...");
  const [totalSupply, setTotalSupply] = useState(() => "Loading...");
  // const [mintedData, setMintedData] = useState("Loading...");
  const [loading, setLoading] = useState(() => true);
  const [loadingCirculating, setLoadingCirculating] = useState(() => true);
  const [loadingTotalSupply, setLoadingTotalSupply] = useState(() => true);

  const [privateDifficulty, setPrivateDifficulty] = useState("Loading...");
  const [publicDifficulty, setPublicDifficulty] = useState("Loading...");

  const [circulatingSupply, setCirculatingSupply] = useState("Loading...");

  const getCirculatingSupply = async () => {
    try {
      const checkReq = await axios.get("https://xiden.com/supply/");
      if (checkReq.status === 200) {
        setCirculatingSupply((+checkReq.data).toFixed(2));
      }
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 404:
            console.log("404 Not found.");
            return;
          default:
            console.log("Something is temporarily wrong.");
            return;
        }
      }
    } finally {
      setLoadingCirculating(false);
    }
  };

  const getDiff = async () => {
    var raw = {
      toggleDifficulty: false,
      size: 1,
      basmInput: 1,
      hidrInput: 0,
      minterInput: 0,
      activeDevices: 0,
    };

    try {
      const checkReq = await axios.post("https://api.heimdall.ro/calculatorFunction", raw);
      if (checkReq.status === 200) {
        setPrivateDifficulty(checkReq.data.privateDifficulty);
        setPublicDifficulty(checkReq.data.publicDifficulty);
      }
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 404:
            console.log("404 Not found.");
            return;
          default:
            console.log("Something is temporarily wrong.");
            return;
        }
      }
    }
  };

  const getPrice = async () => {
    try {
      const checkReq = await axios.get(`https://pools.xiden.com/api/price`);
      if (checkReq.status === 200) {
        setXDENPrice((+checkReq.data.price).toFixed(2) + " USDT");
      }
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 404:
            console.log("404 Not found.");
            return;
          default:
            console.log("Something is temporarily wrong.");
            return;
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const getTotalSupply = async () => {
    try {
      const checkReq = await axios.get(`https://explorer.xiden.com/api?module=stats&action=coinsupply`);
      if (checkReq.status === 200) {
        setTotalSupply((+checkReq.data).toFixed(2));
      }
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 404:
            console.log("404 Not found.");
            return;
          default:
            console.log("Something is temporarily wrong.");
            return;
        }
      }
    } finally {
      setLoadingTotalSupply(false);
    }
  };

  useEffect(() => {
    getCirculatingSupply();
    getPrice();
    getDiff();
    getTotalSupply();
  }, []);

  return (
    <section id="homepage-hero" className="page-section d-flex flex-column">
      <div className="container-main d-flex">
        <Row className="flex-lg-row flex-column-reverse">
          <Col className="d-flex justify-content-center align-items-center homepage-hero-txt" lg={4}>
            <div className="">
              <Uppertitle title={"WELCOME TO XIDEN"} />
              <h2 className="font-size-80 white-color font-pnb line-height-11 mb-0 text-center-991">Internet of </h2>
              <h2 className="font-size-80 white-color font-pnb line-height-11 mb-0 text-center-991">Decentralized </h2>
              <h2 className="font-size-80 white-color font-pnb line-height-11 mb-0 text-center-991">
                Networks
                <span className="purple-color">.</span>{" "}
              </h2>

              <p className="font-family-pnr font-size-24 mb-0 lightgrey-color line-height-11 py-lg-5 py-4 description-991 text-center-991">
                An open-source system that will provide a secure and permanent internet connection for worldwide users regardless
                of their location or device.
              </p>

              <div className="buttons-flex-direction d-flex justify-content-start align-items-center ">
                <a className="buttonBlue hero-custom-btn" href={"https://xiden.com/whitepaper/"} target="_blank">
                  <span className="me-2 ">Whitepaper</span>
                  <ArrowRightUp />
                </a>

                {/* <ScrollLink className="buttonOutlineBlue" to="what-is-xiden" spy={true}>
                           <span>Learn more</span>
                           <ArrowRight className="ms-2" />
                        </ScrollLink> */}

                <button className="buttonOutlineBlue hero-custom-btn" onClick={() => window.open(XidenLightpaper, "_blank")}>
                  <span>Lightpaper</span>
                  <ArrowRight className="ms-2" />
                </button>
              </div>
            </div>
          </Col>
          <Col lg={8} className="d-flex justify-content-start align-items-center">
            {/* <img src={HeroImg} loading="lazy" alt="hero-img" className="hero-img" /> */}
            <video src={loop1} autoPlay playsInline muted loop={true} className="hero-img" />
          </Col>
        </Row>
      </div>

      <div className="banner mt-5 mb-1 d-none d-lg-flex">
        {/* <div className="banner-overlay font-size-48"><MyCountdown date={new Date(2022, 2, 30, 0, 0, 0)} renderer={renderer} /></div> */}
        <div className="banner-column d-flex justify-content-between w-33">
          <div className="banner-item">
            <div className="d-flex flex-column font-size-20 font-pnb">
              <span className="d-flex">
                {" "}
                <Dolar className="me-2" />{" "}
                <span className="white-color">
                  XDEN <br></br>Price
                </span>
              </span>
            </div>

            <span className="font-pnb number-span white-color">{loading ? "Loading..." : XDENPrice}</span>

            {/* <div className="banner-item">
                     <div className="d-flex flex-column font-size-20 font-pnl">
                        <span>Staking</span>
                        <span>supply</span>
                     </div>

                     <span className="font-pnb number-span">123.485</span>
                  </div> */}
          </div>
        </div>

        <div className="banner-column d-flex justify-content-between w-33">
          <div className="banner-item">
            <div className="d-flex flex-column font-size-20 font-pnl">
              <span className="d-flex">
                {" "}
                <Circle className="me-2" />{" "}
                <span className=" ">
                  Circulating <br></br>supply
                </span>
              </span>
            </div>

            <span className="font-pnb number-span line-height-1 text-align-end">
              {loadingCirculating ? "Loading..." : circulatingSupply + " XDEN"}
            </span>
          </div>
        </div>
        <div className="banner-column d-flex justify-content-between w-33">
          <div className="banner-item">
            <div className="d-flex flex-column font-size-20 font-pnl">
              <span className="d-flex">
                {" "}
                <Total className="me-2" />{" "}
                <span className=" ">
                  Total <br></br>supply
                </span>
              </span>
            </div>

            <span className="font-pnb number-span line-height-1 text-align-end">
              {/* {loadingTotalSupply ? "Loading..." : totalSupply + " XDEN"} */}
              {"300M XDEN"}
            </span>
          </div>
        </div>
      </div>
      <div className="banner d-none d-lg-flex mt-0">
        {/* <div className="banner-overlay font-size-48"><MyCountdown date={new Date(2022, 2, 30, 0, 0, 0)} renderer={renderer} /></div> */}
        <div className="banner-column d-flex justify-content-between w-33">
          <div className="banner-item" id="big-bang-era">
            <div className="d-flex flex-column font-size-20 font-pnb white-color">
              <span>
                {" "}
                <Cube className="me-2" /> Age
              </span>
            </div>

            <span className="font-pnb number-span white-color">Big Bang</span>
          </div>

          {/* <div className="banner-item">
                     <div className="d-flex flex-column font-size-20 font-pnl">
                        <span>Staking</span>
                        <span>supply</span>
                     </div>

                     <span className="font-pnb number-span">123.485</span>
                  </div> */}
        </div>

        <div className="banner-column d-flex justify-content-between w-33">
          <div className="banner-item">
            <div className="d-flex flex-column font-size-20 font-pnl">
              <span className="d-flex">
                {" "}
                <Dif className="me-2" />{" "}
                <span className=" ">
                  Public <br></br>Difficulty
                </span>
              </span>
            </div>

            <span className="font-pnb number-span line-height-1 text-align-end">{publicDifficulty}</span>
          </div>
        </div>
        <div className="banner-column d-flex justify-content-between w-33">
          <div className="banner-item">
            <div className="d-flex flex-column font-size-20 font-pnl">
              <span className="d-flex">
                {" "}
                <Private className="me-2" />{" "}
                <span className=" ">
                  Private <br></br>Difficulty
                </span>
              </span>
            </div>

            <span className="font-pnb number-span line-height-1 text-align-end">{privateDifficulty}</span>
          </div>
        </div>
      </div>

      <div className="banners-mobile banner mt-5 mb-1 d-flex d-lg-none">
        <Accordion defaultActiveKey={0}>
          <Accordion.Item eventKey={0} key={0}>
            <Accordion.Header>
              <div className="banner-column d-flex justify-content-between w-100">
                <div className="banner-item">
                  <div className="d-flex flex-column font-size-20 font-pnb banner-title">
                    <span className="d-flex">
                      {" "}
                      <Dolar className="me-3" />{" "}
                      <span className=" white-color m-auto line-height-1">
                        XDEN <br></br>Price
                      </span>
                    </span>
                  </div>

                  <div className="acordeon-plus d-flex align-items-center justicy-content-center">
                    <span className="font-pnb number-span white-color">{loading ? "Loading..." : XDENPrice}</span>
                    <Plus className="button-transition ms-3 white-color" />
                  </div>
                </div>
              </div>
            </Accordion.Header>

            <Accordion.Body>
              <div className="banner-column d-flex justify-content-between w-100">
                <div className="banner-item">
                  <div className="d-flex flex-column font-size-20 font-pnl banner-title">
                    <span className="d-flex">
                      {" "}
                      <Circle className="me-3" />{" "}
                      <span className="line-height-1 m-auto">
                        Circulating <br></br>supply
                      </span>
                    </span>
                  </div>

                  <span className="font-pnb number-span line-height-1">
                    {loadingTotalSupply ? "Loading..." : circulatingSupply + " XDEN"}
                  </span>
                </div>
              </div>
              <div className="banner-column d-flex justify-content-between w-100">
                <div className="banner-item">
                  <div className="d-flex flex-column font-size-20 font-pnl banner-title">
                    <span className="d-flex">
                      {" "}
                      <Total className="me-3" />{" "}
                      <span className="line-height-1 m-auto">
                        Total <br></br>supply
                      </span>
                    </span>
                  </div>

                  <span className="font-pnb number-span line-height-1">
                    {/* {loadingTotalSupply ? "Loading..." : totalSupply + " XDEN"} */}
                    {"300M XDEN"}
                  </span>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion defaultActiveKey={1}>
          <Accordion.Item eventKey={1} key={1}>
            <Accordion.Header>
              <div className="banner-column d-flex justify-content-between w-33">
                <div className="banner-item" id="big-bang-era">
                  <div className="d-flex flex-column font-size-20 font-pnb white-color banner-title">
                    <span className="line-height-1 m-auto">
                      <Cube className="me-3" />
                      Age
                    </span>
                  </div>
                  <div className="acordeon-plus-2 d-flex align-items-center justicy-content-center">
                    <span className="font-pnb number-span white-color line-height-1 m-auto">Big Bang</span>
                    <Plus className="button-transition ms-3" />
                  </div>
                </div>
              </div>
            </Accordion.Header>

            <Accordion.Body>
              <div className="banner-column d-flex justify-content-between w-100">
                <div className="banner-item">
                  <div className="d-flex flex-column font-size-20 font-pnl banner-title">
                    <span className="d-flex">
                      {" "}
                      <Dif className="me-3" />{" "}
                      <span className="line-height-1 m-auto">
                        Public <br></br>Difficulty
                      </span>
                    </span>
                  </div>

                  <span className="font-pnb number-span line-height-1">{publicDifficulty}</span>
                </div>
              </div>
              <div className="banner-column d-flex justify-content-between w-10">
                <div className="banner-item">
                  <div className="d-flex flex-column font-size-20 font-pnl banner-title">
                    <span className="d-flex">
                      {" "}
                      <Private className="me-3" />{" "}
                      <span className="line-height-1 m-auto">
                        Private <br></br>Difficulty
                      </span>
                    </span>
                  </div>

                  <span className="font-pnb number-span line-height-1">{privateDifficulty}</span>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </section>
  );
};

export default Hero;
