import React from "react";
import "./DataPrivacy.scss";
import { Helmet } from "react-helmet";
import SchemaTemplate from "../../SEO/SchemaTemplate.json";

const DataPrivacy = () => {
  return (
    <section id="data-privacy-section" className="page-section">
      {/* seo */}
      <Helmet>
        <title>Xiden | Privacy Notice</title>
        <meta name="title" content="Privacy Notice" />
        <meta name="description" content="" />
        <link rel="canonical" href="" />
        {/* Open Graph Facebook */}
        <meta property="og:title" content="Privacy Notice" />
        <meta property="og:description" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        {/* Open Graph Twitter  */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="" />
        <meta property="twitter:title" content="Privacy Notice" />
        <meta property="twitter:description" content="" />
        <meta property="twitter:image" content="" />
        {/* Structured Data */}
        <script>{JSON.stringify(SchemaTemplate)}</script>
      </Helmet>
      <div className="container-main">
        <h2 className="text-center font-pnb font-size-40 m-custom">
          Privacy Notice
        </h2>

        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          "XIDEN" or "we," "us," or "our" refers to Xiden Technologies SRL,
          acting on behalf of its affiliates doing business as XIDEN or other
          brands owned by XIDEN. "You" or "your" refers to the person or entity
          that is the customer of record. Please read the following to learn
          more about our Privacy Notice ("this Notice").
          <span className="font-pnb">
            {" "}
            This Notice applies to Xiden websites, products, and services that
            display or provide links to this Notice.{" "}
          </span>
        </p>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          This Notice describes how Xiden processes your personal data, but it
          may not address all possible data processing scenarios. Xiden may
          inform you of product - or service - specific data collection through
          supplementary policies or notices provided before collection.
        </p>
        <p className="font-pnr mb-1 font-size-16 lightgrey-color line-height-11">
          This Notice describes:
        </p>
        <ul className="font-pnr font-size-16 lightgrey-color line-height-11">
          <li>
            <span className="white-color">1</span>
            <span className="purple-color">. </span>
            How We Collect &#38; Use Your Personal Data
          </li>
          <li>
            <span className="white-color">2</span>
            <span className="purple-color">. </span>
            Cookies &#38; Similar Technologies
          </li>
          <li>
            <span className="white-color">3</span>
            <span className="purple-color">. </span>
            How We Disclose Personal Data
          </li>
          <li>
            <span className="white-color">4</span>
            <span className="purple-color">. </span>
            How to Access &#38; Control Your Personal Data
          </li>
          <li>
            <span className="white-color">5</span>
            <span className="purple-color">. </span>
            How We Protect Your Personal Data
          </li>
          <li>
            <span className="white-color">6</span>
            <span className="purple-color">. </span>
            How We Process Children's Personal Data
          </li>
          <li>
            <span className="white-color">7</span>
            <span className="purple-color">. </span>
            Third-Party Providers and Their Services
          </li>
          <li>
            <span className="white-color">8</span>
            <span className="purple-color">. </span>
            International Transfers of Your Personal Data
          </li>
          <li>
            <span className="white-color">9</span>
            <span className="purple-color">. </span>
            Updates to This Notice
          </li>
          <li>
            <span className="white-color">10</span>
            <span className="purple-color">. </span>
            How to Contact Us
          </li>
        </ul>

        <h4 className="font-pnsb font-size-24 mb-3">
          1<span className="purple-color">.</span> How We Collect &#38; Use Your
          Personal Data
        </h4>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          Personal data means any data that, either on its own or jointly with
          other data, can be used to identify a natural person. You directly
          provide us with such data when you use our websites, products, or
          services, or interact with us by, for example, creating a Xiden
          account or contacting us for support. We may also obtain data by
          recording how you interact with our websites, products, or services.
          For example, we may use technologies like cookies or receive use data
          from software running on your device. As permitted by law, we may also
          obtain data from public and commercial third-party sources, for
          example, purchasing statistics from other companies to support our
          services. The personal data we collect includes name, gender, postal
          and email addresses, phone number, login information (account and
          password), etc., depending on how you interact with Xiden, for
          example, the website you visit or the products and services that you
          use. We also collect the information you provide to us and the content
          of messages you send us, such as the query information you provide, or
          the questions or information you provide for customer service support.
        </p>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          Before using Xiden’s products or services, you may need to provide
          personal data.  In some cases, you may be able to opt not to disclose
          your personal data to Xiden. However, not providing Xiden with certain
          data may mean that we cannot provide you with certain products or
          services or respond to an issue that you have raised.
        </p>
        <p className="font-pnr mb-1 font-size-16 lightgrey-color line-height-11">
          We may use your personal data for the following purposes:{" "}
        </p>
        <ul className="mt-2 font-pnr font-size-16 lightgrey-color line-height-11">
          <li>
            <span className="purple-color">• </span>
            Creating an account in order to benefit from our products, services
            and applications in more easily manner.
          </li>
          <li>
            <span className="purple-color">• </span>
            Fulfilling your transaction or service requests, including
            fulfilling orders, payment processing, delivering, activating, or
            verifying products or services; providing training and
            certification; participating in onsite or virtual activities;
            fulfilling your requests for changes or providing you with the
            requested information (such as materials for products/services); and
            providing technical support.
          </li>
          <li>
            <span className="purple-color">• </span>
            Contacting you with your consent; sending you marketing information
            about products and services that may interest you; inviting you to
            participate in Xiden activities (including promotional activities).
            If you do not want to receive these types of information, you can
            opt out at any time.
          </li>
          <li>
            <span className="purple-color">• </span>
            Sending you important notices, such as installation of and updates
            to operating system or applications as per ToU.
          </li>
          <li>
            <span className="purple-color">• </span>
            Providing you with customized user experience and content, with your
            consent.
          </li>
          <li>
            <span className="purple-color">• </span>
            Improving our products and services through internal audits, data
            analysis, and research in order to improve business operations.
          </li>
          <li>
            <span className="purple-color">• </span>
            Analyzing the efficiency of business operations and evaluating
            market share for improving of business operations.
          </li>
          <li>
            <span className="purple-color">• </span>
            Troubleshooting when you send us error reports for customer better
            experience.
          </li>
          <li>
            <span className="purple-color">• </span>
            Synchronizing, sharing, and storing the data you upload or download
            and the data needed for the uploading and downloading for customer
            better experience.
          </li>
          <li>
            <span className="purple-color">• </span>
            Ensuring the security of our products, services and customers or
            users, executing and improving our loss prevention and anti-fraud
            programs for security reasons.
          </li>
          <li>
            <span className="purple-color">• </span>
            Complying with and enforcing applicable legal requirements, industry
            standards and our policies.
          </li>
        </ul>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          Xiden may also collect and use non-personally identifiable information
          (Non-PII). Non-PII is information that cannot be used to identify a
          particular individual. For example, Xiden will collect statistical
          data, such as the numbers of visits to its website. We collect this
          data to understand how users use our websites, products, and services
          so that we can improve our services and better satisfy your needs.
          Xiden may collect, use, process, transfer, or disclose non-PII for
          other purposes at its own discretion.
        </p>

        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          We will endeavor to isolate your personal data from non-PII and ensure
          that the two types of data are used separately. If personal data is
          combined with non-PII, it will still be treated as personal data
          during processing.
        </p>

        <p className="mb-1 font-pnr font-size-16 lightgrey-color line-height-11">
          Xiden will process your personal data following the requirements of
          applicable laws on an appropriate legal basis, including:
        </p>
        <ul className="font-pnr font-size-16 lightgrey-color line-height-11">
          <li>
            <span className="purple-color">• </span>
            Processing your personal data to fulfill the contract when
            responding to a transaction or service request;
          </li>
          <li>
            <span className="purple-color">• </span>
            Processing your personal data with your consent;
          </li>
          <li>
            <span className="purple-color">• </span>
            Processing based on the legitimate interests of Xiden or a third
            party when we use your personal data to contact you, conduct
            marketing or market surveys, improve our products and services,
            execute and improve our loss prevention and anti-fraud programs, and
            other purposes. Legitimate interests include enabling us to more
            effectively manage and operate our business and provide our products
            and services; protecting the security of our businesses, systems,
            products, services, and customers; internal management; complying
            with internal policies and processes; and other legitimate interests
            described in this Notice;
          </li>
          <li>
            <span className="purple-color">• </span>
            Processing your personal data as necessary to comply with and
            fulfill legal obligations.
          </li>
        </ul>

        <h4 className="font-pnsb font-size-24 mb-3">
          2<span className="purple-color">.</span> Cookies &#38; Similar
          Technologies
        </h4>
        <h5 className="font-pnsb font-size-20 mb-2 underline">
          2.1<span className="purple-color">.</span> Cookies
        </h5>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          To ensure our website works correctly, we may at times place a small
          piece of data known as a cookie on your computer or mobile device. A
          cookie is a text file stored by a web server on a computer or mobile
          device. The content of a cookie can be retrieved or read only by the
          server that creates the cookie. The text in a cookie often consists of
          identifiers, site names, and some numbers and characters. Cookies are
          unique to the browsers or mobile applications you use, and enable
          websites to store data such as your preferences or items in your
          shopping cart.
        </p>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          Like many other websites or Internet service providers, Xiden uses
          cookies to improve user experience. Session cookies are deleted after
          each visit, while persistent cookies remain in place across multiple
          visits. Cookies allow websites to remember your settings such as
          language, font size on your computer or mobile device, or other
          browser preferences. This means that a user does not need to reset
          preferences for every visit. On the contrary, if cookies are not used,
          websites will treat you as a new visitor every time you load a web
          page. For example, if you are redirected to another web page from a
          website you are already logged in to and then return to the original
          website, it will not recognize you and you must log in again.
        </p>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          Xiden will not use cookies for any purposes not stated in this Notice.
          You can manage or delete cookies based on your own preferences. For
          details, visit{" "}
          <span
            onClick={() => window.open("https://www.aboutcookies.org/")}
            className="purple-color privacy-link"
          >
            AboutCookies.org
          </span>
          . You can clear all the cookies stored on your computer, and most web
          browsers provide the option of blocking cookies. However, by doing so,
          you have to change the user settings every time you visit our website.
        </p>

        <h4 className="font-pnsb font-size-24 mb-3">
          3<span className="purple-color">.</span> How We Disclose Personal Data
        </h4>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          Xiden shares your personal data with other partners, as described in
          this Notice, when services are provided by partners authorized by
          Xiden. For example, when you make an online purchase from Xiden, we
          must share your personal data with the logistics provider to arrange
          shipment or a partner to provide services. In addition, as an
          international company, we may share personal data with Xiden
          affiliates, related-parties, partners and subsidiaries.
        </p>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          To comply with applicable laws or respond to valid legal procedures,
          Xiden may also disclose your personal data to law enforcement or other
          government agencies. If Xiden is involved in a restructuring, merger
          &#38; acquisition, or a bankruptcy or liquidation lawsuit in a given
          jurisdiction, your personal data may be disclosed in connection with
          the transaction. Xiden may also disclose your data when appropriate,
          for example, to execute Terms of Use, when we believe disclosure is
          necessary or appropriate to prevent physical harm or financial loss,
          or when it is in connection with an investigation of suspected or
          actual illegal activity.
        </p>

        <h4 className="font-pnsb font-size-24 mb-3">
          4<span className="purple-color">.</span> How to Access &#38; Control
          Your Personal Data
        </h4>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          It is your responsibility to ensure that all personal data submitted
          to Xiden is correct. Xiden is dedicated to maintaining the accuracy
          and completeness of personal data and keeping the data up-to-date.
        </p>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          To the extent required by applicable law, you may (i) have the right
          to access certain personal data we maintain about you, (ii) request
          that we update or correct inaccuracies in that data, (iii) object or
          restrict to our use of your personal data, and (iv) ask us to delete
          your personal data from our database. To exercise these rights,
          please send an e-mail to{" "}
          <a href="mailto:office@cryptodata.com" className="purple-color">
            office@cryptodata.com
          </a>
          . Your written request may be required for security. We may decline
          the request if we have reasonable grounds to believe that the request
          is a fraudulent, unfeasible or may jeopardize the privacy of others.
        </p>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          If allowed by applicable laws, you have the right to withdraw your
          consent at any time when Xiden processes your personal data based on
          your consent. However, withdrawal does not affect the legitimacy and
          effectiveness of how we process your personal data based on your
          consent before the withdrawal is made; nor does it affect any data
          processing based on another justification other than your consent.
        </p>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          If you think that the way we process your personal information does
          not comply with applicable data protection laws, you can contact the
          relevant competent data protection authority. You can obtain the
          information for contacting EU data protection authorities at{" "}
          <span
            className="purple-color privacy-link"
            onClick={() =>
              window.open(
                "http://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm"
              )
            }
          >
            http://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm
          </span>
          .
        </p>

        <h4 className="font-pnsb font-size-24 mb-3">
          5<span className="purple-color">.</span> How We Protect and Retain
          Your Personal Data
        </h4>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          The security of your personal data is important to us. We use
          appropriate physical, management, and technical measures to protect
          your personal data from unauthorized access, disclosure, use,
          modification, damage, or loss. For example, we use cryptographic
          technologies for data confidentiality, protection mechanisms to
          prevent attacks, and access control mechanisms to permit only
          authorized access to your personal data. We also provide training on
          security and privacy protection for employees to raise their awareness
          of personal data protection. Xiden is committed to protecting your
          personal data.
        </p>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          We will retain your personal information for no longer than is
          necessary for the purposes stated in this Notice, unless otherwise
          extending the retention period is required or permitted by law. The
          data storage period may vary with purpose, product, and service. The
          standards Xiden uses to determine the retention period are as follows:
          the time required to retain personal data to fulfill business
          purposes, including providing products and services; maintaining
          corresponding transaction and business records; controlling and
          improving the performance and quality of products and services;
          ensuring the security of systems, products, and services; handling
          possible user queries or complaints and locating problems; whether the
          user agrees to a longer retention period; and whether the laws,
          contracts, and other equivalencies have special requirements for data
          retention; etc. We will maintain your registration information as long
          as your account is necessary for service provision. You can choose to
          deregister your account. After you deregister your account, we will
          stop providing you with products and services through your account and
          delete your relevant personal data, provided that deletion is not
          otherwise stipulated by special legal requirements or needed for
          protection of interests of Xiden.
        </p>

        <h4 className="font-pnsb font-size-24 mb-3">
          6<span className="purple-color">.</span> How We Process Children's
          Personal Data
        </h4>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          Our websites, products and services are primarily intended for adults.
          A child must not create a Xiden account without the consent of a
          parent or guardian. If a child's personal data is collected with prior
          parental consent, we will only use or disclose the data as permitted
          by law, with the explicit consent of the child's parents or guardians,
          or when necessary for the protection of the child. If we accidentally
          collect a child's personal data without verified prior consent from
          the child's parents, we will attempt to delete the data as soon as
          possible.
        </p>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          You must be at least 16 years old. If you are considered a minor in
          your country, you must have your parent or legal guardian's permission
          to use Xiden website, products and services.
        </p>

        <h4 className="font-pnsb font-size-24 mb-3">
          7<span className="purple-color">.</span> Third-Party Providers and
          Their Services
        </h4>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          To ensure a positive user experience, you may find content or web
          links from third parties other than Xiden and its partners ("third
          parties"). Xiden does not have the right to control such third
          parties, but you can choose whether to use the links, view the content
          and/or access the products or services provided by third parties.
        </p>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          Xiden cannot control the privacy practices and data protection
          policies of third parties that are not subject to this Notice. When
          you submit personal information to such a third party, please read and
          refer to the privacy protection Notice of the third party.
        </p>

        <h4 className="font-pnsb font-size-24 mb-3">
          8<span className="purple-color">.</span> International Transfers of
          Your Personal Data
        </h4>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          As a global company, your personal data collected by Xiden may be
          processed or accessed in the country/region where you use our products
          and services or in other countries/regions where Xiden or its
          affiliates, subsidiaries, service providers or business partners have
          a presence. These jurisdictions may have different data protection
          laws. In such circumstances, Xiden will take measures to ensure that
          data is processed as required by this Notice and applicable laws,
          which includes when transferring the data subject’s personal data from
          the EU to a country or region which have been acknowledged by the EU
          commission as having an adequate level of data protection, we may use
          a variety of legal mechanisms, such as signing standard contractual
          clauses approved by the EU Commission, obtaining the consent to the
          cross-border transfer of a data subject in the EU, or implementing
          security measures like anonymizing personal data before cross-border
          data transfer.
        </p>

        <h4 className="font-pnsb font-size-24 mb-3">
          9<span className="purple-color">.</span> Updates to This Notice
        </h4>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          Xiden reserves the right to update or change this Notice at any time.
          We will release the latest Privacy Notice on this page for any
          changes. If major changes are made to the Privacy Notice, we may
          notify you through different channels, for example, posting a notice
          on our website or sending you direct notification.
        </p>

        <h4 className="font-pnsb font-size-24 mb-3">
          10<span className="purple-color">.</span> How to Contact Us
        </h4>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          If you have any privacy complaints or issues, and want to contact
          Xiden Data Protection Officer (DPO), please contact at{" "}
          <a href="mailto:office@cryptodata.com" className="purple-color">
            office@cryptodata.com
          </a>
          .
        </p>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          Where your personal data is processed by Xiden in accordance with this
          Privacy Notice, the Xiden entity that responds to your request,
          contacts you, provides you with products or services, or has signed or
          is about to sign a contract with you is the controller of the related
          personal data.
        </p>
      </div>
    </section>
  );
};

export default DataPrivacy;
