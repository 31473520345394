import React from "react";
import "./Staking.scss";
import HeroSection from "./HeroSection/HeroSection";
// import CardsSection1 from "./CardsSection1/CardsSection1";
// import HowToStake from "./HowToStake/HowToStake";
import CardsSection2 from "./CardsSection2/CardsSection2";
import { Helmet } from "react-helmet";
import SchemaTemplate from "../../SEO/SchemaTemplate.json";
import CombinesAlgorithms from "./CombinesAlgorithms/CombinesAlgorithms";
import CardsSection1V2 from "./CardsSection1V2/CardsSection1V2";

const Staking = () => {
   return (
      <div className="staking-page">
         {/* seo */}
         <Helmet>
            <title>Xiden | Staking</title>
            <meta name="title" content="Xiden | Staking" />
            <meta name="description" content="If a node becomes a validator through the staking procedure, it is rewarded according to the work done and the resources of the devices connected by it." />
            <link rel="canonical" href="" />
            {/* Open Graph Facebook */}
            <meta property="og:title" content="Xiden | Staking" />
            <meta property="og:description" content="If a node becomes a validator through the staking procedure, it is rewarded according to the work done and the resources of the devices connected by it." />
            <meta property="og:type" content="website" />
            <meta property="og:image" content="" />
            <meta property="og:url" content="" />
            {/* Open Graph Twitter  */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="" />
            <meta property="twitter:title" content="Xiden | Staking" />
            <meta property="twitter:description" content="If a node becomes a validator through the staking procedure, it is rewarded according to the work done and the resources of the devices connected by it." />
            <meta property="twitter:image" content="" />
            {/* Structured Data */}
            <script>{JSON.stringify(SchemaTemplate)}</script>
         </Helmet>

         {/* sections */}
         <HeroSection />
         <CombinesAlgorithms />
         <CardsSection1V2 />
         {/* <CardsSection1 /> */}
         {/* <HowToStake /> */}
         <CardsSection2 />
      </div>
   );
};

export default Staking;
