import React from "react";
import { Col, Row } from "react-bootstrap";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import DiagonalCorners from "../../../components/DiagonalCorners/DiagonalCorners";
import Hidr from "../../../assets/images/png/Hidr-big.png";
import { ReactComponent as ArrowRight } from "../../../assets//images/svg/arrow-right.svg";
import "./HidrDevice.scss";

export default function HidrDevice() {
   return (
      <section id="hidr-device" className="page-section">
         <div className="container-main d-flex flex-column">
            <div className="mobile-header d-none">
               <Uppertitle title={"DEVICE"} />
               <h2 className="font-pnb font-size-80 white-color pb-lg-5 pt-lg-3 pt-2 ">
                  HidR
                  <span className="purple-color">.</span>
               </h2>
            </div>
            <Row className="align-items-center first-section  flex-column-reverse flex-lg-row">
               <Col lg={5}>
                  <div className="desktop-header">
                     <Uppertitle title={"DEVICE"} />
                     <h2 className="font-pnb font-size-80 white-color  pb-lg-5 pb-4 pt-lg-3 pt-2 ">
                        HidR
                        <span className="purple-color">.</span>
                     </h2>
                  </div>
                  <h4 className="font-pnb font-size-32 darkgrey-color">Description</h4>
                  <p className="font-pnr font-size-20 pb-3 lightgrey-color">
                     Strong dead eternal-return decrepit evil fearful joy play burying joy ultimate aversion grandeur.
                     Reason endless moral passion abstract ocean abstract holiest overcome love mountains moral.
                  </p>
                  {/* <div className="buttons-container d-flex mt-lg-5 mt-3">
                            <button className="buttonBlue">
                                Buy now
                                <ArrowRight className="ms-2" />
                            </button>
                            <button className="buttonOutlineBlue">
                                Learn more
                                <ArrowRight className="ms-2" />
                            </button>
                        </div> */}
               </Col>
               <Col lg={7} className="d-flex align-items-center justify-content-center align-content-center">
                  <img src={Hidr} loading="lazy" alt="BASM laptop" className="img-fluid" />
               </Col>
            </Row>

            <div className="corners second-section d-flex flex-column justify-content-center p-lg-5 p-4">
               <Row className="d-flex justify-content-between align-items-center">
                  <Col lg={5} className="d-flex flex-column justify-content-center">
                     <h2 className="title font-size-80 pb-3 white-color font-pnb">
                        Rewards
                        <span className="purple-color">.</span>
                     </h2>
                     <p className="font-pnr font-size-20 pb-2 lightgrey-color description-991">
                        Every active validator node is rewarded by the Xiden Network based on jobs collected and
                        validated in order to ensure the availability and the functionality of the system.
                     </p>
                  </Col>
                  <Col lg={7} className="d-flex flex-column justify-content-center">
                     <Row>
                        <Col className="d-flex align-items-center justify-content-center mb-5">
                           <DiagonalCorners
                              color={"orange"}
                              subtitle={""}
                              title={"+96"}
                              paragraph={"cycles per second"}
                           />
                        </Col>
                     </Row>
                     <Row>
                        <Col sm={6} className="d-flex mb-sm-0 mb-5 align-items-center justify-content-center">
                           <DiagonalCorners
                              color={"orange"}
                              subtitle={"PRIVATE"}
                              title={"$15"}
                              paragraph={"reward per day"}
                           />
                        </Col>
                        <Col sm={6} className="d-flex align-items-center justify-content-center">
                           <DiagonalCorners
                              color={"mauve"}
                              subtitle={"PUBLIC"}
                              title={"$12"}
                              paragraph={"reward per day"}
                           />
                        </Col>
                     </Row>
                  </Col>
               </Row>
            </div>
         </div>
      </section>
   );
}
