import React, { useEffect } from 'react';
import "./AvailableExchanges.scss";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import { Col, Row } from 'react-bootstrap';
import { FiArrowUpRight as ArrowRightUp } from "react-icons/fi";
import { AiOutlineArrowRight as ArrowRight } from "react-icons/ai";
import LatokenImg from "../../../assets/images/png/homePage/latoken-logo.png";
import WhitebitImg from "../../../assets/images/png/homePage/whitebit-logo.png";
import DigifinexImg from "../../../assets/images/png/homePage/digifinex-logo.png";
import LatokenGroup from "../../../assets/images/png/homePage/latoken-group.png";
import BitmartGroup from "../../../assets/images/png/homePage/bitmart-group.png";
import WhitebitGroup from "../../../assets/images/png/homePage/whitebit-group.png";
import DigifinexGroup from "../../../assets/images/png/homePage/digifinex-corners.png";

const AvailableExchanges = () => {


    useEffect(() => {
        const slider = document.getElementById("exchanges-slider");
        let isDown = false;
        let startX;
        let scrollLeft;

        const mouseLeaveFunction = () => {
            // document.getElementById("roadmap-event").style.cursor = 'grabbing';

            isDown = false;
            slider.classList.remove('active');
        }
        const mouseDownFunction = (e) => {
            // document.getElementById("roadmap-event").style.cursor = 'grabbing';
            isDown = true;
            slider.classList.add('active');
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        }
        const mouseUpFunction = () => {
            // document.getElementById("roadmap-event").style.cursor = 'grabbing';
            isDown = false;
            slider.classList.remove('active');
        }
        const touchFunction = (e) => {
            // document.getElementById("roadmap-event").style.cursor = 'grabbing';
            isDown = false;
            slider.classList.remove('active');
            // console.log('sal')
        }
        const mouseMoveFunction = (e) => {
            // document.getElementsByClassName("roadmap-event").style.cursor = 'grabbing';
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 1; //scroll-fast
            slider.scrollLeft = scrollLeft - walk;
            //console.log(walk);
        }

        if (slider) {

            slider?.addEventListener('mousedown', mouseDownFunction);
            slider?.addEventListener('mouseleave', mouseLeaveFunction);
            slider?.addEventListener('mouseup', mouseUpFunction);
            slider?.addEventListener('mousemove', mouseMoveFunction);
            slider?.addEventListener('touchstart', touchFunction);
        }

        return () => {
            slider?.removeEventListener('mousedown', mouseDownFunction);
            slider?.removeEventListener('mouseleave', mouseLeaveFunction);
            slider?.removeEventListener('mouseup', mouseUpFunction);
            slider?.removeEventListener('mousemove', mouseMoveFunction);
            slider?.removeEventListener('mousemove', touchFunction);
        }

    }, []);



    return (
        <section id="exchanges-section" className="page-section">
            <div className="container-main">
                <Uppertitle title={"XIDEN"} />
                <h2 className="title font-size-80 white-color font-pnb line-height-11 pb-lg-5 pb-4">
                    Available exchanges
                    <span className="purple-color">.</span>
                </h2>

                <Row className="d-none">
                    <Col lg={4}>
                        <div className="column-wrapper  mx-auto">
                            <div className="d-flex align-items-center justify-content-center corners">
                                <img src={DigifinexImg} alt="digifinex" className="img-fluid" />
                            </div>

                            <h2 className="font-pnb font-size-40 text-center mt-4">
                                Digifinex
                            </h2>
                            <button className="buttonBlue m-auto mt-3 custom-btn font-pnsb font-size-20 mb-5 mb-lg-0"
                                onClick={() => window.open("https://www.digifinex.com/en-ww/trade/USDT/XDEN")}
                            >
                                Trade now
                                <ArrowRightUp className="ms-1" />
                            </button>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="column-wrapper mx-auto ">
                            <div className="d-flex align-items-center justify-content-center corners">
                                <img src={WhitebitImg} alt="whitebit" className="img-fluid" />
                            </div>

                            <h2 className="font-pnb font-size-40 text-center mt-4">
                                Whitebit
                            </h2>
                            <button className="buttonBlue m-auto mt-3 custom-btn font-pnsb font-size-20 mb-5 mb-lg-0"
                                onClick={() => window.open("https://whitebit.com/trade/XDEN-USDT")}
                            >
                                Trade now
                                <ArrowRightUp className="ms-1" />
                            </button>
                        </div>
                    </Col>
                    <Col lg={4} className="">
                        <div className="column-wrapper wrapper-1  mx-auto">
                            <div className="d-flex align-items-center justify-content-center corners">
                                <img src={LatokenImg} alt="latoken" className="img-fluid" />
                            </div>

                            <h2 className="font-pnb font-size-40 text-center mt-4">
                                LAToken
                            </h2>
                            <button className="buttonBlue m-auto mt-3 custom-btn font-pnsb font-size-20 mb-5 mb-lg-0"
                                onClick={() => window.open("https://latoken.com/exchange/XDEN_USDT")}
                            >
                                Trade now
                                <ArrowRightUp className="ms-1" />
                            </button>
                        </div>
                    </Col>


                </Row>

                <div id="exchanges-slider" className="ps-4 pe-5 d-flex justify-content-between align-items-start exchange-div">
                    {/* <Slider {...settings} className="d-block d-lg-none"> */}
                    <div className="column-wrapper m-auto exchange-event me-exchange-slider-custom">
                        <div className="d-flex align-items-center justify-content-center mx-2">
                            <img src={BitmartGroup} alt="Latoken" className="img-fluid" />
                        </div>

                        <h2 className="font-pnb font-size-40 text-center mt-4">
                            BitMart
                        </h2>
                        <button className="buttonBlue m-auto mt-3 custom-btn font-pnsb font-size-20 mb-5 mb-lg-0"
                            onClick={() => window.open("https://www.bitmart.com/trade/en?symbol=XDEN_USDT&layout=basic")}
                        >
                            Trade now
                            <ArrowRightUp className="ms-1" />
                        </button>
                    </div>
                    <div className="column-wrapper m-auto exchange-event ">
                        <div className="d-flex align-items-center justify-content-center mx-2">
                            <img src={DigifinexGroup} alt="Digifinex" className="img-fluid" />
                        </div>

                        <h2 className="font-pnb font-size-40 text-center mt-4">
                            Digifinex
                        </h2>
                        <button className="buttonBlue m-auto mt-3 custom-btn font-pnsb font-size-20 mb-5 mb-lg-0"
                            onClick={() => window.open("https://www.digifinex.com/en-ww/trade/USDT/XDEN")}
                        >
                            Trade now
                            <ArrowRightUp className="ms-1" />
                        </button>
                    </div>
                    <div className="column-wrapper m-auto exchange-event ">
                        <div className="d-flex align-items-center justify-content-center mx-2">
                            <img src={WhitebitGroup} alt="Whitebit" className="img-fluid" />
                        </div>

                        <h2 className="font-pnb font-size-40 text-center mt-4">
                            Whitebit
                        </h2>
                        <button className="buttonBlue m-auto mt-3 custom-btn font-pnsb font-size-20 mb-5 mb-lg-0"
                            onClick={() => window.open("https://whitebit.com/trade/XDEN-USDT")}
                        >
                            Trade now
                            <ArrowRightUp className="ms-1" />
                        </button>
                    </div>
                    <div className="column-wrapper m-auto exchange-event me-exchange-slider-custom">
                        <div className="d-flex align-items-center justify-content-center mx-2">
                            <img src={LatokenGroup} alt="Latoken" className="img-fluid" />
                        </div>

                        <h2 className="font-pnb font-size-40 text-center mt-4">
                            LAToken
                        </h2>
                        <button className="buttonBlue m-auto mt-3 custom-btn font-pnsb font-size-20 mb-5 mb-lg-0"
                            onClick={() => window.open("https://latoken.com/exchange/XDEN_USDT")}
                        >
                            Trade now
                            <ArrowRightUp className="ms-1" />
                        </button>
                    </div>
                </div>
            </div>
        </section>
    )
}


export default AvailableExchanges