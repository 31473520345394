import EthImg from "../SupportKraterSteps/assets-steps/eth-cables.png";
import SocketImg from "../SupportKraterSteps/assets-steps/socket-cables.png";

export const connectMinterSteps = [
    {
        uppertitle: "STEP 01",
        title: "Cable connections",
        punctuation: ".",
        content: <>
            <ul className="mt-3">
                <li>○ <span className="lightgrey-color font-pnr font-size-20 ">connect the cable from your ISP or from your other internet network device (switch, router) into the Minter WAN port.</span></li>
            </ul>
        </>,
        image: EthImg
    },
    {
        uppertitle: "STEP 02",
        title: "Connect to the power supply",
        punctuation: ".",
        content: <>
            <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                Securely connect the power cord into the Router and the AC adapter into the power supply as shown in the picture.
            </p>
        </>,
        image: SocketImg
    }];