import React from "react";
import Uppertitle from "../../../../components/Uppertitle/Uppertitle";

const GuardianFeaturesCard = (props) => {
  const { uppertitle, description, svgProp, bg, getStarted } = props;

  //   let shortCardDescription = "";
  //   if (props.description.length <= 40) {
  //     shortCardDescription = "mb-auto mt-auto";
  //   }

  return (
    <div className="slider-card">
      <div className={`${bg} wrapper-coloana coloana-r2 threats-blocker-card d-flex flex-column guardian-features-card`}>
        <div className="title-descp-container">
          <Uppertitle title={uppertitle} />
          <div className="mb-lg-5 mb-0 mt-lg-0 mt-4 card-icons">{svgProp && svgProp}</div>
        </div>
        <div className={`font-pnb font-size-56 white-color mb-0 line-height-11 features-description mt-auto mb-auto`}>
          {description} <span className="purple-color">.</span>
        </div>
        {getStarted && getStarted}
      </div>
    </div>
  );
};

export default GuardianFeaturesCard;
