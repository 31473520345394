import React from "react";
import "./Router.scss";
import RealRouter from "../../../assets/images/png/real-router-img.png";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import { Row, Col } from "react-bootstrap";
import { AiOutlineArrowRight as ArrowRight } from "react-icons/ai";
import { FiArrowUpRight as ArrowRightUp } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../Utils/routes";
import { Element } from "react-scroll";
import { toast } from "react-toastify";

const Router = () => {
  const navigate = useNavigate();

  const handleBuyNow = (event) => {
    event.preventDefault();
    toast.info("Coming soon!", { toastId: "guardian-node-router" });
  };

  //   const learnMore = (event) => {
  //     event.preventDefault();
  //     toast.info("Coming soon!", { toastId: "guardian-node-learn-more" });
  //   };

  return (
    <Element name="guardian-node-card-section-1">
      <section id="router-section">
        <div className="page-section">
          <div className="container-main hero-section-container">
            <Row className="flex-lg-row flex-column-reverse">
              <Col lg={5}>
                <div className="tablet-center h-100 d-flex flex-column justify-content-center align-items-start">
                  <Uppertitle title={`ROUTER`} />
                  <h2 className="mb-0 font-size-80 white-color mb-0 font-pnb line-height-11">
                    MinteR
                    <span className="purple-color">.</span>
                  </h2>
                  <div className="pt-4 pb-lg-4 pb-0 description-991">
                    <p className="font-family-pnr font-size-24 lightgrey-color line-height-11">
                      A light validation node that verifies the conformity of both data and the system’s integrated devices,
                      contributing to the network’s operation and providing resources for decentralized computing and storage
                      power.
                    </p>
                  </div>
                  <div className="corners hts-card">
                    <div className="description-col marginRight hts-card-col reverse-description">
                      <h4 className="font-size-40 font-pnb text-start">What it does?</h4>
                      <div className="hts-p-container">
                        <p className="font-family-pnr font-size-20 lightgrey-color mb-0 line-height-11 text-start">
                          It uses Delegated Staking and it allows miners to band together with available resources within the
                          network.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="buttons-flex-direction w-100 d-flex justify-content-start align-items-center pt-4">
                    <a className="buttonBlue" href="https://cryptodata.com/shop" target="_blank">
                      <span className="me-2">Buy now</span>
                      <ArrowRightUp />
                    </a>

                    <button
                      className="buttonOutlineBlue"
                      onClick={() => toast.info("Coming soon!", { toastId: "guardian-node-router2" })}
                    >
                      <span className="me-2">Configure</span>
                      <ArrowRight />
                    </button>
                  </div>
                </div>
              </Col>
              <Col lg={7} className="d-flex justify-content-center align-items-center">
                <img src={RealRouter} loading="lazy" alt="xid coin" className="img-fluid real-router-img pb-lg-0 pb-4" />
              </Col>
            </Row>
          </div>
        </div>
      </section>
    </Element>
  );
};

export default Router;
