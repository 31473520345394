import React from "react";
import "./FullPower.scss";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import LightningPhoto from "../../../assets/images/png/homePage/krater-pool.png";
import CoinPhoto from "../../../assets/images/png/homePage/connect-pool.png";
// import ArrowRight from "../../../assets/images/png/arrow-right.png";
import { AiOutlineArrowRight as ArrowRight } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../Utils/routes";
import { Col, Row } from "react-bootstrap";
import { ReactComponent as LockIco } from "../../../assets/images/svg/lock-big-bang.svg";

export default function FullPower() {
  const navigate = useNavigate();

  return (
    <section id="full-power" className="page-section">
      <div className="full-power-wrapper">
        <div className="container-main">
          <div className="power-title pb-lg-5 pb-4 ">
            <h2 className="font-pnb font-size-80  white-color line-height-11 mb-0">Full power </h2>
            <h2 className="font-pnb font-size-80  white-color line-height-11">
              with KrateR Pool<span className="purple-color">.</span>
            </h2>
          </div>

          <div className="section-zero mb-5 mt-3">
            <Row>
              <Col lg={8} className="mb-4 mb-lg-0">
                <div className="column-wrapper bg-1 d-flex flex-column justify-content-between align-items-start p-4 p-lg-5">
                  <Uppertitle title={"AGE BIG BANG"} />
                  <h4 className="font-pnb font-size-56 text-center m-auto big-bang-section-title">
                    <a
                      style={{ background: "none", color: "white!impor" }}
                      className="buttonBlue diff-buy m-auto mt-5 mb-3 diff-buy"
                      href="https://cryptodata.com/shop"
                      target="_blank"
                    >
                      <span className="me-2">Buy </span>
                    </a>{" "}
                    Minter Router <br />
                    to receive free <br />
                    <span className="blue-color">1000 locked XDEN</span> <br />
                    and use them for <br />
                    staking to become <br />a network validator<span className="purple-color">.</span>
                  </h4>
                </div>
              </Col>
              <Col lg={4}>
                <div className="column-wrapper bg-2 d-flex flex-column justify-content-between align-items-start p-4 p-lg-5">
                  <Uppertitle title={"LOCKED XDEN"} />
                  <div className="d-flex align-items-center justify-content-center flex-column m-auto">
                    <div className="mb-4">
                      <LockIco />
                    </div>
                    <h4 className="font-pnb font-size-40 text-center m-auto locked-xden-title-container">
                      Locked XDEN <br />
                      <span className="blue-color">Can be Unlocked</span> <br />
                      only in <br />
                      private difficulty <br />
                      validation process<span className="purple-color">.</span>
                    </h4>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div className="first-section d-flex align-items-center row py-5">
            <div className="section-box col-lg-6 col-12 order-lg-1 order-2 padding-end-custom">
              <Uppertitle title={"OPEN POOL"} />
              <div className="section-title pb-4 pb-lg-5 font-pnb font-size-56 white-color line-height-11">
                Open your pool <br /> to become a validator
                <span className="purple-color">.</span>
              </div>
              <div className="section-content first-paragraph font-pnr font-size-24 lightgrey-color line-height-11 description-991">
                When opening a Krater Pool, your device will become a light validator node that will verify the conformity of all
                integrated devices into the system.
              </div>
              <button className="buttonBlue mt-5" onClick={() => navigate(routes.validator)}>
                <span className="me-2">Read more</span>
                <ArrowRight />
              </button>
            </div>
            <div className="section-img col-lg-6 col-12 px-0 pb-lg-0 pb-5 order-lg-2 order-1">
              <div className="w-100 d-flex justify-content-center align-items-center">
                <img src={CoinPhoto} loading="lazy" alt="lightning-coin" className="full-power-img image-desktop-2" />
              </div>
            </div>
          </div>

          <div className="second-section d-flex align-items-center row pt-5 mt-3 mt-lg-5">
            <div className="section-img col-lg-6  px-0 pb-lg-0 pb-5 col-12">
              <div className="w-100 d-flex justify-content-center align-items-center">
                <img src={LightningPhoto} loading="lazy" alt="lightning-coin" className="full-power-img image-desktop1" />
              </div>
            </div>
            <div className="section-box col-lg-6 col-12 padding-start-custom">
              <Uppertitle title={"MORE DEVICES"} />
              <div className="section-title pb-4 pb-lg-5 font-pnb font-size-56 white-color line-height-11">
                Connect more validators <br /> in your Krater Pool <br /> to increase your power
                <span className="purple-color">.</span>
              </div>
              <div className="section-content font-pnr font-size-24 lightgrey-color line-height-11 description-991">
                The more devices you connect, the more your earnings will increase. Connecting and making available the
                computational power and storage capacity of multiple smart devices will boost your validating power.
              </div>
              <button className="buttonBlue mt-5" onClick={() => navigate(routes.validator)}>
                <span className="me-2">Read more</span>
                <ArrowRight />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
