import React from "react";
import "./Validator.scss";
import HeroSection from "./HeroSection/HeroSection";
import WhatContains from "./WhatContains/WhatContains";
import Features from "./Features/Features";
import Howitworks from "./Howitworks/Howitworks";
import ComputingPower from "./ComputingPower/ComputingPower";
import { Helmet } from "react-helmet";
import SchemaTemplate from "../../SEO/SchemaTemplate.json";
import Router from "./Router/Router";
import FAQValidator from "./FAQValidator/FAQValidator";
import KraterValidator from "./KraterValidator/KraterValidator";

const Validator = () => {
   return (
      <div className="validator-page">
         {/* seo */}
         <Helmet>
            <title>Xiden | Validator Node</title>
            <meta name="title" content="Xiden | Validator Node" />
            <meta name="description" content="The validator is a physical network node that verifies and validates the integrity of data within the system. Find out how it works and how to set up your node through our quick configuration guides." />
            <link rel="canonical" href="" />
            {/* Open Graph Facebook */}
            <meta property="og:title" content="Xiden | Validator Node" />
            <meta property="og:description" content="The validator is a physical network node that verifies and validates the integrity of data within the system. Find out how it works and how to set up your node through our quick configuration guides." />
            <meta property="og:type" content="website" />
            <meta property="og:image" content="" />
            <meta property="og:url" content="" />
            {/* Open Graph Twitter  */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="" />
            <meta property="twitter:title" content="Xiden | Validator Node" />
            <meta property="twitter:description" content="The validator is a physical network node that verifies and validates the integrity of data within the system. Find out how it works and how to set up your node through our quick configuration guides." />
            <meta property="twitter:image" content="" />
            {/* Structured Data */}
            <script>{JSON.stringify(SchemaTemplate)}</script>
         </Helmet>

         {/* sections */}
         <HeroSection />
         <Router />
         <WhatContains />
         <Features />
         <Howitworks />
         <ComputingPower />
         <KraterValidator />
         <FAQValidator />
      </div>
   );
};

export default Validator;
