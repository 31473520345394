import cryptodataOS from "../SupportKraterSteps/assets-steps/01-loginn.webp";
import encryption from "../SupportKraterSteps/assets-steps/02-guest.webp";
import guest from "../SupportKraterSteps/assets-steps/03-guest.webp";

export const connectMinterSteps = [
    {
        uppertitle: "STEP 01",
        title: "CryptoDATA OS Login",
        punctuation: ".",
        content: <>
            <ul className="mt-3 font-pnr font-size-20 line-height-11">
                <li> <span className="lightgrey-color">Power on your BASM laptop and boot on the CryptoDATA OS. </span></li>
            </ul>
        </>,
        image: cryptodataOS,
        modal: false,
    },
    {
        uppertitle: "STEP 02",
        title: "Encryption Password",
        punctuation: ".",
        content: <>
            <p className="font-pnr font-size-20 lightgrey-color line-height-11 text-start">
                Enter your encryption password or use your encrypted key to bypass this step.
            </p>
        </>,
        image: encryption,
        modal: false,
    },
    {
        uppertitle: "STEP 03",
        title: "Account Login",
        punctuation: ".",
        content: <>
            <ul className="mt-3">
                <li> <span className="lightgrey-color font-pnr font-size-20 ">Login on the "guest" account.</span></li>
            </ul>
        </>,
        image: guest,
        modal: false,
    },
];