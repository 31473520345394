import React from "react";
import "./Features.scss";
import { Col, Row } from "react-bootstrap";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";

import { ReactComponent as DecentralizedSvg } from "../../../assets/images/svg/ValidatorPage/decentralized.svg";
import { ReactComponent as Rewards } from "../../../assets/images/svg/ValidatorPage/get-rewards.svg";
import Decentralized from "../../../assets/images/png/decentralized.png";
import ConnectedDevices from "../../../assets/images/png/validator-page/ConnectedDevices.png";

import FeaturesMobile from "./FeaturesMobile";

const Features = () => {
  return (
    <section id="features-validator" className="page-section">
      <div className="container-main">
        <div className="title">
          <h2 className="font-size-80 white-color font-pnb line-height-11">
            Features
            <span className="purple-color">.</span>
          </h2>
        </div>
        <div className="row-container d-none d-md-block">
          <Row>
            <Col lg={4}>
              <div className="column-wrapper big-column p-4 p-lg-5 d-flex flex-column justify-content-between">
                <Uppertitle title={"REQUIRED"} />
                <div className="card-img">
                  <img src={ConnectedDevices} alt="connected devices" className="img-fluid connected-devices" />
                </div>
                <h4 className="white-color py-lg-0 py-4 font-size-48 font-pnb m-auto ms-0">
                  Connect all <br /> the devices <br /> in the system
                  <br /> to share
                  <br /> all the available
                  <br /> resources &#38; get
                  <br /> more rewards
                  <span className="purple-color">.</span>
                </h4>
              </div>
            </Col>
            <Col lg={8}>
              <Row className="rand d-flex justify-content-end align-items-center">
                <Col md={6}>
                  <div className="column-wrapper p-4 p-lg-5 bg2 d-flex flex-column justify-content-between">
                    <div className="">
                      <Uppertitle title={"GET REWARDED"} />
                      {/* <img src={Verify} alt="Krater pool" className="card-logo" /> */}
                      <Rewards className="card-logo" />
                      <h4 className="white-color font-size-48 font-pnb mt-4-5">
                        Get rewarded <br /> for verifying the <br /> system’s data <br /> integrity
                        <span className="purple-color">.</span>
                      </h4>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="column-wrapper p-4 p-lg-5 bg1 d-flex flex-column justify-content-between">
                    <div className="">
                      <Uppertitle title={"DECENTRALIZED"} />
                      {/* <img src={Xmlid} alt="Krater pool" className="card-logo" /> */}
                      {/* <MoreRewards className="card-logo" /> */}
                      <img src={Decentralized} alt="decentralzed-logo" className="card-logo" />
                      <h4 className="white-color font-size-48 mt-4-5 font-pnb">
                        Benefit of <br />
                        decentralized <br />
                        environment
                        <span className="purple-color">.</span>
                      </h4>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="rand d-flex justify-content-end align-items-center">
                <Col md={6}>
                  <div className="column-wrapper p-4 p-lg-5 bg3 d-flex flex-column justify-content-between">
                    <div className="">
                      <Uppertitle title={"MORE REWARDS"} />
                      {/* <img src={Device} alt="Krater pool" className="card-logo" /> */}
                      <DecentralizedSvg className="card-logo" />
                      <div className="mt-4-5">
                        <h4 className="white-color font-size-48 font-pnb">
                          Use validator <br />
                        </h4>
                        <h4 className="white-color font-size-48 font-pnb">
                          as normal <br />
                          device
                          <span className="purple-color">.</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="column-wrapper nice-bg p-4 p-lg-5 d-flex flex-column justify-content-between">
                    <Uppertitle title={"LOW ENERGY"} />
                    <div className="titles">
                      <div className="font-pnb font-size-56 low-energy line-height-11">Low energy.</div>
                      <div className="font-pnb font-size-56 black-color plant-a-tree line-height-11">
                        Plant a<span> tree.</span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <FeaturesMobile />
      </div>
    </section>
  );
};

export default Features;
