import { ReactComponent as BasmBlackIcon } from "../../../../assets/images/svg/basm.svg";
import { ReactComponent as BasmWhiteIcon } from "../../../../assets/images/svg/basm-white.svg";

export const basmMainCardData = {
  id: 1,
  upperTitle: "DEVICE",
  title: "BASM",
  deviceIcon: [<BasmBlackIcon />, <BasmWhiteIcon />],
};

export const basmCardsData = [
  {
    id: 1,
    upperTitle: "VALIDATING POWER",
    title: "83 T",
  },
  {
    id: 2,
    upperTitle: "PRIVATE DIFFICULTY",
    title: "4.32 T",
  },
  {
    id: 3,
    upperTitle: "PROFIT PER DAY",
    title: "19.2 XDEN",
  },
  {
    id: 4,
    upperTitle: "PROFIT PER MONTH",
    title: "595.6 XDEN",
  },
  {
    id: 5,
    upperTitle: "PROFIT PER YEAR",
    title: "7147.2 XDEN",
  },
  {
    id: 6,
    upperTitle: "PUBLIC DIFFICULTY",
    title: "10.08 T",
  },
  {
    id: 7,
    upperTitle: "PUBLIC DIFFICULTY",
    title: "10.08 T",
  },
  {
    id: 8,
    upperTitle: "PROFIT PER DAY",
    title: "8.2 XDEN",
  },
  {
    id: 9,
    upperTitle: "PROFIT PER MONTH",
    title: "255.3 XDEN",
  },
  {
    id: 10,
    upperTitle: "PROFIT PER YEAR",
    title: "3063.6 XDEN",
  },
];
