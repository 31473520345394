import React from 'react'
import './Navbar.scss'
import { NavLink } from 'react-router-dom'
import { NavDropdown } from 'react-bootstrap'
import { useState, createContext } from 'react'
// import NavbarMobile from "./NavbarMobile";
import { Accordion } from 'react-bootstrap'
// import Logo from "../../assets/images/png/xiden-logo.png";
import { ReactComponent as XidenLogo } from '../../assets/images/svg/xiden-logo.svg'
// import MenuArrow from "../../assets/images/png/arrow-down.png";
import { ReactComponent as Plus } from '../Assets/images/png/test-plus.svg'
import { routes } from '../../Utils/routes'
import { useNavigate, useLocation } from 'react-router-dom'
import { ReactComponent as GoldenLogo } from '../../assets/images/svg/golden-logo.svg'
import { ReactComponent as ArrowRightUp } from '../../assets/images/svg/arrow-right-up.svg'
import { ReactComponent as MenuIcon } from '../../assets/images/svg/dots.svg'
import { IoIosArrowDown as LinkArrowDown } from 'react-icons/io'

import { HashLink } from 'react-router-hash-link'

export default function Navbar() {
  const [expand, setExpand] = useState(false)
  const [expanded, setExpanded] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()
  const value = { expanded, setExpanded }

  const expandMenu = () => {
    setExpand(!expand)
  }

  const handleLinksNavigation = (linkID, mobile = false) => {
    switch (linkID) {
      case routes.home:
        navigate(routes.home)
        break
      case routes.supportUnstake:
        navigate(routes.supportUnstake)
        break

      case routes.sdrLayer:
        navigate(routes.sdrLayer)
        break
      case routes.xiden:
        navigate(routes.xiden)
        break

      case routes.xidenFeatures:
        navigate(routes.xidenFeatures)
        break

      case routes.validator:
        navigate(routes.validator)
        break

      case routes.privateNetwork:
        navigate(routes.privateNetwork)
        break

      case routes.guardianNode:
        navigate(routes.guardianNode)
        break

      case routes.performerGuardian:
        navigate(routes.performerGuardian)
        break

      case routes.minter:
        navigate(routes.minter)
        break

      case routes.apps:
        navigate(routes.apps)
        break

      case routes.ecosystem:
        navigate(routes.ecosystem)
        break

      case routes.staking:
        navigate(routes.staking)
        break

      case routes.rewards:
        navigate(routes.rewards)
        break

      case routes.supportMetamask:
        navigate(routes.supportMetamask)
        break

      case routes.supportKraterPool:
        navigate(routes.supportKraterPool)
        break

      case routes.supportKraterApp:
        navigate(routes.supportKraterApp)
        break

      case routes.supportValidator:
        navigate(routes.supportValidator)
        break

      case routes.supportStake:
        navigate(routes.supportStake)
        break

      case routes.supportHotspot:
        navigate(routes.supportHotspot)
        break

      case routes.supportReward:
        navigate(routes.supportReward)
        break

      case routes.supportUpdate:
        navigate(routes.supportUpdate)
        break

      case routes.supportTroubleshoot:
        navigate(routes.supportTroubleshoot)
        break

      case routes.supportGuide:
        navigate(routes.supportGuide)
        break
      case routes.changeLog:
        navigate(routes.changeLog)
        break

      default:
        navigate(routes.home)
    }

    if (mobile) {
      setExpand(false)
    }
  }

  const connect = 'connect',
    validate = 'validate',
    develop = 'develop',
    wallet = 'wallet',
    activeLinkColor = { color: '#22aaff' },
    activeYellowLinkColor = { color: '#fda544' },
    inactiveLinkColor = { color: '#86868b' }

  const pageRoute = location.pathname

  const handleHighlightNavDropdown = dropdownID => {
    switch (dropdownID) {
      case connect:
        if (
          pageRoute === routes.minter ||
          pageRoute === routes.xiden ||
          pageRoute === routes.xidenFeatures ||
          pageRoute === routes.validator ||
          pageRoute === routes.ecosystem ||
          pageRoute === routes.guardianNode ||
          pageRoute === routes.staking
        ) {
          return 'activeLink'
        } else {
          return ''
        }

      case validate:
        if (
          pageRoute === routes.privateNetwork ||
          pageRoute === routes.apps ||
          pageRoute === routes.sdrLayer

          // pageRoute === routes.performerGuardian
        ) {
          return 'activeLink'
        }
        // else if (pageRoute === routes.minter) {
        //    return "yellowActiveLink";
        // }
        else {
          return ''
        }

      case develop:
        if (pageRoute === routes.apps) {
          return 'activeLink'
        } else {
          return ''
        }

      case wallet:
        if (
          pageRoute === routes.supportHotspot ||
          pageRoute === routes.supportKraterPool ||
          pageRoute === routes.supportMetamask ||
          pageRoute === routes.supportReward ||
          pageRoute === routes.supportStake ||
          pageRoute === routes.supportValidator ||
          pageRoute === routes.supportUpdate ||
          pageRoute === routes.supportTroubleshoot ||
          pageRoute === routes.supportKraterApp ||
          pageRoute === routes.supportGuide
        ) {
          return 'activeLink'
        } else {
          return ''
        }

      default:
        return
    }
  }

  const handleHighlightLinkItem = linkID => {
    switch (linkID) {
      case routes.xiden:
        if (pageRoute === routes.xiden) {
          return activeLinkColor
        } else {
          return inactiveLinkColor
        }
      case routes.sdrLayer:
        if (pageRoute === routes.sdrLayer) {
          return activeLinkColor
        } else {
          return inactiveLinkColor
        }
      case routes.rewards:
        if (pageRoute === routes.rewards) {
          return activeLinkColor
        } else {
          return inactiveLinkColor
        }

      case routes.xidenFeatures:
        if (pageRoute === routes.xidenFeatures) {
          return activeLinkColor
        } else {
          return inactiveLinkColor
        }

      case routes.validator:
        if (pageRoute === routes.validator) {
          return activeLinkColor
        } else {
          return inactiveLinkColor
        }

      case routes.privateNetwork:
        if (pageRoute === routes.privateNetwork) {
          return activeLinkColor
        } else {
          return inactiveLinkColor
        }

      case routes.guardianNode:
        if (pageRoute === routes.guardianNode) {
          return activeLinkColor
        } else {
          return inactiveLinkColor
        }

      case routes.performerGuardian:
        if (pageRoute === routes.performerGuardian) {
          return activeLinkColor
        } else {
          return inactiveLinkColor
        }

      case routes.minter:
        if (pageRoute === routes.minter) {
          return activeLinkColor
        } else {
          return inactiveLinkColor
        }

      case routes.apps:
        if (pageRoute === routes.apps) {
          return activeLinkColor
        } else {
          return inactiveLinkColor
        }

      case routes.ecosystem:
        if (pageRoute === routes.ecosystem) {
          return activeLinkColor
        } else {
          return inactiveLinkColor
        }

      case routes.staking:
        if (pageRoute === routes.staking) {
          return activeLinkColor
        } else {
          return inactiveLinkColor
        }

      case routes.supportReward:
        if (pageRoute === routes.supportReward) {
          return activeLinkColor
        } else {
          return inactiveLinkColor
        }

      case routes.supportMetamask:
        if (pageRoute === routes.supportMetamask) {
          return activeLinkColor
        } else {
          return inactiveLinkColor
        }
      case routes.supportValidator:
        if (pageRoute === routes.supportValidator) {
          return activeLinkColor
        } else {
          return inactiveLinkColor
        }
      case routes.supportHotspot:
        if (pageRoute === routes.supportHotspot) {
          return activeLinkColor
        } else {
          return inactiveLinkColor
        }
      case routes.supportStake:
        if (pageRoute === routes.supportStake) {
          return activeLinkColor
        } else {
          return inactiveLinkColor
        }
      case routes.supportKraterPool:
        if (pageRoute === routes.supportKraterPool) {
          return activeLinkColor
        } else {
          return inactiveLinkColor
        }
      case routes.supportKraterApp:
        if (pageRoute === routes.supportKraterApp) {
          return activeLinkColor
        } else {
          return inactiveLinkColor
        }
      case routes.supportReward:
        if (pageRoute === routes.supportReward) {
          return activeLinkColor
        } else {
          return inactiveLinkColor
        }
      case routes.supportUpdate:
        if (pageRoute === routes.supportUpdate) {
          return activeLinkColor
        } else {
          return inactiveLinkColor
        }
      case routes.supportTroubleshoot:
        if (pageRoute === routes.supportTroubleshoot) {
          return activeLinkColor
        } else {
          return inactiveLinkColor
        }
      case routes.supportGuide:
        if (pageRoute === routes.supportGuide) {
          return activeLinkColor
        } else {
          return inactiveLinkColor
        }
      case routes.changeLog:
        if (pageRoute === routes.changeLog) {
          return activeLinkColor
        } else {
          return inactiveLinkColor
        }

      default:
        return inactiveLinkColor
    }
  }

  return (
    <nav className='container-nav'>
      <div
        className={`NavBar ${
          location.pathname === routes.minter
            ? 'blue-bg-color'
            : 'gray-bg-color'
        } `}
      >
        <div className='container-main'>
          <div className='navigation d-flex flex-row justify-content-between align-items-center align-content-center'>
            <a
              className='cursor-pointer'
              onClick={() => handleLinksNavigation(routes.home, true)}
            >
              {/* {location.pathname === routes.minter ? (
                        <GoldenLogo className="xiden-logo" />
                     ) : (
                        // <img src={Logo} alt="XidenLogo" className="xiden-logo" />
                        <XidenLogo className="xiden-logo" />
                     )} */}

              <XidenLogo className='xiden-logo' />
            </a>

            <div className='flex-row justify-content-between align-items-center align-content-center navigation-menu '>
              {/* connect */}

              <div>
                <NavLink
                  to='#'
                  className='nav-item darkgrey-color font-size-16 font-pnr'
                />

                <NavDropdown
                  title={
                    <>
                      Ecosystem
                      {/* <img src={MenuArrow} alt="MenuArrow" /> */}
                      <LinkArrowDown className='ms-1' />
                    </>
                  }
                  className={`nav-item darkgrey-color font-size-16 font-pnr ${handleHighlightNavDropdown(
                    connect
                  )} ${pageRoute === routes.minter && 'meta-dropdown'}`}
                >
                  <NavDropdown.Item
                    style={handleHighlightLinkItem(routes.xiden)}
                    onClick={() => handleLinksNavigation(routes.xiden)}
                  >
                    Blockchain
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    style={handleHighlightLinkItem(routes.xidenFeatures)}
                    onClick={() => handleLinksNavigation(routes.xidenFeatures)}
                  >
                    Digital Asset XDEN
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    style={handleHighlightLinkItem(routes.staking)}
                    onClick={() => handleLinksNavigation(routes.staking)}
                  >
                    Consensus
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    style={handleHighlightLinkItem(routes.validator)}
                    onClick={() => handleLinksNavigation(routes.validator)}
                  >
                    Validator
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    style={handleHighlightLinkItem(routes.guardianNode)}
                    onClick={() => handleLinksNavigation(routes.guardianNode)}
                  >
                    Guardian Node
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    style={handleHighlightLinkItem(routes.minter)}
                    onClick={() => handleLinksNavigation(routes.minter)}
                  >
                    Meta Realm
                  </NavDropdown.Item>

                  <a
                    href=' https://xiden.com/whitepaper/'
                    className='dropdown-item'
                    target='_blank'
                  >
                    Whitepaper
                  </a>
                </NavDropdown>
              </div>

              {/* validate */}
              <div>
                <NavLink
                  to='#'
                  className='nav-item darkgrey-color font-size-16 font-pnr'
                />
                <NavDropdown
                  title={
                    <>
                      Utility Layers
                      {/* <img src={MenuArrow} alt="MenuArrow" /> */}
                      <LinkArrowDown className='ms-1' />
                    </>
                  }
                  className={`nav-item darkgrey-color font-size-16 font-pnr ${handleHighlightNavDropdown(
                    validate
                  )} ${pageRoute === routes.minter && 'meta-dropdown'}`}
                >
                  <NavDropdown.Item
                    style={handleHighlightLinkItem(routes.sdrLayer)}
                    onClick={() => handleLinksNavigation(routes.sdrLayer)}
                  >
                    SDR
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    style={handleHighlightLinkItem(routes.privateNetwork)}
                    onClick={() => handleLinksNavigation(routes.privateNetwork)}
                  >
                    Spectralis
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    style={handleHighlightLinkItem(routes.apps)}
                    onClick={() => handleLinksNavigation(routes.apps)}
                  >
                    DApps
                  </NavDropdown.Item>

                  {/* <NavDropdown.Item
                              style={handleHighlightLinkItem(routes.performerGuardian)}
                              onClick={() => handleLinksNavigation(routes.performerGuardian)}
                           >
                              Performer Guardian
                           </NavDropdown.Item> */}
                  {/* <NavDropdown.Item
                    style={handleHighlightLinkItem(routes.minter)}
                    onClick={() => handleLinksNavigation(routes.minter)}
                  >
                    Meta Realm
                  </NavDropdown.Item> */}
                </NavDropdown>
              </div>

              {/* develop */}
              <div>
                <NavDropdown.Item
                  style={handleHighlightLinkItem(routes.rewards)}
                  onClick={() => handleLinksNavigation(routes.rewards)}
                  className='nav-item darkgrey-color explorer-link font-size-16 font-pnr d-apps'
                >
                  Rewards
                </NavDropdown.Item>
              </div>

              <div>
                <NavDropdown.Item
                  style={handleHighlightLinkItem(routes.changeLog)}
                  onClick={() => handleLinksNavigation(routes.changeLog)}
                  className='nav-item darkgrey-color explorer-link font-size-16 font-pnr d-apps'
                >
                  Updates
                </NavDropdown.Item>
              </div>
              {/* wallet */}
              <div>
                <NavLink
                  to='#'
                  className='nav-item darkgrey-color font-size-16 font-pnr'
                />
                <NavDropdown
                  title={
                    <>
                      How to
                      {/* <img src={MenuArrow} alt="MenuArrow" /> */}
                      <LinkArrowDown className='ms-1' />
                    </>
                  }
                  className={`nav-item darkgrey-color font-size-16 font-pnr ${handleHighlightNavDropdown(
                    wallet
                  )} ${pageRoute === routes.minter && 'meta-dropdown'}`}
                >
                  {/* <NavDropdown.Item
                              style={handleHighlightLinkItem(routes.explorer)}
                              onClick={() => window.open("https://explorer.xiden.com/")}
                           >      Explorer
                                   </NavDropdown.Item> */}

                  <NavDropdown.Item
                    style={handleHighlightLinkItem(routes.supportKraterPool)}
                    onClick={() =>
                      handleLinksNavigation(routes.supportKraterPool)
                    }
                  >
                    Configure MinteR Guardian
                  </NavDropdown.Item>
                  {/* <NavDropdown.Item
                    style={handleHighlightLinkItem(routes.supportStake)}
                    onClick={() => handleLinksNavigation(routes.supportStake)}
                  >
                    Configure KrateR Pool
                  </NavDropdown.Item> */}

                  {/* <NavDropdown.Item
                    style={handleHighlightLinkItem(routes.supportStake)}
                    onClick={() => handleLinksNavigation(routes.supportStake)}
                  >
                    Configure KrateR Pool
                  </NavDropdown.Item> */}
                  <NavDropdown.Item
                    style={handleHighlightLinkItem(routes.supportValidator)}
                    onClick={() =>
                      handleLinksNavigation(routes.supportValidator)
                    }
                  >
                    Setup Validator
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    style={handleHighlightLinkItem(routes.supportUpdate)}
                    onClick={() => handleLinksNavigation(routes.supportUpdate)}
                  >
                    Change Minter settings
                  </NavDropdown.Item>
                  {/* <NavDropdown.Item
                    style={handleHighlightLinkItem(routes.supportTroubleshoot)}
                    onClick={() =>
                      handleLinksNavigation(routes.supportTroubleshoot)
                    }
                  >
                    Troubleshoot
                  </NavDropdown.Item> */}
                  {/* <NavDropdown.Item
                    style={handleHighlightLinkItem(routes.supportHotspot)}
                    onClick={() => handleLinksNavigation(routes.supportHotspot)}
                  >
                    Setup Hotspot
                  </NavDropdown.Item> */}

                  <NavDropdown.Item
                    style={handleHighlightLinkItem(routes.supportMetamask)}
                    onClick={() =>
                      handleLinksNavigation(routes.supportMetamask)
                    }
                  >
                    Setup Network
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    style={handleHighlightLinkItem(routes.supportKraterApp)}
                    onClick={() =>
                      handleLinksNavigation(routes.supportKraterApp)
                    }
                  >
                    Krater Validator App for Basm
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    style={handleHighlightLinkItem(routes.supportGuide)}
                    onClick={() => handleLinksNavigation(routes.supportGuide)}
                  >
                    Krater Validator App for Smartphone
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    style={handleHighlightLinkItem(routes.supportUnstake)}
                    onClick={() => handleLinksNavigation(routes.supportUnstake)}
                  >
                    Support Unstake
                  </NavDropdown.Item>
                  {/* <NavDropdown.Item
                    style={handleHighlightLinkItem(routes.supportReward)}
                    onClick={() => handleLinksNavigation(routes.supportReward)}
                  >
                    Earn  Rewards
                  </NavDropdown.Item> */}
                </NavDropdown>
              </div>
              <div>
                <a
                  onClick={() => window.open('https://explorer.xiden.com/')}
                  className={`nav-item darkgrey-color explorer-link font-size-16 font-pnr`}
                >
                  Explorer
                </a>
              </div>
            </div>

            {/* <HashLink className="getXDN-btn d-flex flex-row justify-content-center align-items-center align-content-center" smooth to='/#homepage-countdown' >
                     <div className="buy-mobile-btn font-pnsb font-size-18">POOL</div>
                     <ArrowRightUp />
                  </HashLink> */}
            {/* <div></div> */}

            <button
              //  className={`${location.pathname === routes.minter ? "getXDN-btn-gold" : "getXDN-btn"
              className={`getXDN-btn d-flex flex-row justify-content-center align-items-center align-content-center`}
              onClick={() => window.open('https://pools.xiden.com/')}
            >
              <div className='buy-mobile-btn font-pnsb font-size-18 line-height-1'>
                Pools Login
              </div>
              <ArrowRightUp />
            </button>

            {/* mobile menu */}
            <div className='tabletMenuBtn '>
              <button
                className='menu-btn d-flex justify-content-center align-items-center align-content-center'
                onClick={expandMenu}
              >
                <MenuIcon color='#22aaff' size={50} height='40px' />
              </button>
              <div
                className={
                  expand
                    ? expanded
                      ? 'menu-collapsed show-big'
                      : 'menu-collapsed show-small'
                    : 'menu-collapsed'
                }
              >
                <div className='NavbarMobile'>
                  <Accordion className='accordion-navbar d-flex flex-column '>
                    {/* connect */}
                    <Accordion.Item className='gray-bg-color' eventKey='0'>
                      <Accordion.Header
                        className={`font-pnr font-size-16  ${
                          pageRoute === routes.minter &&
                          'meta-realm-accordion-button'
                        }`}
                        onClick={() => {
                          window.open('https://explorer.xiden.com/')
                          setExpand(false)
                        }}
                      >
                        Explorer
                      </Accordion.Header>
                    </Accordion.Item>
                    <Accordion.Item
                      className={`${
                        location.pathname === routes.minter
                          ? 'blue-bg-color'
                          : 'gray-bg-color'
                      }`}
                      eventKey='1'
                    >
                      <Accordion.Header
                        className={`font-pnr font-size-16 ${handleHighlightNavDropdown(
                          connect
                        )} ${
                          pageRoute === routes.minter &&
                          'meta-realm-accordion-button'
                        }`}
                      >
                        Ecosystem{' '}
                        <Plus className='button-transition button-transition-redo' />
                      </Accordion.Header>
                      <Accordion.Body
                        className={`${
                          location.pathname === routes.minter
                            ? 'blue-bg-color'
                            : 'gray-bg-color'
                        }`}
                      >
                        <div className='d-flex flex-column m-2 '>
                          <a
                            onClick={() =>
                              handleLinksNavigation(routes.xiden, true)
                            }
                            className='nav-item darkgrey-color font-size-16 font-pnr p-2 text-decoration-none'
                            style={handleHighlightLinkItem(routes.xiden)}
                          >
                            Blockchain
                          </a>
                          <a
                            onClick={() =>
                              handleLinksNavigation(routes.xidenFeatures, true)
                            }
                            className='nav-item darkgrey-color font-size-16 font-pnr p-2 text-decoration-none'
                            style={handleHighlightLinkItem(
                              routes.xidenFeatures
                            )}
                          >
                            Digital Asset XDEN
                          </a>

                          <a
                            onClick={() =>
                              handleLinksNavigation(routes.staking, true)
                            }
                            className='nav-item darkgrey-color font-size-16 font-pnr p-2 text-decoration-none'
                            style={handleHighlightLinkItem(routes.staking)}
                          >
                            Consensus
                          </a>

                          <a
                            onClick={() =>
                              handleLinksNavigation(routes.validator, true)
                            }
                            className='nav-item darkgrey-color font-size-16 font-pnr p-2 text-decoration-none'
                            style={handleHighlightLinkItem(routes.validator)}
                          >
                            Validator
                          </a>

                          <a
                            onClick={() =>
                              handleLinksNavigation(routes.guardianNode, true)
                            }
                            className='nav-item darkgrey-color font-size-16 font-pnr p-2 text-decoration-none'
                            style={handleHighlightLinkItem(routes.guardianNode)}
                          >
                            Guardian Node
                          </a>
                          <a
                            onClick={() =>
                              handleLinksNavigation(routes.minter, true)
                            }
                            className='nav-item darkgrey-color font-size-16 font-pnr p-2 text-decoration-none'
                            style={handleHighlightLinkItem(routes.minter)}
                          >
                            Meta Realm
                          </a>
                          <a
                            href=' https://xiden.com/whitepaper/'
                            className='nav-item darkgrey-color font-size-16 font-pnr p-2 text-decoration-none'
                            target='_blank'
                          >
                            Whitepaper
                          </a>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>

                    {/* validate */}
                    <Accordion.Item
                      className={`${
                        location.pathname === routes.minter
                          ? 'blue-bg-color'
                          : 'gray-bg-color'
                      }`}
                      eventKey='2'
                    >
                      <Accordion.Header
                        className={`font-pnr font-size-16 ${handleHighlightNavDropdown(
                          validate
                        )} ${
                          pageRoute === routes.minter &&
                          'meta-realm-accordion-button'
                        }`}
                      >
                        Utility Layers{' '}
                        <Plus className='button-transition button-transition-redo' />
                      </Accordion.Header>

                      <Accordion.Body
                        className={`${
                          location.pathname === routes.minter
                            ? 'blue-bg-color'
                            : 'gray-bg-color'
                        }`}
                      >
                        <div className='d-flex flex-column m-2'>
                          <a
                            onClick={() =>
                              handleLinksNavigation(routes.sdrLayer, true)
                            }
                            className='nav-item darkgrey-color font-size-16 font-pnr p-2 text-decoration-none'
                            style={handleHighlightLinkItem(routes.sdrLayer)}
                          >
                            SDR
                          </a>
                          <a
                            onClick={() =>
                              handleLinksNavigation(routes.privateNetwork, true)
                            }
                            className='nav-item darkgrey-color font-size-16 font-pnr p-2 text-decoration-none'
                            style={handleHighlightLinkItem(
                              routes.privateNetwork
                            )}
                          >
                            Spectralis
                          </a>
                          <a
                            onClick={() =>
                              handleLinksNavigation(routes.apps, true)
                            }
                            className='nav-item darkgrey-color font-size-16 font-pnr p-2 text-decoration-none'
                            style={handleHighlightLinkItem(routes.apps)}
                          >
                            DApps
                          </a>
                          {/* <a
                                          onClick={() => handleLinksNavigation(routes.performerGuardian, true)}
                                          className="nav-item darkgrey-color font-size-16 font-pnr p-2 text-decoration-none"
                                          style={handleHighlightLinkItem(routes.performerGuardian)}
                                       >
                                          Performer Guardian
                                       </a> */}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>

                    {/* develop */}
                    <Accordion.Item className='gray-bg-color' eventKey='0'>
                      <Accordion.Header
                        className={`font-pnr font-size-16  ${
                          pageRoute === routes.minter &&
                          'meta-realm-accordion-button'
                        }`}
                        onClick={() =>
                          handleLinksNavigation(routes.rewards, true)
                        }
                      >
                        Rewards
                      </Accordion.Header>
                    </Accordion.Item>
                    <Accordion.Item className='gray-bg-color' eventKey='0'>
                      <Accordion.Header
                        className={`font-pnr font-size-16  ${
                          pageRoute === routes.minter &&
                          'meta-realm-accordion-button'
                        }`}
                        onClick={() =>
                          handleLinksNavigation(routes.changeLog, true)
                        }
                      >
                        Updates
                      </Accordion.Header>
                    </Accordion.Item>

                    {/* wallet */}
                    <Accordion.Item
                      className={`${
                        location.pathname === routes.minter
                          ? 'blue-bg-color'
                          : 'gray-bg-color'
                      }`}
                      eventKey='4'
                    >
                      <Accordion.Header
                        className={`font-pnr font-size-16 ${handleHighlightNavDropdown(
                          wallet
                        )} ${
                          pageRoute === routes.minter &&
                          'meta-realm-accordion-button'
                        }`}
                      >
                        How to
                        <Plus className='button-transition button-transition-redo' />
                      </Accordion.Header>

                      <Accordion.Body
                        className={`${
                          location.pathname === routes.minter
                            ? 'blue-bg-color'
                            : 'gray-bg-color'
                        }`}
                      >
                        <div className='d-flex flex-column m-2'>
                          <a
                            onClick={() =>
                              handleLinksNavigation(
                                routes.supportKraterPool,
                                true
                              )
                            }
                            className='nav-item darkgrey-color font-size-16 font-pnr p-2 text-decoration-none'
                            style={handleHighlightLinkItem(
                              routes.supportKraterPool
                            )}
                          >
                            Configure MinteR Guardian
                          </a>

                          {/* <a
                            onClick={() =>
                              handleLinksNavigation(routes.supportStake, true)
                            }
                            className="nav-item darkgrey-color font-size-16 font-pnr p-2 text-decoration-none"
                            style={handleHighlightLinkItem(routes.supportStake)}
                          >
                            Configure KrateR Pool
                          </a> */}
                          {/* <a
                                          
                                          className="nav-item darkgrey-color font-size-16 font-pnr p-2 text-decoration-none"
                                          style={handleHighlightLinkItem(routes.explorer)}
                                          onClick={() => window.open("https://explorer.xiden.com/")}
                                         
                                       >   Explorer</a> */}

                          <a
                            onClick={() =>
                              handleLinksNavigation(
                                routes.supportValidator,
                                true
                              )
                            }
                            className='nav-item darkgrey-color font-size-16 font-pnr p-2 text-decoration-none'
                            style={handleHighlightLinkItem(
                              routes.supportValidator
                            )}
                          >
                            Setup Validator
                          </a>
                          <a
                            onClick={() =>
                              handleLinksNavigation(routes.supportUpdate, true)
                            }
                            className='nav-item darkgrey-color font-size-16 font-pnr p-2 text-decoration-none'
                            style={handleHighlightLinkItem(
                              routes.supportUpdate
                            )}
                          >
                            Change Minter settings
                          </a>
                          {/* <a
                            onClick={() =>
                              handleLinksNavigation(
                                routes.supportTroubleshoot,
                                true
                              )
                            }
                            className="nav-item darkgrey-color font-size-16 font-pnr p-2 text-decoration-none"
                            style={handleHighlightLinkItem(
                              routes.supportTroubleshoot
                            )}
                          >
                            Troubleshoot
                          </a> */}
                          {/* <a
                            onClick={() =>
                              handleLinksNavigation(routes.supportHotspot, true)
                            }
                            className="nav-item darkgrey-color font-size-16 font-pnr p-2 text-decoration-none"
                            style={handleHighlightLinkItem(
                              routes.supportHotspot
                            )}
                          >
                            Setup  Hotspot
                          </a> */}
                          <a
                            onClick={() =>
                              handleLinksNavigation(
                                routes.supportMetamask,
                                true
                              )
                            }
                            className='nav-item darkgrey-color font-size-16 font-pnr p-2 text-decoration-none'
                            style={handleHighlightLinkItem(
                              routes.supportMetamask
                            )}
                          >
                            Setup Network
                          </a>
                          <a
                            onClick={() =>
                              handleLinksNavigation(
                                routes.supportKraterApp,
                                true
                              )
                            }
                            className='nav-item darkgrey-color font-size-16 font-pnr p-2 text-decoration-none'
                            style={handleHighlightLinkItem(
                              routes.supportKraterApp
                            )}
                          >
                            Krater Validator App for Basm
                          </a>
                          <a
                            onClick={() =>
                              handleLinksNavigation(routes.supportGuide, true)
                            }
                            className='nav-item darkgrey-color font-size-16 font-pnr p-2 text-decoration-none'
                            style={handleHighlightLinkItem(routes.supportGuide)}
                          >
                            Krater Validator App for Smartphone
                          </a>
                          {/* <a
                            onClick={() =>
                              handleLinksNavigation(routes.supportReward, true)
                            }
                            className="nav-item darkgrey-color font-size-16 font-pnr p-2 text-decoration-none"
                            style={handleHighlightLinkItem(
                              routes.supportReward
                            )}
                          >
                            Earn Rewards
                          </a> */}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>

          {/* {
            location.pathname !== routes.miningCompetition &&
            <div onClick={() => navigate(routes.miningCompetition)} className="mining-competition-banner w-100 white-color font-pnb  text-center">
              <div className="d-flex align-items-center justify-content-center font-size-24 h-100">
                Click here to see the Mining Competition
              </div>
            </div>

          } */}
        </div>
      </div>
    </nav>
  )
}
