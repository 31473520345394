import React from "react";
import { Col, Row } from "react-bootstrap";
import "./WhatIsSpectralis.scss";
import HowDo from "../../../assets/images/png/private-network-page/how-do-i-use-it.png";
import WhatIs from "../../../assets/images/png/private-network-page/what-is-spectralis.png";
import VerticalLine1 from "../../../assets/images/png/private-network-page/vertical-1.png";
import HorizontalLine from "../../../assets/images/png/private-network-page/horizontal-line.png";
// import {ReactComponent as WhatIs} from "../../../assets/images/svg/SpectralisPrivateNetworkPage/what-is-spectralis.svg";
// import {ReactComponent as HowDo} from "../../../assets/images/svg/SpectralisPrivateNetworkPage/how-do-i-use-it.svg";

const WhatIsSpectralis = () => {
  return (
    <section id="what-is-spectralis-network" className="page-section">
      <div className="container-main d-flex flex-column">
        <img src={HorizontalLine} alt="line" className="img-fluid" />
        <Row className="py-3 my-lg-5 justify-content-center align-items-center">
          <Col md={4}>
            <div className="d-flex align-items-center justify-content-center h-100 my-3 my-md-0">
              <img src={WhatIs} alt="how do i use it" className="img-fluid content-image" />
              {/* <WhatIs className="img-fluid" /> */}
            </div>
          </Col>
          <div className="horizontal-line d-md-block d-none">
            <img src={VerticalLine1} alt="line" />
          </div>
          <Col md={7}>
            <div className="d-flex flex-column align-items-center align-items-md-start justify-content-center h-100 p-0 p-md-4 p-lg-5 pb-0 pb-lg-5">
              <h2 className="font-pnsb font-size-32 line-height-11 mb-3 custom-text">Connectivity. Security. Privacy.</h2>
              <p className="font-pnr font-size-24 line-height-11 custom-text darkgrey-color">
                Spectralis is a network that forms an internet layer of virtual tunnels that allows you to improve your privacy
                and security on the Internet. It works by sending your traffic through three random servers (relays) within the
                network. Thus, according to user needs, each device is an intermediate point of information transfer or an exit
                relay.
              </p>
            </div>
          </Col>
        </Row>
        <img src={HorizontalLine} alt="line" className="img-fluid" />
        <Row className="py-3 my-lg-5 flex-md-row flex-column-reverse justify-content-center align-items-center">
          <Col md={7}>
            <div className="d-flex flex-column align-items-center align-items-md-start justify-content-center h-100 p-0 p-md-4 p-lg-5 pb-0 pb-lg-5">
              <h2 className="font-pnsb font-size-32 line-height-11 mb-3 custom-text">Easy. Fast. Free.</h2>
              <p className="font-pnr font-size-24 line-height-11 custom-text darkgrey-color">
                Users with Xiden registered devices can connect automatically to any Spectralis Hotspot without being prompted for
                administrator permissions (username and password). Any device not registered in the network can connect to the
                Hotspot if it has administrator permissions. Any Hotspot-connected devices regardless of whether it is registered
                in the network or not will benefit from the network’s features.
              </p>
            </div>
          </Col>
          <div className="horizontal-line d-md-block d-none">
            <img src={VerticalLine1} alt="line" />
          </div>
          <Col md={4}>
            <div className="d-flex align-items-center justify-content-center h-100 my-3 my-md-0">
              <img src={HowDo} alt="how do i use it" className="img-fluid content-image" />
              {/* <HowDo className="img-fluid" /> */}
            </div>
          </Col>
        </Row>
        <img src={HorizontalLine} alt="line" className="img-fluid" />
      </div>
    </section>
  );
};

export default WhatIsSpectralis;
