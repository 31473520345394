import React from 'react';
import "./Register.scss";
import DashRight from "../assets/dash-right.png";
import DashLeft from "../assets/dash-left.png";
import Uppertitle from '../../../components/Uppertitle/Uppertitle';
import { Row, Col } from 'react-bootstrap';
import RegisterPool from './RegisterPool/RegisterPool';
import RegisterPerformance from './RegisterPerformance/RegisterPerformance';
import TermsAndConditions from "../assets/Terms/T&C_Mining_Competition.pdf"

const Register = () => {
  return (
    <section id="prizes-register-section" className="page-section ">
            <div className="container-main">
                <div className="title-container d-flex flex-column align-items-center mb-5">
                    <img src={DashLeft} alt="dash" className='me-auto img-fluid' />
                    <h2 className="my-4 font-size-80 white-color font-pnb line-height-1 text-center my-0">Register Now<span className="purple-color">.</span></h2>

                    <img src={DashRight} alt="dash" className='ms-auto img-fluid' />
                </div>


                <Uppertitle title={"REGISTER"} />

                <div className='prizes py-5'>
                    <Row className="justify-content-between">
                        <Col xl={5} lg={6} md={6} sm={12}>
                            <RegisterPool />
                        </Col>
                        <Col xl={5} lg={6} md={6} sm={12}>
                            <RegisterPerformance />
                        </Col>
                    </Row>
                </div>




                <Uppertitle title={"NOTE"} />

                <div className='font-size-20 font-pnr lightgrey-color'>
                    Note1: The email you enter in the form must be the same one you used to register your pool account.
                </div>
                <div className='font-size-20 font-pnr lightgrey-color'>
                    Note2: All participants are required to have a registered Xiden Pools account.
                </div>
                <div className='font-size-20 font-pnr lightgrey-color unselectable'>
                    <span className='purple-color pointer' onClick={() => window.open(TermsAndConditions)}>*Click here to see the terms and conditions for Mining Competition.</span>
                </div>

            </div>
    </section>
  )
}

export default Register