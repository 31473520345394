import React from "react";
import "./Rewards.scss";
import HeroSection from "./HeroSection/HeroSection";
import InfoCards from "./InfoCards/InfoCards";
import GetRewards from "./GetRewards/GetRewards";
import ComputingPower from "./ComputingPower/ComputingPower";
// import Devices from "./Devices/Devices";
import Boosters from "./Boosters/Boosters";
import Calculator from "./Calculator/Calculator";
import Power from "./Power/Power";
import DApps from "./DApps/DApps";
import { Helmet } from "react-helmet";
import SchemaTemplate from "../../SEO/SchemaTemplate.json";

const Rewards = () => {
  return (
    <div className="rewards-page">
      {/* seo */}
      <Helmet>
        <title>Xiden | Rewards</title>
        <meta name="title" content="Xiden | Rewards" />
        <meta
          name="description"
          content="Every active validator node is rewarded by the Xiden Network based on jobs collected and validated in order to ensure the availability and functionality of the system."
        />
        <link rel="canonical" href="" />
        {/* Open Graph Facebook */}
        <meta property="og:title" content="Xiden | Rewards" />
        <meta
          property="og:description"
          content="Every active validator node is rewarded by the Xiden Network based on jobs collected and validated in order to ensure the availability and functionality of the system."
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        {/* Open Graph Twitter  */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="" />
        <meta property="twitter:title" content="Xiden | Rewards" />
        <meta
          property="twitter:description"
          content="Every active validator node is rewarded by the Xiden Network based on jobs collected and validated in order to ensure the availability and functionality of the system."
        />
        <meta property="twitter:image" content="" />
        {/* Structured Data */}
        <script>{JSON.stringify(SchemaTemplate)}</script>
      </Helmet>

      {/* sections */}
      <HeroSection />
      <InfoCards />
      <GetRewards />
      {/* <ComputingPower /> */}
      {/* <Devices /> */}
      <Boosters />
      <Calculator />
      <Power />
      <DApps />
    </div>
  );
};

export default Rewards;
