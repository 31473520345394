import React from "react";
import "./Spectralis.scss";
import RouterImage from "../../../assets/images/png/private-network-page/private-network-router.png";

import Icon5G from "../../../assets/images/png/private-network-page/5g.png";
import Icon2G from "../../../assets/images/png/private-network-page/2g.png";
import { Element } from "react-scroll";
import { ReactComponent as SignalLogo } from "../../../assets/images/svg/SpectralisPrivateNetworkPage/hotspot.svg";

const Spectralis = () => {
  return (
    <Element name="private-network-section-element">
      <section id="private-network-section" className="page-section">
        <div className="container-main hero-section-container">
          <div className="main-col">
            <div className="icons-container d-flex align-items-center justify-content-center">
              <img src={Icon5G} loading="lazy" alt="5g" className="network-icon icon-5g me-5" />
              <img src={Icon2G} loading="lazy" alt="2g" className="network-icon icon-2g ms-5" />
            </div>
            <div className="section-image-col d-flex flex-column align-items-center justify-content-center mt-5">
              <SignalLogo />

              <div className="hotspot-titles mt-3 text-center">
                <h2 className="pe-custom font-pnb font-size-80 line-height-11">Open Hotspot</h2>
                <h2 className="ps-custom font-pnb font-size-80 line-height-11">&#38; Earn Reward$</h2>
                <p className="font-pnr font-size-28 lightgrey-color text-center line-height-11 mb-0">
                  Open your Hotspot through your Minter Guardian, pair multiple devices to increase your total validation power,
                  and receive higher rewards.
                </p>
              </div>
              {/* <img src={RouterImage} loading="lazy" alt="router" className="section-image img-fluid" />
               

                  <div className="d-flex flex-column align-items-start special-container">
                
                  <div className="network-title-container">
                     <h3 className="mb-0 font-size-80 font-pnb line-height-11 spectralis-title">
                        Together 
                        <br></br> we build 
                        <br></br> the biggest<br></br> decentralized <br></br> network<span className="pink-text">.</span></h3> */}
              {/* <h3 className="mb-0 font-size-80 font-pnb line-height-11">we build</h3>
                     <h3 className="mb-0 font-size-80 font-pnb line-height-11">the biggest</h3>
                     <h3 className="mb-0 font-size-80 font-pnb line-height-11">decentralized</h3>
                     <h3 className="mb-0 font-size-80 font-pnb line-height-11">network
                     <span className="pink-text">.</span> */}
              {/* </h3> */}
              {/* </div>
                  </div> */}
            </div>
          </div>
        </div>
      </section>
    </Element>
  );
};

export default Spectralis;
