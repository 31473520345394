import React from "react";
import "./SupportSteps.scss";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import { AiOutlineArrowRight as ArrowRight } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../Utils/routes";
import { HashLink } from "react-router-hash-link";
import { Link as ScrollLink } from "react-scroll";
import { toast } from "react-toastify";
import Step1 from "./assets-steps/ChangePassword/step1.png";
import Step2 from "./assets-steps/ChangePassword/step2.png";
import Step3 from "./assets-steps/ChangePassword/step3.png";
import Step4 from "./assets-steps/ChangePassword/step4.png";
import Step5 from "./assets-steps/ChangePassword/step5.png";


const SupportChangePassword = () => {
  const navigate = useNavigate();

  return (
    <section id="guardian-steps-section" className="reward-steps-section">
      <div className="page-section text-white">
        <div className="">

          {/* cards */}
          <div className="">
            {/* configure */}
            <div className="row">
              <div className="col-md-7 col-12">
                <h4 className="mb-4 font-size-64 font-pnb get-reward-title line-height-11 mt-3">
                  How to change your WiFi <br />
                  password on your MinteR<span className="purple-color">.</span>
                </h4>
              </div>
            </div>

            <div className="corners hts-card p-lg-5 p-4">
              <div className="rotate-uppertitle">
                <Uppertitle title={"STEP 01"} />
              </div>
              <div className="description-col marginRight hts-card-col reverse-description">
                <p className="pb-4 font-size-40 font-pnb text-start line-height-11">
                  Log in with default username and password
                  <span className="purple-color">.</span>
                </p>
                <div className="hts-p-container">
                  <div className="w-100 text-center text-md-start font-family-pnr font-size-20 lightgrey-color line-height-11 text-start mt-2">
                    Login into your Minter Control Panel. Use the default username and password.
                    <br />
                    Username: <span className="font-pnb purple-color">root</span> <br />
                    Password: <span className="font-pnb purple-color">CryptoData2022</span>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center p-lg-0 p-sm-5 p-3 hts-card-col reverse-img-col">
                <img
                  src={Step1}
                  loading="lazy"
                  alt="router"
                  className="img-fluid border-radius-16"
                />
              </div>
            </div>

            <div className="corners hts-card p-lg-5 p-4">
              <div className="rotate-uppertitle right-0">
                <Uppertitle title={"STEP 02"} />
              </div>
              <div className="d-flex justify-content-center align-items-center marginRight hts-card-col">
                <img
                  src={Step2}
                  loading="lazy"
                  alt="transactions"
                  className="hts-image img-fluid mb-md-0 mb-5 border-radius-16 "
                />
              </div>
              <div className="description-col hts-card-col">
                <p className="mb-4 font-size-40 font-pnb line-height-11">
                  Change default name
                  <span className="purple-color">.</span>
                </p>
                <div className="hts-p-container">
                  <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                    Click on “Network” on the left hand side dashboard and select “Network name (SSID)” to change the Minter's name.
                  </p>
                </div>
              </div>
            </div>

            <div className="corners hts-card p-lg-5 p-4">
              <div className="rotate-uppertitle">
                <Uppertitle title={"STEP 03"} />
              </div>
              <div className="description-col marginRight hts-card-col reverse-description">
                <div className="mb-4 font-size-40 font-pnb line-height-11">
                  <p className="mb-0">
                    Change default password
                    <span className="purple-color">.</span>
                  </p>
                </div>
                <div className="hts-p-container">
                  <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                    Select “Password” to enter your personalized password and confirm it.
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center hts-card-col reverse-img-col">
                <img
                  src={Step3}
                  loading="lazy"
                  alt="transactions"
                  className="hts-image  img-fluid mb-md-0 mb-5  border-radius-16"
                />
              </div>
            </div>
            <div className="corners hts-card p-lg-5 p-4">
              <div className="rotate-uppertitle right-0">
                <Uppertitle title={"STEP 04"} />
              </div>
              <div className="d-flex justify-content-center align-items-center marginRight hts-card-col">
                <img
                  src={Step4}
                  loading="lazy"
                  alt="transactions"
                  className="hts-image img-fluid mb-md-0 mb-5  border-radius-16"
                />
              </div>
              <div className="description-col hts-card-col">
                <p className="mb-4 font-size-40 font-pnb line-height-11">
                  Restart MinteR
                  <span className="purple-color">.</span>
                </p>
                <div className="hts-p-container">
                  <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                    To successfully save your personalized password and name, click on “Restart”.
                  </p>
                </div>
              </div>
            </div>

            <div className="corners hts-card p-lg-5 p-4">
              <div className="rotate-uppertitle">
                <Uppertitle title={"STEP 05"} />
              </div>
              <div className="description-col marginRight hts-card-col reverse-description">
                <div className="mb-4 font-size-40 font-pnb line-height-11">
                  <p className="mb-0">
                    Status
                    <span className="purple-color">.</span>
                  </p>
                </div>
                <div className="hts-p-container">
                  <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                    Click on “Overview” on the left hand side dashboard to check the status of your Minter devices.
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center hts-card-col reverse-img-col">
                <img
                  src={Step5}
                  loading="lazy"
                  alt="transactions"
                  className="hts-image  img-fluid mb-md-0 mb-5  border-radius-16"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SupportChangePassword;
