import React from "react";
import "./SmartDevice.scss";
import { Row, Col } from "react-bootstrap";
import HorizontalLine from "../../../assets/images/png/sdr-layer/horizontal-line.png";
import VerticalLine1 from "../../../assets/images/png/sdr-layer/vertical-line.png";
import SmartDevices1 from "../../../assets/images/png/sdr-layer/validators-group.png";
import SmartDevices2 from "../../../assets/images/png/sdr-layer/devices-img.png";
// import Basm from "../../../assets/images/png/sdr-layer/basm-color.png"
// import Router from "../../../assets/images/png/sdr-layer/router-color.png"
// import Hidr from "../../../assets/images/png/sdr-layer/hidr-color.png"
// import BASMDevice from '../../PerformerGuardian/BASMDevice/BASMDevice'
import { ReactComponent as ArrowRight } from "../../../assets/images/svg/arrow-right.svg";
import { HashLink } from "react-router-hash-link";
import { routes } from "../../../Utils/routes";

const SmartDevice = () => {
  return (
    <section id="sdr-smart-device" className="page-section">
      <div className="container-main d-flex flex-column justify-content-center align-items-center">
        <div className="text-center sdr-text-container d-flex flex-column align-items-center justify-content-center">
          <h2 className="font-size-80 font-pnb line-height-11 mb-0 smart-devices-title-1">Any smart device</h2>
          <h2 className="font-size-80 font-pnb line-height-11 integrated smart-devices-title-2">
            can be integrated<span className="purple-color">.</span>
          </h2>
          <p className="text-center font-pnr font-size-24 line-height-11 smart-devices-description pb-5 pt-3 darkgrey-color">
            Users can integrate into the Xiden blockchain any smart device. This will optimize the network’s operation and will
            facilitate the continuous expansion of the community while receiving rewards for users' contributions.
          </p>
        </div>
        <img src={HorizontalLine} alt="line" className="img-fluid" />
        <Row className="py-3 my-lg-5 w-100 flex-md-row flex-column-reverse justify-content-center align-items-center">
          <Col xl={6} md={7}>
            <div className="d-flex flex-column align-items-center align-items-md-start justify-content-center h-100 py-0 py-md-4 py-lg-5 pe-lg-5 pe-md-4 pe-0 pb-0 pb-lg-5">
              <h2 className="font-pnsb font-size-32 line-height-11 mb-3 custom-text">
                Validators<span className="purple-color">.</span>{" "}
              </h2>
              <p className="font-pnr font-size-24 line-height-11 custom-text darkgrey-color">
                CryptoDATA devices can be connected to the SDR layer through the KrateR Pool, will distribute their resources with
                the network, and will have validator roles assigned.
              </p>
              <HashLink className="font-pnsb font-size-20 read-more-link" to={`${routes.rewards}#rewards-calculator-section`}>
                See rewards calculator
                <ArrowRight />
              </HashLink>
              {/* <button className="font-pnsb font-size-20 read-more-link">
                See rewards calculator
                <ArrowRight />
              </button> */}
            </div>
          </Col>
          <div className="horizontal-line d-md-block d-none">
            <img src={VerticalLine1} alt="line" />
          </div>
          <Col md={4}>
            <div className="d-flex align-items-center justify-content-center h-100 my-3 my-md-0">
              <img src={SmartDevices1} alt="how do i use it" className="img-fluid content-image" />
              {/* <HowDo className="img-fluid" /> */}
            </div>
          </Col>
        </Row>
        <img src={HorizontalLine} alt="line" className="img-fluid" />
        <Row className="py-3 my-lg-5 justify-content-center align-items-center w-100">
          <Col md={4}>
            <div className="d-flex align-items-center justify-content-center h-100 my-3 my-md-0">
              <img src={SmartDevices2} alt="how do i use it" className="img-fluid content-image smart-devices-2" />
              {/* <WhatIs className="img-fluid" /> */}
            </div>
          </Col>
          <div className="horizontal-line d-md-block d-none">
            <img src={VerticalLine1} alt="line" />
          </div>
          <Col xl={6} md={7}>
            <div className="d-flex flex-column align-items-center align-items-md-start justify-content-center h-100 py-0 py-md-4 py-lg-5 ps-lg-5 ps-md-4 ps-0 pb-0 pb-lg-5">
              <h2 className="font-pnsb font-size-32 line-height-11 mb-3 custom-text">
                Boosters<span className="purple-color">.</span>{" "}
              </h2>
              <p className="font-pnr font-size-24 line-height-11 custom-text darkgrey-color">
                Regular devices can be connected to the SDR layer through the Spectralis Network by requesting a connection from
                an SPN Hotspot, will distribute their resources with the network to increase by 20% the total validation power and
                will receive rewards for their work.
              </p>

              <HashLink className="font-pnsb font-size-20 read-more-link" to={`${routes.rewards}#rewards-calculator-section`}>
                See rewards calculator
                <ArrowRight />
              </HashLink>
              {/* <button className="font-pnsb font-size-20 read-more-link">
                See rewards calculator
                <ArrowRight />
              </button> */}
            </div>
          </Col>
        </Row>
        <img src={HorizontalLine} alt="line" className="img-fluid" />
      </div>
    </section>
    // <section id="sdr-smart-device" className="page-section">
    //     <div className="container-main d-flex align-items-center justify-content-center flex-column">
    //         <div className="text-center sdr-text-container d-flex flex-column align-items-center justify-content-center">
    //             <h2 className="font-size-80 font-pnb">Any smart device</h2>
    //             <h2 className="font-size-80 font-pnb integrated">can be integrated<span className="purple-color">.</span></h2>
    //             <p className="text-center font-pnr font-size-28">Any smart devices can be integrated in the Xiden blockchain and it can share its resources to the network and help develop and maintain the community. In this way any device can be rewarded for the its part of growing the Xiden network.</p>
    //         </div>

    //         <div className="d-flex flex-column">

    //             <Row className="py-5 flex-lg-row flex-column-reverse justify-content-end">
    //             <img src={HorizontalLine} alt="line" className='img-fluid horizontal-first-img' />
    //                 <Col lg={7}>
    //                     <div className="d-flex flex-column align-items-center align-items-lg-start justify-content-center h-100 p-5 pb-0 pb-lg-5  stacking-container">
    //                         <h2 className="font-pnsb font-size-32 line-height-11 mb-3 custom-text">Proof of Staking<span className="purple-color">.</span></h2>
    //                         <p className="font-pnr font-size-24 line-height-11 custom-text">
    //                         A consensus algorithm that ensures the conformity of every device aiming to become a validator by submitting predetermined quantity of XID.
    //                         </p>
    //                         <button className="font-pnsb font-size-20 read-more-link">
    //                           See Rewards Calculator
    //                          <ArrowRight />
    //                          </button>
    //                     </div>
    //                 </Col>
    //                 <div className="horizontal-line d-lg-block d-none">
    //                     <img src={VerticalLine1} alt="line" />
    //                 </div>
    //                 <Col lg={4}>
    //                     <div className="d-flex align-items-center justify-content-center h-100 ms-4">
    //                         <img src={Basm} alt="how do i use it" className='img-fluid content-image' />
    //                         <img src={Hidr} alt="how do i use it" className='img-fluid content-image' />
    //                         <img src={Router} alt="how do i use it" className='img-fluid content-image' />
    //                         {/* <HowDo className="img-fluid" /> */}
    //                     </div>
    //                 </Col>
    //             </Row>
    //             <img src={HorizontalLine} alt="line" className='img-fluid' />

    //             <Row className="py-5 d-flex justify-content-end">
    //                 <Col lg={4}>
    //                     <div className="d-flex align-items-center justify-content-center h-100 me-4">
    //                         <img src={Basm} alt="how do i use it" className='img-fluid content-image' />
    //                         <img src={Hidr} alt="how do i use it" className='img-fluid content-image' />
    //                         <img src={Router} alt="how do i use it" className='img-fluid content-image' />
    //                         {/* <WhatIs className="img-fluid" /> */}
    //                     </div>
    //                 </Col>
    //                 <div className="horizontal-line d-lg-block d-none">
    //                     <img src={VerticalLine1} alt="line" />
    //                 </div>
    //                 <Col lg={7}>
    //                     <div className="d-flex flex-column align-items-center align-items-lg-start justify-content-center h-100 p-5 pb-0 pb-lg-5 connectivity-container">
    //                         <h2 className="font-pnsb font-size-32 line-height-11 mb-3 custom-text">Proof of Connectivity <span className="purple-color">.</span></h2>
    //                         <p className="font-pnr font-size-24 line-height-11 custom-text">
    //                         A consensus algorithm that ensures the conformity of every device aiming to become a validator by submitting predetermined quantity of XID.
    //                         </p>
    //                         <button className="font-pnsb font-size-20 read-more-link">
    //                           See Rewards Calculator
    //                          <ArrowRight />
    //                          </button>
    //                     </div>
    //                 </Col>
    //             </Row>
    //             <img src={HorizontalLine} alt="line" className='img-fluid' />
    //         </div>
    //         </div>
    // </section>
  );
};

export default SmartDevice;
