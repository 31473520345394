import React, { useState } from 'react'
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import ImageModal from '../SupportKraterSteps/ImageModal';

const RightSide = (props) => {

    const [showModal, setShowModal] = useState(false);

    return (
        <div className="corners hts-card p-lg-5 p-4">
            <div className="rotate-uppertitle right-0">
                <Uppertitle title={props.uppertitle} />
            </div>
            <div className="d-flex justify-content-center align-items-center marginRight hts-card-col">
                {
                    props.modal?
                                <img src={props.image} loading="lazy" alt="router" className="hts-image img-fluid mb-md-0 mb-4 pointer" onClick={() => setShowModal(true)}/>
                                :
                                <img src={props.image} loading="lazy" alt="router" className="hts-image img-fluid mb-md-0 mb-4 pointer"/>
                }
                <ImageModal 
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    title={props.uppertitle}
                    image={props.image}
                />
            </div>
            <div className="description-col hts-card-col">
                <p className="pb-4 font-size-40 font-pnb text-start line-height-11">
                    {props.title}
                    <span className="purple-color">{props.punctuation}</span>
                </p>
                <div className="hts-p-container  text-start">
                    {props.content}
                </div>
            </div>
        </div>
    )
}

export default RightSide