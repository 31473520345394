import CubePurple from "../../../../assets/images/png/prop-cube.png";
import { ReactComponent as Checked } from "../../../../assets/images/svg/proprietary-checked.svg";
import { ReactComponent as DistributeCube } from "../../../../assets/images/svg/proprietary-cube-prop.svg";
import { ReactComponent as Tap } from "../../../../assets/images/svg/proprietary-tap.svg";
import { ReactComponent as Idea } from "../../../../assets/images/svg/proprietary-business-and-finance.svg";

export const proprietaryData = [
  {
    uppertitle: "DECENTRALIZED",
    imgUrl: { CubePurple },
    description: "Every developed app is decentralized and managed by smart contracts",
    bg: "bg3",
  },
  {
    uppertitle: "USABILITY",
    svgProp: <Idea />,
    description: "Setup Usability Method",
    bg: "bg1",
  },
  {
    uppertitle: "COSTS",
    svgProp: <Checked />,
    description: "Hassle Free Setup",
  },
  {
    uppertitle: "DISTRIBUTE",
    description: "Simple Distribution Setup",
    svgProp: <DistributeCube />,
  },
  {
    uppertitle: "REWARDS",
    description: "Set up reward in one click",
    bg: "bg2",
    svgProp: <Tap />,
  },
];
