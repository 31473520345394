import React from "react";
import "./PerformerGuardian.scss";
import HeroSection from "./HeroSection/HeroSection";
import HelpNetwork from "./HelpNetwork/HelpNetwork";
import PropertiesGuardian from "./PropertiesGuardian/PropertiesGuardian";
import HowTo from "./HowTo/HowTo";
import BASMDevice from "./BASMDevice/BASMDevice";
import ImpulseDevice from "./ImpulseDevice/ImpulseDevice";
import HidrDevice from "./HidrDevice/HidrDevice";
import PCDevice from "./PCDevice/PCDevice";
import "./PerformerGuardian.scss"
import { Helmet } from "react-helmet";
import SchemaTemplate from "../../SEO/SchemaTemplate.json";

const PerformerGuardian = () => {
   return (
      <div className="performer-guardian-page">
         {/* seo */}
         <Helmet>
            <title>Performer guardian</title>
            <meta name="title" content="Performer guardian" />
            <meta name="description" content="" />
            <link rel="canonical" href="" />
            {/* Open Graph Facebook */}
            <meta property="og:title" content="Performer guardian" />
            <meta property="og:description" content="" />
            <meta property="og:type" content="website" />
            <meta property="og:image" content="" />
            <meta property="og:url" content="" />
            {/* Open Graph Twitter  */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="" />
            <meta property="twitter:title" content="Performer guardian" />
            <meta property="twitter:description" content="" />
            <meta property="twitter:image" content="" />
            {/* Structured Data */}
            <script>{JSON.stringify(SchemaTemplate)}</script>
         </Helmet>

         {/* sections */}
         <HeroSection />
         <HelpNetwork />
         <PropertiesGuardian />
         <HowTo />
         <BASMDevice />
         <ImpulseDevice />
         <HidrDevice />
         <PCDevice />
      </div>
   );
};

export default PerformerGuardian;
