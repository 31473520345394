import React from "react";
import "./RewardsSection.scss";
import { Col, Row } from "react-bootstrap";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import Resources from "../../../assets/images/png/resources.png";
// import Reward from "../../../assets/images/png/reward.png";
import Reward from "../../../assets/images/png/ecosystem/apps-card.png";
import Oracle2 from "../../../assets/images/png/xiden-page/boosterDevices.png";
// import Transactions from "../../../assets/images/png/XidenFeatures-market.png";
import { ReactComponent as ArrowRight } from "../../../assets//images/svg/arrow-right.svg";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../Utils/routes";

const RewardsSection = () => {
  const navigate = useNavigate();

  return (
    <section id="rewards-xiden-page" className="page-section">
      <div className="container-main d-flex flex-column">
        <Row className="title align-items-center">
          <div className="my-auto">
            <Uppertitle title={"REWARDS"} />
            <h2 className="title font-size-80 pb-1 white-color font-pnb">
              Rewards<span className="purple-color">.</span>
            </h2>
          </div>
        </Row>

        {/* validating */}
        <div className="corners p-lg-5 p-4 right">
          <Row xs={1} sm={1} md={2} className="rand d-flex justify-content-between align-items-center">
            <Col xs={{ order: 2 }} sm={{ order: 2 }} md={{ order: 1 }}>
              <div className="ecosystem-rewards-info-col">
                <h2 className="title font-size-40 pb-1 pt-3 white-color font-pnb">
                  Validating
                  <span className="purple-color">.</span>
                </h2>
                <p className="font-pnr font-size-20 pb-2 lightgrey-color line-height-11">
                  Validation is the process that ensures the conformity of every smart device integrated into the system, every
                  specific data, or every digital transaction within the network. The user will receive rewards for every
                  successfully completed validation process.
                </p>

                <div
                  className="link-container d-flex align-items-center ustify-content-start"
                  onClick={() => toast.info("Coming soon...", { toastId: "DERFrefg" })}
                >
                  <p className="font-pnsb font-size-20 blue-color">Start validating</p>
                  <ArrowRight />
                </div>
              </div>
            </Col>
            <Col xs={{ order: 1 }} sm={{ order: 1 }} md={{ order: 2 }}>
              <div className="d-flex justify-content-center align-items-center mb-4 mb-sm-4 mb-md-0">
                <img src={Oracle2} loading="lazy" alt="xiden validate" className="img-fluid ecosystem-rewards-img" />
              </div>
            </Col>
          </Row>
        </div>

        {/* resources */}
        <div className="corners p-lg-5 p-4 left ">
          <Row xs={1} sm={1} md={2} className="rand d-flex justify-content-between align-items-center">
            <Col>
              <div className="d-flex justify-content-center align-items-center mb-4 mb-sm-4 mb-md-0">
                <img src={Resources} loading="lazy" alt="xiden resources" className="img-fluid ecosystem-rewards-img" />
              </div>
            </Col>
            <Col>
              <div className="ecosystem-rewards-info-col">
                <h2 className="title font-size-40 pb-1 pt-3 white-color font-pnb">
                  Resources
                  <span className="purple-color">.</span>
                </h2>
                <p className="font-pnr font-size-20 pb-2 lightgrey-color line-height-11">
                  The users that will make their smart electronic devices’ resources available for the mining process required
                  within the network will receive rewards.
                </p>

                <div
                  className="link-container d-flex align-items-center align-contents-center justify-content-start"
                  onClick={() => navigate(routes.rewards)}
                >
                  <p className="font-pnsb font-size-20 blue-color">Calculate resources</p>
                  <ArrowRight />
                </div>
              </div>
            </Col>
          </Row>
        </div>

        {/* dapps */}
        <div className="corners p-lg-5 p-4 left">
          <Row xs={1} sm={1} md={2} className="rand d-flex justify-content-between align-items-center">
            <Col xs={{ order: 2 }} sm={{ order: 2 }} md={{ order: 1 }}>
              <div className="ecosystem-rewards-info-col">
                <h2 className="title font-size-40 pb-1 pt-3 white-color font-pnb">
                  Rewards from DApps<span className="purple-color">.</span>
                </h2>
                <p className="font-pnr font-size-20 pb-2 lightgrey-color line-height-11">
                  The developers that will build DApps on the Xiden network will receive rewards for their work. The more popular
                  your DApp will become, the more rewards you will get.
                </p>

                <div
                  className="link-container d-flex align-items-center align-contents-center justify-content-start"
                  onClick={() => toast.info("Coming soon...", { toastId: "D#EFRRTg" })}
                >
                  <p className="font-pnsb font-size-20 blue-color">Build DApps</p>
                  <ArrowRight />
                </div>
              </div>
            </Col>
            <Col xs={{ order: 1 }} sm={{ order: 1 }} md={{ order: 2 }}>
              <div className="d-flex justify-content-center align-items-center mb-4 mb-sm-4 mb-md-0">
                <img src={Reward} loading="lazy" alt="xiden rewards" className="img-fluid ecosystem-rewards-img eco-dapps-img" />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
};

export default RewardsSection;
