import React from 'react'
import './VersionCard.scss'
import { Fragment } from 'react'
import { ReactComponent as Basm } from '../../../assets/images/svg/Basm-changelog.svg'
import { ReactComponent as Minter } from '../../../assets/images/svg/minter-changelog.svg'
import { ReactComponent as Impulse } from '../../../assets/images/svg/impulse-changelog.svg'
import { ReactComponent as Hidr } from '../../../assets/images/svg/hidr-changelog.svg'
import { ReactComponent as Pool } from '../../../assets/images/svg/pools-changelog.svg'
import { ReactComponent as Chevron } from '../../../assets/images/svg/chevron-changelog.svg'
const VersionCard = props => {
  const [hideButton, setHideButton] = React.useState(false)
  const handleTitleClasses = type => {
    switch (type) {
      case 'fix':
        return 'change-log-fix'
      case 'new':
        return 'change-log-new'
      case 'update':
        return 'change-log-update-type'
      case 'update':
        return 'change-log-update-type'
      default:
        return ''
    }
  }
  const handleName = device => {
    switch (device) {
      case 'minter':
        return 'MinteR'
      case 'impulse':
        return 'IMPulse'
      case 'hidr':
        return 'HidR'
      case 'basm':
        return 'BASM'
      case 'pools':
        return 'Pools'
      default:
        return ''
    }
  }
  const handleIconDevice = device => {
    switch (device) {
      case 'minter':
        return <Minter className='me-3' />
      case 'impulse':
        return <Impulse className='me-3'></Impulse>
      case 'basm':
        return <Basm className='me-3'></Basm>
      case 'pools':
        return <Pool className='me-3' />
      case 'hidr':
        return <Hidr className='me-3' />
      default:
        return ''
    }
  }
  const handleUpdateType = device => {
    switch (device) {
      case 'manual':
        return 'Manual Update'
      case 'automatic':
        return 'Automatic Update'
      default:
        return ''
    }
  }
  const handleDate = date => {
    // transform month from number to string
    const month = new Date(date).toLocaleString('en-us', { month: 'long' })
    const day = new Date(date).getDate()
    const year = new Date(date).getFullYear()
    return `${month} ${day}, ${year}`
  }

  return (
    <div className={`version-card ${props?.isHomepage && 'overview-card'} `}>
      <div className='d-flex flex-direction-row justify-content-start align-items-end gap-3 custom-padding flex-wrap-reverse'>
        {props.version !== undefined && props.version !== '' && (
          <p className='white-color font-size-32 font-pnb version-p mb-3'>
            Version: {props.version} <br></br>
          </p>
        )}
        <div className='d-flex flex-wrap-changelog gap-3 w-date-device'>
          {props.device !== undefined && props.device !== '' && (
            <div className=' font-size-20 font-pnsb device-container-change-log m-0 p-0 d-flex align-items-center justify-content-center'>
              {handleIconDevice(props.device)} {handleName(props.device)}
            </div>
          )}
          {props.date !== undefined && props.date !== '' && (
            <div className='date-container-change-log font-size-20 font-pnsb m-0 p-0 d-flex align-items-center justify-content-center'>
              {handleDate(props.date)}
            </div>
          )}
        </div>
      </div>
      <div className='d-flex flex-column justify-content-start custom-padding mb-3'>
        <div className='update-type-container text-center font-size-20 font-pnsb text-capitalize w-100'>
          <p className='change-log-updatetype'>
            {handleUpdateType(props.updateType)}
          </p>
        </div>
        {props.updates?.map((update, index) => {
          return (
            <Fragment key={index}>
              <div className='update-type-container text-center font-size-20 font-pnsb text-capitalize'>
                <p className={handleTitleClasses(update.type)}>{update.type}</p>
              </div>
              <div className='update-description-container white-color font-size-20 font-pnsb'>
                <p>{update.title}</p>
              </div>
              <div className='update-description-container white-color font-size-20 font-pnr'>
                <ul>
                  {/* When is a single element in description it comes as a string when are multiple comes as an array 🥇 */}
                  {Array.isArray(update?.description) ? (
                    update?.description?.map((el, index) => (
                      <li key={index}>{el}</li>
                    ))
                  ) : (
                    <li>{update?.description}</li>
                  )}{' '}
                </ul>
              </div>
            </Fragment>
          )
        })}
      </div>

      {props.isHomepage &&
        (!hideButton ? (
          <div
            className='text-center white-color m-auto d-flex cursor-pointer'
            onClick={() => {
              props.setHolder(10)
              setHideButton(true)
            }}
          >
            <Chevron className='m-auto'></Chevron>
          </div>
        ) : (
          <></>
        ))}
    </div>
  )
}

export default VersionCard
