export const routes = {
  home: '/',
  xiden: '/ecosystem',
  xidenFeatures: '/xden',
  sdrLayer: '/sdr-layer',
  apps: '/xiden-apps',
  minter: '/meta-realm',
  performerGuardian: '/performer-guardian',
  privateNetwork: '/private-network',
  rewards: '/rewards',
  staking: '/xiden-staking',
  validator: '/xiden-validator',
  guardianNode: '/guardian-node',
  // ecosystem: "/xiden-ecosystem",
  termsOfUse: '/terms-and-conditions',
  dataPrivacy: '/privacy-notice',
  changeLog: '/changelog',
  supportMetamask: '/support-metamask',
  supportReward: '/support-reward',
  supportHotspot: '/support-hotspot',
  supportKraterPool: '/support-minter', // /support-krater-pool -> /support-minter
  supportKraterApp: '/support-krater-app-basm', // /support-krater-pool -> /support-minter
  supportStake: '/support-krater-pool', // /support-stake -> /support-krater-pool
  supportUnstake: '/support-unstake',
  supportValidator: '/support-validator',
  supportUpdate: '/support-settings',
  supportGuide: '/support-krater-app-smartphone',
  miningCompetition: '/mining-competition',
  // supportTroubleshoot: "/support-troubleshoot",

  // userAgreements: "/user-agreements",
  errorPage: '*',
  whitepaper: '/whitepaper',
  changeLog: '/changelog',
}

Object.freeze(routes)
