import { useRef } from "react";

import { cardsData } from "./cardsData";
import FeatureCardMobile from "./FeatureCardMobile/FeatureCardMobile";
import "./Properties.scss";

const MobileProperties = () => {
  //  Refs
  const sliderRef = useRef(null);

  // functions
  //  // slider function start
  let isDown = false;
  let startX;
  let scrollLeft;

  const mouseLeaveFunction = () => {
    isDown = false;
  };
  const mouseDownFunction = (e) => {
    isDown = true;
    startX = e.pageX - sliderRef.current.offsetLeft;
    scrollLeft = sliderRef.current.scrollLeft;
  };
  const mouseUpFunction = () => {
    isDown = false;
  };
  const touchFunction = () => {
    isDown = false;
  };
  const mouseMoveFunction = (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - sliderRef.current.offsetLeft;
    const walk = (x - startX) * 1; //scroll-fast
    sliderRef.current.scrollLeft = scrollLeft - walk;
  };
  // slider function end

  return (
    <section id="properties-section-mobile" className="page-section">
      <div className="container-main">
        <div className="w-100 heading-container">
          <h2 className="font-size-80 white-color font-pnb">
            Properties<span className="purple-color">.</span>
          </h2>
        </div>
        <div className="position-relative slider-height">
          <div
            ref={sliderRef}
            className="descriptions-container slider-div grab d-flex flex-row align-items-center justify-content-start"
            onMouseDown={(e) => mouseDownFunction(e)}
            onMouseLeave={() => mouseLeaveFunction()}
            onMouseUp={() => mouseUpFunction()}
            onMouseMove={(e) => mouseMoveFunction(e)}
            onTouchStart={() => touchFunction()}
          >
            {cardsData.map((card) => (
              <div key={card.id} className="me-3">
                <FeatureCardMobile cardData={card} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default MobileProperties;
