import React from "react";
import "./DiagonalCorners.scss";

const DiagonalCorners = ({ color, title, subtitle, paragraph }) => {
    //subtitle este textul rotit
    //title este textul mare
    //paragraph este textul mic
    const matchTextColor = color => {
        switch (color) {
            case "orange":
                return { color: "#EE694B" };

            case "mauve":
                return { color: "#AF4AFF" };

            default:
                return { color: "#AF4AFF" };
        }
    };

    return (
        <div className="diagonal-corners">
            <div className="box">
                <div className="rotate font-pnsb size-18" style={matchTextColor(color)}>{subtitle}</div>
                <div className="title font-pnb font-size-120 white-color" >
                    {title}
                    <div className="text font-pnr size-18 lightgrey-color" >{paragraph}</div>
                </div>
            </div>
        </div>
    );
};

export default DiagonalCorners;
