import { ReactComponent as RouterWhiteIcon } from "../../../../assets/images/svg/minter-guardian-card-white.svg";
import { ReactComponent as RouterBlackIcon } from "../../../../assets/images/svg/minter-guardian-card.svg";
import RouterBlack from "../../../../assets/images/png/minter-guardian-card-black.png";
import RouterWhite from "../../../../assets/images/png/minter-guardian-card-white.png";

export const routerMainCardData = {
  id: 1,
  upperTitle: "DEVICE",
  title: "ROUTER",
  deviceIcon: [<img src={RouterBlack} alt="router" />, <img src={RouterWhite} alt="router" />],
};

export const routerCardsData = [
  {
    id: 1,
    upperTitle: "VALIDATING POWER",
    title: "49 T",
  },
  {
    id: 2,
    upperTitle: "PRIVATE DIFFICULTY",
    title: "4.32 T",
  },
  {
    id: 3,
    upperTitle: "PROFIT PER DAY",
    title: "11.3 XDEN",
  },
  {
    id: 4,
    upperTitle: "PROFIT PER MONTH",
    title: "351.6 XDEN",
  },
  {
    id: 5,
    upperTitle: "PROFIT PER YEAR",
    title: "4219.2 XDEN",
  },
  {
    id: 6,
    upperTitle: "PUBLIC DIFFICULTY",
    title: "10.08 T",
  },
  {
    id: 7,
    upperTitle: "PUBLIC DIFFICULTY",
    title: "10.08 T",
  },
  {
    id: 8,
    upperTitle: "PROFIT PER DAY",
    title: "4.9 XDEN",
  },
  {
    id: 9,
    upperTitle: "PROFIT PER MONTH",
    title: "150.7 XDEN",
  },
  {
    id: 10,
    upperTitle: "PROFIT PER YEAR",
    title: "1808.4 XDEN",
  },
];
