import { ReactComponent as ImpulseWhiteIcon } from "../../../../assets/images/svg/impulse.svg";
import { ReactComponent as ImpulseBlackIcon } from "../../../../assets/images/svg/impulse-black.svg";

export const hidrMainCardData = {
  id: 1,
  upperTitle: "DEVICE",
  title: "HidR",
  deviceIcon: [<ImpulseBlackIcon />, <ImpulseWhiteIcon />],
};

export const hidrCardsData = [
  {
    id: 1,
    upperTitle: "VALIDATING POWER",
    title: "54 T",
  },
  {
    id: 2,
    upperTitle: "PRIVATE DIFFICULTY",
    title: "4.32 T",
  },
  {
    id: 3,
    upperTitle: "PROFIT PER DAY",
    title: "12.5 XDEN",
  },
  {
    id: 4,
    upperTitle: "PROFIT PER MONTH",
    title: "387.5 XDEN",
  },
  {
    id: 5,
    upperTitle: "PROFIT PER YEAR",
    title: "4650 XDEN",
  },
  {
    id: 6,
    upperTitle: "PUBLIC DIFFICULTY",
    title: "10.08 T",
  },
  {
    id: 7,
    upperTitle: "PUBLIC DIFFICULTY",
    title: "10.08 T",
  },
  {
    id: 8,
    upperTitle: "PROFIT PER DAY",
    title: "5.4 XDEN",
  },
  {
    id: 9,
    upperTitle: "PROFIT PER MONTH",
    title: "166.1 XDEN",
  },
  {
    id: 10,
    upperTitle: "PROFIT PER YEAR",
    title: "1993.2 XDEN",
  },
];
