import React from "react";
import "./GetRewards.scss";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import { Row, Col } from "react-bootstrap";
import PrivateImage from "../../../assets/images/png/get-rewards-private.png";
import PublicImage from "../../../assets/images/png/get-rewards-public.png";
import CoinImage from "../../../assets/images/png/rewardsPage/gas-coin.png";
import { useState, useEffect } from "react";
import axios from "axios";

const GetRewards = () => {
  const [privateDifficulty, setPrivateDifficulty] = useState(() => 0);
  const [publicDifficulty, setPublicDifficulty] = useState(() => 0);

  const getRewards = async (toggleDifficulty) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      toggleDifficulty: toggleDifficulty,
      size: 1,
      basmInput: 1,
      hidrInput: 1,
      minterInput: 1,
      devicesInput: 0,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      const checkReq = await axios.post("https://api.heimdall.ro/calculatorFunction", requestOptions);
      if (checkReq.status === 200) {
        setPrivateDifficulty(checkReq.data.privateDifficulty);
        setPublicDifficulty(checkReq.data.publicDifficulty);
      }
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 404:
            console.log("404 Not found.");
            return;
          default:
            console.log("Something is temporarily wrong.");
            return;
        }
      }
    }
  };
  useEffect(() => {
    getRewards();
  }, []);

  return (
    <section id="rewards-get-rewards" className="page-section">
      <div className="container-main get-rewards-section">
        {/* heading */}
        <div className="w-100">
          <Uppertitle title={`DIFFICULTY`} />
          <h2 className="font-size-80 font-pnb">
            How to get rewards<span className="pink-text">.</span>
          </h2>
          <div className="rewards-p-container-lg">
            <p className="font-family-pnr font-size-24 lightgrey-color line-height-11">
              Discover how to contribute to our Xiden network and start earning rewards to increase your earnings.
            </p>
          </div>
        </div>

        {/* cards */}
        <Row xs={1} sm={1} md={1} lg={2} className="mt-1 mt-lg-5 pt-5">
          <Col className="mb-4 mb-sm-4 mb-md-4 mb-lg-0">
            <div className="w-100 d-flex flex-column cards-col">
              <div className="difficulty-card">
                <img src={PrivateImage} loading="lazy" alt="private difficulty" className="card-image-absolute" />
                <div className="top-image-container">
                  <img src={PrivateImage} loading="lazy" alt="private difficulty" className="top-card-image" />
                </div>
                <div className="w-100 h-100 d-flex flex-column justify-content-between">
                  <div className="w-100 top-paragraph">
                    <div className="heading-container">
                      <h5 className="font-size-32 font-pnb">
                        Private difficulty<span className="pink-text">.</span>
                      </h5>
                      <p className="font-size-20 lightgrey-color font-pnr line-height-11">
                        When mining in a private difficulty, the miner will only use its integrated devices’ available resources
                        for computing power in order to verify data and transaction in the network in order to unlock its minimum
                        amount of 1.000 XDEN.
                      </p>
                    </div>
                  </div>
                  <div className="bottom-container">
                    <div className="line-height-11 bottom-text position-relative ">
                      <span className="font-size-80 font-pnb">{privateDifficulty} T</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="difficulty-card">
                <img src={PublicImage} loading="lazy" alt="public difficulty" className="card-image-absolute" />
                <div className="top-image-container">
                  <img src={PublicImage} loading="lazy" alt="private difficulty" className="top-card-image" />
                </div>
                <div className="w-100 h-100 d-flex flex-column justify-content-between">
                  <div className="w-100 top-paragraph">
                    <div className="heading-container">
                      <h5 className="font-size-32 font-pnb">
                        Public difficulty<span className="pink-text">.</span>
                      </h5>
                      <p className="font-size-20 lightgrey-color font-pnr line-height-11">
                        When mining in a public difficulty, multiple miners can band together their integrated devices’ available
                        resources for computing power in order to verify data and transaction in the network in order to unlock
                        XDEN tokens.
                      </p>
                    </div>
                  </div>
                  <div className="bottom-container">
                    <div className="line-height-11 bottom-text position-relative ">
                      <span className="font-size-80 font-pnb">{publicDifficulty} T</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>

          <Col className="get-rewards-corners-col">
            <div className="corners get-rewards-corners">
              <div className="coin-image-container">
                <img src={CoinImage} loading="lazy" alt="coin" className="coin-image" />
              </div>
              <div className="get-rewards-corners-txt">
                <Uppertitle title={`GAS`} />
                <h2 className="font-size-80 font-pnb">
                  Gas<span className="pink-text">.</span>
                </h2>
                <div className="rewards-p-container">
                  <p className="font-family-pnr font-size-20 lightgrey-color line-height-11">
                    The gas fees from the validator nodes will be distributed to miners depending on the power and the time spent
                    online. Public rewards will be divided, and the initial amount will distributed to online devices during the
                    first Age in order to generate liquidity in the market.
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default GetRewards;
