import React from "react";
import "./SupportSteps.scss";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
// import DepositImage from "../../../assets/images/png/blue-coins.png";
// import DepositImage from "../../../assets/images/png/guardian-node-page/blue-coins.png";
import DepositImage from "../../../assets/images/png/guardian-node-page/depositImage.png";
// import OnlineImage from "../../../assets/images/png/staking-online.png";
import OnlineImage from "../../../assets/images/png/guardian-node-page/staking-online.png";
import ConfigureImage from "../../../assets/images/png/guardian-node-page/router-guardian-node.png";
// import HotspotImage from "../../../assets/images/png/guardian-node-booster-devices.png";
import HotspotImage from "../../../assets/images/png/guardian-node-page/guardian-node-booster-devices.png";
// import KraterImage from "../../../assets/images/png/guard-connection.png";
import KraterImage from "../../../assets/images/png/guardian-node-page/guard-krater.png";
import RouterValidateImage from "../../../assets/images/png/how-guardian-works/router-validate.png";
import WalletImage from "../../../assets/images/png/staking/wallet.jpg";
import { AiOutlineArrowRight as ArrowRight } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../Utils/routes";
import { HashLink } from 'react-router-hash-link';
import { Link as ScrollLink } from "react-scroll";
import { toast } from "react-toastify";
import First from './assets-steps/1.png';
import Second from './assets-steps/2.png';
import Third from './assets-steps/3.png';
import Four from './assets-steps/4.png';
import Five from './assets-steps/5.png';

import Step1 from "./assets-steps/step1.png";
import Step2 from "./assets-steps/step2.png";
import Step3 from "./assets-steps/step3.png";
import Step4 from "./assets-steps/step4.png";
import Step5 from "./assets-steps/step5.png";
import Step6 from "./assets-steps/step6.png";
import Step7 from "./assets-steps/step7.png";
import Step8 from "./assets-steps/step8.png";
import Step9 from "./assets-steps/step9.png";


const SupportHotspotSteps = () => {
  const navigate = useNavigate();

  return (
    <section id="guardian-steps-section" className="support-validator-steps-section">
      <div className="page-section text-white">
        <div className="container-main">
          {/* heading */}
          {/* <div className="heading-container">
            <Uppertitle title={`ROUTER`} />
            <div className="line-height-11">
              <h2 className="mb-0 font-size-80 font-pnb line-height-11">
              Get reward by making
Validations<span className="purple-color">.</span>
              </h2>
            </div>
          </div> */}

          {/* cards */}
          <div className="mt-5">
            {/* configure */}
            <div className="corners hts-card p-lg-5 p-4">
              <div className="rotate-uppertitle">
                <Uppertitle title={"STEP 01"} />
              </div>
              <div className="description-col marginRight hts-card-col reverse-description">
                <p className="pb-4 font-size-40 font-pnb text-start line-height-11">
                  Miner device
                  <span className="purple-color">.</span>
                </p>
                <div className="hts-p-container">
                  <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                    Open the KrateR Pool interface to add miners and select “Pools” from the top navbar.
                  </p>
                </div>
                <div className="link-container w-100 d-flex align-items-center justify-content-start blue-color line-height-11">
                  {/* <a onClick={() => toast.info("Coming soon!", { toastId: "guardian-node-router3" })}
                    className="font-pnsb font-size-20 blue-color me-lg-5 me-4 pointer">
                    Learn more <ArrowRight />
                  </a> */}
                </div>

                {/* <HashLink className="link-container d-flex align-items-center justify-content-start blue-color line-height-11" smooth to={`${routes.validator}/#howitworks-validator`}>
                  <div className="font-pnsb font-size-20 blue-color pointer guardian-hover">
                    Learn more <ArrowRight />
                  </div>
                </HashLink> */}
              </div>
              <div className="d-flex justify-content-center align-items-center hts-card-col reverse-img-col mb-3 mb-md-0">
                <img src={Step1} loading="lazy" alt="router" className="img-fluid mh-432 border-radius-16" />
              </div>
            </div>

            {/* kreater pool */}
            <div className="corners hts-card p-lg-5 p-4">
              <div className="rotate-uppertitle right-0">
                <Uppertitle title={"STEP 02"} />
              </div>
              <div className="d-flex justify-content-center align-items-center marginRight hts-card-col">
                <img src={Step2} loading="lazy" alt="transactions" className="hts-image img-fluid mb-md-0 mb-4 border-radius-16" />
              </div>
              <div className="description-col hts-card-col">
                <p className="mb-4 font-size-40 font-pnb line-height-11">
                  Select your pool<span className="purple-color">.</span>
                </p>
                <div className="hts-p-container">
                  <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                    In the “My Pools” section at the bottom of the page, you will find your Pool ID. Click on the Pool ID to open the detailed view of the pool.

                  </p>
                </div>
                <div className="link-container w-100 d-flex align-items-center justify-content-start blue-color">
                </div>
              </div>
            </div>

            {/* activate */}
            <div className="corners hts-card p-lg-5 p-4">
              <div className="rotate-uppertitle">
                <Uppertitle title={"STEP 03"} />
              </div>
              <div className="description-col marginRight hts-card-col reverse-description">
                <div className="mb-4 font-size-40 font-pnb line-height-11">

                  <p className="mb-0">
                    Add miner<span className="purple-color">.</span>
                  </p>
                </div>
                <div className="hts-p-container">
                  <p className="pb-3 font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                    Scroll down to the bottom of the page.

                  </p>
                  <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                    To add miner press on “+ Add miner”. A new page will be displayed with your Miner configuration and Pool ID. Click on “Next” and follow the instructions.

                  </p>
                </div>
                <div className="link-container w-100 d-flex align-items-center justify-content-start blue-color">
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center hts-card-col reverse-img-col">
                <img src={Step3} loading="lazy" alt="transactions" className="hts-image  img-fluid mb-md-0 mb-4 mh-432 border-radius-16" />
              </div>
            </div>

            {/* online */}

            {/* activate */}
            <div className="corners hts-card p-lg-5 p-4">
              <div className="rotate-uppertitle">
                <Uppertitle title={"STEP 04"} />
              </div>
              <div className="d-flex justify-content-center align-items-center hts-card-col reverse-img-col">
                <img src={Step5} loading="lazy" alt="transactions" className="hts-image  img-fluid mb-md-0 mb-4 mh-432 border-radius-16" />
              </div>
              <div className="description-col marginRight hts-card-col reverse-description">
                <div className="mb-4 font-size-40 font-pnb line-height-11">

                  <p className="mb-0">
                    Pair miner<span className="purple-color">.</span>
                  </p>
                </div>
                <div className="hts-p-container">
                  <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                    To pair the Miner with your KrateR Pool, write down on a piece of paper the 12 mnemonic words in the exact sequence and follow the instructions.

                  </p>
                </div>
                <div className="link-container w-100 d-flex align-items-center justify-content-start blue-color">
                </div>
              </div>

            </div>

            {/* online */}
            <div className="corners hts-card p-lg-5 p-4">
              <div className="rotate-uppertitle right-0">
                <Uppertitle title={"STEP 05"} />
              </div>
              <div className="description-col hts-card-col">
                <p className="mb-4 font-size-40 font-pnb line-height-11">
                  Link validator with KrateR Pool
                  <span className="purple-color">.</span>
                </p>
                <div className="hts-p-container">
                  <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                    To successfully add your miner, open the “Mining” app pre-installed on your HiDR or BASM devices, insert the 12 mnemonic words in the exact sequence, and click on “Link” to successfully complete the process.

                  </p>
                </div>

                <div className="link-container w-100 d-flex align-items-center justify-content-start blue-color">
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center marginRight hts-card-col">
                <img src={Step6} loading="lazy" alt="transactions" className="hts-image img-fluid mb-md-0 mb-5 border-radius-16" />
              </div>

            </div>

            {/* activate */}
            {/* <div className="corners hts-card p-lg-5 p-4">
              <div className="rotate-uppertitle">
                <Uppertitle title={"STEP 07"} />
              </div>
              <div className="description-col marginRight hts-card-col reverse-description">
                <div className="mb-4 font-size-40 font-pnb line-height-11">

                  <p className="mb-0">
                    Pair your device
                    with the KrateR Pool<span className="purple-color">.</span>
                  </p>
                </div>
                <div className="hts-p-container">
                  <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                    To link your device with the KrateR Pool scan the QR code and follow the steps.
                  </p>
                </div>
                <div className="link-container w-100 d-flex align-items-center justify-content-start blue-color">
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center hts-card-col reverse-img-col">
                <img src={Step7} loading="lazy" alt="transactions" className="hts-image  img-fluid mb-md-0 mb-4 mh-432 border-radius-16" />
              </div>
            </div> */}

            {/* online */}
            <div className="corners hts-card p-lg-5 p-4">
              <div className="rotate-uppertitle right-0">
                <Uppertitle title={"STEP 06"} />
              </div>
              <div className="d-flex justify-content-center align-items-center marginRight hts-card-col">
                <img src={Step8} loading="lazy" alt="transactions" className="hts-image img-fluid mb-md-0 mb-5 border-radius-16" />
              </div>
              <div className="description-col hts-card-col">
                <p className="mb-4 font-size-40 font-pnb line-height-11">
                  Activate miner<span className="purple-color">.</span>
                </p>
                <div className="hts-p-container">
                  <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                    Check the status of your miner and click on the “Turn on” button to activate it.

                  </p>
                </div>

                <div className="link-container w-100 d-flex align-items-center justify-content-start blue-color">
                </div>
              </div>
            </div>

            <div className="corners hts-card p-lg-5 p-4">
              <div className="rotate-uppertitle">
                <Uppertitle title={"STEP 07"} />
              </div>
              <div className="description-col marginRight hts-card-col reverse-description">
                <div className="mb-4 font-size-40 font-pnb line-height-11">

                  <p className="mb-0">
                    Stats<span className="purple-color">.</span>
                  </p>
                </div>
                <div className="hts-p-container">
                  <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                    Your newly added miner device will be displayed at the bottom of the page in the "Mining devices" table.

                  </p>
                </div>
                <div className="link-container w-100 d-flex align-items-center justify-content-start blue-color">
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center hts-card-col reverse-img-col">
                <img src={Step9} loading="lazy" alt="transactions" className="hts-image  img-fluid mb-md-0 mb-4 mh-432 border-radius-16" />
              </div>
            </div>
            {/* validating */}


            {/* wallet */}

          </div>
        </div>
      </div>
    </section>
  );
};

export default SupportHotspotSteps;
