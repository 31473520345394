import React from "react";
import "./FullPower.scss";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import LightningPhoto from "../../../assets/images/png/homePage/krater-pool.png";
import CoinPhoto from "../../../assets/images/png/homePage/connect-pool.png";
// import ArrowRight from "../../../assets/images/png/arrow-right.png";
import { AiOutlineArrowRight as ArrowRight } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../Utils/routes";
import { Col, Row } from "react-bootstrap";
import { ReactComponent as LockIco } from "../../../assets/images/svg/lock-big-bang.svg";

export default function FullPowerCardOnly() {
  const navigate = useNavigate();

  return (
    <section id="full-power" className="page-section">
      <div className="full-power-wrapper w-100">
        <div className="container-main">
          <div className="power-title font-pnb font-size-80 pb-lg-5 pb-4 white-color line-height-11"></div>

          <div className="section-zero mb-4 mt-3">
            <Row>
              <Col lg={8} className="mb-4 mb-lg-0">
                <div className="column-wrapper bg-1 d-flex flex-column justify-content-between align-items-start p-4 p-lg-5">
                  <Uppertitle title={"AGE BIG BANG"} />
                  <h4 className="font-pnb font-size-56 text-center m-auto">
                    <a
                      style={{ background: "none", color: "white!impor" }}
                      className="buttonBlue diff-buy m-auto mt-5 mb-3"
                      href="https://cryptodata.com/shop"
                      target="_blank"
                    >
                      <span className="me-2">Buy </span>
                    </a>{" "}
                    Minter Router <br />
                    to receive free <br />
                    <span className="blue-color">1000 locked XDEN</span> <br />
                    and use them for <br />
                    staking to become <br />a network validator<span className="purple-color">.</span>
                  </h4>
                </div>
              </Col>
              <Col lg={4}>
                <div className="column-wrapper bg-2 d-flex flex-column justify-content-between align-items-start p-4 p-lg-5">
                  <Uppertitle title={"LOCKED XDEN"} />
                  <div className="d-flex align-items-center justify-content-center flex-column m-auto">
                    <div className="mb-4">
                      <LockIco />
                    </div>
                    <h4 className="font-pnb font-size-40 text-center m-auto">
                      Locked XDEN <br />
                      <span className="blue-color">Can be Unlocked</span> <br />
                      only in <br />
                      private difficulty <br />
                      validation process<span className="purple-color">.</span>
                    </h4>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </section>
  );
}
