import React from "react";
import { ReactComponent as ArrowRight } from "../../../assets/images/svg/arrow-right.svg";

const SolutionsCard = (props) => {
  return (
    <div className="position-relative d-flex align-items-center justify-content-between sector-background min-width-sector">
      <div className=" d-flex flex-column  align-items-start">
        <div className="d-flex align-items-center justify-content-center">
          <div className="letter-wrapper font-size-28 blue-color font-pnb d-flex justify-content-center align-items-center">
            {props.letter}
          </div>
          <div className="sector-line" id="sector-line-one"></div>
        </div>

        <div className="sector-btn d-flex justify-content-center align-items-center white-color font-pnb font-size-40">
          {props.title} <ArrowRight />
        </div>
      </div>

      <img src={props.imageUrl} loading="lazy" alt="group" className="img-fluid sector-img" />
    </div>
  );
};

export default SolutionsCard;
