import React from "react";
import "./ChangeLog.scss";
import HeroSection from "./HeroSection/HeroSection";
import ChangeLogCards from "./ChangeLogCards/ChangeLogCards";

const ChangeLog = () => {
  return (
    <div className="change-log-main-page">
      <HeroSection />
      <ChangeLogCards />
    </div>
  );
};

export default ChangeLog;
