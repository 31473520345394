import "../../../template/color.scss";
import "../../../template/container.scss";
import "../../../template/fonts.scss";
import "./Sector.scss";
import { ReactComponent as ArrowRight } from "../../../assets/images/svg/arrow-right.svg";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import { Col, Row } from "react-bootstrap";
import Government from "../../../assets/images/png/xiden-page/government-img.png";
import Business from "../../../assets/images/png/xiden-page/business-img.png";
import Corporate from "../../../assets/images/png/xiden-page/corporate-img.png";
import Education from "../../../assets/images/png/xiden-page/education-img.png";
import Financial from "../../../assets/images/png/xiden-page/financial-img.png";
import SmartCity from "../../../assets/images/png/xiden-page/smart-city-img.png";
import SectorMobileSlider from "./SectorMobileSlider";

export default function Sector() {
  return (
    <section id="sector-section" className="page-section">
      <div className="container-main">
        <div className="d-flex flex-column justify-content-center align-items-start where-container">
          <Uppertitle title="SOLUTIONS" />
          <h3 className="font-size-80 font-pnb white-color line-height-11">
            Where it can be used<span className="purple-color">.</span>
          </h3>
          <p className="font-size-24 lightgrey-color font-pnr line-height-11 description-991">
            The Xiden network has a wide range of applicability. Thus, it can be implemented into various fields that require a
            rapid and secure data transmission environment.
          </p>
        </div>
        <div>
          <div className="content-margin">
            <Row className="d-lg-flex d-none">
              <Col lg={6}>
                <div className="column-wrapper position-relative d-flex align-items-center justify-content-between sector-background wrapper-left">
                  <div className="small-section-wrapper d-flex flex-column  align-items-start">
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="letter-wrapper font-size-28 blue-color font-pnb d-flex justify-content-center align-items-center">
                        G
                      </div>
                      <div className="sector-line" id="sector-line-one"></div>
                    </div>

                    <div className="sector-btn d-flex justify-content-center align-items-center white-color font-pnb font-size-40">
                      Government <ArrowRight />
                    </div>
                  </div>

                  <img src={Government} loading="lazy" alt="group" className="img-fluid sector-img" />
                </div>
              </Col>

              <Col lg={6}>
                <div className="column-wrapper position-relative d-flex align-items-center justify-content-between sector-background wrapper-right">
                  <div className="small-section-wrapper d-flex flex-column  align-items-start">
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="letter-wrapper font-size-28 blue-color font-pnb d-flex justify-content-center align-items-center">
                        B
                      </div>
                      <div className="sector-line" id="sector-line-two"></div>
                    </div>

                    <div className="sector-btn d-flex justify-content-center align-items-center white-color font-pnb font-size-40">
                      Business <ArrowRight />
                    </div>
                  </div>
                  <img src={Business} loading="lazy" alt="group" className="img-fluid sector-img" />
                </div>
              </Col>
            </Row>
            <Row className="d-lg-flex d-none">
              <Col lg={6}>
                <div className="column-wrapper position-relative d-flex align-items-center justify-content-between sector-background wrapper-left">
                  <div className="small-section-wrapper d-flex flex-column  align-items-start">
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="letter-wrapper font-size-28 blue-color font-pnb d-flex justify-content-center align-items-center">
                        C
                      </div>
                      <div className="sector-line" id="sector-line-three"></div>
                    </div>

                    <div className="sector-btn disabled d-flex justify-content-center align-items-center white-color font-pnb font-size-40">
                      Corporate <ArrowRight />
                    </div>
                  </div>
                  <img src={Corporate} loading="lazy" alt="group" className="img-fluid sector-img" />
                </div>
              </Col>
              <Col lg={6}>
                <div className="column-wrapper position-relative d-flex align-items-center justify-content-between sector-background wrapper-right">
                  <div className="small-section-wrapper d-flex flex-column  align-items-start">
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="letter-wrapper font-size-28 blue-color font-pnb d-flex justify-content-center align-items-center">
                        S
                      </div>
                      <div className="sector-line" id="sector-line-four"></div>
                    </div>

                    <div className="sector-btn d-flex justify-content-center align-items-center white-color font-pnb font-size-40">
                      Smart city <ArrowRight />
                    </div>
                  </div>
                  <img src={SmartCity} loading="lazy" alt="group" className="img-fluid sector-img" />
                </div>
              </Col>
            </Row>
            <Row className="d-lg-flex d-none">
              <Col lg={6}>
                <div className="column-wrapper position-relative d-flex align-items-center justify-content-between sector-background wrapper-left">
                  <div className="small-section-wrapper d-flex flex-column  align-items-start">
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="letter-wrapper font-size-28 blue-color font-pnb d-flex justify-content-center align-items-center">
                        F
                      </div>
                      <div className="sector-line" id="sector-line-five"></div>
                    </div>

                    <div className="sector-btn d-flex justify-content-center align-items-center white-color font-pnb font-size-40">
                      Financial <ArrowRight />
                    </div>
                  </div>
                  <img src={Financial} loading="lazy" alt="group" className="img-fluid sector-img" />
                </div>
              </Col>
              <Col lg={6}>
                <div className="column-wrapper position-relative d-flex align-items-center justify-content-between sector-background wrapper-right">
                  <div className="small-section-wrapper d-flex flex-column  align-items-start">
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="letter-wrapper font-size-28 blue-color font-pnb d-flex justify-content-center align-items-center">
                        E
                      </div>
                      <div className="sector-line" id="sector-line-six"></div>
                    </div>

                    <div className="sector-btn d-flex justify-content-center align-items-center white-color font-pnb font-size-40">
                      Education <ArrowRight />
                    </div>
                  </div>
                  <img src={Education} loading="lazy" alt="group" className="img-fluid sector-img" />
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <SectorMobileSlider />
      </div>
    </section>
  );
}
