import React from "react";
import "./CombinesAlgorithms.scss";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import { Row, Col } from "react-bootstrap";
import ProofOfStake from "../../../assets/images/png/proof-of-stake.png";
import ProofOfExistence from "../../../assets/images/png/proof-of-existence.png";
import ProofOfConnectivity from "../../../assets/images/png/proof-of-connectivity.png";
// import CoinImage from "../../../assets/images/png/gas-coin-red.png";
import CoinImage from "../../../assets/images/png/staking/moneda-consensus.png";
import { ReactComponent as ArrowRight } from "../../../assets//images/svg/arrow-right.svg";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../Utils/routes";
import { Link as Scrolllink } from "react-scroll";
import { HashLink } from "react-router-hash-link";

const CombinesAlgorithms = () => {
  const navigate = useNavigate();

  return (
    <section
      id="rewards-get-rewards"
      className="page-section combines-algorithms-section"
    >
      <div
        className="container-main get-rewards-section"
        style={{ zIndex: "9" }}
      >
        {/* heading */}
        <div className="w-100">
          <Uppertitle title={`MORE ALGORITHMS`} />
          <h2 className="font-size-80 font-pnb line-height-11">
            Consensus combines <br /> more algorithms
            <span className="pink-text">.</span>
          </h2>
          <div className="rewards-p-container-lg">
            <p className="font-family-pnr font-size-24 lightgrey-color line-height-11 combines-algorithms-text mb-0">
              Multiple integrated distributed processes ensure that all peers
              reach a common agreement and establish the optimal operation of
              the network.
            </p>
          </div>
        </div>

        {/* cards */}
        <Row xs={1} sm={1} md={1} lg={2} className="mt-2 pt-5">
          <Col className="mb-4 mb-sm-4 mb-md-4 mb-lg-0">
            <div className="w-100 d-flex flex-column cards-col">
              <div className="difficulty-card">
                <img
                  src={ProofOfStake}
                  loading="lazy"
                  alt="private difficulty"
                  className="card-image-absolute"
                />
                <div className="top-image-container">
                  <img
                    src={ProofOfStake}
                    loading="lazy"
                    alt="private difficulty"
                    className="top-card-image"
                  />
                </div>
                <div className="w-100 h-100 d-flex flex-column justify-content-between">
                  <div className="w-100 top-paragraph">
                    <div className="heading-container">
                      <h5 className="font-size-32 font-pnb">
                        Proof of Stake<span className="pink-text">.</span>
                      </h5>
                      <p className="font-size-20 lightgrey-color font-pnr line-height-11">
                        A consensus mechanism used to validate transactions.
                        With this system, owners of the XDEN can stake their
                        tokens, which gives them the right to check new blocks
                        of transactions and add them to the blockchain.
                      </p>
                      <HashLink
                        className="font-pnsb font-size-20 read-more-link me-3 white-color"
                        smooth
                        to="/support-minter/#how-to-get-1000-xden"
                        offset={-70}
                      >
                        How to Stake
                        <ArrowRight />
                      </HashLink>
                    </div>
                  </div>
                </div>
              </div>

              <div className="difficulty-card">
                <img
                  src={ProofOfExistence}
                  loading="lazy"
                  alt="public difficulty"
                  className="card-image-absolute"
                />
                <div className="top-image-container">
                  <img
                    src={ProofOfExistence}
                    loading="lazy"
                    alt="private difficulty"
                    className="top-card-image"
                  />
                </div>
                <div className="w-100 h-100 d-flex flex-column justify-content-between">
                  <div className="w-100 top-paragraph">
                    <div className="heading-container">
                      <h5 className="font-size-32 font-pnb">
                        Proof of Existence<span className="pink-text">.</span>
                      </h5>
                      <p className="font-size-20 lightgrey-color font-pnr line-height-11">
                        A consensus mechanism that integrates and verifies the
                        identities of the validators within the system. PoE is
                        designed to work together with the other consensus
                        mechanisms - PoS and PoC - and increase the system's
                        security.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* 
              <div className="difficulty-card">
                <img src={ProofOfExistence} loading="lazy" alt="public difficulty" className="card-image-absolute" />
                <div className="top-image-container">
                  <img src={ProofOfExistence} loading="lazy" alt="private difficulty" className="top-card-image" />
                </div>
                <div className="w-100 h-100 d-flex flex-column justify-content-between">
                  <div className="w-100 top-paragraph">
                    <div className="heading-container">
                      <h5 className="font-size-32 font-pnb">
                        Proof of existence<span className="pink-text">.</span>
                      </h5>
                      <p className="font-size-20 lightgrey-color font-pnr line-height-11">
                        A consensus mechanism that integrates and verifies the identities of the validators within the system. PoE
                        is designed to work together with the other consensus mechanisms - PoS and PoC - and increase the system's
                        security.
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="difficulty-card">
                <img
                  src={ProofOfConnectivity}
                  loading="lazy"
                  alt="public difficulty"
                  className="card-image-absolute"
                />
                <div className="top-image-container">
                  <img
                    src={ProofOfConnectivity}
                    loading="lazy"
                    alt="private difficulty"
                    className="top-card-image"
                  />
                </div>
                <div className="w-100 h-100 d-flex flex-column justify-content-between">
                  <div className="w-100 top-paragraph">
                    <div className="heading-container">
                      <h5 className="font-size-32 font-pnb">
                        Proof of Connectivity
                        <span className="pink-text">.</span>
                      </h5>
                      <p className="font-size-20 lightgrey-color font-pnr line-height-11">
                        PoC is a layer consensus mechanism that verifies the
                        status, validity, and availability of all the devices
                        integrated into the Xiden blockchain, whether they are
                        validators or devices that are part of the SDR layer.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>

          <Col className="get-rewards-corners-col">
            <div className="corners get-rewards-corners">
              <div className="coin-image-container">
                <img
                  src={CoinImage}
                  loading="lazy"
                  alt="coin"
                  className="coin-image"
                />
              </div>
              <div className="get-rewards-corners-txt">
                <Uppertitle title={`PENALTY`} />
                <h2 className="font-size-80 font-pnb">
                  Fault<span className="pink-text-punishment">.</span>
                </h2>
                <div className="rewards-p-container">
                  <p className="font-family-pnr font-size-20 lightgrey-color line-height-11">
                    Any attempt to fraud the system or to break the consensus
                    rules is punished by unstaking the user’s XDEN from its
                    KrateR Pool. The amount will be divided and distributed to
                    the online registered devices during the first Age in order
                    to generate liquidity in the market.{" "}
                  </p>
                </div>
                <button
                  className="font-pnsb font-size-20 read-more-link"
                  onClick={() =>
                    window.open(
                      "https://xiden.com/whitepaper/xiden-layers/consensus/#7332-fault",
                      "_blank"
                    )
                  }
                >
                  Read more
                  <ArrowRight />
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default CombinesAlgorithms;
