import React from "react";
import "./Properties.scss";
import { Row, Col } from "react-bootstrap";

import FeatureCard from "../../../components/FeatureCard/FeatureCard";
import { cardsData } from "./cardsData";

const Properties = () => {
  return (
    <section id="properties-section" className="page-section">
      <div className="container-main">
        <div className="w-100 heading-container">
          <h2 className="font-size-80 white-color font-pnb">
            Properties<span className="purple-color">.</span>
          </h2>
        </div>
        <Row xs={1} sm={2} lg={4} className="cards-row">
          {cardsData.map((card) => (
            <Col key={card.id}>
              <FeatureCard cardData={card} />
            </Col>
          ))}
        </Row>
      </div>
    </section>
  );
};

export default Properties;
