import React from 'react';
import "./Steps.scss";
import DashRight from "../assets/dash-right.png";
import DashLeft from "../assets/dash-left.png";
import Uppertitle from '../../../components/Uppertitle/Uppertitle';
import { Row, Col } from 'react-bootstrap';

const Steps = () => {
    return (
        <section id="prizes-steps-section" className="page-section ">
            <div className="container-main">
                <div className="title-container d-flex flex-column align-items-center mb-5">
                    <img src={DashLeft} alt="dash" className='me-auto img-fluid' />
                    <h2 className="mt-4 font-size-80 white-color font-pnb line-height-1 text-center my-0">Steps</h2>
                    <h2 className="mb-4 font-size-80 white-color font-pnb line-height-1 text-center my-0">to participate<span className="purple-color">.</span></h2>

                    <img src={DashRight} alt="dash" className='ms-auto img-fluid' />
                </div>


                <Uppertitle title={"STEPS"} />

                <div className='prizes pt-5'>
                    <Row className="justify-content-between">
                        <Col xl={5} lg={6} md={6} sm={12}>
                            <div className="text-center corners p-1 p-sm-3 p-md-4 p-lg-5 my-0">
                                <div className="design-title font-size-40 font-pnb text-center mb-5">Pool Design</div>

                                {/* STEP */}
                                <div className="step-container d-flex align-items-center justify-content-center flex-row mb-5">
                                    <div className="step-number d-flex align-items-center justify-content-center font-pnb font-size-64">
                                        1
                                    </div>
                                    <div className="step-content ms-3">
                                        <div className="step-title font-size-20 font-pnsb text-start line-height-11 mb-2">
                                            Take a pic
                                        </div>
                                        <div className="step-description font-size-20 font-pnr lightgrey-color text-start line-height-11">
                                            Take a clear picture of your mining setup, preferably using the landscape format.
                                        </div>
                                    </div>
                                </div>

                                {/* STEP */}
                                <div className="step-container d-flex align-items-center justify-content-center flex-row mb-5">
                                    <div className="step-number d-flex align-items-center justify-content-center font-pnb font-size-64">
                                        2
                                    </div>
                                    <div className="step-content ms-3">
                                        <div className="step-title font-size-20 font-pnsb text-start line-height-11 mb-2">
                                            Twitter post
                                        </div>
                                        <div className="step-description font-size-20 font-pnr lightgrey-color text-start line-height-11">
                                            Reply to this {" "}
                                            <span
                                                className='purple-color pointer'
                                                onClick={() => window.open("https://twitter.com/xidenblockchain/status/1546820489145901062")}
                                            >
                                                post
                                            </span> 
                                            {" "}on Twitter with your picture and the following hashtags: #xidenpools #miningcompetition. Copy the link specific to your reply.
                                        </div>
                                    </div>
                                </div>

                                {/* STEP */}
                                <div className="step-container d-flex align-items-center justify-content-center flex-row mb-5">
                                    <div className="step-number d-flex align-items-center justify-content-center font-pnb font-size-64">
                                        3
                                    </div>
                                    <div className="step-content ms-3">
                                        <div className="step-title font-size-20 font-pnsb text-start line-height-11 mb-2">
                                            Where to find the web form
                                        </div>
                                        <div className="step-description font-size-20 font-pnr lightgrey-color text-start line-height-11">
                                            Come back to the Mining Competition landing page and scroll down until you get to the web forms.
                                        </div>
                                    </div>
                                </div>

                                {/* STEP */}
                                <div className="step-container d-flex align-items-center justify-content-center flex-row">
                                    <div className="step-number d-flex align-items-center justify-content-center font-pnb font-size-64">
                                        4
                                    </div>
                                    <div className="step-content ms-3">
                                        <div className="step-title font-size-20 font-pnsb text-start line-height-11 mb-2">
                                            Submit your details
                                        </div>
                                        <div className="step-description font-size-20 font-pnr lightgrey-color text-start line-height-11">
                                            Complete the Pool Design registration form with all the details and click ‘Submit’
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </Col>
                        <Col xl={5} lg={6} md={6} sm={12}>
                            <div className="text-center corners p-1 p-sm-3 p-md-4 p-lg-5 mb-0 mt-md-0">
                                <div className="performance-title font-size-40 font-pnb text-center mb-5">Pool Performance</div>

                                {/* STEP */}
                                <div className="step-container d-flex align-items-center justify-content-center flex-row mb-5">
                                    <div className="step-number pool-number d-flex align-items-center justify-content-center font-pnb font-size-64">
                                        1
                                    </div>
                                    <div className="step-content ms-3">
                                        <div className="step-title font-size-20 font-pnsb text-start line-height-11 mb-2">
                                            Login to your Pool
                                        </div>
                                        <div className="step-description font-size-20 font-pnr lightgrey-color text-start line-height-11">
                                            Go to xiden.com and login to your pool account using your account credentials.
                                        </div>
                                    </div>
                                </div>

                                {/* STEP */}
                                <div className="step-container d-flex align-items-center justify-content-center flex-row mb-5">
                                    <div className="step-number pool-number d-flex align-items-center justify-content-center font-pnb font-size-64">
                                        2
                                    </div>
                                    <div className="step-content ms-3">
                                        <div className="step-title font-size-20 font-pnsb text-start line-height-11 mb-2">
                                            Find your Pool ID
                                        </div>
                                        <div className="step-description font-size-20 font-pnr lightgrey-color text-start line-height-11">
                                            Go to the Pools tab and scroll down to the bottom of the page to find your pool ID
                                        </div>
                                    </div>
                                </div>

                                {/* STEP */}
                                <div className="step-container d-flex align-items-center justify-content-center flex-row mb-5">
                                    <div className="step-number pool-number d-flex align-items-center justify-content-center font-pnb font-size-64">
                                        3
                                    </div>
                                    <div className="step-content ms-3">
                                        <div className="step-title font-size-20 font-pnsb text-start line-height-11 mb-2">
                                            Where to find the web form
                                        </div>
                                        <div className="step-description font-size-20 font-pnr lightgrey-color text-start line-height-11">
                                            Come back to the Mining Competition landing page and scroll down until you get to the web forms.
                                        </div>
                                    </div>
                                </div>

                                {/* STEP */}
                                <div className="step-container d-flex align-items-center justify-content-center flex-row">
                                    <div className="step-number pool-number d-flex align-items-center justify-content-center font-pnb font-size-64">
                                        4
                                    </div>
                                    <div className="step-content ms-3">
                                        <div className="step-title font-size-20 font-pnsb text-start line-height-11 mb-2">
                                            Submit your details
                                        </div>
                                        <div className="step-description font-size-20 font-pnr lightgrey-color text-start line-height-11">
                                            Complete the Pool Performance registration form with all the details and click ‘Submit’.
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </Col>
                    </Row>
                </div>

            </div>
        </section>
    )
}

export default Steps