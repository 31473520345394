import React from "react";
import "./MetaRealm.scss";

import { Helmet } from "react-helmet";
import SchemaTemplate from "../../SEO/SchemaTemplate.json";
import HeroSection from "./HeroSection/HeroSection";
import SliderSection from "./SliderSection/SliderSection";
import Steps from "./Steps/Steps";
import XidenPartner from "./XidenPartner/XidenPartner";

const MetaRealm = () => {
   return (
      <div id="meta-realm-page">
         {/* seo */}
         <Helmet>
            <title>Xiden | Realm Metanodes</title>
            <meta name="title" content="Xiden | Realm Metanodes" />
            <meta name="description" content="Become a Xiden Partner by owning one of 30 Realm Metanodes. Find out how to configure, activate and build upon your Realm Metanode in order to maximize validation rewards." />
            <link rel="canonical" href="" />
            {/* Open Graph Facebook */}
            <meta property="og:title" content="Xiden | Realm Metanodes" />
            <meta property="og:description" content="" />
            <meta property="og:type" content="website" />
            <meta property="og:image" content="" />
            <meta property="og:url" content="" />
            {/* Open Graph Twitter  */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="" />
            <meta property="twitter:title" content="Xiden | Realm Metanodes" />
            <meta property="twitter:description" content="" />
            <meta property="twitter:image" content="" />
            {/* Structured Data */}
            <script>{JSON.stringify(SchemaTemplate)}</script>
         </Helmet>

         {/* sections */}
         <HeroSection />
         <SliderSection />
         <XidenPartner />
         <Steps />
      </div>
   );
};

export default MetaRealm;
