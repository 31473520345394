import React from "react";
import "./GuardianFeatures.scss";
import { Row, Col } from "react-bootstrap";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import { ReactComponent as ArrowRight } from "../../../assets/images/svg/arrow-right.svg";
import { ReactComponent as ThreatsBlockerIcon } from "../../../assets/images/svg/threats-blocker-icon.svg";
import { ReactComponent as VPNIcon } from "../../../assets/images/svg/vpn-icon.svg";
import { ReactComponent as HotspotIcon } from "../../../assets/images/svg/web-access-icon.svg";
import { ReactComponent as AdBlockerIcon } from "../../../assets/images/svg/ad-blocker-icon.svg";
import { ReactComponent as HideIdentityIcon } from "../../../assets/images/svg/hide-identity-icon.svg";
import { ReactComponent as TransactionValidatorIcon } from "../../../assets/images/svg/transaction-validator-icon.svg";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../Utils/routes";
import GuardianFeaturesMobile from "./GuardianFeaturesMobileCard/GuardianFeaturesMobile";

const GuardianFeatures = () => {
  const navigate = useNavigate();

  return (
    <section className="page-section guardian-features">
      <div className="container-main">
        {/* heading */}
        <h2 className="mb-0 font-size-80 white-color mb-0 font-pnb line-height-11">
          Features
          <span className="purple-color">.</span>
        </h2>
        <div className="py-lg-5 py-4 paragraph-container ">
          <p className="font-family-pnr font-size-24 lightgrey-color  line-height-11 ">
            Discover the features of a multiple functions Router that provides free, restrictionless, and permanent internet
            connection regardless of user location or device.
          </p>
        </div>

        {/* cards */}
        {/* main row */}
        <div className="d-none d-lg-flex w-100">
          <Row xs={1} sm={1} md={1} lg={3} className="w-100">
            {/* column 1 */}
            <Col>
              {/* threats card */}
              <Row>
                <Col>
                  <div className="wrapper-coloana bg1 coloana-r2 threats-blocker-card d-flex flex-column guardian-features-card">
                    <Uppertitle title={"THREATS BLOCKER"} />
                    <div className="d-flex flex-column justify-content-between align-items-start py-lg-0 py-5">
                      <ThreatsBlockerIcon className="img-fluid mb-lg-5 mb-4 card-icons" />
                      <div className="content-card d-flex flex-column justify-content-between">
                        <div className="font-pnb font-size-56 white-color mb-0 line-height-11 features-description">
                          <p className="mb-0 line-height-11">Secures every</p>
                          <p className="mb-0 line-height-11">connected</p>
                          <p className="mb-0 line-height-11">device in</p>
                          <p className="mb-0 line-height-11">
                            the network<span className="purple-color">.</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <button className="font-size-20 get-started-button font-pnsb" onClick={() => navigate(routes.apps)}>
                    Read more
                    <ArrowRight />
                  </button> */}
                  </div>
                </Col>
              </Row>

              {/* transaction card */}
              <Row>
                <Col>
                  <div className="wrapper-coloana bg6 coloana-r2 threats-blocker-card d-flex flex-column transaction-card guardian-features-card">
                    <Uppertitle title={"TRANSACTION VALIDATOR"} />
                    <div className="d-flex flex-column justify-content-between align-items-start py-lg-0 py-5">
                      <TransactionValidatorIcon className="img-fluid mb-lg-5 mb-4 card-icons" />
                      <div className="content-card-two d-flex flex-column justify-content-between">
                        <div className="font-pnb font-size-56 white-color mb-0 line-height-11 features-description">
                          <p className="mb-0">Validates that</p>
                          <p className="mb-0">transactions</p>
                          <p className="mb-0">are signed by</p>
                          <p className="mb-0">pre-identified</p>
                          <p className="mb-0">
                            devices<span className="purple-color">.</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <button className="font-size-20 get-started-button font-pnsb" onClick={() => navigate(routes.apps)}>
                    Read more
                    <ArrowRight />
                  </button> */}
                    <div></div>
                  </div>
                </Col>
              </Row>
            </Col>

            {/* column 2 */}
            <Col>
              {/* vpn */}
              <Row>
                <Col>
                  <div className="wrapper-coloana bg2 coloana-r2-c2-c1 d-flex flex-column guardian-features-card">
                    <Uppertitle title={"VPN"} />
                    <div className="py-lg-0 py-5">
                      <VPNIcon className="img-fluid mb-4 card-icons" />
                      <div className="font-pnb font-size-56 white-color mb-0 line-height-11 features-description">
                        <p className="mb-0">
                          VPN
                          <span className="purple-color">.</span>
                        </p>
                      </div>
                    </div>

                    {/* <button className="font-size-20 get-started-button font-pnsb" onClick={() => navigate(routes.apps)}>
                    Read more
                    <ArrowRight />
                  </button> */}
                    <div></div>
                  </div>
                </Col>
              </Row>

              {/* ad blocker */}
              <Row>
                <Col>
                  <div className="wrapper-coloana bg4 coloana-r2-c2-c2 ad-blocker blue-color d-flex flex-column guardian-features-card">
                    <Uppertitle title={"AD BLOCKER"} />
                    <div className="py-lg-0 py-5">
                      <AdBlockerIcon className="img-fluid mb-4 card-icons" />
                      <div className="font-pnb font-size-56 white-color mb-0 line-height-11 features-description">
                        <p className="mb-0">
                          Ad Blocker
                          <span className="purple-color">.</span>
                        </p>
                      </div>
                    </div>

                    {/* <button className="font-size-20 get-started-button font-pnsb" onClick={() => navigate(routes.apps)}>
                    Read more
                    <ArrowRight />
                  </button> */}
                    <div></div>
                  </div>
                </Col>
              </Row>

              {/* computing power */}
              <Row>
                <Col>
                  <div className="wrapper-coloana bg7 coloana-r2 threats-blocker-card d-flex flex-column guardian-features-card">
                    <Uppertitle title={"COMPUTING POWER"} />
                    <div className="d-flex flex-column justify-content-between align-items-start py-lg-0 py-5">
                      <ThreatsBlockerIcon className="img-fluid mb-lg-5 mb-4 card-icons" />
                      <div className="content-card d-flex flex-column justify-content-between">
                        <div className="font-pnb font-size-56 white-color mb-0 line-height-11 features-description">
                          <p className="mb-0">Computing</p>
                          <p className="mb-0">resources</p>
                          <p className="mb-0">for DApps</p>
                          <p className="mb-0">
                            development
                            <span className="purple-color">.</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <button className="font-size-20 get-started-button font-pnsb" onClick={() => navigate(routes.apps)}>
                    Read more
                    <ArrowRight />
                  </button> */}
                    <div></div>
                  </div>
                </Col>
              </Row>
            </Col>

            {/* column 3 */}
            <Col>
              {/* 5g */}
              <Row>
                <Col>
                  <div className="wrapper-coloana bg3 coloana-r2-c2-c2 blue-color d-flex flex-column guardian-features-card">
                    <Uppertitle title={"5G HOTSPOT"} />
                    <div className="py-lg-0 py-5">
                      <HotspotIcon className="img-fluid mb-4 card-icons" />
                      <div className="font-pnb font-size-56 white-color mb-0 line-height-11 features-description">
                        <p className="mb-0">
                          5G Hotspot<span className="purple-color">.</span>
                        </p>
                      </div>
                    </div>

                    {/* <button className="font-size-20 get-started-button font-pnsb" onClick={() => navigate(routes.apps)}>
                    Read more
                    <ArrowRight />
                  </button> */}
                    <div></div>
                  </div>
                </Col>
              </Row>

              {/* anonymous */}
              <Row>
                <Col>
                  <div className="wrapper-coloana bg5 coloana-r2 d-flex flex-column guardian-features-card">
                    <Uppertitle title={"PROTECT MY IDENTITY"} />
                    <div className="d-flex flex-column justify-content-between align-items-start py-lg-0 py-5">
                      <HideIdentityIcon className="img-fluid mb-lg-5 mb-4 card-icons" />
                      <div className="content-card d-flex flex-column justify-content-between">
                        <div className="font-pnb font-size-56 white-color mb-0 line-height-11 features-description">
                          <p className="mb-0"> Remain</p>
                          <p className="mb-0"> anonymous</p>
                          <p className="mb-0">
                            online<span className="purple-color">.</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <button className="font-size-20 get-started-button font-pnsb" onClick={() => navigate(routes.apps)}>
                    Read more
                    <ArrowRight />
                  </button> */}
                    <div></div>
                  </div>
                </Col>
              </Row>

              {/* plant */}
              <Row>
                <Col>
                  <div className="wrapper-coloana coloana-r3-c2 blue-color d-flex flex-column guardian-features-card ethernytree-card">
                    <Uppertitle title={"LOW ENERGY"} />
                    <div className="titles">
                      <div className="font-pnb font-size-56 low-energy line-height-11">Low energy.</div>
                      <div className="font-pnb font-size-56 black-color plant-a-tree line-height-11">
                        Plant a<span> tree.</span>
                      </div>
                    </div>
                    <div>
                      <button
                        className="font-size-20 plant-link font-pnsb"
                        onClick={() => window.open("https://ethernytree.com/")}
                      >
                        Get started
                        <ArrowRight className="ms-2" />
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <GuardianFeaturesMobile />
      </div>
    </section>
  );
};

export default GuardianFeatures;
