import React, { useState } from "react";
import "./Newsletter.scss";
import "../../template/color.scss";
import "../../template/container.scss";
import "../../template/fonts.scss";
import "../../template/buttons.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { ReactComponent as Arrowrightup } from "../Assets/images/svg/arrowrightup.svg";
import { useLocation } from "react-router-dom";
import { routes } from "../../Utils/routes";
// import { emailPattern } from "../../Utils/regexPatterns";
import { toast } from "react-toastify";
import axios from "axios";

export default function Newsletter() {
  const [email, setEmail] = useState("");
  const [buttonState, setButtonState] = useState(true);

  const location = useLocation();

  // const emailInputRef = useRef();

  // const handleEmailSubmit = event => {

  //    event.preventDefault();
  //    toast.error("The service is currently unavailable", { toastId: "newsletter" });
  // };

  const emailSetter = (e) => {
    setEmail(e.target.value);
    console.log(email);
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    if (email === "") {
      return toast.error("Fields can't be empty");
    }

    let body = {
      email: email,
    };
    console.log("eu is obiectu", body);
    try {
      let response = await axios.post("https://jupiter-test.com/api/invitation/subscribe-xiden", body);
      if (response.status === 200) {
        toast.success("Newsletter Subscription Registration Success");
        setButtonState(false);
      }
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          default:
            toast.error("Ooops... Looks like something went wrong");
            break;
        }
      }
    }
  };

  return (
    <section
      id="newsletter-component"
      className={`pb-5 ${location.pathname === routes.minter ? "blue-bg-color" : "gray-bg-color"} `}
    >
      <div className="container-main p-2">
        {buttonState ? (
          <div
            className={`${
              location.pathname === routes.minter ? "blue-bg-color" : "gray-bg-color"
            } d-flex align-items-center newsletter-section p-4 justify-content-around`}
          >
            <h4 className="newsletter-title font-pnb text-white font-size-40">Get Xiden Updates to your Inbox</h4>

            <form className="d-flex align-items-center custom-search " onSubmit={handleEmailSubmit}>
              <input
                onChange={emailSetter}
                value={email}
                id="devices"
                className={`${
                  location.pathname === routes.minter ? "blue-bg-color" : "gray-bg-color"
                } custom-search-input font-size-24 font-pnr white-color`}
                type="text"
                placeholder="e.g. connect@xiden.com"
              />
              <button
                type="submit"
                className="custom-search-button font-size-24 font-pnr d-flex justify-content-center align-items-center"
                onClick={handleEmailSubmit}
              >
                Sign up <Arrowrightup className="arrow-svg" />
              </button>
            </form>
          </div>
        ) : (
          <div className="d-flex align-items-center newsletter-section py-5 px-4 justify-content-around">
            <h4 className="newsletter-title font-pnb text-white font-size-40">Thank you for newsletter Subscription</h4>
          </div>
        )}
      </div>
    </section>
  );
}
