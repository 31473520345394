import React from "react";
import "./TermsOfUse.scss";
import { Helmet } from "react-helmet";
import SchemaTemplate from "../../SEO/SchemaTemplate.json";
import { useNavigate } from "react-router-dom";
import { routes } from "../../Utils/routes";

const TermsOfUse = () => {
  const navigate = useNavigate();
  return (
    <section id="terms-of-use-section" className="page-section">
      {/* seo */}
      <Helmet>
        <title>Xiden | Terms and conditions</title>
        <meta name="title" content="Terms and conditions" />
        <meta name="description" content="" />
        <link rel="canonical" href="" />
        {/* Open Graph Facebook */}
        <meta property="og:title" content="Terms and conditions" />
        <meta property="og:description" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        {/* Open Graph Twitter  */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="" />
        <meta property="twitter:title" content="Terms and conditions" />
        <meta property="twitter:description" content="" />
        <meta property="twitter:image" content="" />
        {/* Structured Data */}
        <script>{JSON.stringify(SchemaTemplate)}</script>
      </Helmet>
      <div className="container-main">
        <h2 className="text-center font-pnb font-size-40 m-custom">
          Terms and conditions
        </h2>

        <h4 className="font-pnsb font-size-24 mb-3">Acceptance of Terms</h4>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          These Xiden Terms of Use is entered into between Xiden Technologies
          SRL (hereinafter Xiden, “we” or “us”) and you (hereinafter referred to
          as “you” or “your”). By accessing, downloading, using or clicking on
          “I agree” to accept any Xiden Services (as defined below) provided by
          Xiden (as defined below), you agree that you have read, understood and
          accepted all of the terms and conditions stipulated in these Terms of
          Use (hereinafter referred to as “these Terms”) as well as our Privacy
          Policy at
          <span
            className="purple-color terms-link"
            onClick={() => navigate(routes.dataPrivacy)}
          >
            {" "}
            Xiden Privacy Policy
          </span>
          . In addition, when using some features of the Services, you may be
          subject to specific additional terms and conditions applicable to
          those features. Please read the terms carefully as they govern your
          use of Xiden Services. By accessing, using or attempting to use Xiden
          Services in any capacity, you acknowledge that you accept and agree to
          be bound by these Terms. If you do not agree, do not access Xiden or
          utilize Xiden services.
        </p>

        <h4 className="font-pnsb font-size-24 mb-3">
          I<span className="purple-color">.</span> Definitions
        </h4>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          <span className="white-color">1</span>
          <span className="purple-color">.</span>{" "}
          <span className="font-pnb white-color">Xiden</span> refers to an
          ecosystem comprising Xiden websites (whose domain names include but
          are not limited to{" "}
          <span
            onClick={() => navigate(routes.home)}
            className="purple-color terms-link"
          >
            https://www.xiden.com/
          </span>
          , mobile applications, clients, applets and other applications that
          are developed to offer Xiden Services, and includes
          independently-operated platforms, websites and clients within the
          ecosystem. In case of any inconsistency between relevant terms of use
          of the above platforms and the contents of these Terms, the respective
          applicable terms of such platforms shall prevail.
        </p>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          <span className="white-color">2</span>
          <span className="purple-color">.</span>{" "}
          <span className="font-pnb white-color">Xiden Services </span> refer to
          various services provided to you by Xiden that are based on Internet
          and/or blockchain technologies and offered via Xiden websites,
          devices, mobile applications, clients and other forms (including new
          ones enabled by future technological development). Xiden Services.
        </p>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          <span className="white-color">3</span>
          <span className="purple-color">.</span>{" "}
          <span className="font-pnb white-color">Xiden Platform Rules </span>{" "}
          refer to all rules, interpretations, announcements, statements,
          letters of consent and other contents that have been and will be
          subsequently released by Xiden, as well as all regulations,
          implementation rules, product process descriptions, and announcements
          published on the website or within products or service processes.
        </p>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          <span className="white-color">4</span>
          <span className="purple-color">.</span>{" "}
          <span className="font-pnb white-color">Users </span> refer to all
          individuals, institutions or organizations that access, download or
          use Xiden or Xiden Services and who meet the criteria and conditions
          stipulated by Xiden. If there exist other agreements for such entities
          as developers, distributors, market makers, such agreements shall be
          followed.
        </p>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          <span className="white-color">5</span>
          <span className="purple-color">.</span>{" "}
          <span className="font-pnb white-color">Utility tokens </span> refer to
          encrypted or digital tokens that are based on blockchain and
          cryptography technologies and are issued and managed in a
          decentralized form.
        </p>

        <h4 className="font-pnsb font-size-24 mb-3">
          II<span className="purple-color">.</span> General Provisions
        </h4>
        <h5 className="font-pnsb font-size-20 mb-2">
          1<span className="purple-color">.</span> About These Terms
        </h5>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          <span className="white-color">a</span>
          <span className="purple-color">.</span>{" "}
          <span className="font-pnb white-color">Contractual Relationship</span>{" "}
          <br />
          These Terms constitute a legal agreement and create a binding contract
          between you and Xiden.
        </p>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          <span className="white-color">b</span>
          <span className="purple-color">.</span>{" "}
          <span className="font-pnb white-color">Supplementary Terms </span>{" "}
          <br />
          Due to the rapid development of technological developments, these
          Terms between you and Xiden do not enumerate or cover all rights and
          obligations of each party, and do not guarantee full alignment with
          needs arising from future development. Therefore, THE PRIVACY POLICY (
          <span
            className="purple-color terms-link"
            onClick={() => navigate(routes.dataPrivacy)}
          >
            Xiden Privacy Policy
          </span>
          ), XIDEN PLATFORM RULES, AND ALL OTHER AGREEMENTS ENTERED INTO
          SEPARATELY BETWEEN YOU AND XIDEN ARE DEEMED SUPPLEMENTARY TERMS THAT
          ARE AN INTEGRAL PART OF THESE TERMS AND SHALL HAVE THE SAME LEGAL
          EFFECT. YOUR USE OF XIDEN SERVICES IS DEEMED YOUR ACCEPTANCE OF THE
          ABOVE SUPPLEMENTARY TERMS.
        </p>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          <span className="white-color">c</span>
          <span className="purple-color">.</span>{" "}
          <span className="font-pnb white-color">Changes to These Terms </span>{" "}
          <br />
          Xiden reserves the right to change or modify these Terms in its
          discretion at any time. Xiden will notify such changes by updating the
          terms on its website. ANY AND ALL MODIFICATIONS OR CHANGES TO THESE
          TERMS WILL BECOME EFFECTIVE UPON PUBLICATION ON THE WEBSITE OR RELEASE
          TO USERS. THEREFORE, YOUR CONTINUED USE OF XIDEN SERVICES IS DEEMED
          YOUR ACCEPTANCE OF THE MODIFIED AGREEMENT AND RULES. IF YOU DO NOT
          AGREE TO ANY CHANGES TO THESE TERMS, YOU MUST STOP USING XIDEN
          SERVICES IMMEDIATELY. YOU ARE RECOMMENDED TO FREQUENTLY REVIEW THESE
          TERMS TO ENSURE YOUR UNDERSTANDING OF THE TERMS AND CONDITIONS THAT
          APPLY TO YOUR ACCESS TO AND USE OF XIDEN SERVICES.
        </p>

        <h5 className="font-pnsb font-size-20 mb-2">
          2<span className="purple-color">.</span> About Xiden
        </h5>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          As an important part of the Xiden Ecosystem, Xiden mainly serves as a
          decentralized network that provides users with the opportunity to have
          a free, unrestricted, and permanent internet connection regardless of
          user location or device. Although Xiden has been committed to
          maintaining the accuracy of the information provided through Xiden
          Services, Xiden cannot and does not guarantee its accuracy,
          applicability, reliability, integrity, performance or appropriateness,
          nor shall Xiden be liable for any loss or damage that may be caused
          directly or indirectly by your use of these contents. The information
          about Xiden Services may change without notice, and the main purpose
          of providing such information is to help Users make independent
          decisions. Xiden does not provide investment or consulting advice of
          any kind, and is not responsible for the use or interpretation of
          information on Xiden or any other communication medium. All Users of
          Xiden Services must understand the risks involved, and are recommended
          to exercise prudence and involve themselves responsibly within their
          own capabilities.
        </p>

        <h5 className="font-pnsb font-size-20 mb-2">
          3<span className="purple-color">.</span> Xiden Account Registration
          and Requirements
        </h5>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          <span className="white-color">a</span>
          <span className="purple-color">.</span>{" "}
          <span className="font-pnb white-color">Registration</span> <br />
          All Users must apply for a Xiden Account before using Xiden Services.
          When you register a Xiden Account, you must provide the information as
          requested by Xiden, and accept these Terms, the Privacy Policy, and
          other Xiden Platform Rules. Xiden may refuse, in its discretion, to
          open a Xiden account for you. You agree to provide complete and
          accurate information when opening a Xiden account and agree to timely
          update any information you provide to Xiden to maintain the integrity
          and accuracy of the information. For certain Xiden Services, you may
          be required to set up a specific account independent from your Xiden
          account, based on the provisions of these Terms or the Supplementary
          Terms. The registration, use, protection and management of such
          accounts are equally governed by the provisions of this Section and
          Section VI, unless otherwise stated in these Terms or the
          Supplementary Terms.
        </p>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          <span className="white-color">b</span>
          <span className="purple-color">.</span>{" "}
          <span className="font-pnb white-color">Eligibility</span> <br />
          By registering to use a Xiden Account, you represent and warrant that
          (i) as an individual, you are at least 18 or are of legal age to form
          a binding contract under applicable laws; (ii) as an individual, legal
          person, or other organization, you have full legal capacity and
          sufficient authorizations to enter into these Terms; (iii) you have
          not been previously suspended or removed from using Xiden Services;
          (iv) you do not currently have a Xiden Account; (v) if you act as an
          employee or agent of a legal entity, and enter into these Terms on
          their behalf, you represent and warrant that you have all the
          necessary rights and authorizations to bind such legal entity; (vi)
          your use of Xiden Services will not violate any and all laws and
          regulations applicable to you, including but not limited to
          regulations on anti-money laundering, anti-corruption, and
          counter-terrorist financing. Please note that some products and
          services may not be available in certain jurisdictions or regions or
          to certain users. Xiden reserves the right to change, modify or impose
          additional restrictions at its discretion at any time.
        </p>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          <span className="white-color">c</span>
          <span className="purple-color">.</span>{" "}
          <span className="font-pnb white-color">
            Account Usage Requirements
          </span>{" "}
          <br />
          The Xiden Account can only be used by the account registrant. Xiden
          reserves the right to suspend, freeze or cancel the use of Xiden
          Accounts by persons other than account registrant. If you suspect or
          become aware of any unauthorized use of your username and password,
          you should notify Xiden immediately. Xiden assumes no liability for
          any loss or damage arising from the use of Xiden Account by you or any
          third party with or without your authorization.
        </p>
        <p className="font-pnr mb-1 font-size-16 lightgrey-color line-height-11">
          <span className="white-color">d</span>
          <span className="purple-color">.</span>{" "}
          <span className="font-pnb white-color">Account Security</span> <br />
          Xiden has been committed to maintaining the security of User and has
          implemented industry standard protection for Xiden Services. However,
          the actions of individual Users may pose risks. You shall agree to
          treat your access credentials (such as username and password) as
          confidential information, and not to disclose such information to any
          third party. You also agree to be solely responsible for taking the
          necessary security measures to protect your Xiden Account and personal
          information. You should be solely responsible for keeping safe of your
          Xiden Account and password, and be responsible for all the
          transactions under your Xiden Account. Xiden assumes no liability for
          any loss or consequences caused by authorized or unauthorized use of
          your account credentials, including but not limited to information
          disclosure, information release, consent or submission of various
          rules and agreements by clicking on the website, online agreement
          renewal, etc. By creating a Xiden Account, you hereby agree that:
        </p>
        <ul className="font-pnr font-size-16 lightgrey-color line-height-11">
          <li>
            <span className="purple-color">• </span>
            you will notify Xiden immediately if you are aware of any
            unauthorized use of your Xiden Account and password or any other
            violation of security rules;
          </li>
          <li>
            <span className="purple-color">• </span>
            you will strictly abide by all mechanisms or procedures of Xiden
            regarding security, authentication, trading, charging, and
            withdrawal; and
          </li>
          <li>
            <span className="purple-color">• </span>
            you will take appropriate steps to logout from Xiden at the end of
            each visit.
          </li>
        </ul>

        <h4 className="font-pnsb font-size-24 mb-3">
          III<span className="purple-color">.</span> Xiden Services
        </h4>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11 mb-1">
          Upon completion of the registration for your Xiden Account, you may
          use various Xiden Services, in accordance with the provisions of these
          Terms. Xiden has the right to:
        </p>
        <ul className="font-pnr font-size-16 lightgrey-color line-height-11">
          <li>
            <span className="purple-color">• </span>
            Provide, modify or terminate, in its discretion, any Xiden Services
            based on its development plan; and
          </li>
          <li>
            <span className="purple-color">• </span>
            Allow or prohibit some Users’ use of any Xiden Services in
            accordance with relevant Xiden Platform Rules.
          </li>
        </ul>
        <h5 className="font-pnsb font-size-20 mb-2">
          1<span className="purple-color">.</span> Service Usage Guidelines
        </h5>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          <span className="white-color">a</span>
          <span className="purple-color">.</span>{" "}
          <span className="font-pnb white-color">License </span> <br />
          Provided that you constantly comply with the express terms and
          conditions stated in these Terms, Xiden grants you a revocable,
          limited, royalty-free, non-exclusive, non-transferable, and
          non-sublicensable license to access and use Xiden Services through
          your computer or Internet compatible devices for your
          personal/internal purposes. You are prohibited to use Xiden Services
          for resale or commercial purposes, including transactions on behalf of
          other persons or entities. All the above actions are expressly
          prohibited and constitute a material violation of these Terms. The
          content layout, format, function and access rights regarding Xiden
          Services should be stipulated in the discretion of Xiden. Xiden
          reserves all rights not expressly granted in these Terms. Therefore,
          you are hereby prohibited from using Xiden Services in any way not
          expressly authorized by these Terms. These Terms only grant a limited
          license to access and use Xiden Services. Therefore, you hereby agree
          that when you use Xiden Services, Xiden does not transfer Xiden
          Services or the ownership or intellectual property rights of any Xiden
          intellectual property to you or anyone else. All the text, graphics,
          user interfaces, visual interface, photos, sounds, process flow
          diagrams, computer code (including html code), programs, software,
          products, information and documents, as well as the design, structure,
          selection, coordination, expression, look and feel, and layout of any
          content included in the services or provided through Xiden Services,
          are exclusively owned, controlled and/or licensed by Xiden or its
          members, parent companies, licensors or affiliates. Xiden owns any
          feedback, suggestions, ideas, or other information or materials
          (hereinafter collectively referred to as “Feedback”) about Xiden or
          Xiden Services that you provide through email, Xiden Services, or
          other ways. You hereby transfer all rights, ownership and interests of
          the Feedback and all related intellectual property rights to Xiden.
          You have no right and hereby waive any request for acknowledgment or
          compensation based on any Feedback, or any modifications based on any
          Feedback.
        </p>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11 mb-1">
          <span className="white-color">b</span>
          <span className="purple-color">.</span>{" "}
          <span className="font-pnb white-color">Restriction </span> <br />
          When you use Xiden Services, you agree and undertake to comply with
          the following provisions: <br />
          During the use of Xiden Services, all activities you carry out should
          comply with the requirements of applicable laws and regulations, these
          Terms, and various guidelines of Xiden;
          <br />
          Your use of Xiden Services should not violate public interests, public
          morals, or the legitimate interests of others, including any actions
          that would interfere with, disrupt, negatively affect, or prohibit
          other Users from using Xiden Services;
          <br />
          You agree not to use the services for market manipulation (such as
          pump and dump schemes, wash trading, self-trading, front running,
          quote stuffing, and spoofing or layering, regardless of whether
          prohibited by law);
          <br />
          Without written consent from Xiden, the following commercial uses of
          Xiden data are prohibited:
        </p>
        <ul className="font-pnr font-size-16 lightgrey-color line-height-11 ">
          <li>
            1<span className="purple-color">) </span>
            Trading services that make use of Xiden quotes or market bulletin
            board information.
          </li>
          <li>
            2<span className="purple-color">{`)`} </span>
            Data feeding or streaming services that make use of any market data
            of Xiden.
          </li>
          <li>
            3<span className="purple-color">{`)`} </span>
            Any other websites/apps/services that charge for or otherwise profit
            from (including through advertising or referral fees) market data
            obtained from Xiden.
          </li>
        </ul>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11 mb-1">
          Without prior written consent from Xiden, you may not modify,
          replicate, duplicate, copy, download, store, further transmit,
          disseminate, transfer, disassemble, broadcast, publish, remove or
          alter any copyright statement or label, or license, sub-license, sell,
          mirror, design, rent, lease, private label, grant security interests
          in the properties or any part of the properties, or create their
          derivative works or otherwise take advantage of any part of the
          properties.
          <br />
          You may not (i) use any deep linking, web crawlers, bots, spiders or
          other automatic devices, programs, scripts, algorithms or methods, or
          any similar or equivalent manual processes to access, obtain, copy or
          monitor any part of the properties, or replicate or bypass the
          navigational structure or presentation of Xiden Services in any way,
          in order to obtain or attempt to obtain any materials, documents or
          information in any manner not purposely provided through Xiden
          Services; (ii) attempt to access any part or function of the
          properties without authorization, or connect to Xiden Services or any
          Xiden servers or any other systems or networks of any Xiden Services
          provided through the services by hacking, password mining or any other
          unlawful or prohibited means; (iii) probe, scan or test the
          vulnerabilities of Xiden Services or any network connected to the
          properties, or violate any security or authentication measures on
          Xiden Services or any network connected to Xiden Services; (iv)
          reverse look-up, track or seek to track any information of any other
          Users or visitors of Xiden Services; (v) take any actions that imposes
          an unreasonable or disproportionately large load on the infrastructure
          of systems or networks of Xiden Services or Xiden, or the
          infrastructure of any systems or networks connected to Xiden services;
          (vi) use any devices, software or routine programs to interfere with
          the normal operation of Xiden Services or any transactions on Xiden
          Services, or any other person’s use of Xiden Services; (vii) forge
          headers, impersonate, or otherwise manipulate identification, to
          disguise your identity or the origin of any messages or transmissions
          you send to Xiden, or (viii) use Xiden Services in an illegal way. By
          accessing Xiden Services, you agree that Xiden has the right to
          investigate any violation of these Terms, unilaterally determine
          whether you have violated these Terms, and take actions under relevant
          regulations without your consent or prior notice. Examples of such
          actions include, but are not limited to:
        </p>
        <ul className="font-pnr font-size-16 lightgrey-color line-height-11">
          <li>
            <span className="purple-color">• </span>
            Blocking and closing order requests;
          </li>
          <li>
            <span className="purple-color">• </span>
            Freezing your account;
          </li>
          <li>
            <span className="purple-color">• </span>
            Reporting the incident to the authorities;
          </li>
          <li>
            <span className="purple-color">• </span>
            Publishing the alleged violations and actions that have been taken;
          </li>
          <li>
            <span className="purple-color">• </span>
            Deleting any information you published that are found to be
            violations.
          </li>
        </ul>

        <h5 className="font-pnsb font-size-20 mb-2">
          2<span className="purple-color">.</span> Staking Programs
        </h5>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          Xiden will from time to time launch Staking Programs for specific
          types of Digital Currencies to reward, as per certain rules, users who
          hold such Digital Currencies in their Xiden Accounts. When
          participating in Staking Programs, you should note that:
          <br />
          <span className="white-color">a</span>
          <span className="purple-color">.</span> Unless otherwise stipulated by
          Xiden, Staking Programs are free of charge and Users may trade during
          the staking period;
          <br />
          <span className="white-color">b</span>
          <span className="purple-color">.</span> Xiden does not guarantee
          Users’ proceeds under any Staking Program;
          <br />
          <span className="white-color">c</span>
          <span className="purple-color">.</span> Xiden has the right to
          initiate or terminate Staking Program for any Digital Currencies or
          modify rules on such programs in its sole discretion;
          <br />
          <span className="white-color">d</span>
          <span className="purple-color">.</span> Users shall ensure that
          sources of the Digital Currencies they hold in Xiden Accounts are
          legal and compliant and undertake to observe related laws and
          regulations. Otherwise, Xiden has the right to take necessary steps in
          accordance with these Terms or Xiden Platform Rules, including,
          without limitation, freezing Xiden Accounts or deducting the Digital
          Currencies awarded to Users who violate the rules of respective
          Staking Programs.
          <br />
          <span className="white-color">e</span>
          <span className="purple-color">.</span> the Digital Currency rewarded
          in the Staking Program represents only an utility token with the
          specific use of accessing Xiden Services.
        </p>

        <h5 className="font-pnsb font-size-20 mb-2">
          3<span className="purple-color">.</span> Disclaimer of Warranties
        </h5>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, XIDEN SERVICES,
          XIDEN MATERIALS AND ANY PRODUCT, SERVICE OR OTHER ITEM PROVIDED BY OR
          ON BEHALF OF XIDEN ARE OFFERED ON AN “AS IS” AND “AS AVAILABLE” BASIS,
          AND XIDEN EXPRESSLY DISCLAIMS, AND YOU WAIVE, ANY AND ALL OTHER
          WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT
          LIMITATION, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE, TITLE OR NON-INFRINGEMENT OR WARRANTIES ARISING FROM COURSE
          OF PERFORMANCE, COURSE OF DEALING OR USAGE IN TRADE. WITHOUT LIMITING
          THE FOREGOING, XIDEN DOES NOT REPRESENT OR WARRANT THAT THE SITE,
          XIDEN SERVICES OR XIDEN MATERIALS ARE ACCURATE, COMPLETE, RELIABLE,
          CURRENT, ERROR-FREE, OR FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
          XIDEN DOES NOT GUARANTEE THAT ANY ORDER WILL BE EXECUTED, ACCEPTED,
          RECORDED OR REMAIN OPEN. EXCEPT FOR THE EXPRESS STATEMENTS, AGREEMENTS
          AND RULES SET FORTH IN THESE TERMS, YOU HEREBY ACKNOWLEDGE AND AGREE
          THAT YOU HAVE NOT RELIED UPON ANY OTHER STATEMENT OR AGREEMENT,
          WHETHER WRITTEN OR ORAL, WITH RESPECT TO YOUR USE AND ACCESS OF XIDEN
          SERVICES. WITHOUT LIMITING THE FOREGOING, YOU HEREBY UNDERSTAND AND
          AGREE THAT XIDEN WILL NOT BE LIABLE FOR ANY LOSSES OR DAMAGES ARISING
          OUT OF OR RELATING TO: (A) ANY INACCURACY, DEFECT OR OMISSION OF
          DIGITAL ASSETS PRICE DATA, (B) ANY ERROR OR DELAY IN THE TRANSMISSION
          OF SUCH DATA, (C) INTERRUPTION IN ANY SUCH DATA, (D) REGULAR OR
          UNSCHEDULED MAINTENANCE CARRIED OUT BY XIDEN AND SERVICE INTERRUPTION
          AND CHANGE RESULTING FROM SUCH MAINTENANCE, (E) ANY DAMAGES INCURRED
          BY OTHER USERS’ ACTIONS, OMISSIONS OR VIOLATION OF THESE TERMS, (F)
          ANY DAMAGE CAUSED BY ILLEGAL ACTIONS OF OTHER THIRD PARTIES OR ACTIONS
          WITHOUT AUTHORIZED BY XIDEN; AND (G) OTHER EXEMPTIONS MENTIONED IN
          DISCLAIMERS AND PLATFORM RULES ISSUED BY XIDEN. THE DISCLAIMER OF
          IMPLIED WARRANTIES CONTAINED HEREIN MAY NOT APPLY IF AND TO THE EXTENT
          IT IS PROHIBITED BY APPLICABLE LAW OF THE JURISDICTION IN WHICH YOU
          RESIDE.
        </p>

        <h5 className="font-pnsb font-size-20 mb-2">
          4<span className="purple-color">.</span> Disclaimer of Damages and
          Limitation of Liability
        </h5>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL
          XIDEN, ITS AFFILIATES AND THEIR RESPECTIVE SHAREHOLDERS, MEMBERS,
          DIRECTORS, OFFICERS, EMPLOYEES, ATTORNEYS, AGENTS, REPRESENTATIVES,
          SUPPLIERS OR CONTRACTORS BE LIABLE FOR ANY INCIDENTAL, INDIRECT,
          SPECIAL, PUNITIVE, CONSEQUENTIAL OR SIMILAR DAMAGES OR LIABILITIES
          WHATSOEVER (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF DATA,
          INFORMATION, REVENUE, PROFITS OR OTHER BUSINESSES OR FINANCIAL
          BENEFITS) ARISING OUT OF XIDEN SERVICES, ANY PERFORMANCE OR
          NON-PERFORMANCE OF XIDEN SERVICES, OR ANY OTHER PRODUCT, SERVICE OR
          OTHER ITEM PROVIDED BY OR ON BEHALF OF XIDEN AND ITS AFFILIATES,
          WHETHER UNDER CONTRACT, STATUTE, STRICT LIABILITY OR OTHER THEORY EVEN
          IF XIDEN HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES EXCEPT TO
          THE EXTENT OF A FINAL JUDICIAL DETERMINATION THAT SUCH DAMAGES WERE A
          RESULT OF XIDEN’S GROSS NEGLIGENCE, FRAUD, WILLFUL MISCONDUCT OR
          INTENTIONAL VIOLATION OF LAW. SOME JURISDICTIONS DO NOT ALLOW THE
          EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE
          ABOVE LIMITATION MAY NOT APPLY TO YOU. NOTWITHSTANDING THE FOREGOING,
          IN NO EVENT WILL THE LIABILITY OF XIDEN, ITS AFFILIATES AND THEIR
          RESPECTIVE SHAREHOLDERS, MEMBERS, DIRECTORS, OFFICERS, EMPLOYEES,
          ATTORNEYS, AGENTS, REPRESENTATIVES, SUPPLIERS OR CONTRACTORS ARISING
          OUT OF SERVICES OFFERED BY OR ON BEHALF OF XIDEN AND ITS AFFILIATES,
          ANY PERFORMANCE OR NON-PERFORMANCE OF XIDEN SERVICES, OR ANY OTHER
          PRODUCT, SERVICE OR OTHER ITEM, WHETHER UNDER CONTRACT, STATUTE,
          STRICT LIABILITY OR OTHER THEORY, EXCEED THE AMOUNT OF THE FEES PAID
          BY YOU TO XIDEN UNDER THESE TERMS IN THE TWELVE-MONTH PERIOD
          IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO THE CLAIM FOR
          LIABILITY.
        </p>

        <h5 className="font-pnsb font-size-20 mb-2">
          5<span className="purple-color">.</span> Indemnification
        </h5>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          You agree to indemnify and hold harmless Xiden Operators, their
          affiliates, contractors, licensors, and their respective directors,
          officers, employees and agents from and against any claims, actions,
          proceedings, investigations, demands, suits, costs, expenses and
          damages (including attorneys’ fees, fines or penalties imposed by any
          regulatory authority) arising out of or related to (i) your use of, or
          conduct in connection with, Xiden Services, (ii) your breach or our
          enforcement of these Terms, or (iii) your violation of any applicable
          law, regulation, or rights of any third party during your use of Xiden
          Services. If you are obligated to indemnify Xiden, their affiliates,
          contractors, licensors, and their respective directors, officers,
          employees or agents pursuant to these Terms, Xiden will have the
          right, in its sole discretion, to control any action or proceeding and
          to determine whether Xiden wishes to settle, and if so, on what terms.
        </p>

        <h4 className="font-pnsb font-size-24 mb-3">
          IV<span className="purple-color">.</span> Announcements
        </h4>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          Please be aware that all official announcements, news, promotions,
          competitions and airdrops will be listed on{" "}
          <span
            onClick={() => navigate(routes.home)}
            className="purple-color terms-link"
          >
            www.xiden.com
          </span>
          . USERS UNDERTAKE TO REFER TO THESE MATERIALS REGULARLY AND PROMPTLY.
          XIDEN WILL NOT BE HELD LIABLE OR RESPONSIBLE IN ANY MANNER OF
          COMPENSATION SHOULD USERS INCUR PERSONAL LOSSES ARISING FROM IGNORANCE
          OR NEGLIGENCE OF THE ANNOUNCEMENTS
        </p>

        <h4 className="font-pnsb font-size-24 mb-3">
          V<span className="purple-color">.</span> Termination of Agreement
        </h4>
        <h5 className="font-pnsb font-size-20 mb-2">
          1<span className="purple-color">.</span> Suspension of Xiden accounts
        </h5>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11 mb-1">
          You agree that Xiden shall have the right to immediately suspend your
          Xiden Account (and any accounts beneficially owned by related entities
          or affiliates), freeze or lock the Digital Assets or funds in all such
          accounts, and suspend your access to Xiden for any reason including if
          Xiden suspects any such accounts to be in violation of these Terms,
          our Privacy Policy, or any applicable laws and regulations. You agree
          that Xiden shall not be liable to you for any permanent or temporary
          modification of your Xiden account, or suspension or termination of
          your access to all or any portion of Xiden Services. Xiden shall
          reserve the right to keep and use the transaction data or other
          information related to such Xiden accounts. The above account controls
          may also be applied in the following cases:
        </p>
        <ul className="font-pnr font-size-16 lightgrey-color line-height-11">
          <li>
            <span className="purple-color">• </span>
            The Xiden account is subject to a governmental proceeding, criminal
            investigation or other pending litigation;
          </li>
          <li>
            <span className="purple-color">• </span>
            We detect unusual activities in the Xiden account;
          </li>
          <li>
            <span className="purple-color">• </span>
            We detect unauthorized access to the Xiden account;
          </li>
          <li>
            <span className="purple-color">• </span>
            We are required to do so by a court order or command by a
            regulatory/government authority.
          </li>
        </ul>

        <h5 className="font-pnsb font-size-20 mb-2">
          2<span className="purple-color">.</span> Cancellation of Xiden
          accounts
        </h5>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11 mb-1">
          In case of any of the following events, Xiden shall have the right to
          directly terminate these Terms by cancelling your Xiden Account, and
          shall enjoy the right but not the obligation to permanently freeze
          (cancel) the authorizations of your Xiden Account on Xiden and
          withdraw the corresponding Xiden Account thereof:
        </p>
        <ul className="font-pnr font-size-16 lightgrey-color line-height-11">
          <li>
            <span className="purple-color">• </span>
            after Xiden terminates services to you;
          </li>
          <li>
            <span className="purple-color">• </span>
            you allegedly register or register in any other person’s name as a
            Xiden User again, directly or indirectly;
          </li>
          <li>
            <span className="purple-color">• </span>
            the information that you have provided is untruthful, inaccurate,
            outdated or incomplete;
          </li>
          <li>
            <span className="purple-color">• </span>
            when these Terms are amended, you state your unwillingness to accept
            the amended Terms by applying for cancellation of your Xiden Account
            or by other means;
          </li>
          <li>
            <span className="purple-color">• </span>
            you request that Xiden Services be terminated; and
          </li>
          <li>
            <span className="purple-color">• </span>
            any other circumstances where Xiden deems it should terminate Xiden
            Services.
          </li>
        </ul>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11 mb-1">
          Should your Xiden Account be terminated, the account and transactional
          information that meet data retention standards will be securely stored
          for 5 years. In addition, if a transaction is unfinished during the
          account termination process, Xiden shall have the right to notify your
          counterparty of the situation at that time. You acknowledge that a
          user-initiated account exit (right to erasure under GDPR or other
          equivalent regulations) will also be subjected to the termination
          protocol stated above. If Xiden is informed that any Digital Assets or
          funds held in your Xiden Account are stolen or otherwise are not
          lawfully possessed by you, Xiden may, but has no obligation to, place
          an administrative hold on the affected funds and your Xiden Account.
          If Xiden does lay down an administrative hold on some or all of your
          funds or Xiden Account, Xiden may continue such hold until such time
          as the dispute has been resolved and evidence of the resolution
          acceptable to Xiden has been provided to Xiden in a form acceptable to
          Xiden. Xiden will not involve itself in any such dispute or the
          resolution of the dispute. You agree that Xiden will have no liability
          or responsibility for any such hold, or for your inability to withdraw
          Digital Assets or funds or execute trades during the period of any
          such hold.
        </p>

        <h4 className="font-pnsb font-size-24 mb-3">
          VI<span className="purple-color">.</span> No Financial Advice
        </h4>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          Xiden is not your broker, intermediary, agent, or advisor and has no
          fiduciary relationship or obligation to you in connection with any
          trades or other decisions or activities effected by you using Xiden
          Services. No communication or information provided to you by Xiden is
          intended as, or shall be considered or construed as, investment
          advice, financial advice, trading advice, or any other sort of advice.
          Unless otherwise specified in these Terms, all trades are executed
          automatically, based on the parameters of your order instructions and
          in accordance with posted trade execution procedures, and you are
          solely responsible for determining whether any investment, investment
          strategy or related transaction is appropriate for you according to
          your personal investment objectives, financial circumstances and risk
          tolerance, and you shall be solely responsible for any loss or
          liability therefrom. You should consult legal or tax professionals
          regarding your specific situation. Xiden does not recommend that any
          Digital Asset should be bought, earned, sold, or held by you. Before
          making the decision to buy, sell or hold any Digital Asset, you should
          conduct your own due diligence and consult your financial advisors
          prior to making any investment decision. Xiden will not be held
          responsible for the decisions you make to buy, sell, or hold Digital
          Asset based on the information provided by Xiden.
        </p>

        <h4 className="font-pnsb font-size-24 mb-3">
          VII<span className="purple-color">.</span> Compliance with Local Laws
        </h4>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          It is Users’ responsibility to abide by local laws in relation to the
          legal usage of Xiden Services in their local jurisdiction as well as
          other laws and regulations applicable to Users. Users must also
          factor, to the extent of their local laws all aspects of taxation, the
          withholding, collection, reporting and remittance to their appropriate
          tax authorities. ALL USERS OF XIDEN SERVICES ACKNOWLEDGE AND DECLARE
          THAT THEIR FUNDS COME FROM LEGITIMATE SOURCES AND DO NOT ORIGINATE
          FROM ILLEGAL ACTIVITIES; USERS AGREE THAT XIDEN WILL REQUIRE THEM TO
          PROVIDE OR OTHERWISE COLLECT THE NECESSARY INFORMATION AND MATERIALS
          AS PER RELEVANT LAWS OR GOVERNMENT ORDERS TO VERIFY THE LEGALITY OF
          THE SOURCES AND USE OF THEIR FUNDS. Xiden maintains a stance of
          cooperation with law enforcement authorities globally and will not
          hesitate to seize, freeze, terminate Users’ accounts and funds which
          are flagged out or investigated by legal mandate.
        </p>

        <h4 className="font-pnsb font-size-24 mb-3">
          VIII<span className="purple-color">.</span> Privacy Policy
        </h4>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          Access to Xiden Services will require the submission of certain
          personally identifiable information. Please review Xiden’s Privacy
          Policy at
          <span
            className="purple-color terms-link"
            onClick={() => navigate(routes.dataPrivacy)}
          >
            {" "}
            Xiden Privacy Policy{" "}
          </span>
          for a summary of Xiden’s guidelines regarding the collection and use
          of personally identifiable information.
        </p>

        <h4 className="font-pnsb font-size-24 mb-3">
          IX<span className="purple-color">.</span> Resolving Disputes: Forum,
          Arbitration, Class Action Waiver
        </h4>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          PLEASE READ THIS SECTION CAREFULLY, AS IT INVOLVES A WAIVER OF CERTAIN
          RIGHTS TO BRING LEGAL PROCEEDINGS, INCLUDING AS A CLASS ACTION.
        </p>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          <span className="white-color">1</span>
          <span className="purple-color">.</span>{" "}
          <span className="font-pnb white-color">
            Notice of Claim and Dispute Resolution Period.{" "}
          </span>{" "}
          Please contact Xiden first! Xiden wants to address your concerns
          without resorting to formal legal proceedings, if possible. If you
          have a dispute with Xiden, then you should contact Xiden and a ticket
          number will be assigned. Xiden will attempt to resolve your dispute
          internally as soon as possible. The parties agree to negotiate in good
          faith to resolve the dispute (which discussions shall remain
          confidential and be subject to applicable rules protecting settlement
          discussions from use as evidence in any legal proceeding). In the
          event the dispute cannot be resolved satisfactorily, and you wish to
          assert a legal claim against Xiden, then you agree to set forth the
          basis of such claim in writing in a “Notice of Claim,” as a form of
          prior notice to Xiden. The Notice of Claim must (1) describe the
          nature and basis of the claim or dispute, (2) set forth the specific
          relief sought, (3) provide the original ticket number, and (4) include
          your Xiden account email. The Notice of Claim should be submitted to
          an email address or hyperlink provided in your correspondence with
          Xiden. After you have provided the Notice of Claim to Xiden, the
          dispute referenced in the Notice of Claim may be submitted by either
          Xiden or you to arbitration in accordance with paragraph 2 of this
          Section, below. For the avoidance of doubt, the submission of a
          dispute to Xiden for resolution internally and the delivery of a
          Notice of Claim to Xiden are prerequisites to commencement of an
          arbitration proceeding (or any other legal proceeding). During the
          arbitration, the amount of any settlement offer made by you or Xiden
          shall not be disclosed to the arbitrator.
        </p>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          <span className="white-color">2</span>
          <span className="purple-color">.</span>{" "}
          <span className="font-pnb white-color">Class Action Waiver. </span>{" "}
          You and Xiden agree that any claims relating to these Terms or to your
          relationship with Xiden as a user of Xiden Services (whether based in
          contract, tort, statute, fraud, misrepresentation, or any other legal
          theory, and whether the claims arise during or after the termination
          of these Terms) shall be brought against the other party in an
          arbitration on an individual basis only and not as a plaintiff or
          class member in a purported class or representative action. You and
          Xiden further agree to waive any right for such claims to be brought,
          heard, or arbitrated as a class, collective, representative, or
          private attorney general action, to the extent permissible by
          applicable law. Combining or consolidating individual arbitrations
          into a single arbitration is not permitted without the consent of all
          parties, including Xiden.
        </p>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          <span className="white-color">3</span>
          <span className="purple-color">.</span>{" "}
          <span className="font-pnb white-color">Modifications. </span> Xiden
          reserves the right to update, modify, revise, suspend, or make any
          future changes to Section X regarding the parties’ Agreement to
          Arbitrate, subject to applicable law. You hereby consent and agree
          that it is your responsibility to ensure that your understanding of
          this Section is up to date. Subject to the applicable law, your
          continued use of your Xiden account shall be deemed to be your
          acceptance of any modifications to Section X regarding the parties’
          Agreement to Arbitrate. You agree that if you object to the
          modifications to Section X, Xiden may block access to your account
          pending closure of your account. In such circumstances, the Terms of
          Use prior to modification shall remain in full force and effect
          pending closure of your account.
        </p>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          <span className="white-color">4</span>
          <span className="purple-color">.</span>{" "}
          <span className="font-pnb white-color">Severability. </span> If any
          portion of these Terms are adjudged to be invalid or unenforceable for
          any reason or to any extent, the remainder of these Terms will remain
          valid and enforceable and the invalid or unenforceable portion will be
          given effect to the greatest extent permitted by law. pending closure
          of your account.
        </p>

        <h4 className="font-pnsb font-size-24 mb-3">
          X<span className="purple-color">.</span> Miscellaneous
        </h4>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          <span className="white-color">1</span>
          <span className="purple-color">.</span>{" "}
          <span className="font-pnb white-color">Independent Parties. </span>{" "}
          Xiden is an independent contractor but not an agent of you in the
          performance of these Terms. These Terms shall not be interpreted as
          facts or evidence of an association, joint venture, partnership, or
          franchise between the parties.
        </p>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          <span className="white-color">2</span>
          <span className="purple-color">.</span>{" "}
          <span className="font-pnb white-color">Entire Agreement. </span> These
          Terms constitute the entire agreement between the parties regarding
          use of Xiden Services and will supersede all prior written or oral
          agreements between the parties. No usage of trade or other regular
          practice or method of dealing between the parties will be used to
          modify, interpret, supplement, or alter the terms herein.
        </p>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          <span className="white-color">3</span>
          <span className="purple-color">.</span>{" "}
          <span className="font-pnb white-color">
            Interpretation and Revision.{" "}
          </span>{" "}
          Xiden reserves the right to alter, revise, modify, and/or change these
          Terms at any time. All changes will take effect immediately upon being
          published on Xiden websites. It is your responsibility to regularly
          check relevant pages on our websites/applications to confirm the
          latest version of these Terms. If you do not agree to any such
          modifications, your only remedy is to terminate your usage of Xiden
          Services and cancel your account. You agree that, unless otherwise
          expressly provided in these Terms, Xiden will not be responsible for
          any modification or termination of Xiden Services by you or any third
          party, or suspension or termination of your access to Xiden Services.
        </p>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          <span className="white-color">4</span>
          <span className="purple-color">.</span>{" "}
          <span className="font-pnb white-color">Force Majeure. </span> Xiden
          will not be liable for any delay or failure to perform as required by
          these Terms because of any cause or condition beyond Xiden’s
          reasonable control.
        </p>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          <span className="white-color">5</span>
          <span className="purple-color">.</span>{" "}
          <span className="font-pnb white-color">Severability. </span> If any
          portion of these Terms is held invalid or unenforceable, such
          invalidity or enforceability will not affect the other provisions of
          these Terms, which will remain in full force and effect, and the
          invalid or unenforceable portion will be given effect to the greatest
          extent possible.
        </p>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          <span className="white-color">6</span>
          <span className="purple-color">.</span>{" "}
          <span className="font-pnb white-color">Assignment. </span> You may not
          assign or transfer any right to use Xiden Services or any of your
          rights or obligations under these Terms without prior written consent
          from Xiden, including any right or obligation related to the
          enforcement of laws or the change of control. Xiden may assign or
          transfer any or all of its rights or obligations under these Terms, in
          whole or in part, without notice or obtaining your consent or
          approval.
        </p>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          <span className="white-color">7</span>
          <span className="purple-color">.</span>{" "}
          <span className="font-pnb white-color">Waiver. </span> The failure of
          one party to require performance of any provision will not affect that
          party’s right to require performance at any time thereafter. At the
          same time, the waiver of one party to seek recovery for the other
          party’s violation of these Terms or any provision of applicable terms
          shall not constitute a waiver by that party of any subsequent breach
          or violation by the other party or of the provision itself.
        </p>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          <span className="white-color">8</span>
          <span className="purple-color">.</span>{" "}
          <span className="font-pnb white-color">
            Third-Party Website Disclaimer.{" "}
          </span>{" "}
          Any links to third-party websites from Xiden Services does not imply
          endorsement by Xiden of any product, service, information or
          disclaimer presented therein, nor does Xiden guarantee the accuracy of
          the information contained on them. If you suffer loss from using such
          third-party product and service, Xiden will not be liable for such
          loss. In addition, since Xiden has no control over the terms of use or
          privacy policies of third-party websites, you should read and
          understand those policies carefully.
        </p>
        <p className="font-pnr font-size-16 lightgrey-color line-height-11">
          <span className="white-color">9</span>
          <span className="purple-color">.</span>{" "}
          <span className="font-pnb white-color">Contact Information. </span>{" "}
          For more information on Xiden, you may refer to the company and
          license information found on Xiden websites. If you have questions
          regarding these Terms, please feel free to contact Xiden for
          clarification via our Customer Support team at{" "}
          <a href="mailto:support@cryptodata.com" className="purple-color">
            support@cryptodata.com
          </a>
          .
        </p>
      </div>
    </section>
  );
};

export default TermsOfUse;
