import React, { useState, useEffect, useRef } from "react";
import "./FAQValidator.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import { Accordion } from "react-bootstrap";
import { ReactComponent as Plus } from "../../../components/Assets/images/png/test-plus.svg";
import { ReactComponent as ArrowRight } from "../../../assets//images/svg/arrow-right.svg";

let bullets = [];

const FAQValidator = () => {
  const myRef = useRef(null);
  const [page, setPage] = useState(0);
  const max_items = 4;
  {
    /* numarul maxim de itemi afisati initial */
  }
  const [itemsToShow, setItemsToShow] = useState(max_items);

  {
    /* Cand mai apar intrebari se adauga in acest array si daca sunt peste max_items se introduce si butonul pentru show more */
  }
  const FAQData = [
    {
      question: "What is Xiden?",
      answer: `A decentralized network that provides users with the opportunity to have a free, 
                    unrestricted, and permanent internet connection regardless of user location or device. 
                    By decentralizing the Internet, the user is no longer conditioned by affiliation or 
                    association with an internet service provider, no longer has limited access to certain 
                    parts of the Internet, and its identity will be permanently protected and secured.`,
    },
    {
      question: "What is a Validator?",
      answer: `The validator is a physical network node that verifies and validates the integrity 
                    and conformity of data, transactions, and smart devices within the network.`,
    },
    {
      question: "How do I become a validator?",
      answer: `When opening a Krater Pool, your device will become a light validator 
                    that will verify the conformity of all integrated devices.`,
    },
    {
      question: "What is Spectralis Network?",
      answer: `A WiFi point unlocked by a Router that can open a pool through which smart 
                    devices can connect freely in order to access an anonymous internet network.`,
    },
  ];

  let max_pages;
  if (FAQData.length % max_items) {
    max_pages = parseInt(FAQData.length / max_items) + 1;
  } else {
    max_pages = FAQData.length / max_items;
  }

  useEffect(() => {
    for (let i = 0; i < max_pages; i++) {
      bullets.push(i);
    }
  }, [max_pages]);

  const handleAllItems = () => {
    if (itemsToShow > max_items) {
      setItemsToShow(max_items);
      myRef.current.scrollIntoView();
    } else setItemsToShow(FAQData.length);
  };

  return (
    <section id="faq-validator" className="page-section" ref={myRef}>
      <div className="container-main">
        <Uppertitle title={"FAQ"} />
        <div className="title mb-4">
          <h2 className="font-size-80 white-color font-pnb">In case</h2>
          <h2 className="font-size-80 white-color font-pnb">
            you missed anything
            <span className="purple-color">.</span>
          </h2>
        </div>
        <Accordion className="dark-grey-color " defaultActiveKey="0">
          {FAQData.map((row, index) => {
            if (index < itemsToShow)
              return (
                <Accordion.Item eventKey={`${index}`} key={index}>
                  <Accordion.Header className="white-color font-pnsb mb-10px ">
                    <div className="white-color font-size-18 font-pnsb mb-10px ">{row.question}</div>{" "}
                    <Plus className="button-transition" />
                  </Accordion.Header>

                  <Accordion.Body>
                    <p className="font-pnr font-size-18 line-height-18 darkgrey-color">{row.answer}</p>
                  </Accordion.Body>
                </Accordion.Item>
              );
          })}
        </Accordion>

        {/* Cand apar mai mult de 5 intrebari se poate introduce butonul si restul functioneaza ok */}
        {/* <button className="font-size-20 mt-lg-5 mt-4 font-pnsb btn-all-questions" onClick={handleAllItems}>
                    {itemsToShow === max_items ? <> View all questions
                        <ArrowRight /></> :
                        <>
                            Hide
                            <div className="hide-questions"><ArrowRight /> </div>
                        </>}
                </button> */}
      </div>
    </section>
  );
};

export default FAQValidator;
