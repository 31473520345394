import "../../../template/color.scss";
import "../../../template/container.scss";
import "../../../template/fonts.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Developers.scss";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import { Container, Row, Col, Tooltip, OverlayTrigger } from "react-bootstrap";
import hardhatlogo from "../../../assets/images/png/homePage/hardhat.png";
import solidity from "../../../assets/images/png/homePage/solidity.png";
import etherslogo from "../../../assets/images/png/homePage/ethers.png";
import mmlogo from "../../../assets/images/png/homePage/metamask.png";
// import GraphicLeverage from "../Assets/images/png/GraphicLeverage.png";
// import GraphicExecution from "../Assets/images/png/GraphicExecution.png";
import DevelopersLaptop from "../../../components/Assets/images/png/developers-laptop.png";
import { ReactComponent as ArrowRight } from "../../../assets/images/svg/arrow-right.svg";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../Utils/routes";

// import GetRewardsLeft from "../../assets/images/png/get-rewards-left.png";
// import GetRewardsRight from "../../assets/images/png/get-rewards-right.png";

import GetRewardsLeft from "../../../assets/images/png/homePage/get-rewards-left.png";
import GetRewardsRight from "../../../assets/images/png/homePage/get-rewards-right.png";

export default function Developers(props) {
   const navigate = useNavigate();
   // TODO padding not consistent with the rest of the page
   // check #be-part for inspiration
   function switchMe(description) {
      // console.log("switch " + description);
      switch (description) {
         case 1:
            return "Metamask";
         case 2:
            return "Ethers";
         case 3:
            return "Hardhat";
         case 4:
            return "Solidity";
      }
   }
   const renderTooltip = (props, description) => (
      <Tooltip id="button-tooltip" {...props}>
         {switchMe(description)}
      </Tooltip>
   );
   // TODO breakpoint not consisten with rest of the page
   // use col- for "designed-for-developers white-color " and "container-main developers-second-section"
   return (
      <section id="developer-section" className="page-section">
         <div className="container-main developers-section">
            <div className="developers-first-section d-flex align-items-center justify-content-center pb-5 w-100">
               <Row className="framework-row d-flex align-items-center w-100">
                  <Col lg={6} md={12}>
                     <div className="developers-img-container mb-lg-0 mb-5">
                        <img className="developers-laptop-img img-fluid " src={DevelopersLaptop} alt="laptop"></img>
                     </div>
                  </Col>
                  <Col lg={6} md={12}>
                     <div className="designed-for-developers white-color">
                        <Uppertitle title="DEVELOPERS" />
                        <h2 className="font-size-56 font-pnb">
                           Designed <br></br>for developers
                           <span className="purple-color">.</span>
                        </h2>
                        <p className="font-size-24 font-pnr lightgrey-color description-991 line-height-11">
                           All the tools you’re familiar with are integrated into the network. Start developing on Xiden
                           with your favorite frameworks, integrate your resources, and increase your overall earnings.
                        </p>
                        <button
                           className="d-flex align-items-center justify-content-start get-started-btn font-size-20 blue-color font-pnsb"
                           onClick={() => navigate(routes.apps)}
                        >
                           Read more
                           <ArrowRight className="ms-2" />
                        </button>
                     </div>
                     <div className="framework-container">
                        <h4 className="white-color font-size-40 font-pnsb">Your favorite frameworks</h4>
                        <div className="d-flex framework-section framework-section-desktop">
                           <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 40 }}
                              overlay={renderTooltip(props, 1)}
                           >
                              <img src={mmlogo} loading="lazy" alt="metamask" className="framework-icon" />
                           </OverlayTrigger>

                           <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 40 }}
                              overlay={renderTooltip(props, 2)}
                           >
                              <img src={etherslogo} loading="lazy" alt="ethers" className="framework-icon" />
                           </OverlayTrigger>

                           <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 40 }}
                              overlay={renderTooltip(props, 3)}
                           >
                              <img src={hardhatlogo} loading="lazy" alt="hardhat" className="framework-icon" />
                           </OverlayTrigger>

                           <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 40 }}
                              overlay={renderTooltip(props, 4)}
                           >
                              <img src={solidity} loading="lazy" alt="solidity" className="framework-icon" />
                           </OverlayTrigger>
                        </div>
                     </div>
                  </Col>
               </Row>
            </div>

            <div className="developers-second-section pt-5 d-none">
               <Row>
                  <Col xl={3} sm={6} className="first-row-col">
                     <div className="rewards-wrapper">
                        <div
                           className="rewards-background developer-three-col p-xl-5 p-4 d-flex flex-column justify-content-between align-items-start"
                           id="background-one"
                        >
                           <Uppertitle title="DAPPS" className="font-size-18" />
                           <h3 className="white-color font-size-40 font-pnb line-height-11 py-lg-0 py-5">
                              Build <br></br>DApps <br></br> that use <br></br> devices <br></br> resources
                              <span className="purple-color">.</span>
                           </h3>
                           {/* <button
                              className="d-flex align-items-center justify-content-start get-started-btn blue-color font-size-20 font-pnsb"
                              onClick={() => navigate(routes.apps)}
                           >
                              Get started
                              <ArrowRight className="ms-2" />
                           </button> */}
                           <div></div>
                        </div>
                     </div>
                  </Col>

                  <Col xl={3} sm={6} className="first-row-col">
                     <div className="rewards-wrapper">
                        <div
                           className="rewards-background developer-three-col p-xl-5 p-4 d-flex flex-column justify-content-between"
                           id="background-two"
                        >
                           <Uppertitle title="FINACIAL APPS" className="font-size-18" />
                           <h3 className="white-color font-size-40 font-pnb line-height-11 py-lg-0 py-5">
                              Financial <br></br>apps that <br></br> use few <br></br>
                              resources
                              <span className="purple-color">.</span>
                           </h3>
                           {/* <button
                              className="d-flex align-items-center justify-content-start get-started-btn blue-color font-size-20 font-pnsb"
                              onClick={() => navigate(routes.apps)}
                           >
                              Get started
                              <ArrowRight className="ms-2" />
                           </button> */}
                           <div></div>
                        </div>
                     </div>
                  </Col>
                  <Col xl={3} sm={6} className="first-row-col">
                     <div className="rewards-wrapper">
                        <div
                           className="rewards-background developer-three-col p-xl-5 p-4 d-flex flex-column justify-content-between"
                           id="background-three"
                        >
                           <Uppertitle title="UTILITY TOKEN" className="font-size-18" />
                           <h3 className="white-color font-size-40 font-pnb line-height-11 py-lg-0 py-5">
                              Utility <br></br> tokens <br></br> are one <br></br>of our <br></br> types units <br></br>{" "}
                              of value
                              <span className="purple-color">.</span>
                           </h3>
                           {/* <button
                              className="d-flex align-items-center justify-content-start get-started-btn blue-color font-size-20 font-pnsb"
                              onClick={() => navigate(routes.apps)}
                           >
                              Get started
                              <ArrowRight className="ms-2" />
                           </button> */}
                           <div></div>
                        </div>
                     </div>
                  </Col>
                  <Col xl={3} sm={6} className="first-row-col">
                     <div className="rewards-wrapper">
                        <div
                           className="rewards-background developer-three-col p-xl-5 p-4 d-flex flex-column justify-content-between"
                           id="background-four"
                        >
                           <Uppertitle title="NFT ART" className="font-size-18" />

                           <h3 className="white-color font-size-40 font-pnb line-height-11 py-lg-0 py-5">
                              Blockchain <br></br>minted <br></br> artworks <br></br> with unique <br></br> encryption{" "}
                              <br></br> codes
                              <span className="purple-color">.</span>
                           </h3>

                           {/* <button
                              className="d-flex align-items-center justify-content-start get-started-btn blue-color font-size-20 font-pnsb"
                              onClick={() => navigate(routes.apps)}
                           >
                              Get started
                              <ArrowRight className="ms-2" />
                           </button> */}
                           <div></div>
                        </div>
                     </div>
                  </Col>
               </Row>
               <Row className="second-row">
                  <Col lg={6} className="second-row-col">
                     <div className="rewards-wrapper">
                        <div className="d-flex py-xl-5 py-4 ps-xl-5 ps-4 justify-content-between align-items-center developer-six-col rewards-background">
                           <div className="rewards-wrapper-text d-flex flex-column align-items-start justify-content-between h-100">
                              <Uppertitle title="GET REWARDS" className="font-size-18" />
                              <h3 className="white-color font-size-40 font-pnb line-height-11 py-sm-0 py-4">
                                 Get rewards from <br></br> all users that are <br></br>
                                 using your apps
                                 <span className="purple-color">.</span>
                              </h3>
                              <button
                                 className="d-flex align-items-center justify-content-start get-started-btn blue-color font-size-20 font-pnsb"
                                 onClick={() => navigate(routes.rewards)}
                              >
                                 Get started
                                 <ArrowRight className="ms-2" />
                              </button>
                           </div>
                           <img className="graphic-leverage-img ps-lg-5 ps-4 img-fluid" src={GetRewardsLeft}></img>
                        </div>
                     </div>
                  </Col>

                  <Col lg={6} className="second-row-col-two">
                     <div className="rewards-wrapper">
                        <div className="d-flex py-xl-5 py-4 pe-xl-5 pe-4 justify-content-between align-items-center developer-six-col rewards-background">
                           <img className="graphic-execution-img img-fluid pe-lg-5 pe-4" src={GetRewardsRight}></img>
                           <div className="rewards-wrapper-text d-flex flex-column justify-content-between align-items-start h-100">
                              <Uppertitle title="DISTRIBUTE" className="font-size-18" />
                              <h3 className="white-color font-size-40 font-pnb line-height-11 py-sm-0 py-4">
                                 Distribute a <br></br> part of your <br></br>profit to all <br></br> the devices{" "}
                                 <br></br> connected <br></br>
                                 to network
                                 <span className="purple-color">.</span>
                              </h3>
                              <button
                                 className="d-flex align-items-center justify-content-start get-started-btn blue-color font-size-20 font-pnsb"
                                 onClick={() => navigate(routes.apps)}
                              >
                                 Get started
                                 <ArrowRight className="ms-2" />
                              </button>
                           </div>
                        </div>
                     </div>
                  </Col>
               </Row>
            </div>
         </div>
      </section>
   );
}
