import React from "react";
import "./Howit.scss";
import { Col, Row } from "react-bootstrap";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import { ReactComponent as ArrowRight } from "../../../assets//images/svg/arrow-right.svg";
import SmartContract from "../../../assets/images/png/smart-contract.png";
import Defi from "../../../assets/images/png/defi.png";
import Nft from "../../../assets/images/png/nft.png";
import Dapps from "../../../assets/images/png/dapps.png";
import { toast } from "react-toastify";

const Howit = () => {
  return (
    <section id="howit-xiden" className="page-section">
      <div className="container-main d-flex flex-column">
        <Row className="title align-items-center">
          <div className="my-auto">
            <Uppertitle title={"USABILITY"} />
            <h2 className="title font-size-80 white-color font-pnb line-height-11">
              How it <br />
              can be used<span className="purple-color">.</span>
            </h2>
          </div>
        </Row>

        {/* defi */}
        <div className="corners right">
          <Row xs={1} sm={1} md={2} className="rand d-flex justify-content-between align-items-center">
            <Col xs={{ order: 2 }} sm={{ order: 2 }} md={{ order: 1 }}>
              <div className="ecosystem-how-it-info-col">
                <h2 className="title font-size-40 white-color font-pnb">
                  DeFi<span className="purple-color">.</span>
                </h2>
                <p className="font-pnr font-size-20 lightgrey-color">
                  The magic behind decentralized finance is here. Building DeFi on the Xiden network is super easy and will
                  provide high financial benefits for users in a decentralized manner.
                </p>

                <div
                  className="link-container d-flex align-items-center justify-content-start"
                  onClick={() => toast.info("Coming soon...", { toastId: "dferkjklj" })}
                >
                  <p className="font-pnsb font-size-20 blue-color">Start buiding</p>
                  <ArrowRight />
                </div>
              </div>
            </Col>
            <Col xs={{ order: 1 }} sm={{ order: 1 }} md={{ order: 2 }}>
              <div className="d-flex justify-content-center align-items-center mb-4 mb-sm-4 mb-md-4 mb-lg-0">
                <img src={Defi} loading="lazy" alt="deFi" className="ecosystem-how-it-img" />
              </div>
            </Col>
          </Row>
        </div>

        {/* nft */}
        <div className="corners left ">
          <Row xs={1} sm={1} md={2} className="rand d-flex justify-content-between align-items-center">
            <Col>
              <div className="d-flex justify-content-center align-items-center mb-4 mb-sm-4 mb-md-4 mb-lg-0">
                <img src={Nft} loading="lazy" alt="nft" className="ecosystem-how-it-img" />
              </div>
            </Col>
            <Col>
              <div className="ecosystem-how-it-info-col">
                <h2 className="title font-size-40 white-color font-pnb">
                  Nft<span className="purple-color">.</span>
                </h2>
                <p className="font-pnr font-size-20 lightgrey-color">
                  A highly-curated platform for minting, collecting, and selling your NFTs. Start minting your favorite NFTs and
                  increase your passive income.
                </p>
                <div
                  className="link-container d-flex align-items-center align-contents-center justify-content-start"
                  onClick={() => toast.info("Coming soon...", { toastId: "ferk" })}
                >
                  <p className="font-pnsb font-size-20 blue-color">Mint NFT's</p>
                  <ArrowRight />
                </div>
              </div>
            </Col>
          </Row>
        </div>

        {/* dapps */}
        <div className="corners right ">
          <Row xs={1} sm={1} md={2} className="rand d-flex justify-content-between align-items-center">
            <Col xs={{ order: 2 }} sm={{ order: 2 }} md={{ order: 1 }}>
              <div className="ecosystem-how-it-info-col">
                <h2 className="title font-size-40 white-color font-pnb">
                  DApps<span className="purple-color">.</span>
                </h2>
                <p className="font-pnr font-size-20 lightgrey-color">
                  A development environment, testing framework, and other tools. We facilitate access for developers that wish to
                  build their own DApps. Imagine the one that suits you and start building it on the Xiden network.
                </p>
                <div
                  className="link-container d-flex align-items-center align-contents-center justify-content-start"
                  onClick={() => toast.info("Coming soon...", { toastId: "r4rt5" })}
                >
                  <p className="font-pnsb font-size-20 blue-color">Build DApps</p>
                  <ArrowRight />
                </div>
              </div>
            </Col>
            <Col xs={{ order: 1 }} sm={{ order: 1 }} md={{ order: 2 }}>
              <div className="d-flex justify-content-center align-items-center mb-4 mb-sm-4 mb-md-4 mb-lg-0">
                <img src={Dapps} loading="lazy" alt="Dapps" className="ecosystem-how-it-img" />
              </div>
            </Col>
          </Row>
        </div>

        {/* smart contracts */}
        <div className="corners left">
          <Row xs={1} sm={1} md={2} className="rand d-flex justify-content-between align-items-center">
            <Col>
              <div className="d-flex justify-content-center align-items-center mb-4 mb-sm-4 mb-md-4 mb-lg-0">
                <img src={SmartContract} loading="lazy" alt="" className="ecosystem-how-it-img" />
              </div>
            </Col>
            <Col>
              <div className="ecosystem-how-it-info-col">
                <h2 className="title font-size-40 white-color font-pnb">
                  Smart contracts<span className="purple-color">.</span>
                </h2>
                <p className="font-pnr font-size-20 lightgrey-color">
                  Create, deploy, and test your smart contract on a highly transparent, secure, and performant platform.
                </p>
                <div
                  className="link-container d-flex align-items-center align-contents-center justify-content-start"
                  onClick={() => toast.info("Coming soon...", { toastId: "dertyhytj" })}
                >
                  <p className="font-pnsb font-size-20 blue-color">Create smart contract</p>
                  <ArrowRight />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
};

export default Howit;
