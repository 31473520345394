import React from "react";
import "./DApps.scss";
import { Row, Col } from "react-bootstrap";
import { AiOutlineArrowRight as ArrowRight } from "react-icons/ai";
import AppsImage from "../../../assets/images/png/dapps.png";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../Utils/routes";

const DApps = () => {
  const navigate = useNavigate();

  return (
    <div className="page-section rewards-apps-section">
      <div className="container-main">
        <div className="corners p-lg-5 p-4 rewards-apps-corners">
          <Row xs={1} sm={1} md={2}>
            <Col
              xs={{ order: 2 }}
              sm={{ order: 2 }}
              md={{ order: 1 }}
              className="d-flex flex-column justify-content-center align-items-md-start align-items-center"
            >
              <h2 className="title font-size-80 white-color font-pnb mb-4">
                DApps<span className="purple-color">.</span>
              </h2>
              <div className="paragraph-container">
                <p className="font-pnr font-size-24 lightgrey-color mb-5 line-height-11">
                  Develop applications and get rewarded XDEN based on your costs and settings chosen by you.
                </p>
              </div>
              <button className="buttonBlue rewards-dapps-button" onClick={() => navigate(routes.apps)}>
                <span className="me-2">Developer portal</span>
                <ArrowRight />
              </button>
            </Col>
            <Col xs={{ order: 1 }} sm={{ order: 1 }} md={{ order: 2 }}>
              <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                <img src={AppsImage} loading="lazy" alt="apps" className="apps-image" />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default DApps;
