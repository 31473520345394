import React, { createContext, useEffect, useState } from "react";
import "./ExtraStep.scss";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import { FiArrowUpRight as ArrowRightUp } from "react-icons/fi";
import { FiArrowRight as ArrowRight } from "react-icons/fi";
import ServicesSlider from "./ServicesSlider";
import Basm from "../../../assets/images/png/validator-basm.png";
import Impulse from "../../../assets/images/png/validator-impulse.png";
import RouterImg from "../../../assets/images/png/validator-router.png";
import Hidr from "../../../assets/images/png/validator-hidr.png";
import { Element } from "react-scroll";

const contentContext = createContext();

const ExtraStep = () => {
  const sliderData = [
    {
      id: 1,
      image: <img src={RouterImg} loading="lazy" alt="Router" className="slide-image router-img" />,
      title: "MinteR",
    },
    {
      id: 2,
      image: <img src={Hidr} loading="lazy" alt="HidR" className="slide-image hidr-img" />,
      title: "HidR",
    },
    {
      id: 3,
      image: (
        <img
          src={Impulse}
          loading="lazy"
          alt="Impulse"
          className="slide-image hidr-img"
        />
      ),
      title: "IMPulse",
    },
    {
      id: 4,
      image: (
        <img
          src={Basm}
          loading="lazy"
          alt="Basm laptop"
          className="slide-image basm-img"
        />
      ),
      title: "Basm",
    },
  ];
  const contentData = [
    {
      uperTitle: "MINTER",
      title: "MinteR",
      punctuation: ".",
      paragraph: `A redefined smart device that will unlock the full potential of blockchain 
                     technology by creating new and innovative business opportunities. It functions 
                     as a WiFi point unlocked by a Router that can open a pool through which smart 
                     devices can connect freely in order to access an anonymous internet network.
                     `,
      readMore: (
        <button className="buttonBlue" onClick={() => window.open("https://cryptodata.com/minter")}>
          <span className="me-2">Read more</span>
          <ArrowRight />
        </button>
      ),
      buyNow: (
        <button className="buttonOutlineBlue" onClick={() => window.open("https://cryptodata.com/product/minter")}>
          <span className="me-2">Buy now</span>
          <ArrowRightUp />
        </button>
      ),
      storage: "128",
      storage_unit: "MB",
      computing: "49",
      consumption: "max. 12",
    },
    {
      uperTitle: "HIDR",
      title: "HidR",
      punctuation: ".",
      paragraph: `A dual environment device integrated into a secure ecosystem that provides users 
                     two different platforms for regular daily activities and encrypted and 
                     privacy-oriented data and assets transfer.
                     `,
      readMore: (
        <button className="buttonBlue" onClick={() => window.open("https://cryptodata.com/hidr")}>
          <span className="me-2">Read more</span>
          <ArrowRight />
        </button>
      ),
      buyNow: (
        <button className="buttonOutlineBlue" onClick={() => window.open("https://cryptodata.com/shop")}>
          <span className="me-2">Buy now</span>
          <ArrowRightUp />
        </button>
      ),
      storage: "128",
      storage_unit: "GB",
      computing: "54",
      consumption: "max. 10",
    },
    {
      uperTitle: "IMPulse",
      title: "IMPulse",
      punctuation: ".",
      paragraph: `A smartphone designed to provide user privacy and ensure its data security. Built-in security-optimized features that facilitate connection between all our encryption mobile devices.`,
      readMore: (
        <button
          className="buttonBlue"
          onClick={() => window.open("https://cryptodata.com/impulse")}
        >
          <span className="me-2">Read more</span>
          <ArrowRight />
        </button>
      ),
      buyNow: (
        <button
          className="buttonOutlineBlue"
          onClick={() => window.open("https://cryptodata.com/product/impulse-k1")}
        >
          <span className="me-2">Buy now</span>
          <ArrowRightUp />
        </button>
      ),
      storage: "64",
      storage_unit: "GB",
      computing: "39",
      consumption: "max. 10",
    },
    {
      uperTitle: "BASM",
      title: "Basm",
      punctuation: ".",
      paragraph: `BASM is the first encrypted laptop that offers integral protection in 
                     accessing and transmitting user data by using Voice Over Blockchain 
                     Protocol (VOBP) technology.
                     `,
      readMore: (
        <button className="buttonBlue" onClick={() => window.open("https://cryptodata.com/basm")}>
          <span className="me-2">Read more</span>
          <ArrowRight />
        </button>
      ),
      buyNow: (
        <button className="buttonOutlineBlue" onClick={() => window.open("https://cryptodata.com/shop")}>
          <span className="me-2">Buy now</span>
          <ArrowRightUp />
        </button>
      ),
      storage: "512",
      storage_unit: "GB",
      computing: "83",
      consumption: "max. 65",
    },
  ];

  // const navigate = useNavigate();

  const [contentIndex, setContentIndex] = useState(0);

  const [animate, setAnimate] = useState(false);

  const value = { contentIndex, setContentIndex };

  const [displayData, setDisplayData] = useState(contentData[contentIndex]);

  useEffect(() => {
    setDisplayData(contentData[contentIndex]);
    setAnimate(true);
    setTimeout(() => setAnimate(false), 500);
  }, [contentIndex]);
  return (
    <contentContext.Provider value={value}>
      <Element name="extra-steps-section">
        <section id="extra-steps" className="page-section">
          <div className="container-main what-contianer d-flex flex-column">
            <div className="mb-5">
              <Uppertitle title={"EXTRA STEP"} />
              <div className="font-size-80 font-pnb line-height-11">
                <p className="mb-0">Add more validators</p>
                <p className="mb-0 darkgrey-color">into your pool to</p>
                <p className="mb-0">
                  increase validating power
                  <span className="purple-color">.</span>
                </p>
              </div>
            </div>

            <div className="what-contianer">
              <div className="row">
                <div
                  className={
                    animate
                      ? "col-lg-6 col-12 order-lg-1 order-2 coloana-stanga apply-effect"
                      : "col-lg-6 col-12 order-lg-1 order-2 coloana-stanga"
                  }
                >
                  <Uppertitle title={"VALIDATORS"} />
                  <h2 className="mb-3 font-size-80 white-color font-pnb">
                    {displayData.title}
                    <span className="purple-color">{displayData.punctuation}</span>
                  </h2>
                  <div className="description description-991">
                    <p className="font-family-pnr font-size-24 lightgrey-color line-height-11">{displayData.paragraph}</p>
                  </div>

                  <div className="properties-container mt-5 d-flex justify-content-between align-items-center align-content-center">
                    <div className="storage">
                      <h4 className="font-pnr line-height-11 font-size-18 lightgrey-color">Storage</h4>
                      <div className="container-values d-flex align-items-end">
                        <h4 className="font-pnb font-size-32 line-height-11">
                          {/* <span className="gradient1">Coming</span> <br/>
                                    <span className="gradient1">Soon...</span> */}
                          <span className="gradient1">{displayData.storage}</span>
                          <span className="ps-1 font-size-18 lightgrey-color">{displayData.storage_unit}</span>
                        </h4>
                      </div>
                    </div>

                    <div className="computing">
                      <h4 className="font-pnr line-height-11 font-size-18 lightgrey-color">Computing</h4>
                      <div className="container-values d-flex align-items-end">
                        <h4 className="font-pnb font-size-32 line-height-11">
                          {/* <span className="gradient2">Coming</span> <br/>
                                    <span className="gradient2">Soon...</span> */}
                          <span className="gradient2">{displayData.computing}</span>
                          <span className="ps-1 font-size-18 lightgrey-color">cycles</span>
                        </h4>
                      </div>
                    </div>

                    <div className="consumption">
                      <h4 className="font-pnr line-height-11 font-size-18 lightgrey-color">Consumption</h4>
                      <div className="container-values d-flex align-items-end">
                        <h4 className="font-pnb font-size-32 line-height-11">
                          {/* <span className="gradient3">Coming</span> <br/>
                                    <span className="gradient3">Soon...</span> */}
                          <span className="gradient3">{displayData.consumption}</span>
                          <span className="ps-1 font-size-18 lightgrey-color">W (&#177;10%)</span>
                        </h4>
                      </div>
                    </div>
                  </div>

                  <div className="buttons-flex-direction d-flex justify-content-start align-items-start pt-lg-5 pt-4">
                    {displayData.readMore}
                    {displayData.buyNow}
                  </div>
                </div>

                {/* <ServicesSlider data={sliderData} /> */}
                <div className="col-lg-6 col-12 order-lg-2 order-1 pb-lg-0 pb-5 position-relative">
                  <div className="pos-absolute-slider">
                    <ServicesSlider data={sliderData} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Element>
    </contentContext.Provider>
  );
};

export { contentContext };

export default ExtraStep;
