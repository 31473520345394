import React from "react";
import "./BePart.scss";
import { Col, Row } from "react-bootstrap";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import { ReactComponent as ArrowRight } from "../../../assets//images/svg/arrow-right.svg";
// import LowEnergy from "../../../assets/images/png/low-energy-bg.png"
import Rewards2 from "../../../assets/images/png/homePage/pc.png";
// import Rewards2 from "../../../assets/images/png/homePage/xid-rewards-hero.png";
import Img1 from "../../../assets/images/png/Scroll Group 4.png";
import Img2 from "../../../assets/images/png/homePage/blue-xid-coin.png";
import Img3 from "../../../assets/images/png/Scroll Group 6.png";
import BottomPart from "../../../assets/images/png/homePage/network-bottom-part.png";
import TopPart from "../../../assets/images/png/homePage/network-top-part.png";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../Utils/routes";


const BePart = () => {
   const navigate = useNavigate();

   return (
      <section id="be-part" className="page-section">
         <div className="container-main">
            <h2 className="title font-size-80 white-color font-pnb line-height-11 pb-lg-5 pb-4">
               Be part of <br /> The new internet
               <span className="purple-color">.</span>
            </h2>
            <Row>
               <Col>
                  <div className="wrapper-coloana coloana-r1 d-flex flex-row align-items-center">
                     <div className="content ">
                        <Uppertitle title={"DECENTRALIZED PRIVATE NETWORKS"} />
                        <div className="font-pnb font-size-56 white-color line-height-11 py-md-4 py-5 card-heading">
                           <p className="mb-0">A new internet layer</p>
                           <p className="mb-0">Based on blockchain</p>
                           <p className="mb-0">And built on</p>
                           <p className="mb-0">Decentralized</p>
                           <p className="mb-0">
                              Private Networks<span className="pink-text">.</span>
                           </p>
                        </div>
                        <h4 className="font-pnb font-size-56 white-color line-height-11 py-4 sm-heading">
                           New internet layer Based on blockchain And built on Decentralized Private Networks
                           <span className="purple-color">.</span>
                        </h4>
                        <button className="font-size-20 font-pnsb read-more-btn" onClick={() => navigate(routes.privateNetwork)}>
                           Read more
                           <ArrowRight />
                        </button>
                     </div>
                     <div className="content-img pt-md-0 pt-4 position-relative d-flex align-items-center">
                        {/* <img src={Img1} loading="lazy" alt="wifi" /> */}
                        <img src={TopPart} alt="decentralized network" className="img-fluid top-image" />
                        <img src={BottomPart} alt="decentralized network" className="img-fluid bottom-image" />
                     </div>
                  </div>
               </Col>
            </Row>
            <Row>
               <Col lg={4}>
                  <div className="wrapper-coloana coloana-r2 d-flex flex-column">
                     <Uppertitle title={"MINING"} />
                     <div className="content-card d-flex flex-column justify-content-between">
                        <h4 className="font-pnb font-size-56 white-color line-height-11 pt-lg-0 pt-4">
                           Open <br /> Pool
                           <span className="purple-color">.</span>
                        </h4>
                        <img src={Img2} loading="lazy" alt="coin" className="img-fluid py-4 pe-4" />
                        <h4 className="font-pnb font-size-56 white-color line-height-11 pb-lg-0 pb-4">
                           Start <br /> Validating
                           <span className="purple-color">.</span>
                        </h4>
                     </div>
                     <button className="font-size-20 font-pnsb read-more-btn" onClick={() => navigate(routes.validator)}>
                        Get started
                        <ArrowRight />
                     </button>
                  </div>
               </Col>
               <Col lg={8}>
                  <Row>
                     <Col lg={6}>
                        <div className="wrapper-coloana coloana-r2-c2-c1 d-flex flex-column">
                           <Uppertitle title={"DAPPS"} />
                           <h4 className="font-pnb font-size-56 line-height-11">
                              Start <br /> Using DApps<span className="blue-color">.</span>
                           </h4>
                           <button className="font-size-20 font-pnsb read-more-btn" onClick={() => navigate(routes.apps)}>
                              Read more
                              <ArrowRight />
                           </button>
                        </div>
                     </Col>
                     <Col lg={6}>
                        <div className="wrapper-coloana coloana-r2-c2-c2 blue-color d-flex flex-column">
                           <Uppertitle title={"BUILD"} />
                           <h4 className="font-pnb font-size-56 line-height-11">
                              Start <br /> Building<span className="purple-color">.</span>
                           </h4>
                           <button className="font-size-20 font-pnsb read-more-btn" onClick={() => navigate(routes.apps)}>
                              Get started
                              <ArrowRight />
                           </button>
                        </div>
                     </Col>
                  </Row>
                  <Row>
                     <Col>
                        <div className="wrapper-coloana coloana-r2-r2 d-flex flex-row align-items-center">
                           <div className="content d-flex flex-column justify-content-between">
                              <Uppertitle title={"CONNECT FOR FREE"} />
                              <h4 className="font-pnb font-size-56 white-color line-height-11 py-4">
                                 Internet
                                 <span className="purple-color">.</span>
                                 <br />
                                 Storage
                                 <span className="purple-color">.</span>
                                 <br />
                                 Computing
                                 <span className="purple-color">.</span>
                              </h4>
                              <button
                                 className="font-size-20 font-pnsb read-more-btn"
                                 onClick={() => navigate(routes.xiden)}
                              >
                                 Read more
                                 <ArrowRight />
                              </button>
                           </div>
                           <div className="content-img pb-md-0 pb-4">
                              <img src={Img3} loading="lazy" alt="computing" />
                           </div>
                        </div>
                     </Col>
                  </Row>
               </Col>
            </Row>
            <Row>
               <Col lg={8}>
                  <div className="wrapper-coloana coloana-r3 d-flex flex-row align-items-center">
                     <div className="content-img pt-md-0 pb-md-0 pb-4">
                        <img src={Rewards2} loading="lazy" alt="wifi" />
                     </div>
                     <div className="content ">
                        <Uppertitle title={"CONNECT FOR FREE"} />
                        {/* <div className="font-pnb font-size-56 white-color line-height-11 py-4">
                           <p className="mb-0">Start earning with</p>
                           <p className="mb-0">
                              your services & devices<span className="purple-color">.</span>
                           </p>
                        </div> */}
                        <div className="font-pnb font-size-56 white-color line-height-11 py-4">
                           <p className="mb-0">Start earning with</p>
                           <p className="mb-0">
                              {`your services & devices`}
                              <span className="purple-color">.</span>
                           </p>

                           {/* Start earning with <br /> your services & devices */}
                        </div>
                        <button className="font-size-20 font-pnsb read-more-btn" onClick={() => navigate(routes.rewards)}>
                           Read more
                           <ArrowRight />
                        </button>
                     </div>
                  </div>
               </Col>
               <Col lg={4}>
                  <div className="wrapper-coloana coloana-r3-c2 blue-color d-flex flex-column ethernytree-card">
                     <Uppertitle title={"LOW ENERGY"} />
                     <div className="titles">
                        <div className="font-pnb font-size-56 low-energy line-height-11 text-shadow">Low energy.</div>
                        <div className="font-pnb font-size-56 black-color plant-a-tree line-height-11">
                           Plant a<span> tree.</span>
                        </div>
                     </div>

                     <div>
                        <button
                           className="font-size-20 font-pnsb read-more-btn"
                           onClick={() => window.open("https://ethernytree.com/")}
                        >
                           Get started
                           <ArrowRight />
                        </button>
                     </div>
                  </div>
               </Col>
            </Row>
         </div>
      </section>
   );
};

export default BePart;
