import React from "react";
import { Helmet } from "react-helmet";
import SchemaTemplate from "../../SEO/SchemaTemplate.json";
import Card from "./Card/Card";
import HeroSection from "./HeroSection/HeroSection";
import ConnectedDevices from "./ConnectedDevices/ConnectedDevices";
import SdrBenefits from "./SDRBenefits/SdrBenefits";
import SmartDevice from "./SmartDevice/SmartDevice";
import Card2 from "./Card2/Card2";

const SDRLayer = () => {
  return (
    <div className="private-network-page">
      {/* seo */}
      <Helmet>
        <title>Xiden | Smart Distributed Resources</title>
        <meta name="title" content="Xiden | Smart Distributed Resources" />
        <meta
          name="description"
          content="SDR is an utility layer of the Xiden network that gathers all the resources from the connected devices in the blockchain."
        />
        <link rel="canonical" href="" />
        {/* Open Graph Facebook */}
        <meta property="og:title" content="Xiden | Smart Distributed Resources" />
        <meta
          property="og:description"
          content="SDR is an utility layer of the Xiden network that gathers all the resources from the connected devices in the blockchain."
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        {/* Open Graph Twitter  */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="" />
        <meta property="twitter:title" content="Xiden | Smart Distributed Resources" />
        <meta
          property="twitter:description"
          content="SDR is an utility layer of the Xiden network that gathers all the resources from the connected devices in the blockchain."
        />
        <meta property="twitter:image" content="" />
        {/* Structured Data */}
        <script>{JSON.stringify(SchemaTemplate)}</script>
      </Helmet>

      {/* sections */}
      <HeroSection />

      <ConnectedDevices />
      <SmartDevice />
      <Card />
      <Card2 />
      <SdrBenefits />
    </div>
  );
};

export default SDRLayer;
