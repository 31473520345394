import React from "react";
import { Row, Col } from "react-bootstrap";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import { ReactComponent as ArrowRight } from "../../../assets//images/svg/arrow-right.svg";

import FeatureCard from "../../../components/FeatureCard/FeatureCard";
import { cardsData } from "./cardsData";

import "./PropertiesGuardian.scss"

const PropertiesGuardian = () => {
  return (
    <section id="properties-guardian-section" className="page-section">
      <div className="container-main">
        <div className="w-100 heading-container">
          <h2 className="font-size-80 white-color font-pnb">
            Properties<span className="purple-color">.</span>
          </h2>
        </div>
        <Row xs={1} sm={2} lg={4} className="cards-row">
          {cardsData.map((card) => (
            <Col key={card.title}>
              <FeatureCard cardData={card} />
            </Col>
          ))}
        </Row>
      </div>
    </section>
  );
};

export default PropertiesGuardian;
