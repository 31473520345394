import React from "react";
import Uppertitle from "../../../../components/Uppertitle/Uppertitle";

const ProprietaryMobileCard = (props) => {
  const { uppertitle, description, svgProp, bg } = props;

  return (
    <div className="slider-card-proprietary me-3">
      <div className={`${bg} column-wrapper small-col p-4 p-xl-5 d-flex flex-column align-items-start justify-content-between`}>
        <div className="title-descp-container">
          <Uppertitle title={uppertitle} />
          <div className="mb-lg-5 mb-0 mt-lg-0 mt-4 card-icons">{svgProp && svgProp}</div>
        </div>
        <div className={`font-pnb font-size-40 white-color prop-control-title line-height-11`}>
          {description}<span className="purple-color">.</span>
        </div>
      </div>
    </div>
  );
};

export default ProprietaryMobileCard;
