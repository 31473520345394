import React, { useRef } from "react";
import "./Consensus.scss";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import StakingImg from "../../../assets/images/png/homePage/consensus-staking.jpg";
// import StakingImg from "../../../assets/images/png/homePage/consensus-stalking-new2.png";
import ConnectivityImg from "../../../assets/images/png/consensus-connectivity.png";
import ExistanceImg from "../../../assets/images/png/consensus-existance.png";
import { ReactComponent as ArrowRight } from "../../../assets/images/svg/arrow-right.svg";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../Utils/routes";

const Consensus = () => {
  const navigate = useNavigate();

  //  Refs
  const sliderRef = useRef(null);

  // functions
  //  // slider function start
  let isDown = false;
  let startX;
  let scrollLeft;

  const mouseLeaveFunction = () => {
    isDown = false;
  };
  const mouseDownFunction = (e) => {
    isDown = true;
    startX = e.pageX - sliderRef.current.offsetLeft;
    scrollLeft = sliderRef.current.scrollLeft;
  };
  const mouseUpFunction = () => {
    isDown = false;
  };
  const touchFunction = () => {
    isDown = false;
  };
  const mouseMoveFunction = (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - sliderRef.current.offsetLeft;
    const walk = (x - startX) * 1; //scroll-fast
    sliderRef.current.scrollLeft = scrollLeft - walk;
  };
  // slider function end

  return (
    <div className="page-section consensus-section-ecosystem">
      <div className="container-main">
        <div className="main-col">
          <div className="cns-ut-container">
            <Uppertitle title={`MORE ALGORITHMS`} />
          </div>
          <h2 className="mb-0 font-size-80 white-color font-pnb line-height-11 consensus-title">Consensus combines</h2>
          <h2 className="mb-2 mb-lg-5 font-size-80 white-color font-pnb line-height-11 algorithm-title">
            more algorithms<span className="purple-color">.</span>
          </h2>
          <div className="dsc-container">
            <p className="font-family-pnr font-size-24 lightgrey-color line-height-11">
              Multiple integrated distributed processes ensure that all peers reach a common agreement and establish the optimal
              operation of the network.
            </p>
          </div>
        </div>

        <div className="position-relative slider-height">
          <div
            ref={sliderRef}
            className="descriptions-container slider-div grab"
            onMouseDown={(e) => mouseDownFunction(e)}
            onMouseLeave={() => mouseLeaveFunction()}
            onMouseUp={() => mouseUpFunction()}
            onMouseMove={(e) => mouseMoveFunction(e)}
            onTouchStart={() => touchFunction()}
          >
            <div className="description-col">
              <div className="image-container">
                <img src={StakingImg} loading="lazy" alt="staking" className="description-first-image" />
              </div>
              <div className="custom-height-ecosystem d-flex align-items-start justify-content-between flex-column">
                <h4 className="text-white font-size-40 font-pnb box-title">
                  Proof of Staking<span className="purple-color">.</span>
                </h4>
                <p className="font-family-pnr font-size-24 lightgrey-color line-height-11 custom-min-height">
                  A consensus algorithm that ensures the conformity of every device aiming to become a validator by submitting
                  predetermined quantity of XDEN.
                </p>
                <button className="font-pnsb font-size-20 read-more-link" onClick={() => navigate(routes.staking)}>
                  Read more
                  <ArrowRight />
                </button>
              </div>
            </div>

            <div className="description-col">
              <div className="image-container">
                <img src={ConnectivityImg} loading="lazy" alt="connectivity" className="description-image" />
              </div>
              <div className="custom-height-ecosystem d-flex align-items-start justify-content-between flex-column">
                <h4 className="text-white font-size-40 font-pnb box-title">
                  Proof of Connectivity<span className="purple-color">.</span>
                </h4>
                <p className="font-family-pnr font-size-24 lightgrey-color line-height-11 custom-min-height">
                  A consensus algorithm used to ensure that all devices integrated into the system are genuine, connected and
                  function within the system.
                </p>
                <button className="font-pnsb font-size-20 read-more-link" onClick={() => navigate(routes.privateNetwork)}>
                  Read more
                  <ArrowRight />
                </button>
              </div>
            </div>

            <div className="description-col">
              <div className="image-container">
                <img src={ExistanceImg} loading="lazy" alt="staking" className="description-image" />
              </div>
              <div className="custom-height-ecosystem d-flex align-items-start justify-content-between flex-column">
                <h4 className="text-white font-size-40 font-pnb box-title">
                  Proof of Existence<span className="purple-color">.</span>
                </h4>
                <p className="font-family-pnr font-size-24 lightgrey-color line-height-11 custom-min-height">
                  A consensus algorithm that ensures a specific data or digital transaction is associated with a timestamp and a
                  signature.
                </p>
                <button className="font-pnsb font-size-20 read-more-link" onClick={() => navigate(routes.validator)}>
                  Read more
                  <ArrowRight />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Consensus;
