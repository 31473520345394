import React from 'react';
import './MiningCompetition.scss';
import { Helmet } from 'react-helmet';
import Hero from './Hero/Hero';
import SchemaTemplate from "../../SEO/SchemaTemplate.json";
import OGImage from "../../assets/images/metaTags/xiden-og-image.jpg";
import PrizePool from './PrizePool/PrizePool';
import Timeline from './Timeline/Timeline';
import FAQ from './FAQ/FAQ';
import Steps from './Steps/Steps';
import Register from './Register/Register';

const MiningCompetition = () => {
  return (
    <div className="mining-competition">
    {/* seo */}
    <Helmet>
       <title>Xiden | Mining competition</title>
       <meta name="title" content="Xiden | Mining competition" />
       <meta name="description" content=" Xiden is a dual-layered decentralized network built to facilitate the integration of all smart devices in order to provide a free connection and internet access in a secure, anonymous, and unrestricted environment." />
       <link rel="canonical" href="" />
       {/* Open Graph Facebook */}
       <meta property="og:title" content="Xiden | Mining competition" />
       <meta property="og:description" content="Xiden is a dual-layered decentralized network built to facilitate the integration of all smart devices in order to provide a free connection and internet access in a secure, anonymous, and unrestricted environment." />
       <meta property="og:type" content="website" />
       <meta property="og:image" content={OGImage} />
       <meta property="og:url" content="" />
       {/* Open Graph Twitter  */}
       <meta property="twitter:card" content="summary_large_image" />
       <meta property="twitter:url" content="" />
       <meta property="twitter:title" content="Xiden | Mining competition" />
       <meta property="twitter:description" content="Xiden is a dual-layered decentralized network built to facilitate the integration of all smart devices in order to provide a free connection and internet access in a secure, anonymous, and unrestricted environment." />
       <meta property="twitter:image" content={OGImage} />
       {/* Structured Data */}
       <script>{JSON.stringify(SchemaTemplate)}</script>
    </Helmet>

    {/* sections */}
    <Hero />
    <PrizePool /> 
    <Timeline />
    <Steps />
    <Register />
    <FAQ />

 </div>
  )
}

export default MiningCompetition