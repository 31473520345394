import React from "react";
import "./DisclaimerCard.scss";
import { Col, Row } from "react-bootstrap";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import CubePurple from "../../../assets/images/png/stake-support-validators-cooldown.png";

const DisclaimerCard = () => {


    return (
        <section id="disclaimer-card-section" className="page-section">
            <div className="container-main position-relative">
                <div className="rotate-uppertitle">
                    <Uppertitle title={"DISCLAIMER"} />
                </div>

                <div className="disclaimer-card d-flex justify-content-center align-items-center">
                    <h4 className="font-pnb py-lg-0 py-sm-4 py-3 font-size-56 white-color text-center disclaimer-card-text">
                        Minter Guardian's validator <br />
                        role can be configurated as <br />
                        any other validator device.

                    </h4>
                </div>

            </div>
        </section>
    );
};

export default DisclaimerCard;
