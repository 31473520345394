import React from "react";
import { Col, Row } from "react-bootstrap";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import Validates from "../../../assets/images/png/guardian-validates.png";
import Defi from "../../../assets/images/png/Daps-defi.png";
import { ReactComponent as ArrowRight } from "../../../assets//images/svg/arrow-right.svg";
import "./HowTo.scss";

export default function HowTo() {
    return (
        <section id="howto-guardian"  className="page-section">
            <div className="container-main d-flex flex-column">
                <Row className="title align-items-center">
                    <div className="my-auto">
                        <Uppertitle title={"REWARDS"} />
                        <h2 className="title font-size-80 pb-4 pt-3 white-color font-pnb">
                            How to <br />
                            get rewards<span className="purple-color">.</span>
                        </h2>
                    </div>
                </Row>
                <div className="corners p-lg-5 p-4 right">
                    <Row className="rand d-flex justify-content-between align-items-center">
                        <Col className="d-flex flex-column" lg={6}>
                            <h2 className="title font-size-40 pb-3 white-color font-pnb">
                                Validates
                                <span className="purple-color">.</span>
                            </h2>
                            <p className="font-pnr font-size-20 pb-2 lightgrey-color description-991">
                                Strong dead eternal-return decrepit evil fearful joy play burying
                                joy ultimate aversion grandeur. Reason endless moral passion abstract
                                ocean abstract holiest overcome love mountains moral.
                            </p>
                        </Col>
                        <Col lg={6} className="d-flex justify-content-end">
                            <img src={Validates} loading="lazy" alt="" className="img-fluid" />
                        </Col>
                    </Row>
                </div>

                <div className="corners p-lg-5 p-4 left ">
                    <Row className="rand d-flex flex-row-reverse justify-content-between align-items-center">
                        <Col lg={5} className="d-flex flex-column" >
                            <h2 className="title font-size-40 pb-3 white-color font-pnb">
                                Resources for DApps
                                <span className="purple-color">.</span>
                            </h2>
                            <p className="font-pnr font-size-20 pb-2 lightgrey-color description-991">
                                Strong dead eternal-return decrepit evil fearful joy play burying
                                joy ultimate aversion grandeur. Reason endless moral passion abstract
                                ocean abstract holiest overcome love mountains moral.
                            </p>
                        </Col>
                        <Col lg={7} className="d-flex justify-content-end">
                            <img src={Defi} loading="lazy" alt="" className="img-fluid daps-img" />
                        </Col>
                    </Row>
                </div>
            </div>
        </section>
    );
}
