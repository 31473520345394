import "./Card2.scss";
import "../Card/Card.scss";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import React, { useState } from "react";
import Cube from "./assets/cube-img.png";
import PlusIcon from "./assets/plus-icon.png";
import Idea from "./assets/idea-img.png";
import Checked from "./assets/checked.png";
import Free from "./assets/free.png";
import DCube from "./assets/3DCube-img.png";

import CustomUpperTitle from "../CustomUpperTitle/CustomUpperTitle";

const Card2 = () => {
  const [rotateCard1, setRotateCard1] = useState(() => false);
  const [rotateCard2, setRotateCard2] = useState(() => false);
  const [rotateCard3, setRotateCard3] = useState(() => false);
  const [rotateCard4, setRotateCard4] = useState(() => false);
  const [rotateCard5, setRotateCard5] = useState(() => false);

  const handleRotate = (card) => {
    switch (card) {
      case "card1":
        setRotateCard1(!rotateCard1);
        break;
      case "card2":
        setRotateCard2(!rotateCard2);
        break;
      case "card3":
        setRotateCard3(!rotateCard3);
        break;
      case "card4":
        setRotateCard4(!rotateCard4);
        break;
      case "card5":
        setRotateCard5(!rotateCard5);
        break;
    }
  };

  return (
    <section id="sdr-card-two" className="page-section">
      <div className="container-main">
        <div className="row om-bg h-100 justify-content-end">
          <div className="col-lg-10 lg-mb-4 md-mb-0">
            <div id="xiden-card7-box" className="xiden-card7-box-app-card xiden-card7-box-card">
              <div className="p-4 position-relative transform-card h-100">
                <div
                  onClick={() => handleRotate("card1")}
                  className={`${
                    rotateCard1 ? "rotate-xiden-card7-box-front-side" : ""
                  } d-flex flex-column justify-content-between xiden-card-box-app xiden-card7-box-card-side xiden-card7-box-front-side p-lg-5 p-4`}
                >
                  <CustomUpperTitle title={"SCALABILITY"} blueColor lineBlueColor />

                  <div className="align-self-center">
                    <img src={Cube} alt="" className="img-fluid" />
                  </div>
                  <h4 className="line-height-1 white-color py-lg-0 py-3 font-size-80 font-pnb ms-0 text-center font-pnb">
                    Xiden’s infrastructure gives a smart way of using resources with reduced costs and maximum performance
                    <br />
                  </h4>
                  <div className="plus-btn plus-btn-xiden-card7-box d-flex justify-content-center align-items-center">
                    <img className="plus-icons plus-btn-xiden-card7-box-img" src={PlusIcon} alt="plus" />
                  </div>
                </div>
                <div
                  onClick={() => handleRotate("card1")}
                  className={`${
                    rotateCard1 ? "rotate-xiden-card7-box-back-side" : ""
                  } d-flex flex-column justify-content-between xiden-card-box-app xiden-card7-box-card-side xiden-card7-box-back-side p-lg-5 p-4`}
                >
                  <CustomUpperTitle title={"SCALABILITY"} blueColor lineBlueColor />
                  {/* <div className="align-self-center">
                    <img src={Cube} alt="" className="img-fluid" />
                  </div> */}
                  <h4 className="line-height-1 white-color py-lg-0 py-3 font-size-48 font-pnb ms-0 text-center font-pnb">
                    Can find all the necessary resources for developing and running apps and projects without subscriptions and
                    additional costs. It is not necessary to own hardware infrastructure. You can use an existing one when you
                    want, where you want and how long you need.
                  </h4>

                  <div className="plus-btn plus-btn-xiden-card7-box d-flex justify-content-center align-items-center">
                    <img
                      className={`${rotateCard1 ? "close-btn" : ""} plus-icons plus-btn-xiden-card7-box-img`}
                      src={PlusIcon}
                      alt="plus"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 lg-mb-4 md-mb-0 mt-4">
            <div id="xiden-card8-box" className="xiden-card8-box-app-card xiden-card8-box-card">
              <div className="p-4 position-relative transform-card h-100">
                <div
                  onClick={() => handleRotate("card2")}
                  className={`${
                    rotateCard2 ? "rotate-xiden-card8-box-front-side" : ""
                  } d-flex flex-column justify-content-between xiden-card-box-app xiden-card8-box-card-side xiden-card8-box-front-side p-lg-5 p-4`}
                >
                  <CustomUpperTitle title={"ALL IN ONE"} blueColor lineBlueColor />

                  <div className="">
                    <img src={Idea} alt="" className="img-fluid" />
                  </div>
                  <h4 className="line-height-1 white-color py-lg-0 py-3 font-size-48 font-pnb ms-0">
                    VPN.
                    <br />
                    Storage.
                    <br />
                    Computing.
                    <br />
                    Connectivity.
                    <br />
                    Cloud rendering.
                    <br />
                  </h4>
                  <div className="plus-btn plus-btn-xiden-card8-box d-flex justify-content-center align-items-center">
                    <img className="plus-icons plus-btn-xiden-card8-box-img" src={PlusIcon} alt="plus" />
                  </div>
                </div>
                <div
                  onClick={() => handleRotate("card2")}
                  className={`${
                    rotateCard2 ? "rotate-xiden-card8-box-back-side" : ""
                  } d-flex flex-column justify-content-between xiden-card-box-app xiden-card8-box-card-side xiden-card8-box-back-side p-lg-5 p-4`}
                >
                  <CustomUpperTitle title={"ALL IN ONE"} blueColor lineBlueColor />
                  {/* <div className="">
                    <img src={Idea} alt="" className="img-fluid" />
                  </div> */}
                  <h4 className="line-height-1 white-color py-lg-0 py-3 font-size-40 font-pnb ms-0">
                    You can benefit from an all-in-one infrastructure that provides all the tools required for developers.
                  </h4>

                  <div className="plus-btn plus-btn-xiden-card8-box d-flex justify-content-center align-items-center">
                    <img
                      className={`${rotateCard2 ? "close-btn" : ""} plus-icons plus-btn-xiden-card8-box-img`}
                      src={PlusIcon}
                      alt="plus"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-md-12">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div id="xiden-card9-box" className="xiden-card9-box-app-card xiden-card9-box-card mt-4">
                  <div className="p-4 position-relative transform-card h-100">
                    <div
                      onClick={() => handleRotate("card3")}
                      className={`${
                        rotateCard3 ? "rotate-xiden-card9-box-front-side" : ""
                      } d-flex flex-column justify-content-between xiden-card-box-app xiden-card9-box-card-side xiden-card9-box-front-side p-lg-5 p-4`}
                    >
                      <CustomUpperTitle title={"TOTAL CONTROL"} blueColor lineBlueColor />
                      <div className="">
                        <img src={Checked} alt="" className="img-fluid" />
                      </div>
                      <h4 className="line-height-1 white-color py-lg-0 py-3 font-size-40 font-pnb ms-0">
                        Cryptographic
                        <br />
                        Secured.
                      </h4>
                      <div className="plus-btn plus-btn-xiden-card9-box d-flex justify-content-center align-items-center">
                        <img className="plus-icons plus-btn-xiden-card9-box-img" src={PlusIcon} alt="plus" />
                      </div>
                    </div>
                    <div
                      onClick={() => handleRotate("card3")}
                      className={`${
                        rotateCard3 ? "rotate-xiden-card9-box-back-side" : ""
                      } d-flex flex-column justify-content-between xiden-card-box-app xiden-card9-box-card-side xiden-card9-box-back-side p-lg-5 p-4`}
                    >
                      <CustomUpperTitle title={"TOTAL CONTROL"} blueColor lineBlueColor />
                      {/* <div className="">
                        <img src={Checked} alt="" className="img-fluid" />
                      </div> */}
                      <h4 className="line-height-1 white-color py-lg-0 py-3 font-size-28 font-pnb ms-0">
                        Your identity is always protected. Access is granted by the Xiden blockchain which uses encryption and
                        protection algorithms.
                      </h4>

                      <div className="plus-btn plus-btn-xiden-card9-box d-flex justify-content-center align-items-center">
                        <img
                          className={`${rotateCard3 ? "close-btn" : ""} plus-icons plus-btn-xiden-card9-box-img`}
                          src={PlusIcon}
                          alt="plus"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <div id="xiden-card10-box" className="xiden-card10-box-app-card xiden-card10-box-card mt-4">
                  <div className="p-4 position-relative transform-card h-100">
                    <div
                      onClick={() => handleRotate("card4")}
                      className={`${
                        rotateCard4 ? "rotate-xiden-card10-box-front-side" : ""
                      } d-flex flex-column justify-content-between xiden-card-box-app xiden-card10-box-card-side xiden-card10-box-front-side p-lg-5 p-4`}
                    >
                      <CustomUpperTitle title={"FREE"} blueColor lineBlueColor />
                      <div className="">
                        <img src={Free} alt="" className="img-fluid" />
                      </div>
                      <h4 className="line-height-1 white-color py-lg-0 py-3 font-size-40 font-pnb ms-0">
                        Free to choose
                        <br /> No monthly fees.
                      </h4>
                      <div className="plus-btn plus-btn-xiden-card10-box d-flex justify-content-center align-items-center">
                        <img className="plus-icons plus-btn-xiden-card10-box-img" src={PlusIcon} alt="plus" />
                      </div>
                    </div>
                    <div
                      onClick={() => handleRotate("card4")}
                      className={`${
                        rotateCard4 ? "rotate-xiden-card10-box-back-side" : ""
                      } d-flex flex-column justify-content-between xiden-card-box-app xiden-card10-box-card-side xiden-card10-box-back-side p-lg-5 p-4`}
                    >
                      <CustomUpperTitle title={"FREE"} blueColor lineBlueColor />
                      {/* <div className="">
                        <img src={Free} alt="" className="img-fluid" />
                      </div> */}
                      <h4 className="line-height-1 white-color py-lg-0 py-3 font-size-28 font-pnb ms-0">
                        Freedom to benefit from the network’s resources without hidden subscriptions or additional fees. You can
                        use it whenever you want for as long as you want.
                      </h4>

                      <div className="plus-btn plus-btn-xiden-card10-box d-flex justify-content-center align-items-center">
                        <img
                          className={`${rotateCard4 ? "close-btn" : ""} plus-icons plus-btn-xiden-card10-box-img`}
                          src={PlusIcon}
                          alt="plus"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="xiden-card11-box" className="xiden-card11-box-app-card xiden-card11-box-card mt-4">
              <div className="p-4 position-relative transform-card h-100">
                <div
                  onClick={() => handleRotate("card5")}
                  className={`${
                    rotateCard5 ? "rotate-xiden-card11-box-front-side" : ""
                  } d-flex flex-column justify-content-between xiden-card-box-app xiden-card11-box-card-side xiden-card11-box-front-side p-lg-5 p-4`}
                >
                  <CustomUpperTitle title={"SCALABILITY"} blueColor lineBlueColor />
                  <div className="mt-4">
                    <img src={Cube} alt="" className="img-fluid" />
                  </div>
                  <div className="d-flex align-items-center justify-content-between mobile-flex">
                    <h4 className="line-height-1 white-color py-lg-0 py-3 font-size-40 font-pnb ms-0">
                      Infinite scalability
                      <br /> is the easiest way.
                    </h4>
                    <img src={DCube} alt="cube" className="img-fluid cube-img"></img>
                  </div>
                  <div className="plus-btn plus-btn-xiden-card11-box d-flex justify-content-center align-items-center">
                    <img className="plus-icons plus-btn-xiden-card11-box-img" src={PlusIcon} alt="plus" />
                  </div>
                </div>
                <div
                  onClick={() => handleRotate("card5")}
                  className={`${
                    rotateCard5 ? "rotate-xiden-card11-box-back-side" : ""
                  } d-flex flex-column justify-content-between xiden-card-box-app xiden-card11-box-card-side xiden-card11-box-back-side p-lg-5 p-4`}
                >
                  <CustomUpperTitle title={"SCALABILITY"} blueColor lineBlueColor />
                  {/* <div className="">
                    <img src={Free} alt="" className="img-fluid" />
                  </div> */}
                  <h4 className="line-height-1 white-color py-lg-0 py-3 font-size-40 font-pnb ms-0 custom-mobile-margin">
                    Ensure smart use of the network’s infrastructure without worrying about maintenance or additional costs.
                  </h4>

                  <div className="plus-btn plus-btn-xiden-card11-box d-flex justify-content-center align-items-center">
                    <img
                      className={`${rotateCard5 ? "close-btn" : ""} plus-icons plus-btn-xiden-card11-box-img`}
                      src={PlusIcon}
                      alt="plus"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Card2;
