import React from 'react'
// import SupportSteps from './SupportStepsReward/SupportSteps'
import "./SupportUpdate.scss"
import HeroSection from './HeroSection/HeroSection'
import SupportStepsSwitch from './SupportStepsUpdate/SupportStepsSwitch'

export default function SupportUpdate() {
  return (
    <section id="support-minter-settings">
      <HeroSection />
      <SupportStepsSwitch />
    </section>

  )
}
