import "../../template/color.scss";
import "../../template/container.scss";
import "../../template/fonts.scss";
import "bootstrap/dist/css/bootstrap.min.css";

// SVG imports

import { ReactComponent as XidenLogo } from "../../assets/images/svg/xiden-logo.svg";
import { ReactComponent as SitemapGoldIcon } from "../../assets/images/svg/sitemap-gold.svg";
import { ReactComponent as LanguageGoldIcon } from "../../assets/images/svg/language-gold.svg";
import { ReactComponent as Plus } from "../Assets/images/png/test-plus.svg";
import { ReactComponent as MotoGPLogo } from "../../assets/images/svg/motoGplogo.svg";

import discord from "../Assets/images/svg/discord.svg";
import telegram from "../Assets/images/svg/telegram.svg";
import twitter from "../Assets/images/svg/twitter.svg";
import coinMarket from "../../assets/images/svg/coinmarketcap-logowhite.svg";
import sitemapicon from "../Assets/images/svg/sitemapicon.svg";
import languageicon from "../Assets/images/svg/languageicon.svg";

// PNG imports

import linemiddle from "../Assets/images/svg/line-between.png";

// UTILS imports

import { FaDiscord as DiscordIcon } from "react-icons/fa";
import { Accordion } from "react-bootstrap";

import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../Utils/routes";

// PDF imports

import XidenLightpaper from "../../assets/pdf/Lightpaper Xiden V1.2.pdf";

// CSS import

import "./Footer.scss";

export default function Footer() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <footer
      className={`${
        location.pathname === routes.minter ? "blue-bg-color" : "gray-bg-color"
      } d-flex flex-column footer-section  black-color`}
    >
      <div className="container-main">
        <div className="d-flex justify-content-between align-items-center  mt-4 footer-title">
          {/* {location.pathname === routes.minter ? (
                  <GoldenLogo className="footer-logo" />
               ) : (
                  <img className="footer-logo" src={xidenLogo}></img>
               )} */}
          {/* <img className="footer-logo" src={xidenLogo}></img> */}
          <div className="logo-container-xid d-flex align-items-center justify-content-center flex-row">
            <XidenLogo className="xiden-logo" />
            <MotoGPLogo className="ms-4 moto-gp-logo" />
          </div>

          <div className="social-media">
            {/* <button>
              <img
                src={facebook}
                onClick={() =>
                  window.open("https://www.facebook.com/cryptodatamobileus")
                }
              ></img>
            </button> */}
            <button>
              <img
                src={coinMarket}
                className="coin-market-svg"
                onClick={() =>
                  window.open("https://coinmarketcap.com/currencies/xiden/")
                }
              ></img>
            </button>
            <button>
              <img
                src={twitter}
                onClick={() =>
                  window.open("https://twitter.com/xidenblockchain")
                }
              ></img>
            </button>
            {/* <button>
              <img
                src={linkedin}
                onClick={() =>
                  window.open("https://ro.linkedin.com/company/cryptodatatech")
                }
              ></img>
            </button> */}

            {/* <button>
                     {" "}
                     <img src={reddit} onClick={() => window.open("https://www.reddit.com/")}></img>
                  </button> */}
            <button>
              <img
                src={discord}
                onClick={() =>
                  window.open("https://medium.com/@xiden.blockchain")
                }
              ></img>
            </button>
            <button>
              {" "}
              <img
                src={telegram}
                onClick={() => window.open("https://t.me/xiden_official")}
              ></img>
            </button>
            <button className="d-flex justify-content-center align-items-end">
              <DiscordIcon
                className="discord-icon"
                onClick={() => window.open("https://discord.gg/xtnCgBzK9M")}
              />
            </button>
          </div>
        </div>
        <div className="footer-visibility">
          <div className="darkgrey-color d-flex justify-content-between font-pnr font-size-18 fixed-width margin-top-bottom">
            <ul>
              <li className=" white-color font-pnsb mb-10px">Ecosystem</li>
              <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.xiden)}
              >
                Blockchain
              </li>
              <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.xidenFeatures)}
              >
                Digital Asset XDEN
              </li>
              <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.staking)}
              >
                Consensus
              </li>
              <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.validator)}
              >
                Validator
              </li>
              <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.guardianNode)}
              >
                Guarian Node
              </li>
              <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.minter)}
              >
                Meta Realm
              </li>
              <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.rewards)}
              >
                Rewards
              </li>
              {/* <li className="font-pnl mb-10px">Wiki</li> */}
            </ul>
            <ul>
              <li className="white-color font-pnsb mb-10px">Utility Layers</li>
              <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.sdrLayer)}
              >
                SDR
              </li>
              <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.privateNetwork)}
              >
                Spectralis
              </li>
              <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.apps)}
              >
                DApps
              </li>
            </ul>
            <ul>
              <li
                className="white-color font-pnsb mb-10px"
                onClick={() => navigate(routes.rewards)}
              >
                Resources
              </li>

              <li
                className="font-pnl mb-10px"
                onClick={() => window.open("https://explorer.xiden.com/")}
              >
                Explorer
              </li>
              <li className="font-pnl mb-10px ">
                <a
                  className="no-hov-effect-a darkgrey-color "
                  href={"https://xiden.com/files/whitepaper-xiden.pdf"}
                  target="_blank"
                >
                  Whitepaper
                </a>{" "}
              </li>
              <li
                className="font-pnl mb-10px"
                onClick={() => window.open(XidenLightpaper, "_blank")}
              >
                Lightpaper
              </li>
              <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.changeLog)}
              >
                Changelog
              </li>
              {/* <li className="font-pnl mb-10px" onClick={() => navigate(routes.ecosystem)}>
                        Ecosystem
                     </li> */}
              {/* <li className="font-pnl mb-10px">Bugs</li>
              <li className="font-pnl mb-10px">Docs</li> */}
            </ul>
            <ul>
              <li className="white-color font-pnsb mb-10px">How to</li>
              <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.supportKraterPool)}
              >
                Configure MinteR Guardian
              </li>

              {/* <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.supportStake)}
              >
                Configure KrateR Pool
              </li> */}
              <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.supportValidator)}
              >
                Setup Validator
              </li>
              <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.supportUpdate)}
              >
                Change Minter settings
              </li>
              {/* <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.supportTroubleshoot)}
              >
                Troubleshoot
              </li> */}
              {/* <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.supportHotspot)}
              >
                Setup Hotspot
              </li> */}
              <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.supportMetamask)}
              >
                Setup Network
              </li>
              <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.supportKraterApp)}
              >
                Krater Validator App for Basm
              </li>
              <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.supportGuide)}
              >
                Krater Validator App for Smartphone
              </li>
              {/* <li className="font-pnl mb-10px" onClick={() => navigate(routes.supportReward)}>
                Earn Rewards
              </li> */}
              {/* <li className="font-pnl mb-10px">Bugs</li>
              <li className="font-pnl mb-10px">Docs</li> */}
            </ul>
            {/* <ul> */}

            {/* <li
                className="font-pnl mb-10px "
                onClick={() => navigate(routes.staking)}
              >
                Staking
              </li>
              <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.rewards)}
              >
                Rewards
              </li>
              <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.supportMetamask)}
              >
                Setup Network
              </li>
              <li
                className="font-pnl mb-10px"
                onClick={() => window.open("https://explorer.xiden.com/")}
              >
                Explorer
              </li> */}
            {/* <li className="font-pnl mb-10px">Xiden</li>
              <li className="font-pnl mb-10px">Docs</li> */}
            {/* </ul> */}
            {/* <ul>
              <li className="white-color font-pnsb mb-10px">Ecosystem</li>
              <li className="font-pnl mb-10px">Entities</li>
              <li className="font-pnl mb-10px">Functionality</li>
              <li className="font-pnl mb-10px">Wallets</li>
              <li className="font-pnl mb-10px">Devices</li>
            </ul> */}
            {/* <ul>
              <li className="white-color font-pnsb mb-10px">Resources</li>
              <li className="font-pnl mb-10px">About</li>
              <li className="font-pnl mb-10px">Community</li>
              <li className="font-pnl mb-10px">Press Kit</li>
              <li className="font-pnl mb-10px">Events</li>
            </ul> */}
          </div>
        </div>

        <Accordion className="dark-grey-color accordion-footer">
          <Accordion.Item
            eventKey="0"
            className={`${location.pathname === routes.minter && "meta-realm"}`}
          >
            <Accordion.Header className="white-color font-pnsb mb-10px ">
              <div className="white-color font-pnsb mb-10px ">Ecosystem</div>{" "}
              <Plus className="button-transition" />
            </Accordion.Header>

            <Accordion.Body>
              <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.xiden)}
              >
                Blockchain
              </li>
              <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.xidenFeatures)}
              >
                Digital Asset XDEN
              </li>
              <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.staking)}
              >
                Consensus
              </li>
              <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.validator)}
              >
                Validator
              </li>
              <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.guardianNode)}
              >
                Guardian Node
              </li>
              <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.minter)}
              >
                Meta Realm
              </li>
              <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.rewards)}
              >
                Rewards
              </li>
              {/* <li className="font-pnl mb-10px">Wiki</li> */}
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item
            eventKey="1"
            className={`${location.pathname === routes.minter && "meta-realm"}`}
          >
            <Accordion.Header>
              <div className="white-color font-pnsb mb-10px ">
                Utility Layers
              </div>

              <Plus className="button-transition" />
            </Accordion.Header>

            <Accordion.Body>
              <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.sdrLayer)}
              >
                SDR
              </li>
              <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.privateNetwork)}
              >
                Spectralis
              </li>
              <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.apps)}
              >
                DApps
              </li>
              {/* <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.minter)}
              >
                Meta Realm
              </li> */}
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item
            eventKey="2"
            className={`${location.pathname === routes.minter && "meta-realm"}`}
          >
            <Accordion.Header>
              <div className="white-color font-pnsb mb-10px ">Resources</div>

              <Plus className="button-transition" />
            </Accordion.Header>

            <Accordion.Body>
              <li
                className="font-pnl mb-10px"
                onClick={() => window.open("https://explorer.xiden.com/")}
              >
                Explorer
              </li>
              <li className="font-pnl mb-10px">
                <a
                  className="no-hov-effect-a darkgrey-color "
                  href={"https://xiden.com/files/whitepaper-xiden.pdf"}
                  target="_blank"
                >
                  Whitepaper
                </a>
              </li>
              <li
                className="font-pnl mb-10px"
                onClick={() => window.open(XidenLightpaper, "_blank")}
              >
                Lightpaper
              </li>
              <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.changeLog)}
              >
                Changelog
              </li>
              {/* <li className="font-pnl mb-10px" onClick={() => navigate(routes.ecosystem)}>
                        Ecosystem
                     </li> */}
              {/* <li className="font-pnl mb-10px">Bugs</li>
                     <li className="font-pnl mb-10px">Docs</li> */}
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item
            eventKey="4"
            className={`${location.pathname === routes.minter && "meta-realm"}`}
          >
            <Accordion.Header>
              <div className="white-color font-pnsb mb-10px ">How To</div>

              <Plus className="button-transition" />
            </Accordion.Header>

            <Accordion.Body>
              <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.supportKraterPool)}
              >
                Configure MinteR Guardian
              </li>

              {/* <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.supportStake)}
              >
                Configure KrateR Pool
              </li> */}
              <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.supportValidator)}
              >
                Setup Validator
              </li>
              <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.supportUpdate)}
              >
                Change Minter settings
              </li>
              {/* <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.supportTroubleshoot)}
              >
                Troubleshoot
              </li> */}
              {/* <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.supportHotspot)}
              >
                Setup Hotspot
              </li> */}
              <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.supportMetamask)}
              >
                {" "}
                Setup Network
              </li>
              <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.supportKraterApp)}
              >
                Krater Validator App for Basm
              </li>
              <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.supportGuide)}
              >
                {" "}
                Krater Validator App for Smartphone
              </li>
              {/* <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.supportReward)}
              >
                {" "}
                Earn Rewards
              </li> */}
              {/* <li className="font-pnl mb-10px" onClick={() => navigate(routes.ecosystem)}>
                        Ecosystem
                     </li> */}
              {/* <li className="font-pnl mb-10px">Bugs</li>
                     <li className="font-pnl mb-10px">Docs</li> */}
            </Accordion.Body>
          </Accordion.Item>

          {/* <Accordion.Item
            eventKey="3"
            className={`${location.pathname === routes.minter && "meta-realm"}`}
          >
            <Accordion.Header
              onClick={() => window.open("https://explorer.xiden.com/")}
            >
              <div className="white-color font-pnsb mb-10px ">Explorer</div>

              <Plus className="button-transition" />
            </Accordion.Header> */}

          {/* <Accordion.Body>
              <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.staking)}
              >
                Staking
              </li>
              <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.rewards)}
              >
                Rewards
              </li>
              <li
                className="font-pnl mb-10px"
                onClick={() => window.open("https://explorer.xiden.com/")}
              >
                Explorer
              </li>
              <li
                className="font-pnl mb-10px"
                onClick={() => navigate(routes.supportMetamask)}
              >
                Network Setup
              </li> */}
          {/* <li className="font-pnl mb-10px">Xiden</li>
                     <li className="font-pnl mb-10px">Docs</li> */}
          {/* </Accordion.Body> */}
          {/* </Accordion.Item> */}
          {/* <Accordion.Item eventKey="4">
                  <Accordion.Header className=" ">
                     <div className="white-color font-pnsb mb-10px ">Ecosystem</div>

                     <Plus className="button-transition" />
                  </Accordion.Header>

                  <Accordion.Body>
                     <li className="font-pnl mb-10px">Entities</li>
                     <li className="font-pnl mb-10px">Functionality</li>
                     <li className="font-pnl mb-10px">Wallets</li>
                     <li className="font-pnl mb-10px">Devices</li>
                  </Accordion.Body>
               </Accordion.Item> */}
          {/* <Accordion.Item eventKey="5">
                  <Accordion.Header className="">
                     <div className="white-color font-pnsb mb-10px ">Resources</div>
                     <Plus className="button-transition" />
                  </Accordion.Header>

                  <Accordion.Body>
                     <li className="font-pnl mb-10px">About</li>
                     <li className="font-pnl mb-10px">Community</li>
                     <li className="font-pnl mb-10px">Press Kit</li>
                     <li className="font-pnl mb-10px">Events</li>
                  </Accordion.Body>
               </Accordion.Item> */}
        </Accordion>
        <div className="d-flex align-items-center justify-content-between footer-final-section darkgrey-color font-pnr font-size-16">
          <p>
            Copyright <span className="blue-color">©</span> 2022 Xiden
          </p>

          <div className="footer-list">
            <ul className="d-flex justify-content-around align-items-center utility-space">
              <li
                className="privacy-policy mr-4"
                onClick={() => navigate(routes.dataPrivacy)}
              >
                Privacy Notice
              </li>

              <img src={linemiddle} alt="separator" className="mx-2" />

              <li
                className="terms-of-use"
                onClick={() => navigate(routes.termsOfUse)}
              >
                Terms and conditions
              </li>
              {/* {location.pathname === routes.minter ? (
                        <LineGoldIcon className="mx-2" />
                     ) : (
                        <img src={linemiddle} alt="separator" className="mx-2" />
                     )}

                     <li className="user-agreements ml-4" onClick={() => navigate(routes.userAgreements)}>User Agreements</li> */}
            </ul>
          </div>

          <div className="d-flex justify-content-between utility-space-two sitemap-and-language">
            <p className="mr-4">
              {" "}
              {location.pathname === routes.minter ? (
                <SitemapGoldIcon className="sitemap-icon me-2" />
              ) : (
                <img className="sitemap-icon me-2" src={sitemapicon}></img>
              )}
              Sitemap
            </p>

            <p className="ml-4">
              {" "}
              {location.pathname === routes.minter ? (
                <LanguageGoldIcon className="lang-icon me-2" />
              ) : (
                <img className="lang-icon me-2" src={languageicon}></img>
              )}
              English
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
