import React from "react";
import "./FeatureCard.scss";
import Uppertitle from "../Uppertitle/Uppertitle";

const FeatureCard = ({ cardData }) => {
   const matchCardBackground = cardId => {
      switch (cardId) {
         case 1:
            return "internet-card-bg";

         case 3:
            return "connection-card-bg";

         case 6:
            return "security-card-bg";

         case 8:
            return "price-card-bg";
      }
   };

   return (
      <div className={`text-white feature-card ${matchCardBackground(cardData.id)} ${cardData.id === 5 && "bg5"} ${cardData.id === 6 && "bg6"} ${cardData.id === 7 && "bg7"} ${cardData.id === 8 && "bg8"}`}>
         <Uppertitle title={cardData.upperTitle} cards />

         <div className="card-content">
            <div className="mb-3">{cardData.icon}</div>

            <h4 className="font-pnb feature-card-title">
               {cardData.title}
               <span className="purple-color">.</span>
            </h4>
         </div>
      </div>
   );
};

export default FeatureCard;
