import React, { useRef } from "react";
import FeaturesCardXden from "./FeaturesCardXden";
import Coins from "../../../assets/images/png/xiden-features-coins-bg.png";
import Splash from "../../../assets/images/png/xiden-features-card-img.png";
import Cube from "../../../assets/images/png/XID-features-page/cryptographic-cube.png";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { routes } from "../../../Utils/routes";

const FeaturesMobileSection = () => {
  //  Refs
  const sliderRef = useRef(null);

  // navigate
  const navigate = useNavigate();

  // functions
  //  // slider function start
  let isDown = false;
  let startX;
  let scrollLeft;

  const mouseLeaveFunction = () => {
    isDown = false;
  };
  const mouseDownFunction = (e) => {
    isDown = true;
    startX = e.pageX - sliderRef.current.offsetLeft;
    scrollLeft = sliderRef.current.scrollLeft;
  };
  const mouseUpFunction = () => {
    isDown = false;
  };
  const touchFunction = () => {
    isDown = false;
  };
  const mouseMoveFunction = (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - sliderRef.current.offsetLeft;
    const walk = (x - startX) * 1; //scroll-fast
    sliderRef.current.scrollLeft = scrollLeft - walk;
  };
  // slider function end

  const handleNavigation = (url, toastId) => {
    if (url) navigate(url);
    else toast.info("Coming soon...", { toastId: toastId });
  };

  return (
    <section id="features-xiden-section-mobile" className="page-section">
      <div className="container-main ">
        <h2 className="title font-size-80 white-color font-pnb mb-0">
          Features
          <span className="purple-color">.</span>
        </h2>

        <div className="position-relative slider-height">
          <div
            ref={sliderRef}
            className="descriptions-container slider-div grab"
            onMouseDown={(e) => mouseDownFunction(e)}
            onMouseLeave={() => mouseLeaveFunction()}
            onMouseUp={() => mouseUpFunction()}
            onMouseMove={(e) => mouseMoveFunction(e)}
            onTouchStart={() => touchFunction()}
          >
            <FeaturesCardXden
              title="Free for exchange"
              imageUrl={Coins}
              description={`Free for exchange`}
              bg="bg1"
              onNavigation={() => handleNavigation("", 0)}
            />

            <FeaturesCardXden
              title="Owner"
              description="You are sole owner"
              bg="bg2"
              onNavigation={() => handleNavigation("", 1)}
            />

            <FeaturesCardXden title="Open" description="Open to everyone" bg="bg3" onNavigation={() => handleNavigation("", 2)} />

            <FeaturesCardXden
              title="Secured"
              imageUrl={Cube}
              description={`Cryptographic secured`}
              customClass
              bg="bg5"
              onNavigation={() => handleNavigation(routes.privateNetwork)}
            />

            <FeaturesCardXden
              title="Validation"
              imageUrl={Coins}
              description={`Automatically validated by the network`}
              bg="bg4"
              onNavigation={() => handleNavigation(routes.validator)}
            />

            <FeaturesCardXden
              title="Decentralized"
              imageUrl={Splash}
              description={`Decentralized`}
              bg="bg6"
              onNavigation={() => handleNavigation("", 3)}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesMobileSection;
