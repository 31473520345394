import React from "react";
import "./HeroSection.scss";
import PerfGuardianImage from "../../../assets/images/png/perf-guard.png";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import { ReactComponent as ArrowRight } from "../../../assets//images/svg/arrow-right.svg";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../Utils/routes";

const HeroSection = () => {
   const navigate = useNavigate();

   return (
      <section id="performer-guardian-hero" className="page-section">
         <div className="container-main ">
            <div className="main-col">
               <Uppertitle title={`PERFORMER`} />
               <h2 className="mb-0 font-size-80 white-color font-pnb">PerformeR</h2>
               <h2 className="font-size-80 white-color font-pnb">
                  Guardian
                  <span className="purple-color">.</span>
               </h2>
               <div className="my-4 paragraph-container">
                  <p className="font-family-pnr font-size-24 lightgrey-color">
                     The performer guardians are validator nodes of the devices identity that executes the validation
                     function of the network data integrity.
                  </p>
               </div>
               <div className="hero-btn-container">
                  <button className="buttonBlue" onClick={() => navigate(routes.staking)}>
                     <span className="me-2">Begin staking</span>
                     <ArrowRight />
                  </button>
               </div>
            </div>

            <div className="section-image-col">
               <img
                  src={PerfGuardianImage} loading="lazy" 
                  alt="xid coin"
                  className="section-image img-fluid"
                  style={{ maxWidth: "700px" }}
               />
            </div>
         </div>
      </section>
   );
};

export default HeroSection;
