import React from "react";
import "./HeroSection.scss";
// import PerfGuardianImage from "../../../assets/images/png/guardian-node-hero-img.png";
import ConnectionImage from "../../../assets/images/png/guardian-node-page/HeroImg.png";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import { AiOutlineArrowRight as ArrowRight } from "react-icons/ai";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../Utils/routes";
import { Link as ScrollLink } from "react-scroll";
import { toast } from "react-toastify";

const HeroSection = () => {
  const navigate = useNavigate();

  const handleBeginStaking = (event) => {
    event.preventDefault();
    toast.info("Coming soon!", { toastId: "begin-staking" });
  };

  return (
    <div className="page-section hero-section-guardian-node">
      <div className="container-main hero-section-container">
        <Row className="flex-lg-row flex-column-reverse m-auto">
          <Col lg={5}>
            <div className="h-100 d-flex flex-column justify-content-center align-items-lg-start align-items-center">
              <div className="minter-guardian-uppertitle-container">
                <Uppertitle title={`PERFORMER`} />
              </div>
              <div className="node-title-container">
                <h2 className="mb-0 font-size-80 white-color mb-0 font-pnb line-height-11 text-center-991">Minter</h2>
                <h2 className="mb-0 font-size-80 white-color mb-0 font-pnb line-height-11 text-center-991">
                  Guardian Node
                  <span className="purple-color">.</span>
                </h2>
              </div>
              <div className="py-lg-5 py-4 paragraph-container">
                <p className="font-family-pnr font-size-24 lightgrey-color text-center-991 line-height-11">
                  The Guardian Node validates the other devices registered within the network including configured Decentralized
                  Private Networks using the Router.
                </p>
              </div>
              <div className="buttons-flex-direction w-100 d-flex justify-content-lg-start justify-content-center align-items-center">
                {/* <ScrollLink className="buttonBlue" to="guardian-node-card-section-1" spy={true}>
                           <span className="me-2">Begin staking</span>
                           <ArrowRight />
                        </ScrollLink> */}

                <button className="buttonBlue" onClick={handleBeginStaking}>
                  <span className="me-2">Begin staking</span>
                  <ArrowRight />
                </button>
              </div>
            </div>
          </Col>
          <Col lg={7} className="d-flex justify-content-center align-items-center">
            {/* <img src={PerfGuardianImage} alt="xid coin" className="img-fluid staking-hero-img pb-lg-0 pb-4" /> */}
            <img src={ConnectionImage} loading="lazy" alt="xid coin" className="img-fluid staking-hero-img pb-lg-0 pb-4" />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default HeroSection;
