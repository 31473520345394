import "./XidenAttributes.scss";
import { Col, Row } from "react-bootstrap";
import Uppertitle from "../../components/Uppertitle/Uppertitle";
import Gas from "../../../src/assets/images/png/features-blue-gas-coin.png";
import Attr1 from "../../../src/assets/images/png/XID-features-page/XID_attr_1.png";
import Attr2 from "../../../src/assets/images/png/XID-features-page/XID_attr_2.png";
import Attr3 from "../../../src/assets/images/png/XID-features-page/XID_attr_3.png";
import { ReactComponent as ArrowRight } from "../../../src/assets/images/svg/arrow-right.svg";
import { toast } from "react-toastify";

const XidenAttributes = () => {
  return (
    <section id="xiden-attributes" className="page-section">
      <div className="container-main">
        <div className="xiden-section-container">
          <div className="xdn-attributes-container">
            <Uppertitle title="XDEN" />
            <h2 className=" pb-2 font-size-80 white-color font-pnb">
              XDEN attributes<span className="purple-color">.</span>
            </h2>
            <p className="font-pnr font-size-24 lightgrey-color xiden-spacer description-991 line-height-11">
              Discover the attributes of the XDEN token.
            </p>

            <Row>
              <Col xl={6} className="d-flex flex-column justify-content-between">
                <div className="d-flex py-lg-5 p-4 xiden-background align-items-center">
                  <Row className="w-100">
                    <Col
                      sm={4}
                      md={4}
                      lg={3}
                      xl={4}
                      className="d-flex align-items-center align-content-center justify-content-cennter"
                    >
                      <div className="img-container">
                        <img className="xiden-container-img" src={Attr3} alt="" />
                      </div>
                    </Col>
                    <Col
                      sm={8}
                      md={8}
                      lg={9}
                      xl={8}
                      className="d-flex align-items-center align-content-center justify-content-cennter"
                    >
                      <div className="special-margin">
                        <h4 className="white-color font-pnb font-size-32 mb-0 pb-4">
                          {" "}
                          {/* put pb-4 after inserting paragraph */}
                          Not sold by company or developers<span className="purple-color">.</span>
                        </h4>
                        <p className="font-pnr lightgrey-color font-size-20 line-height-11">
                          We don’t sell the XDEN token to companies, but users that acquire XDEN via unlocking or distributing can
                          transfer it between them or can put it on public exchanges.
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="d-flex py-lg-5 p-4 xiden-background align-items-center">
                  <Row className="w-100">
                    <Col
                      sm={4}
                      md={4}
                      lg={3}
                      xl={4}
                      className="d-flex align-items-center align-content-center justify-content-cennter"
                    >
                      <div className="img-container">
                        <img className="xiden-container-img" src={Attr2}></img>
                      </div>
                    </Col>
                    <Col
                      sm={8}
                      md={8}
                      lg={9}
                      xl={8}
                      className="d-flex align-items-center align-content-center justify-content-cennter"
                    >
                      <div className="special-margin">
                        <h4 className="white-color font-pnb font-size-32 mb-0 pb-4">
                          {" "}
                          {/* put pb-4 after inserting paragraph */}
                          It can be acquired only by unlocking and distributing<span className="purple-color">.</span>
                        </h4>
                        <p className="font-pnr lightgrey-color font-size-20 line-height-11">
                          XDEN tokens can be acquired by mining through the devices integrated into the Xiden blockchain. Users
                          that make their resources available for the network will unlock XDEN tokens.
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="d-flex py-lg-5 p-4 xiden-background align-items-center">
                  <Row className="w-100">
                    <Col
                      sm={4}
                      md={4}
                      lg={3}
                      xl={4}
                      className="d-flex align-items-center align-content-center justify-content-cennter"
                    >
                      <div className="img-container">
                        <img className="xiden-container-img" src={Attr1}></img>
                      </div>
                    </Col>
                    <Col
                      sm={8}
                      md={8}
                      lg={9}
                      xl={8}
                      className="d-flex align-items-center align-content-center justify-content-cennter"
                    >
                      <div className="special-margin">
                        <h4 className="white-color font-pnb pb-4 font-size-32 mb-0">
                          Developers built only the technology architecture<span className="purple-color">.</span>
                        </h4>
                        <p className="font-pnr lightgrey-color font-size-20 line-height-11">
                          Our developers created the technology’s architecture to facilitate the unlocking of digital assets for
                          users.
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xl={6}>
                <div className="gas-container d-flex justify-content-center flex-column p-lg-5 p-4 corners">
                  <img className="gas-container-img img-fluid" src={Gas} alt="gas and coin"></img>
                  <div className="content-container">
                    <Uppertitle title="GAS" />
                    <h2 className="sect-title pb-3 font-size-56 white-color font-pnb">
                      Gas
                      <span className="purple-color">.</span>
                    </h2>
                    <p className="font-pnr font-size-20 lightgrey-color line-height-11">
                      The gas fees from the validator nodes will be distributed to miners depending on the power and the time
                      spent online. Public rewards will be divided, and the initial amount will be distributed to all the online
                      devices during the first Age in order to generate liquidity in the market.
                    </p>
                    <button
                      className="read-more-btn font-pnsb font-size-20 d-flex blue-color align-items-center"
                      onClick={() => toast.info("Coming soon...", { toastId: "113dkerjfuhrtg" })}
                    >
                      Read more
                      <ArrowRight className="xiden-arrow-svg" />
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </section>
  );
};

export default XidenAttributes;
