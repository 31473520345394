import React, { useState } from "react";
import "./SupportSteps.scss";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import { AiOutlineArrowRight as ArrowRight } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import SupportStepsUpdate from "./SupportStepsUpdate";
import SupportStepsUnpair from "./SupportStepsUnpair";
import SupportStepsRename from "./SupportStepsRename";
import SupportStepsChangePassword from "./SupportStepsChangePassword";


const SupportStepsSwitch = () => {
    const navigate = useNavigate();

    const [activeSteps, setActiveStep] = useState("");

    const renderSteps = (step) => {
        switch (step) {
            case 'update':
                return <SupportStepsUpdate />

            case 'rename':
                return <SupportStepsRename />

            case 'unpair':
                return <SupportStepsUnpair />

            case 'password':
                return <SupportStepsChangePassword />

            case '':
                return <h2 className="font-pnsb font-size-24 text-center mt-5">Select one instruction set in order to see the associate steps.</h2>

            default:
                return <SupportStepsUpdate />
        }
    }

    return (
        <section id="guardian-steps-section" className="reward-steps-section switch-section">
            <div className="page-section text-white">
                <div className="container-main">

                    <h2 className="font-pnsb font-size-32 text-center">Please select the instructions set you need from the list below:</h2>
                    <div className="d-flex flex-wrap align-items-center justify-content-center">
                        <button onClick={() => setActiveStep('update')} className={`buttonBlue mt-3 mx-2 d-flex align-items-center justify-content-center ${activeSteps === 'update' && 'buttonBlueActive'}`}>How to update</button>
                        <button onClick={() => setActiveStep('rename')} className={`buttonBlue mt-3 mx-2 d-flex align-items-center justify-content-center ${activeSteps === 'rename' && 'buttonBlueActive'}`}>How to rename</button>
                        <button onClick={() => setActiveStep('unpair')} className={`buttonBlue mt-3 mx-2 d-flex align-items-center justify-content-center ${activeSteps === 'unpair' && 'buttonBlueActive'}`}>How to unpair</button>
                        <button onClick={() => setActiveStep('password')} className={`buttonBlue mt-3 mx-2 d-flex align-items-center justify-content-center ${activeSteps === 'password' && 'buttonBlueActive'}`}>How to change password</button>
                    </div>

                    {
                        renderSteps(activeSteps)
                    }
                </div>
            </div>
        </section>
    );
};

export default SupportStepsSwitch;
