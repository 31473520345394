import React from "react";
import { Col, Row } from "react-bootstrap";
import "./HumanCards.scss";
import PhoneImg from "../../../assets/images/png/private-network-page/xiden-app.png";
import RouterImg from "../../../assets/images/png/private-network-page/routerCard.png";
import { ReactComponent as DownloadImg } from "../../../assets/images/svg/SpectralisPrivateNetworkPage/downloading.svg";
import { ReactComponent as BagImg } from "../../../assets/images/svg/SpectralisPrivateNetworkPage/shopping-bag.svg";
import { ReactComponent as ArrowRight } from "../../../assets//images/svg/arrow-right.svg";
import { toast } from "react-toastify";

const HumanCards = () => {
  return (
    <section id="human-cards-section" className="page-section">
      <div className="container-main">
        <Row className="m-auto justify-content-center">
          <Col lg={6} className="ps-0 custom-padding">
            <div className="ms-auto column-wrapper p-4 p-lg-5 position-relative">
              <Row className="h-100 minimum-height custom-flex-1">
                <Col className="col-6">
                  <div className="h-100 content-container d-flex flex-column justify-content-between">
                    <DownloadImg className="mb-5 mb-lg-0 svg-img" />
                    <h2 className="font-size-40 font-pnb mb-5 mb-lg-0 line-height-11 custom-min-width">
                      Download <br />
                      Spectralis <br />
                      App and <br />
                      connect <br /> automatically <br />
                      to any hotspot.
                      {/* Download the <br />
                                            Spectralis App <br />
                                            to connect automatically <br />
                                            to any registered router. <br /> */}
                    </h2>
                    <button
                      onClick={() => toast.info("Coming soon...", { toastId: "iuioyuaa" })}
                      className="btn-get-started font-size-20 font-pnsb blue-color d-flex align-content-center align-items-center justify-content-start"
                    >
                      <div>Download app</div>
                      <ArrowRight />
                    </button>
                  </div>
                </Col>
                <Col className="col-6 d-flex align-items-center justify-content-start justify-content-sm-center">
                  <img src={PhoneImg} alt="Phone image with xiden app" className="img-fluid card-img" />
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={6} className="mt-4 mt-lg-0 pe-0 custom-padding">
            <div className="me-auto column-wrapper bg-card p-4 p-lg-5 ">
              <Row className="h-100 minimum-height custom-flex-2">
                <Col className="col-5 d-flex align-items-center justify-content-start justify-content-sm-center">
                  <img src={RouterImg} alt="Minter router" className="img-fluid card-img" />
                </Col>
                <Col className="col-7">
                  <div className="h-100 content-container custom-align d-flex flex-column justify-content-between align-items-end">
                    <BagImg className="mb-5 mb-md-0 svg-img" />
                    <h2 className="font-size-40 font-pnb text-right mb-5 mb-md-0 line-height-11 custom-min-width">
                      Own a Minter <br />
                      router to open <br />
                      a Hotspot and <br />
                      get users <br />
                      to connect.
                    </h2>
                    <button
                      onClick={() => window.open("https://cryptodata.com/product/minter")}
                      className="btn-get-started font-size-20 font-pnsb blue-color d-flex align-content-center align-items-center justify-content-start"
                    >
                      <div>Purchase Minter</div>
                      <ArrowRight />
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default HumanCards;
