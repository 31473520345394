import React from "react";
import "./InfoCards.scss";
import { Row, Col } from "react-bootstrap";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import { ReactComponent as VerifyIcon } from "../../../assets/images/svg/verify.svg";
import { ReactComponent as BoosterIcon } from "../../../assets/images/svg/booster.svg";
import { ReactComponent as CoinsIcon } from "../../../assets/images/svg/coins.svg";
import { ReactComponent as CubeIcon } from "../../../assets/images/svg/transaction.svg";
import { ReactComponent as LockIcon } from "../../../assets/images/svg/node.svg";

const InfoCards = () => {
  return (
    <div className="page-section rewards-info-section">
      <div className="container-main">
        <Row className="">
          <Col md={6} lg={4}>
            <div className="me-4 box small-box">
              <Uppertitle title={`PROOF OF STAKE`} />
              <VerifyIcon className="card-icon" />
              <div className="font-pnb line-height-11 titleSize complex-text-container">
                <p className="mb-0">Stake</p>
                <p className="mb-0 darkgrey-color">your tokens</p>
                <p className="mb-0">to increase</p>
                <p className="mb-0">your</p>
                <p className="mb-0">
                  earnings<span className="pink-text">.</span>
                </p>
              </div>
            </div>
          </Col>

          <Col md={6} lg={4}>
            <div className="me-4 box small-box special-bg-1">
              <Uppertitle title={`PROOF OF CONNECTIVITY`} />
              <BoosterIcon className="card-icon" />
              <div className="font-pnb line-height-11 titleSize complex-text-container">
                <p className="mb-0">Ensure</p>
                <p className="mb-0">system's</p>
                <p className="mb-0">devices are</p>
                <p className="mb-0 pinkGradientText gradient-text">genuine and</p>
                <p className="mb-0 pinkGradientText gradient-text">
                  connected<span className="pink-text">.</span>
                </p>
              </div>
            </div>
          </Col>

          <Col md={12} lg={4}>
            <div className="box small-box">
              <Uppertitle title={`PROOF OF EXISTANCE`} />
              <CoinsIcon className="card-icon" />
              <div className="font-pnb line-height-11 titleSize complex-text-container">
                <p className="mb-0">Ensure data</p>
                <p className="mb-0 darkgrey-color">is associated</p>
                <p className="mb-0 darkgrey-color">with</p>
                <p className="mb-0">timestamp</p>
                <p className="mb-0">
                  signature<span className="pink-text">.</span>
                </p>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={4} md={6}>
            <div className="me-4 box small-box special-bg-2">
              <Uppertitle title={`TRANSACTION VALIDATION`} />
              <CubeIcon className="card-icon" />
              <div className="font-pnb line-height-11 titleSize complex-text-container">
                <p className="mb-0">Verify data</p>
                <p className="mb-0 darkgrey-color">integrity and</p>
                <p className="mb-0">
                  get rewards<span className="pink-text">.</span>
                </p>
              </div>
            </div>
          </Col>

          <Col lg={8} md={6}>
            <div className="box large-box ">
              <Uppertitle title={`VALIDATION NODE`} />
              <div>
                <LockIcon className="card-icon lock-icon" />
              </div>
              <div className="font-pnb line-height-11 large-box-titleSize complex-text-container">
                <p className="mb-0">Every validator node</p>
                <p className="mb-0">receives 1000 XDEN</p>
                <p className="mb-0 transparent-darkgrey-color">
                  in a locked state<span className="pink-text">.</span>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default InfoCards;
