import React from "react";
import DisclaimerCard from "./DisclaimerCard/DisclaimerCard";
import HeroSection from "./HeroSection/HeroSection";
import SupportSteps from "./SupportValidatorSteps/SupportSteps";

export default function SupportValidator() {
  return (
    <>
      <HeroSection></HeroSection>
      <DisclaimerCard />
      <SupportSteps></SupportSteps>
    </>
  );
}
