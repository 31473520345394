import React from "react";
import "../Proprietary.scss";
import Slider from "../../../../components/Slider/Slider";
import ProprietaryMobileCard from "./ProprietaryMobileCard";
import { proprietaryData } from "./proprietaryData";

const ProprietaryMobile = () => {
  return (
    <div className="d-flex d-lg-none">
      <div className="w-100 proprietary-slider">
        <Slider>
          {proprietaryData.map((card, index) => {
            return (
              <ProprietaryMobileCard
                key={index}
                uppertitle={card.uppertitle}
                description={card.description}
                svgProp={card.svgProp}
                bg={card.bg}
              />
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default ProprietaryMobile;
