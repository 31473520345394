import FourKrater from '../SupportKraterSteps/assets-steps/krater/step4.png';

export const unstakeSteps = [
    {
        uppertitle: "STEP 01",
        title: "Access your wallet and press unstake",
        punctuation: ".",
        content: <>
            <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                Access your wallet and press the ''Unstake'' button to end your staking period. If you choose to Unstake you will not receive staking rewards anymore.
            </p>
        </>,
        image: FourKrater
    }];