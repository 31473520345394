import React from "react";
import "./HeroSection.scss";
import AppsImage from "../../../assets/images/png/apps.png";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import DappsVideo from "../../../assets/videos/dapps-page/dapps-video.mp4";
import { ReactComponent as ArrowRight } from "../../../assets/images/svg/arrow-right.svg";
import { ReactComponent as ArrowRightUp } from "../../../assets/images/svg/arrow-right-up.svg";
import { routes } from "../../../Utils/routes";
import { useNavigate } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

import { toast } from "react-toastify";

const HeroSection = () => {
  const navigate = useNavigate();

  const handleBuildApps = (event) => {
    event.preventDefault();
    toast.info("Coming soon!", { toastId: "guardian-node-router" });
  };

  return (
    <section className="daps-hero">
      <div className="hero-apps page-section">
        <div className="container-main">
          <div className="main-col">
            <Uppertitle title={`DAPPS`} />
            <h2 className="mb-0 font-size-80 pb-3 white-color font-pnb">
              Decentralized
              <br />
              Applications
              <span className="purple-color">.</span>
            </h2>
            <div className="paragraph-container">
              <p className="font-family-pnr py-lg-4 py-3 font-size-24 lightgrey-color line-height-11">
                DApps are a growing movement of decentralized tools and programs that use Xiden to disrupt business models or
                invent new ones.
              </p>
            </div>
            <div className="buttons-flex-direction w-100 d-flex justify-content-start align-items-center">
              <ScrollLink className="buttonBlue" to="dapps-types-section" spy={true}>
                <span>Explore DApps</span>
                <ArrowRight className="ms-2" />
              </ScrollLink>

              {/* <ScrollLink className="buttonOutlineBlue" to="builds-apps-section" spy={true}>
                        <span>Build DApps</span>
                        <ArrowRightUp className="ms-2" />
                     </ScrollLink> */}

              <button className="buttonOutlineBlue" onClick={handleBuildApps}>
                <span className="me-2">Build DApps</span>
                <ArrowRightUp />
              </button>
            </div>
          </div>

          <div className="section-image-col">
            {/* <img src={AppsImage} loading="lazy" alt="xid coin" className="section-image img-fluid" /> */}
            <video src={DappsVideo} autoPlay playsInline muted loop={true} className="section-image img-fluid" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
