import React from "react";
import "./GuardianSteps.scss";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
// import DepositImage from "../../../assets/images/png/blue-coins.png";
// import DepositImage from "../../../assets/images/png/guardian-node-page/blue-coins.png";
import DepositImage from "../../../assets/images/png/guardian-node-page/depositImage.png";
// import OnlineImage from "../../../assets/images/png/staking-online.png";
import OnlineImage from "../../../assets/images/png/guardian-node-page/staking-online.png";
import ConfigureImage from "../../../assets/images/png/guardian-node-page/router-guardian-node.png";
// import HotspotImage from "../../../assets/images/png/guardian-node-booster-devices.png";
import HotspotImage from "../../../assets/images/png/guardian-node-page/guardian-node-booster-devices.png";
// import KraterImage from "../../../assets/images/png/guard-connection.png";
import KraterImage from "../../../assets/images/png/guardian-node-page/guard-krater.png";
import RouterValidateImage from "../../../assets/images/png/how-guardian-works/router-validate.png";
import WalletImage from "../../../assets/images/png/staking/wallet.jpg";
import { AiOutlineArrowRight as ArrowRight } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../Utils/routes";
import { HashLink } from "react-router-hash-link";
import { Link as ScrollLink } from "react-scroll";
import { toast } from "react-toastify";

const GuardianSteps = () => {
  const navigate = useNavigate();

  return (
    <section id="guardian-steps-section">
      <div className="page-section text-white">
        <div className="container-main">
          {/* heading */}
          <div className="heading-container">
            <Uppertitle title={`ROUTER`} />
            <div className="line-height-11">
              <h2 className="mb-0 font-size-80 font-pnb line-height-11">
                How it works<span className="purple-color">.</span>
              </h2>
            </div>
          </div>

          {/* cards */}
          <div className="mt-2 mt-lg-5">
            {/* configure */}
            <div className="corners hts-card p-lg-5 p-4">
              <div className="rotate-uppertitle">
                <Uppertitle title={"STEP 01"} />
              </div>
              <div className="description-col marginRight hts-card-col reverse-description">
                <h4 className="mb-2 font-size-40 font-pnb text-start line-height-11">
                  Configure router<span className="purple-color">.</span>
                </h4>
                <div className="hts-p-container">
                  <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                    Setup your device as a normal device and login into your Xiden account to bring it to life. By following the
                    steps you will be able to be part of the Xiden decentralized network.
                  </p>
                </div>
                <div className="link-container w-100 d-flex align-items-center justify-content-start blue-color line-height-11">
                  <a
                    onClick={() => toast.info("Coming soon!", { toastId: "guardian-node-router3" })}
                    className="font-pnsb font-size-20 blue-color me-lg-5 me-4 pointer"
                  >
                    Learn more <ArrowRight />
                  </a>
                </div>

                {/* <HashLink className="link-container d-flex align-items-center justify-content-start blue-color line-height-11" smooth to={`${routes.validator}/#howitworks-validator`}>
                  <div className="font-pnsb font-size-20 blue-color pointer guardian-hover">
                    Learn more <ArrowRight />
                  </div>
                </HashLink> */}
              </div>
              <div className="d-flex justify-content-center align-items-center hts-card-col reverse-img-col">
                <img src={ConfigureImage} loading="lazy" alt="router" className="hts-image img-fluid mb-md-0 mb-5" />
              </div>
            </div>

            {/* kreater pool */}
            <div className="corners hts-card p-lg-5 p-4">
              <div className="rotate-uppertitle right-0">
                <Uppertitle title={"STEP 02"} />
              </div>
              <div className="d-flex justify-content-center align-items-center marginRight hts-card-col">
                <img
                  src={KraterImage}
                  loading="lazy"
                  alt="transactions"
                  className="hts-image img-fluid mb-md-0 mb-5 krater-image"
                />
              </div>
              <div className="description-col hts-card-col">
                <h4 className="mb-2 font-size-40 font-pnb line-height-11">
                  Open KrateR Pool<span className="purple-color">.</span>
                </h4>
                <div className="hts-p-container">
                  <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                    In your Xiden account, open your Krater Pool and connect all your available smart devices in order to unlock
                    your XDEN amount.
                  </p>
                </div>
                <div className="link-container w-100 d-flex align-items-center justify-content-start blue-color">
                  <a
                    onClick={() => toast.info("Coming soon!", { toastId: "guardian-node-router43" })}
                    className="font-pnsb font-size-20 blue-color guardian-hover"
                  >
                    Learn more <ArrowRight />
                  </a>
                  {/* <ScrollLink className="font-pnsb font-size-20 blue-color guardian-hover" to="extra-steps-section" spy={true}>
                    Learn more <ArrowRight />
                  </ScrollLink> */}

                  {/* <a href="#" className="font-pnsb font-size-20 blue-color">
                           Create wallet <ArrowRightUp />
                        </a> */}
                </div>
              </div>
            </div>

            {/* activate */}
            <div className="corners hts-card p-lg-5 p-4">
              <div className="rotate-uppertitle">
                <Uppertitle title={"STEP 03"} />
              </div>
              <div className="description-col marginRight hts-card-col reverse-description">
                <div className="mb-2 font-size-40 font-pnb line-height-11">
                  <p className="mb-0">Stake 1000 XDEN to</p>{" "}
                  <p className="mb-0">
                    activate your pool<span className="purple-color">.</span>
                  </p>
                </div>
                <div className="hts-p-container">
                  <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                    To start the staking process make sure you have the predetermined amount of 1000 XDEN.
                  </p>
                </div>
                <div className="link-container w-100 d-flex align-items-center justify-content-start blue-color">
                  <a
                    onClick={() => toast.info("Coming soon!", { toastId: "guardian-node-routera3" })}
                    className="font-pnsb font-size-20 blue-color me-lg-5 me-4"
                  >
                    Learn more <ArrowRight />
                  </a>
                  {/* <a href="#" className="font-pnsb font-size-20 blue-color">
                           Buy XDEN <ArrowRightUp />
                        </a> */}
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center hts-card-col reverse-img-col">
                <img
                  src={DepositImage}
                  loading="lazy"
                  alt="transactions"
                  className="hts-image coins-img img-fluid mb-md-0 mb-5"
                />
              </div>
            </div>

            {/* online */}
            <div className="corners hts-card p-lg-5 p-4">
              <div className="rotate-uppertitle right-0">
                <Uppertitle title={"STEP 04"} />
              </div>
              <div className="d-flex justify-content-center align-items-center marginRight hts-card-col">
                <img src={OnlineImage} loading="lazy" alt="transactions" className="hts-image img-fluid mb-md-0 mb-5" />
              </div>
              <div className="description-col hts-card-col">
                <h4 className="mb-2 font-size-40 font-pnb line-height-11">
                  Keep pool online<span className="purple-color">.</span>
                </h4>
                <div className="hts-p-container">
                  <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                    Maintain a permanent connection with the network through your Router and integrated devices.
                  </p>
                </div>

                <div className="link-container w-100 d-flex align-items-center justify-content-start blue-color">
                  <a
                    onClick={() => toast.info("Coming soon!", { toastId: "guardian-node-router311" })}
                    className="font-pnsb font-size-20 blue-color pointer"
                  >
                    Learn more <ArrowRight />
                  </a>
                </div>
              </div>
            </div>

            {/* validating */}
            <div className="corners hts-card p-lg-5 p-4">
              <div className="rotate-uppertitle">
                <Uppertitle title={"STEP 05"} />
              </div>
              <div className="description-col marginRight hts-card-col reverse-description">
                <h4 className="mb-2 font-size-40 font-pnb line-height-11">
                  Start validating<span className="purple-color">.</span>
                </h4>
                <div className="hts-p-container">
                  <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                    To perform validations, a Krater Pool must have an amount of 1000 XDEN in the staking wallet.
                  </p>
                </div>
                <div className="link-container w-100 d-flex align-items-center justify-content-start blue-color">
                  <a
                    className="font-pnsb font-size-20 blue-color guardian-hover"
                    onClick={() => toast.info("Coming soon!", { toastId: "guardian-node-router3321" })}
                  >
                    Learn more <ArrowRight />
                  </a>
                  {/* <a href="#" className="font-pnsb font-size-20 blue-color">
                           Buy XDEN <ArrowRightUp />
                        </a> */}
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center hts-card-col reverse-img-col">
                <img src={RouterValidateImage} loading="lazy" alt="transactions" className="hts-image img-fluid mb-md-0 mb-5" />
              </div>
            </div>

            {/* hotspot */}
            <div className="corners hts-card p-lg-5 p-4">
              <div className="rotate-uppertitle right-0">
                <Uppertitle title={"STEP 06"} />
              </div>
              <div className="d-flex justify-content-center align-items-center marginRight hts-card-col">
                <img src={HotspotImage} loading="lazy" alt="transactions" className="hts-image img-fluid mb-md-0 mb-5" />
              </div>
              <div className="description-col hts-card-col">
                <h5 className="mb-0 font-size-18 font-pnr">Up to</h5>
                <p className="mb-0 font-size-56 font-pnb purpleGradientText">+20%</p>
                <div className="mb-2 font-size-40 font-pnb line-height-11">
                  <p className="mb-0">Connect devices to Hotspot</p>{" "}
                  <p className="mb-0">
                    to activate the Booster<span className="purple-color">.</span>
                  </p>
                </div>
                <div className="hts-p-container">
                  <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                    You can connect up to 20 devices to your Hotspot. By making their resources available for the network you will
                    benefit from an up to 20% mining booster.
                  </p>
                </div>
                {/* <div className="link-container w-100 d-flex align-items-center justify-content-start blue-color ">
                        <a
                           onClick={() => navigate(routes.guardianNode)}
                           className="font-pnsb font-size-20 blue-color pointer guardian-hover"
                        >
                           Learn more <ArrowRight />
                        </a>
                     </div> */}
                <div
                  onClick={() => toast.info("Coming soon!", { toastId: "guardian-node-router11113" })}
                  className="link-container d-flex align-items-center justify-content-start blue-color line-height-11"
                >
                  <a className="font-pnsb font-size-20 blue-color pointer guardian-hover">
                    Learn more <ArrowRight />
                  </a>
                </div>
                {/* <HashLink className="link-container d-flex align-items-center justify-content-start blue-color line-height-11" smooth to={`${routes.rewards}/#boosters-section`}>
                  <div className="font-pnsb font-size-20 blue-color pointer guardian-hover">
                    Learn more <ArrowRight />
                  </div>
                </HashLink> */}
              </div>
            </div>

            {/* wallet */}
            <div className="corners hts-card p-lg-5 p-4">
              <div className="rotate-uppertitle">
                <Uppertitle title={"STEP 07"} />
              </div>
              <div className="description-col marginRight hts-card-col reverse-description">
                <div className="mb-2 font-size-40 font-pnb line-height-11">
                  <p className="mb-0">Add wallet to</p>{" "}
                  <p className="mb-0">
                    collect your rewards<span className="purple-color">.</span>
                  </p>
                </div>
                <div className="hts-p-container">
                  <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                    Make sure to add your wallet in your Xiden profile to collect your rewards.
                  </p>
                </div>
                <div
                  onClick={() => toast.info("Coming soon!", { toastId: "guardian-node-router3" })}
                  className="link-container w-100 d-flex align-items-center justify-content-start blue-color"
                >
                  <a className="font-pnsb font-size-20 blue-color  guardian-hover">
                    {" "}
                    {/* me-lg-5 me-4 */}
                    Learn more <ArrowRight />
                  </a>
                  {/* <a href="#" className="font-pnsb font-size-20 blue-color">
                           Buy XDEN <ArrowRightUp />
                        </a> */}
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center hts-card-col reverse-img-col">
                <img src={WalletImage} loading="lazy" alt="transactions" className="hts-image img-fluid mb-md-0 mb-5" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GuardianSteps;
