import React from "react";
import "./WhatContains.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Row } from "react-bootstrap";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import Img1 from "../../../assets/images/png/Validator-HardwarePart.png";
import Img2 from "../../../assets/images/png/Validator-Software.png";
import { ReactComponent as ArrowRight } from "../../../assets//images/svg/arrow-right.svg";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../Utils/routes";

// TODO img wrong cut
// Margin bottom 53 / design 45
// Padding bottom computingpower-validator too big
// what-contains margin between button and img on mobile
// on mobile #computingpower-validator distance between subtile and title e.g. Computing power
const WhatContains = () => {
  const navigate = useNavigate();

  return (
    <section id="what-contains" className="page-section">
      <div className="container-main">
        <h2 className="font-pnb font-size-80 white-color line-height-11">
          What is inside <br /> The Validator
          <span className="purple-color">.</span>
        </h2>

        <Row className="align-items-center mobile-column-reverse">
          <Col md={6} className="coloana">
            <Uppertitle title={"HARDWARE"} />
            <h4 className="font-pnb font-size-56 white-color">
              Hardware part
              <span className="purple-color">.</span>
            </h4>
            <p className="font-pnr py-lg-4 py-3 font-size-24 lightgrey-color description-991 line-height-11">
              A physical system that requires resources such as processing power, RAM memory, and storage space in order to
              calculate and validate the network’s data.
            </p>
            {/* <button className="font-pnr buttonBlue font-size-20 font-pnsb black-color d-flex align-items-center align-content-center justify-content-center"
                     onClick={() => navigate(routes.home)}>
                     Read More
                     <ArrowRight />
                  </button> */}
          </Col>
          <Col md={6} className="coloana-img">
            <img src={Img1} loading="lazy" alt="hardware" className="present-photo" />
          </Col>
        </Row>
        <Row className="align-items-center flex-row-reverse mobile-column-reverse">
          <Col md={6} xl={5} className="coloana">
            <Uppertitle title={"SOFTWARE"} />
            <h4 className="font-pnb font-size-56 white-color">
              Software layer
              <span className="purple-color">.</span>
            </h4>
            <p className="font-pnr py-lg-4 py-3 font-size-24 lightgrey-color description-991 line-height-11">
              An integrated custom operating system that supports the installation of multiple applications developed for
              maintaining the connections online when other devices (miners) attempt to connect to the network for validating
              blockchain jobs.
            </p>
            {/* <button className="font-pnr buttonBlue font-size-20 font-pnsb black-color d-flex align-items-center align-content-center justify-content-center"
                     onClick={() => navigate(routes.apps)}>
                     Read More
                     <ArrowRight />
                  </button> */}
          </Col>
          <Col md={6} xl={7} className="coloana-img">
            <img src={Img2} loading="lazy" alt="software" className="present-photo-left" />
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default WhatContains;
