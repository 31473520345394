import React from "react";
import "./Power.scss";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import StorageImage from "../../../assets/images/png/storage-power.png";
import ComputingImage from "../../../assets/images/png/Rewards-computing.png";
import GpuPower from "../../../assets/images/png/gpu-power.png";

const Power = () => {
  return (
    <div className="page-section rewards-power-section">
      <div className="container-main">
        {/* heading */}
        <div className="w-100">
          <Uppertitle title={`POWER`} />
          <h2 className="font-size-80 font-pnb">
            Power<span className="pink-text">.</span>
          </h2>
          <div className="rewards-p-container-lg">
            <p className="font-family-pnr font-size-24 lightgrey-color line-height-11 ">
              The Xiden network’s power comes from its users. As it is a decentralized system that provides a free, secure and
              unrestricted connection, the network is based on multiple connection points across the world.
            </p>
          </div>
        </div>

        {/* specs */}
        <div className="power-spects-container">
          <div className="power-col">
            <div className="img-row d-flex align-items-center align-content-center justify-content-center">
              <img src={StorageImage} loading="lazy" alt="storage" className="power-image" />
            </div>
            <h5 className="mb-4 font-size-56 font-pnb">
              Storage<span className="pink-text">.</span>
            </h5>
            <p className="font-size-20 font-pnr lightgrey-color line-height-11 first-p">
              Users receive rewards by integrating their smart electronic devices in the system and making available for the Xiden
              network their storage capacity. This ensures once again the decentralized principle of the network as there is no
              central point of vulnerability.
            </p>
          </div>

          <div className="power-col">
            <div className="img-row d-flex align-items-center align-content-center justify-content-center">
              <img src={ComputingImage} loading="lazy" alt="computing" className="power-image computing-image" />
            </div>
            <h5 className="mb-4 font-size-56 font-pnb">
              Computing<span className="pink-text">.</span>
            </h5>
            <p className="font-size-20 font-pnr lightgrey-color line-height-11 first-p">
              Users receive rewards by integrating their smart electronic devices in the system and making available for the Xiden
              network their computing power. A higher number of validator nodes will ensure a higher computing power for the
              network.
            </p>
          </div>
          <div className="power-col">
            <div className="img-row d-flex align-items-center align-content-center justify-content-center">
              <img src={GpuPower} loading="lazy" alt="computing" className="power-image computing-image" />
            </div>
            <h5 className="mb-4 font-size-56 font-pnb">
              GPU<span className="pink-text">.</span>
            </h5>
            <p className="font-size-20 font-pnr lightgrey-color line-height-11">
              An important resource of computing technology used in a wide range of applications, including graphics and video
              rendering.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Power;
