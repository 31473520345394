import React from "react";
import "./HeroSection.scss";
import Validator from "../../../assets/images/png/hero-final-validator.png";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import ValidatorVideo from "../../../assets/videos/validator-page/video-validator.mp4";
import { ReactComponent as ArrowRight } from "../../../assets//images/svg/arrow-right.svg";
import { toast } from "react-toastify";

const HeroSection = () => {
  return (
    <section id="validator-hero">
      <div className="hero-validator page-section">
        <div className="container-main">
          <div className="main-col">
            <div className="validator-uppertitle-container">
              <Uppertitle title={`VALIDATOR`} />
            </div>
            <h2 className="mb-0 font-size-80 pb-3 white-color font-pnb">
              What is a
              <br />
              Validator
              <span className="purple-color">.</span>
            </h2>
            <div className="paragraph-container">
              <p className="font-pnr py-lg-4 py-3 font-size-24 lightgrey-color line-height-11">
                The validator is a physical network node that verifies and validates the integrity of data within the system.
              </p>
            </div>
            <div className="hero-btn-container">
              <button className="buttonBlue" onClick={() => window.open("https://cryptodata.com/product/minter")}>
                <span className="me-2">Buy now</span>
                <ArrowRight />
              </button>
            </div>
          </div>

          <div className="section-image-col">
            {/* <img src={Validator} loading="lazy" alt="xid coin" className="section-image img-fluid" /> */}
            <video src={ValidatorVideo} autoPlay playsInline muted loop={true} className="section-image img-fluid" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
