import React, { useState } from "react";
import "./SupportSteps.scss";
import LeftSide from "../StepsComponents/LeftSide";
import RightSide from "../StepsComponents/RightSide";
import { connectMinterSteps } from "../StepsData/connectMinterData";
import { accessMinterSteps } from "../StepsData/accessMinterData";
import { linkMinterSteps } from "../StepsData/linkMinterData";
import { getLockedXdenSteps } from "../StepsData/getLockedXdenData";
import { unstakeSteps } from "../StepsData/unstakeData";

const SupportHotspotSteps = () => {
  return (
    <section id="guardian-steps-section" className="krater-pool-steps-section">
      <div className="page-section text-white">
        <div className="container-main">
          {/* heading */}

          {/* HOW TO CONNECT MINTER */}
          <h2 id="how-to-connect-minter" className="font-pnb font-size-56 blue-color">
            How to connect Minter?
          </h2>
          <div className="mt-5">
            {connectMinterSteps.map((step, index) => {
              return index % 2 === 0 ? (
                <LeftSide
                  uppertitle={step.uppertitle}
                  title={step.title}
                  punctuation={step.punctuation}
                  content={step.content}
                  image={step.image}
                  key={step.title}
                />
              ) : (
                <RightSide
                  uppertitle={step.uppertitle}
                  title={step.title}
                  punctuation={step.punctuation}
                  content={step.content}
                  image={step.image}
                  key={step.title}
                />
              );
            })}

            {/* HOW TO ACCESS MINTER */}
            <h2 id="how-to-access-minter" className="font-pnb font-size-56 blue-color">
              How to access Minter?
            </h2>

            {accessMinterSteps.map((step, index) => {
              return index % 2 === 0 ? (
                <LeftSide
                  uppertitle={step.uppertitle}
                  title={step.title}
                  punctuation={step.punctuation}
                  content={step.content}
                  image={step.image}
                  key={step.title}
                />
              ) : (
                <RightSide
                  uppertitle={step.uppertitle}
                  title={step.title}
                  punctuation={step.punctuation}
                  content={step.content}
                  image={step.image}
                  key={step.title}
                />
              );
            })}

            {/* HOW TO LINK MINTER */}
            <h2 id="how-to-link-minter" className="font-pnb font-size-56 blue-color">
              How to link your Minter with pool?
            </h2>

            {linkMinterSteps.map((step, index) => {
              return index % 2 !== 0 ? (
                <LeftSide
                  uppertitle={step.uppertitle}
                  title={step.title}
                  punctuation={step.punctuation}
                  content={step.content}
                  image={step.image}
                  key={step.title}
                />
              ) : (
                <RightSide
                  uppertitle={step.uppertitle}
                  title={step.title}
                  punctuation={step.punctuation}
                  content={step.content}
                  image={step.image}
                  key={step.title}
                />
              );
            })}

            {/* How to get the 1000 locked XDEN? */}
            <h2 id="how-to-get-1000-xden" className="font-pnb font-size-56 blue-color">
              How to get the 1000 locked XDEN?
            </h2>

            {getLockedXdenSteps.map((step, index) => {
              return index % 2 !== 0 ? (
                <LeftSide
                  uppertitle={step.uppertitle}
                  title={step.title}
                  punctuation={step.punctuation}
                  content={step.content}
                  image={step.image}
                  key={step.title}
                />
              ) : (
                <RightSide
                  uppertitle={step.uppertitle}
                  title={step.title}
                  punctuation={step.punctuation}
                  content={step.content}
                  image={step.image}
                  key={step.title}
                />
              );
            })}

            {/* How to unstake */}
            <h2 id="how-to-get-1000-xden" className="font-pnb font-size-56 blue-color">
              How to unstake?
            </h2>

            <RightSide
              uppertitle={unstakeSteps[0].uppertitle}
              title={unstakeSteps[0].title}
              punctuation={unstakeSteps[0].punctuation}
              content={unstakeSteps[0].content}
              image={unstakeSteps[0].image}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SupportHotspotSteps;
