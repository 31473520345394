import React from "react";
import "./HeroSection.scss";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";

const HeroSection = () => {
  return (
    <section id="change-log-section" className="page-section">
      <div className="hero-section-change-log d-flex align-items-center justify-content-center flex-column">
        <Uppertitle title="Changelog" />
        <h2 className="font-size-80 font-pnb line-height-11 changelog-title">
          Changelog<span className="purple-color">.</span>
        </h2>
        <p className="font-size-24 font-pnr lightgrey-color line-height-11 hero-changelog-p">
          Stay tuned to Xiden blockchain news, analysis, announcements and
          in-depth articles.
        </p>
      </div>
    </section>
  );
};

export default HeroSection;
