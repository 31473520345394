import React, { useEffect } from "react";
import "./Audits.scss";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import { Col, Row } from "react-bootstrap";
import { FiArrowUpRight as ArrowRightUp } from "react-icons/fi";
import { AiOutlineArrowRight as ArrowRight } from "react-icons/ai";
import HakenImg from "../../../assets/images/png/homePage/hacken.png";
import CertikImg from "../../../assets/images/png/homePage/certik-logotype.png";
import Slider from "react-slick";
import CertikGroup from "../../../assets/images/png/homePage/certik-group.png";
import HackenGroup from "../../../assets/images/png/homePage/hacken-grup.png";

const Audits = () => {
  useEffect(() => {
    const slider = document.getElementById("audit-slider");
    let isDown = false;
    let startX;
    let scrollLeft;

    const mouseLeaveFunction = () => {
      // document.getElementById("roadmap-event").style.cursor = 'grabbing';

      isDown = false;
      slider.classList.remove("active");
    };
    const mouseDownFunction = (e) => {
      // document.getElementById("roadmap-event").style.cursor = 'grabbing';
      isDown = true;
      slider.classList.add("active");
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    };
    const mouseUpFunction = () => {
      // document.getElementById("roadmap-event").style.cursor = 'grabbing';
      isDown = false;
      slider.classList.remove("active");
    };
    const touchFunction = (e) => {
      // document.getElementById("roadmap-event").style.cursor = 'grabbing';
      isDown = false;
      slider.classList.remove("active");
      // console.log('sal')
    };
    const mouseMoveFunction = (e) => {
      // document.getElementsByClassName("roadmap-event").style.cursor = 'grabbing';
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 1; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
      //console.log(walk);
    };

    if (slider) {
      slider?.addEventListener("mousedown", mouseDownFunction);
      slider?.addEventListener("mouseleave", mouseLeaveFunction);
      slider?.addEventListener("mouseup", mouseUpFunction);
      slider?.addEventListener("mousemove", mouseMoveFunction);
      slider?.addEventListener("touchstart", touchFunction);
    }

    return () => {
      slider?.removeEventListener("mousedown", mouseDownFunction);
      slider?.removeEventListener("mouseleave", mouseLeaveFunction);
      slider?.removeEventListener("mouseup", mouseUpFunction);
      slider?.removeEventListener("mousemove", mouseMoveFunction);
      slider?.removeEventListener("mousemove", touchFunction);
    };
  }, []);

  return (
    <section id="audits-section" className="page-section">
      <div className="container-main">
        <Uppertitle title={"XIDEN"} />
        <h2 className="title font-size-80 white-color font-pnb line-height-11 pb-lg-4 pb-3">
          Audits
          <span className="purple-color">.</span>
        </h2>

        <Row className="d-none d-lg-flex">
          <Col lg={6} className="">
            <div className="column-wrapper wrapper-1 ms-auto">
              <div className="d-flex align-items-center justify-content-center corners">
                <img src={HakenImg} alt="Hacken audit" className="img-fluid" />
              </div>

              <h2 className="font-pnb font-size-40 text-center mt-4">HACKEN Audit</h2>
              <button
                className="buttonBlue m-auto mt-3 custom-btn font-pnsb font-size-20 mb-5 mb-lg-0"
                onClick={() =>
                  window.open(
                    "https://hacken.io/wp-content/uploads/2022/03/ZIXXAR-INTERNATIONAL-DISTRIBUTION-SRL-16032022_SCAudit_Report.docx.pdf"
                  )
                }
              >
                Smart contract audit
                <ArrowRightUp className="ms-1" />
              </button>
            </div>
          </Col>
          <Col lg={6}>
            <div className="column-wrapper wrapper-2 me-auto">
              <div className="d-flex align-items-center justify-content-center corners">
                <img src={CertikImg} alt="Certik audit" className="img-fluid" />
              </div>

              <h2 className="font-pnb font-size-40 text-center mt-4">Certik Audit</h2>
              <button disabled className="buttonOutlineBlue m-auto mt-3 custom-btn disabled-btn font-pnsb font-size-20">
                Coming soon
                <ArrowRight className="ms-1" />
              </button>
            </div>
          </Col>
        </Row>

        <div id="audit-slider" className="ps-4 pe-5 d-flex d-lg-none justify-content-between align-items-start audit-div">
          {/* <Slider {...settings} className="d-block d-lg-none"> */}
          <div className="column-wrapper m-auto audit-event me-audit-slider-custom">
            <div className="d-flex align-items-center justify-content-center mx-2">
              <img src={HackenGroup} alt="Hacken audit" className="img-fluid" />
            </div>

            <h2 className="font-pnb font-size-40 text-center mt-4">HACKEN Audit</h2>
            <button
              className="buttonBlue m-auto mt-3 custom-btn font-pnsb font-size-20 mb-5 mb-lg-0"
              onClick={() =>
                window.open(
                  "https://hacken.io/wp-content/uploads/2022/03/ZIXXAR-INTERNATIONAL-DISTRIBUTION-SRL-16032022_SCAudit_Report.docx.pdf"
                )
              }
            >
              Smart contract audit
              <ArrowRightUp className="ms-1" />
            </button>
          </div>
          <div className="column-wrapper m-auto audit-event ">
            <div className="d-flex align-items-center justify-content-center mx-2">
              <img src={CertikGroup} alt="Certik audit" className="img-fluid" />
            </div>

            <h2 className="font-pnb font-size-40 text-center mt-4">Certik Audit</h2>
            <button disabled className="buttonOutlineBlue m-auto mt-3 custom-btn disabled-btn font-pnsb font-size-20">
              Coming soon
              <ArrowRight className="ms-1" />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Audits;
