import React, { useCallback, useState } from 'react'
import { AiOutlineArrowRight as ArrowRight } from "react-icons/ai";
import { toast } from "react-toastify"
import axios from "axios"

const initialUserData = {
    email: "",
    twitterLink: "",
};
const MESSAGE_INVALID_MAIL = "Invalid email!"
const MESSAGE_EMPTY_MAIL = "This field can't be empty!"
const URL = "https://mining-competition-5e9e8-default-rtdb.europe-west1.firebasedatabase.app/competition-design.json"

const RegisterPool = () => {

    const [userData, setUserData] = useState(initialUserData);
    const [submitted, setSubmitted] = useState(false)
    const [emailGood, setEmailGood] = useState(false)
    const [linkGood, setLinkGood] = useState(false)
    const [disableButton, setDisableButton] = useState(false)
    const [errorEmail, setErrorEmail] = useState("This field can't be empty!")
    const [registrationSuccess, setRegistrationSuccess] = useState(false)
    const [successSubmit, setSuccessSubmit] = useState(false)

    const updateUserDataHandler = useCallback(
        (type) => (event) => {
            setUserData({ ...userData, [type]: event.target.value });
        },
        [userData]
    );

    const formHandler = useCallback(
        () => async (event) => {
            event.preventDefault();
            setSubmitted(true)

            if (userData.email !== "" && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(userData.email) && userData.twitterLink !== "") {
                setEmailGood(true)
                setLinkGood(true)
                setDisableButton(true)
                setRegistrationSuccess(true);

                try {
                    let sendData = await axios.post(URL, {
                        email: userData.email,
                        twitterLink: userData.twitterLink
                    })
                    if (sendData.status === 200) {
                        setSuccessSubmit(true)
                        return toast.info("Registered successfully", { toastId: "data-send2" })
                    }
                } catch (err) {
                    if (err.response) {
                        switch (err.response.status) {
                            case 404:
                                toast.error("Not found!");
                                break;
                            default:
                                toast.error("Ooops... Looks like something went wrong");
                                break;
                        }
                    }
                }


            }

            if (userData.email === "") {
                setEmailGood(false);
                setErrorEmail(MESSAGE_EMPTY_MAIL)
            }
            else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(userData.email)) {
                setEmailGood(false)
                setErrorEmail(MESSAGE_INVALID_MAIL)
            }
            else setEmailGood(true)

            if (userData.twitterLink === "") setLinkGood(false);
            else setLinkGood(true)

        },
        [userData]
    );

    return (
        <div className='register-form pool-form p-4 p-lg-5 h-100'>
            <div className='font-pnb font-size-40 text-start'>
                Pool Design
            </div>
            <form className="mt-4" onSubmit={formHandler()}>
                <div className='input-field d-flex flex-column align-items-start justify-content-start'>
                    <label className='font-pnr font-size-20 text-start' htmlFor='pool-email'>
                        {"Email ( Xiden Account ):"}
                    </label>
                    <input
                        id="pool-email"
                        className="px-3"
                        type="text"
                        disabled={registrationSuccess}
                        value={userData.email}
                        onChange={updateUserDataHandler("email")}
                        placeholder='Email'
                    />
                    <div className={`error-msg font-size-12 font-pnr ${(submitted && !emailGood) ? "d-block" : "d-none"}`}>*{errorEmail}</div>
                </div>

                <div className='input-field  d-flex flex-column align-items-start justify-content-start mt-3'>
                    <label className='font-pnr font-size-20 text-start' htmlFor='twitter-account'>
                        {"Link to Twitter comment:"}
                    </label>
                    <input
                        id="twitter-account"
                        className="px-3"
                        type="text"
                        disabled={registrationSuccess}
                        value={userData.twitterLink}
                        onChange={updateUserDataHandler("twitterLink")}
                        placeholder='Twitter comment'
                    />
                    <div className={`error-msg font-size-12 font-pnr ${(submitted && !linkGood) ? "d-block" : "d-none"}`}>*This field can't be empty!</div>
                </div>
                {
                    successSubmit ?
                        <div className='font-pnsb blue-color font-size-24 text-center mt-5'>Your form has been submitted successfully.</div>
                        :
                        <button disabled={disableButton} className={`buttonBlue w-100 mt-5 pointer" type="submit ${disableButton && "btn-disabled"}`}
                        >
                            <span className="me-2">Submit</span>
                            <ArrowRight />
                        </button>
                }
            </form>

        </div>
    )
}

export default RegisterPool