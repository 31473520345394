import React from "react";
// style
import "./HeroSection.scss";

import { Row, Col } from "react-bootstrap";
//sections
import HeroImg from "./hero.png";
// import {ReactComponent as PlusIco} from "../../../assets/images/png/SupportMetamask/plus-button.svg";
import { Link as ScrollLink } from "react-scroll";

const HeroSection = () => {
  return (
    <section id="metamask-support-hero-page" className="page-section support-update-hero">
      <div className="container-main d-flex flex-column justify-content-start align-items-start">
        <Row className="hero-row w-100">
          <Col
            lg={6}
            className="content-col d-flex flex-column align-items-start justify-content-center"
          >
            <h2 className="title-hero font-size-80 line-height-11 font-pnb white-color">
              How to change settings?
            </h2>

            <ScrollLink
              className="buttonBlue mt-2 mt-lg-3 me-0 d-flex align-items-center justify-content-center"
              to="guardian-steps-section"
              // offset={-90}
              spy={true}
            >
              <span className="font-size-20 font-pnb"> Jump to content </span>
            </ScrollLink>
          </Col>

          <Col
            lg={6}
            className="mobile-col d-flex aling-items-center justify-content-end"
          >
            <img
              src={HeroImg}
              alt="Xiden setup in Metamask"
              className="img-fluid hero-img"
            />
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default HeroSection;
