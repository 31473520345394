import React from "react";
import "./Types.scss";
import { Row, Col } from "react-bootstrap";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import { ReactComponent as ArrowRight } from "../../../assets//images/svg/arrow-right.svg";
import Dapps from "../../../assets/images/png/Daps-apps.png";
import Contact from "../../../assets/images/png/Daps-contact.png";
import Defi from "../../../assets/images/png/Daps-defi.png";
import Nft from "../../../assets/images/png/Daps-nft.png";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../Utils/routes";
import { toast } from "react-toastify";
import { Element } from "react-scroll";

const Types = () => {
  const navigate = useNavigate();
  return (
    <Element name={"dapps-types-section"}>
      <section id="types-section" className="page-section">
        <div className="container-main">
          <div className="w-100 heading-container">
            <Uppertitle title={"DAPPS TYPES"} />
            <h2 className="font-size-80 white-color pb-lg-5 pb-4 font-pnb">
              Types<span className="purple-color">.</span>
            </h2>
          </div>

          <Row className="rand corners p-xl-5 p-4 align-items-center">
            <Col lg={6} className="col-img d-flex align-items-center">
              <img src={Nft} loading="lazy" alt="Nft" className="img-fluid types-img" />
            </Col>
            <Col lg={6} className="mobile-flex">
              <h4 className="font-pnb font-size-40 white-color">
                Nft
                <span className="purple-color">.</span>
              </h4>
              <div className="font-pnr  description-991 font-size-20 lightgrey-color line-height-11 p-types-width">
                A highly-curated platform for minting, collecting, and selling your NFTs. Start minting your favorite NFTs and
                increase your passive income.
              </div>
              <button
                className="font-pnsb font-size-20 text-shadowed blue-color d-flex justify-content-start align-items-baseline align-content-center mt-4"
                onClick={() => toast.info("Coming soon...", { toastId: "mint-nft" })}
              >
                Mint NFT's
                <ArrowRight />
              </button>
            </Col>
          </Row>

          <Row className="rand corners p-xl-5 p-4 align-items-center">
            <Col lg={6} className="col-img d-flex align-items-center">
              <img src={Contact} loading="lazy" alt="Contract" className="img-fluid contact-img types-img" />
            </Col>
            <Col lg={6} className="mobile-flex">
              <h4 className="font-pnb font-size-40 white-color smart-ct-title">
                Smart contracts
                <span className="purple-color">.</span>
              </h4>
              <div className="font-pnr description-991 font-size-20 lightgrey-color line-height-11 p-types-width">
                Create, deploy, and test your smart contract on a highly transparent, secure, and performant platform.
              </div>
              <button
                className="font-pnsb font-size-20 btn2 text-shadowed blue-color d-flex justify-content-start align-items-baseline align-content-center mt-4"
                onClick={() => toast.info("Coming soon...", { toastId: "smart-contracts" })}
              >
                Create smart contact
                <ArrowRight />
              </button>
            </Col>
          </Row>

          <Row className="rand corners p-xl-5 p-4 align-items-center">
            <Col lg={6} className="col-img d-flex align-items-center">
              <img src={Defi} loading="lazy" alt="DeFi" className="img-fluid types-img" />
            </Col>
            <Col lg={6} className="mobile-flex">
              <h4 className="font-pnb font-size-40 white-color">
                DeFi
                <span className="purple-color">.</span>
              </h4>
              <div className="font-pnr description-991 font-size-20 lightgrey-color line-height-11 p-types-width">
                The magic behind decentralized finance is here. Building DeFi on the Xiden network is super easy and will provide
                high financial benefits for users in a decentralized manner.
              </div>
              <button
                className="font-pnsb font-size-20 btn3 text-shadowed blue-color d-flex justify-content-start align-items-baseline align-content-center mt-4"
                onClick={() => toast.info("Coming soon...", { toastId: "defi" })}
              >
                Start building
                <ArrowRight />
              </button>
            </Col>
          </Row>

          <Row className="rand corners p-xl-5 p-4 align-items-center">
            <Col lg={6} className="col-img d-flex align-items-center">
              <img src={Dapps} loading="lazy" alt="Dapps" className="img-fluid types-img" />
            </Col>
            <Col lg={6} className="mobile-flex">
              <h4 className="font-pnb font-size-40 white-color">
                Utility Apps
                <span className="purple-color">.</span>
              </h4>
              <div className="font-pnr description-991 font-size-20 lightgrey-color line-height-11 p-types-width">
                A development environment, testing framework, and other tools. We facilitate access for developers that wish to
                build their own DApps. Imagine the one that suits you and start building it on the Xiden network.
              </div>
              <button
                className="font-pnsb font-size-20 btn4 text-shadowed blue-color d-flex justify-content-start align-items-baseline align-content-center mt-4"
                onClick={() => toast.info("Coming soon...", { toastId: "utility-apps" })}
              >
                Build Utility Apps
                <ArrowRight />
              </button>
            </Col>
          </Row>
        </div>
      </section>
    </Element>
  );
};

export default Types;
