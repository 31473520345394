import React from "react";
import "./TransparentCard.scss";

const TransparentCard = ({ color, cardData }) => {
  const matchTextColor = (color) => {
    switch (color) {
      case "yellow":
        return { color: "#fda544" };

      case "blue":
        return { color: "#22aaff" };

      case "white":
        return { color: "#fff" };

      default:
        return { color: "#22aaff" };
    }
  };

  return (
    <div className="transparent-card">
      <span className="mb-2 font-pnr span-text-size lightgrey-color">{cardData.upperTitle}</span>
      <h5 className="mb-0 font-pnsb heading-text-size" style={matchTextColor(color)}>
        {cardData.title}
      </h5>
    </div>
  );
};

export default TransparentCard;
