import React, { useState, createContext, useEffect } from "react";
import "./CardsSection1V2.scss";
import { Row, Col } from "react-bootstrap";
import StakingCard from "./StakingCard";
import StakingCardImg1 from "../../../assets/images/png/eco-light.png";
import StakingCardImg2 from "../../../assets/images/png/verified.png";
import StakingCardImg3 from "../../../assets/images/png/fraud.png";
import StakingCardImg4 from "../../../assets/images/png/deadline.png";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import { Element } from "react-scroll";
import BluePlus from "../../../assets/images/png/blue-plus.png";
import GreenPlus from "../../../assets/images/png/green-plus.png";
import RedPlus from "../../../assets/images/png/red-plus.png";
import YellowPlus from "../../../assets/images/png/yellow-plus.png";

const rotateContext = createContext(null);

const CardsSection1V2 = () => {
  const [rotate, setRotate] = useState([false, false, false, false]);

  const value = { rotate, setRotate };

  const cardsData = [
    {
      id: 1,
      cardUpperTitle: "LOW ENERGY",
      img: <img src={StakingCardImg1} alt={"staking-card-1"} className="staking-card-img" />,
      plusImg: <img src={YellowPlus} alt="yellow-plus" className="plus-icons" />,
      title: (
        <>
          <h4 className="my-auto font-size-40-b font-pnb line-height-11 staking-cards">
            Low
            <span style={{ display: "block" }}>Energy</span>
            <span style={{ display: "block" }}>Consumption.</span>
          </h4>
        </>
      ),
      backsideTitle: (
        <h4 className="font-size-28 font-pnb  my-auto line-height-11 staking-cards">
          Integrated SDR layer to ensure smart distribution of resources within the network.
        </h4>
      ),
    },
    {
      id: 2,
      cardUpperTitle: "SECURED",
      img: <img src={StakingCardImg2} alt={"staking-card-2"} className="staking-card-img" />,
      plusImg: <img src={BluePlus} alt="blue-plus" className="plus-icons" />,
      title: (
        <h4 className="my-auto font-size-40-b font-pnb line-height-11 staking-cards">
          Protection of
          <span style={{ display: "block" }}>Xiden system</span>
          <span style={{ display: "block" }}>&#38; Participants.</span>
        </h4>
      ),
      backsideTitle: (
        <h4 className="font-size-28 font-pnb  my-auto line-height-11 staking-cards">
          The system is cryptographically secure and both the user and its device identities are protected.
        </h4>
      ),
    },
    {
      id: 3,
      cardUpperTitle: "NO FRAUD",
      img: <img src={StakingCardImg3} alt={"staking-card-3"} className="staking-card-img" />,
      plusImg: <img src={RedPlus} alt="red-plus" className="plus-icons" />,
      title: (
        <h4 className="my-auto font-size-40-b font-pnb line-height-11 staking-cards">
          Eliminate
          <span style={{ display: "block" }}>fraud from</span>
          <span style={{ display: "block" }}>the system.</span>
        </h4>
      ),
      backsideTitle: (
        <h4 className="font-size-28 font-pnb my-auto line-height-11 staking-cards">
          Integrated process of identifying and punishing fraud by confiscating the fraudster’s available amount of XDEN.
        </h4>
      ),
    },
    {
      id: 4,
      cardUpperTitle: "QUICK",
      img: <img src={StakingCardImg4} alt={"staking-card-4"} className="staking-card-img" />,
      plusImg: <img src={GreenPlus} alt="green-plus" className="plus-icons" />,
      title: (
        <h4 className="my-auto font-size-40-b font-pnb line-height-11 staking-cards">
          Low gas
          <span style={{ display: "block" }}>fees &#38; fast</span>
          <span style={{ display: "block" }}>transactions.</span>
        </h4>
      ),
      backsideTitle: (
        <h4 className="font-size-28 font-pnb  my-auto line-height-11 staking-cards">
          A decentralized network owned by users that ensures reduced costs and fast validation processes.
        </h4>
      ),
    },
  ];

  return (
    <Element name="staking-card-section-1">
      <section id="cards-section-1" className="page-section">
        <div className="container-main">
          {/* heading */}
          <div className="w-100">
            <div className="upper-title-consensus">
              <Uppertitle title={`CONSENSUS`} />
            </div>
            <h2 className="font-size-80 font-pnb line-height-11">
              Consensus built <br /> for a long future<span className="pink-text">.</span>
            </h2>
          </div>
          <Row className="cards-row pt-3">
            {cardsData.map((card, index) => (
              <Col xl={3} lg={6} md={6} key={card.cardUpperTitle}>
                <rotateContext.Provider value={value}>
                  <StakingCard cardData={card} index={index} currentRotate={rotate[index]} />
                </rotateContext.Provider>
              </Col>
            ))}
          </Row>
        </div>
      </section>
    </Element>
  );
};

export default CardsSection1V2;
export { rotateContext };
