import React from "react";
import "./EcoSection.scss";
import { Col, Row } from "react-bootstrap";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import NodeValidator from "../../../assets/images/png/xiden-page/realm-img.png";
import MapVideo from "../../../assets/videos/ecosystem-page/ecosystem-meta-video.mp4";
import { ReactComponent as ArrowRight } from "../../../assets//images/svg/arrow-right.svg";
import CubesImage from "../../../assets/images/png/realm-cubes.png";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../Utils/routes";

const EcoSection = () => {
  const navigate = useNavigate();

  return (
    <section className="page-section xiden-ecosystem-section">
      <div className="container-main">
        <Row className="title align-items-center">
          <div className="my-auto">
            <Uppertitle title={"XIDEN ECOSYSTEM"} />
            <div className="mb-4 font-size-80 font-pnb line-height-11">
              <p className="mb-0">The heart</p>{" "}
              <p className="mb-0">
                of our system<span className="purple-color">.</span>
              </p>
            </div>
          </div>
        </Row>
      </div>

      <div className="realms-subsection">
        <div className="container-main overlay">
          <img src={CubesImage} loading="lazy" alt="realm" className="cubes-image" />

          <Row xs={1} sm={1} md={2} className="realm-row">
            <Col className="mb-5 mb-sm-5 mb-md-0">
              <div className="d-flex align-items-center justify-content-center">
                <img src={NodeValidator} loading="lazy" alt="Node validator" className="realm-image" />
              </div>
            </Col>
            <Col>
              <div className="my-auto">
                <Uppertitle title={"REALM METANODE"} />
                <h2 className="title font-size-56 pb-4 white-color font-pnb">
                  30 Meta Realms<span className="pink-text">.</span>
                </h2>
                <p className="font-pnr font-size-24 lightgrey-color line-height-11 ecosystem-heading-section">
                  Every validator node can open a new Realm. A new decentralized world where users can open public and private
                  pools, can connect smart devices or their own Guardian Nodes.
                </p>
                <div className="btn-container mt-lg-5 mt-4 d-flex">
                  <button className="buttonBlue" onClick={() => toast.info("Coming soon...", { toastId: "derfrf" })}>
                    <span className="me-2">Buy realm</span>
                    <ArrowRight />
                  </button>

                  <button className="buttonOutlineBlue" onClick={() => navigate(routes.minter)}>
                    <span className="me-2">Learn more</span>
                    <ArrowRight />
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="video-container mt-custom d-flex justify-content-center align-items-center">
          <video src={MapVideo} autoPlay playsInline muted loop={true} className="video-fluid mt-0 mt-md-5 px-4" />
        </div>

        <div className="large-container ecosystem-large-container">
          <div className="model-3d-subsection"></div>
        </div>
      </div>
    </section>
  );
};

export default EcoSection;
