import React from "react";
import "./CorneredCards.scss";
import { Col, Row } from "react-bootstrap";
// import Uppertitle from "../../../components/Uppertitle/Uppertitle";
// import Conectivity from "../../../assets/images/png/ecosystem-connectivity.png";
// import Pool from "../../../assets/images/png/guard-connection.png";
// import Hotspot from "../../../assets/images/png/pool-hotspot.png";
// import Volume from "../../../assets/images/png/xiden-page/booster-devices.png";
// import Node from "../../../assets/images/png/ecosystem-guardian-node.png";
import GuardianNodeImg from "../../../assets/images/png/ecosystem/guardian-nodes-card.png";
import KraterPoolImg from "../../../assets/images/png/ecosystem/krater-pool-card.png";
import HotspotImg from "../../../assets/images/png/ecosystem/krater-pool-hotspot-card.png";
import BoosterImg from "../../../assets/images/png/ecosystem/booster-devices-card.png";
// import Staking from "../../../assets/images/png/ecosystem-staking.png";
// import PrivateDiff from "../../../assets/images/png/ecosystem-private-difficulty.png";
// import PublicDiff from "../../../assets/images/png/ecosystem-public-difficulty.png";
import { ReactComponent as ArrowRight } from "../../../assets//images/svg/arrow-right.svg";
import { Link, Navigate, NavLink, useNavigate } from "react-router-dom";
import { routes } from "../../../Utils/routes";
import { HashLink } from "react-router-hash-link";

const CorneredCards = () => {
  const navigate = useNavigate();
  //const history = useHistory();

  return (
    <section id="cornered-cards-ecosystem" className="page-section">
      <div className="container-main d-flex flex-column">
        {/* guardian node card */}
        <div className="corners d-flex align-items-center right">
          <Row xs={1} sm={1} md={2} className="rand d-flex justify-content-between align-items-center m-auto">
            <Col xs={{ order: 2 }} sm={{ order: 2 }} md={{ order: 1 }}>
              <div className="ecosystem-corners-info-col">
                <h2 className="title font-size-40 pb-1 pt-3 white-color font-pnb">
                  Guardian Node
                  <span className="purple-color">.</span>
                </h2>
                <p className="font-pnr font-size-20 pb-2 lightgrey-color">
                  Every Guardian Node validates that all devices and nodes across the network are genuine by verifying the data of
                  the system’s epoch.
                </p>
                {/* <button
                           className="read-more-btn font-pnsb font-size-20 blue-color d-flex justify-content-start align-items-baseline align-content-center"
                           onClick={() => navigate(routes.guardianNode)}
                        >
                           Read more
                           <ArrowRight />
                        </button> */}
                <HashLink
                  className="read-more-btn font-pnsb font-size-20 blue-color d-flex justify-content-start align-items-baseline align-content-center"
                  to={`${routes.guardianNode}#router-functions-section`}
                >
                  Read more
                  <ArrowRight />
                </HashLink>
              </div>
            </Col>
            <Col xs={{ order: 1 }} sm={{ order: 1 }} md={{ order: 2 }}>
              <div className="d-flex align-items-center justify-content-center">
                <img
                  src={GuardianNodeImg}
                  loading="lazy"
                  alt="hotspot"
                  className="img-fluid right-img guardian-node-img ecosystem-corners-img"
                />
              </div>
            </Col>
          </Row>
        </div>

        {/* krater pool card */}
        <div className="corners d-flex align-items-center left ">
          <Row xs={1} sm={1} md={2} className="rand justify-content-between align-items-center m-auto">
            <Col>
              <div className="d-flex align-items-center justify-content-center">
                <img src={KraterPoolImg} alt="volume" className="img-fluid left-img krater-pool-img ecosystem-corners-img" />
              </div>
            </Col>
            <Col>
              <div className="ecosystem-corners-info-col">
                <h2 className="title font-size-40 pb-1 pt-3 white-color font-pnb">
                  KrateR Pool
                  <span className="purple-color">.</span>
                </h2>
                <p className="font-pnr font-size-20 pb-2 lightgrey-color">
                  The KrateR Pool acts as a Hotspot that connects both user Guardian Nodes and integrated smart devices to
                  validate and use shared computing power and storage capacity across the network.
                </p>
                {/* <button
                           className="read-more-btn font-pnsb font-size-20 blue-color d-flex justify-content-start align-items-baseline align-content-center"
                           onClick={() => navigate(routes.guardianNode)}
                        >
                           Read more
                           <ArrowRight />
                        </button> */}
                {/* <button className="read-more-btn font-pnsb font-size-20 blue-color d-flex justify-content-start align-items-baseline align-content-center" 
                                  onClick = {() => navigate("/guardian-node#extra-steps")}>
                           Read more
                           <ArrowRight />
                        </button> */}
                <HashLink
                  className="read-more-btn font-pnsb font-size-20 blue-color d-flex justify-content-start align-items-baseline align-content-center"
                  to={`${routes.guardianNode}#extra-steps`}
                >
                  Read more
                  <ArrowRight />
                </HashLink>
              </div>
            </Col>
          </Row>
        </div>

        {/* hotspot card */}
        <div className="corners d-flex align-items-center right">
          <Row xs={1} sm={1} md={2} className="rand  d-flex justify-content-between align-items-center m-auto">
            <Col xs={{ order: 2 }} sm={{ order: 2 }} md={{ order: 1 }}>
              <div className="ecosystem-corners-info-col">
                <h2 className="title font-size-40 pb-1 pt-3 white-color font-pnb">
                  Spectralis Hotspot
                  <span className="purple-color">.</span>
                </h2>
                <p className="font-pnr font-size-20 pb-2 lightgrey-color">
                  Facilitates the integration of regular smart electronic devices into the system. Sharing their available
                  resources with the network will ensure a mining boost which will be calculated based on the amount of data
                  provided by the respective devices.
                </p>
                <button
                  className="read-more-btn font-pnsb font-size-20 blue-color d-flex justify-content-start align-items-baseline align-content-center"
                  onClick={() => navigate(routes.guardianNode)}
                >
                  Read more
                  <ArrowRight />
                </button>
              </div>
            </Col>
            <Col xs={{ order: 1 }} sm={{ order: 1 }} md={{ order: 2 }}>
              <div className="d-flex align-items-center justify-content-center">
                <img
                  src={HotspotImg}
                  loading="lazy"
                  alt="hotspot"
                  className="img-fluid krater-pool-hotspot-img right-img ecosystem-corners-img"
                />
              </div>
            </Col>
          </Row>
        </div>

        {/* booster card */}
        <div className="corners d-flex align-items-center left ">
          <Row xs={1} sm={1} md={2} className="rand  justify-content-between align-items-center m-auto">
            <Col>
              <div className="d-flex align-items-center justify-content-center">
                <img src={BoosterImg} alt="volume" className="img-fluid left-img booster-img " />
              </div>
            </Col>
            <Col>
              <div className="ecosystem-corners-info-col">
                <h2 className="title font-size-40 pb-1 pt-3 white-color font-pnb">
                  Booster Devices
                  <span className="purple-color">.</span>
                </h2>
                <p className="font-pnr font-size-20 pb-2 lightgrey-color ">
                  Owning multiple smart devices integrated into the system will provide the user with a cumulative boost for
                  mining, thus ensuring greater rewards and overall increased earnings.
                </p>
                <button
                  className="read-more-btn font-pnsb font-size-20 blue-color d-flex justify-content-start align-items-baseline align-content-center"
                  onClick={() => navigate(routes.rewards)}
                >
                  Read more
                  <ArrowRight />
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
};

export default CorneredCards;
