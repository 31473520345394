import React from 'react';
import './PrizePool.scss';
import DashRight from "../assets/dash-right.png";
import DashLeft from "../assets/dash-left.png";
import { Col, Row } from 'react-bootstrap';

import Design1st from "../assets/pool1st.png";
import Design2nd from "../assets/pool2nd.png";
import Design3rd from "../assets/pool3rd.png";
import Perf1st from "../assets/perf1st.png";
import Perf2bd from "../assets/perf2nd.png";

import FirstImg from "../assets/first.png";
import FirstImg2 from "../assets/first2.png";
import SecondImg from "../assets/second.png";
import SecondImg2 from "../assets/second2.png";
import ThirdImg from "../assets/third.png";
import ThirdImg2 from "../assets/third2.png";
import Uppertitle from '../../../components/Uppertitle/Uppertitle';

const PrizePool = () => {
    return (
        <section id="prizes-pool-section" className="page-section ">
            <div className="container-main">
                <div className="title-container d-flex flex-column align-items-center mb-5">
                    <img src={DashLeft} alt="dash" className='me-auto img-fluid' />
                    <h2 className="my-4 font-size-80 white-color font-pnb line-height-11 text-center my-0">Prize Pool<span className="purple-color">.</span></h2>
                    <img src={DashRight} alt="dash" className='ms-auto img-fluid' />
                </div>

                <Uppertitle title={"PRIZE"} />
                <div className='description-container me-auto'>
                    <p className='font-size-24 font-pnr lightgrey-color line-height-11 text-start mt-3'>Multiple integrated distributed processes ensure that all peers reach a common agreement and establish the optimal operation of the network.</p>
                </div>

                <div className='prizes pt-5'>
                    <Row className="justify-content-between">
                        <Col xl={5} lg={6} md={6} sm={12}>
                            <div className="text-center corners p-4 p-lg-5 my-0">
                                <div className="design-title font-size-40 font-pnb text-center mb-5">Pool Design</div>

                                {/* Prize */}
                                <div className='prizes-container d-flex align-items-start justify-content-start flex-column'>
                                    <div className='prize-container d-flex align-items-end justify-content-start '>
                                        <div className='place-container d-flex align-items-center justify-content-start flex-row'>
                                            <img src={FirstImg} alt="first place" className="img-fluid prize-img" />
                                            <span className='font-pnsb font-size-16 gradient-1st ms-2'>1st place:</span>
                                        </div>
                                        <div className='prizes-list'>
                                            <img src={Design1st} alt="prizes" className='img-fluid prize-design-1st' />
                                        </div>
                                    </div>

                                    {/* Prize */}
                                    <div className='prize-container d-flex align-items-center justify-content-start mt-5'>
                                        <div className='place-container d-flex align-items-center justify-content-start flex-row'>
                                            <img src={SecondImg} alt="first place" className="img-fluid prize-img" />
                                            <span className='font-pnsb font-size-16 gradient-2nd ms-2'>2nd place:</span>
                                        </div>
                                        <div className='prizes-list'>
                                            <img src={Design2nd} alt="prizes" className='img-fluid prize-design-2nd' />
                                        </div>
                                    </div>

                                    {/* Prize */}
                                    <div className='prize-container d-flex align-items-center justify-content-start mt-5'>
                                        <div className='place-container d-flex align-items-center justify-content-start flex-row'>
                                            <img src={ThirdImg} alt="first place" className="img-fluid prize-img" />
                                            <span className='font-pnsb font-size-16 gradient-3rd ms-2'>3rd place:</span>
                                        </div>
                                        <div className='prizes-list'>
                                            <img src={Design3rd} alt="prizes" className='img-fluid prize-design-3rd' />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </Col>
                        <Col xl={5} lg={6} md={6} sm={12} className="performance-col">
                            <div className="text-center corners p-4 p-lg-5 mb-0 mt-md-0">
                                <div className="performance-title font-size-40 font-pnb text-center mb-5">Pool Performance</div>

                                {/* Prize */}
                                <div className='prize-container d-flex align-items-end justify-content-start'>
                                    <div className='place-container d-flex align-items-center justify-content-start flex-row'>
                                        <img src={FirstImg2} alt="first place" className="img-fluid prize-img" />
                                        <span className='font-pnsb font-size-16 gradient-1st ms-2'>1st place:</span>
                                    </div>
                                    <div className='prizes-list'>
                                        <img src={Perf1st} alt="prizes" className='img-fluid prize-design-1st' />
                                    </div>
                                </div>

                                {/* Prize */}
                                <div className='prize-container d-flex align-items-end justify-content-start mt-5'>
                                    <div className='place-container d-flex align-items-center justify-content-start flex-row'>
                                        <img src={SecondImg2} alt="first place" className="img-fluid prize-img" />
                                        <span className='font-pnsb font-size-16 gradient-2nd ms-2'>2nd place:</span>
                                    </div>
                                    <div className='prizes-list'>
                                        <img src={Perf2bd} alt="prizes" className='img-fluid prize-design-1st' />
                                    </div>
                                </div>

                                {/* Prize */}
                                <div className='prize-container d-flex align-items-center justify-content-start mt-5'>
                                    <div className='place-container d-flex align-items-center justify-content-start flex-row'>
                                        <img src={ThirdImg2} alt="first place" className="img-fluid prize-img" />
                                        <span className='font-pnsb font-size-16 gradient-3rd ms-2'>3rd place:</span>
                                    </div>
                                    <div className='prizes-list'>
                                        <img src={Design3rd} alt="prizes" className='img-fluid prize-design-3rd' />
                                    </div>
                                </div>

                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </section>
    )
}

export default PrizePool