import React from "react";
import "./ConnectedDevices.scss";
import { Row, Col } from "react-bootstrap";
import ConnectedXid from "../../../assets/images/png/sdr-layer/connected-xiden.png";

const ConnectedDevices = () => {
  return (
    <section id="connected-devices" className="page-section">
      <div className="container-main d-flex align-items-center justify-content-center flex-column">
        <Row className="d-flex align-items-center justify-content-center connected-row">
          <div className="col-md-6 col-12">
            <div className="mobile-flex d-flex d-lg-none">
              <img src={ConnectedXid} alt="xiden" className="img-fluid connected-xid-img"></img>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="mobile-flex text-end">
              <h3 className="font-size-80 font-pnb line-height-11 first-title mb-0">
                Connect <span className="devices-gradient">Devices</span>
              </h3>

              <h3 className="font-size-80 font-pnb line-height-11 mb-0">
                Share <span className="resources-gradient">Resources</span>{" "}
              </h3>
              <h3 className="font-size-80 font-pnb line-height-11 mb-0">
                Earn <span className="rewards-gradient">Reward$</span>
              </h3>

              <p className="font-size-24 font-pnr line-height-11 mt-4 description-sdr mt-custom">
                SDR is a utility layer integrated into the Xiden blockchain that collects all the resources distributed by the
                network’s registered devices and makes them available for all users, in a decentralized manner, according to their
                needs.
              </p>
            </div>
          </div>
        </Row>
      </div>
    </section>
  );
};

export default ConnectedDevices;
