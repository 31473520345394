import React from "react";
import "./SdrBenefits.scss";
import { Col, Row } from "react-bootstrap";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";

import { ReactComponent as VMS } from "../../../assets/images/svg/sdr-layer/vms-icon.svg";

import { ReactComponent as CPU } from "../../../assets/images/svg/sdr-layer/cpu-icon.svg";

import { ReactComponent as IP } from "../../../assets/images/svg/sdr-layer/ip-icon.svg";

import { ReactComponent as GPU } from "../../../assets/images/svg/sdr-layer/gpuicon.svg";
import { ReactComponent as Connectivity } from "../../../assets/images/svg/EcosystemPage/benefits-bitcoin.svg";

const SdrBenefits = () => {
  return (
    <section id="sdr-benefits" className="page-section">
      <div className="container-main d-flex flex-column">
        <Row className="title align-items-start flex-column">
          <Uppertitle title={"SDR BENEFITS"} />
          <h2 className="title font-size-80 pb-0 mb-0 white-color font-pnb">
            Where it can be used
            <span className="purple-color">.</span>
          </h2>

          <h4 className="font-pnb gradient-subtitle font-size-56 pt-lg-4 pt-3 pb-lg-3 pb-2">Multiple use cases.</h4>
          <p className="font-pnr mb-4 font-size-24 white-color title-description">
            The resources made available for developers by the Xiden network can be used for any project development.
          </p>
        </Row>
        <Row className="mt-4 mt-lg-5 cards-row align-items-center">
          <Col xl md={4} sm={6}>
            <div className="column-wrapper pe-2 d-flex flex-column  align-items-start corners">
              <div className="logo-container mb-3 d-flex justify-content-center align-items-center align-content-center">
                {/* <img src={Scissor} alt="scissor" className="img-fluid" /> */}
                <GPU />
              </div>
              <div className="container-content">
                <h4 className="font-pnb font-size-32 white-color mb-0">
                  GPU
                  <span className="purple-color">.</span>
                </h4>
                <p className="font-pnr pt-3 font-size-18 lightgrey-color">
                  An important resource of computing technology used in a wide range of applications, including graphics and video
                  rendering.
                </p>
              </div>
            </div>
          </Col>
          <Col xl md={4} sm={6}>
            <div className="column-wrapper pe-2 d-flex flex-column  align-items-start corners">
              <div className="logo-container mb-3 d-flex justify-content-center align-items-center align-content-center">
                {/* <img src={Connectivity} alt="Connectivity" className="img-fluid" /> */}
                <CPU />
              </div>
              <div className="container-content">
                <h4 className="font-pnb font-size-32 white-color mb-0">
                  CPU
                  <span className="purple-color">.</span>
                </h4>
                <p className="font-pnr pt-3 font-size-18 lightgrey-color">
                  The brain of all digital systems, the CPU is responsible for doing all the work. It performs every single action
                  a computer does and executes programs.
                </p>
              </div>
            </div>
          </Col>
          <Col xl md={4} sm={6}>
            <div className="column-wrapper pe-2 d-flex flex-column  align-items-start corners">
              <div className="logo-container mb-3 d-flex justify-content-center align-items-center align-content-center">
                {/* <img src={Security} alt="security" className="img-fluid" /> */}
                <Connectivity />
              </div>
              <div className="container-content">
                <h4 className="font-pnb font-size-32 white-color mb-0">
                  Storage
                  <span className="purple-color">.</span>
                </h4>
                <p className="font-pnr pt-3 font-size-18 lightgrey-color">
                  High storage capacity as every device registered in the network provides storage capacity and computational
                  power.
                </p>
              </div>
            </div>
          </Col>
          <Col xl md={4} sm={6}>
            <div className="column-wrapper pe-2 d-flex flex-column  align-items-start corners">
              <div className="logo-container mb-3 d-flex justify-content-center align-items-center align-content-center">
                {/* <img src={Anonimity} alt="Anonymity" className="img-fluid" /> */}
                <IP />
              </div>
              <div className="container-content">
                <h4 className="font-pnb font-size-32 white-color mb-0">
                  IP
                  <span className="purple-color">.</span>
                </h4>
                <p className="font-pnr pt-3 font-size-18 lightgrey-color">
                  Protected IP address as the network’s layers secures connections and traffic sources.
                </p>
              </div>
            </div>
          </Col>
          <Col xl md={4} sm={6}>
            <div className="column-wrapper pe-2 d-flex flex-column  align-items-start corners">
              <div className="logo-container mb-3 d-flex justify-content-center align-items-center align-content-center">
                {/* <img src={Passiveincome} alt="Passive income" className="img-fluid" /> */}
                <VMS />
              </div>
              <div className="container-content">
                <h4 className="font-pnb font-size-32 white-color mb-0">
                  VMS
                  <span className="purple-color">.</span>
                </h4>
                <p className="font-pnr pt-3 font-size-18 lightgrey-color">
                  Every registered device in the Xiden network can be used as a virtual machine.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default SdrBenefits;
