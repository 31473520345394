import "../../../template/color.scss";
import "../../../template/container.scss";
import "../../../template/fonts.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Changelog.scss";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import { Container, Row, Col, Tooltip, OverlayTrigger } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import { routes } from "../../../Utils/routes";
import { useState } from "react";
import GetRewardsRight from "../../../assets/images/png/homePage/get-rewards-right.png";
import ChangeLogCards from "../../ChangeLog/ChangeLogCards/ChangeLogCards";
import { ReactComponent as ArrowRight } from "../../../assets//images/svg/arrow-right.svg";

export default function Changelog(props) {
  const [isHomepage, setIsHomepage] = useState(true);
  const navigate = useNavigate();
  // TODO padding not consistent with the rest of the page
  // check #be-part for inspiration
  function switchMe(description) {
    // console.log("switch " + description);
    switch (description) {
      case 1:
        return "Metamask";
      case 2:
        return "Ethers";
      case 3:
        return "Hardhat";
      case 4:
        return "Solidity";
    }
  }
  const renderTooltip = (props, description) => (
    <Tooltip id="button-tooltip" {...props}>
      {switchMe(description)}
    </Tooltip>
  );
  // TODO breakpoint not consisten with rest of the page
  // use col- for "designed-for-developers white-color " and "container-main developers-second-section"
  return (
    <>
      <div className="container-main what-contianer mt-5  mb-5">
        <section id="change-log-section" className=" pb-0  mb-4">
          <div className="hero-section-change-log d-flex  flex-column">
            <Uppertitle title="UPDATES" />
            <h2 className="font-size-80 font-pnb line-height-11 changelog-title white-color">
              Changelog<span className="purple-color">.</span>
            </h2>
          </div>
        </section>
        <ChangeLogCards isHomepage={isHomepage} />
        <button
          className="buttonBlue mt-5 m-auto"
          onClick={() => navigate(routes.changeLog)}
        >
          <span className="me-2">Load more</span>
          <ArrowRight />
        </button>
      </div>
    </>
  );
}
