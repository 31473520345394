import React from "react";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";

const FeaturesCard = (props) => {
  return (
    <div>
      <div className={`${props.bg} column-wrapper big-column p-4 p-lg-5 d-flex flex-column justify-content-between`}>
        <Uppertitle title={props.uppertitle} />
        <div className="card-img">
          {props.imgUrl && <img src={props.imgUrl} alt="connected devices" className="img-fluid connected-devices" />}

          {props.svgProp && props.svgProp}
        </div>
        <h4 className="white-color py-lg-0 py-4 font-size-48 font-pnb m-auto ms-0 card-description">
          {props.description}
          {props.uppertitle !== "LOW ENERGY" && <span className="purple-color">.</span>}
        </h4>
      </div>
    </div>
  );
};

export default FeaturesCard;
