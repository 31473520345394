import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import "./RewardCalculator.scss";
import ImpulseIcon from "../../assets/images/svg/hidr-ico.svg";
import MinterIcon from "../../assets/images/svg/minter-guardian-icon.svg";
import BasmIcon from "../../assets/images/svg/basm-ico.svg";
import CustomToggleButton from "../ToggleButton/CustomToggleButton";
// import { AiOutlineArrowRight as ArrowRight } from "react-icons/ai";
import { RiErrorWarningLine as WarningSign } from "react-icons/ri";
import { ReactComponent as FireIcon } from "../../assets/images/svg/fire.svg";
import MyCountdown from "react-countdown";
import CountdownService from "../../Views/Homepage/Countdown/CountdownService";
import { Overlay, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { ReactComponent as ArrowRight } from "../../assets//images/svg/arrow-right.svg";
import { ReactComponent as CalculatorLogo } from "../../assets//images/svg/calculator.svg";
import { ReactComponent as RefreshLogo } from "../../assets//images/svg/refresh-ico.svg";
import { HashLink } from "react-router-hash-link";
import { routes } from "../../Utils/routes";
import { toast } from "react-toastify";


const RewardCalculator = () => {

   const target = useRef(null);

   const [showTooltip, setShowTooltip] = useState(() => false);

   const [refreshDisable, setRefreshDisable] = useState(() => false)

   const [priceAvailable, setPriceAvailable] = useState(() => false);


   const [toggleDifficulty, setToggleDifficulty] = useState(() => false);
   const [activeMinter, setActiveMinter] = useState(() => 1);
   const [activeBasm, setActiveBasm] = useState(() => 0);
   const [activeHidr, setActiveHidr] = useState(() => 0);
   const [showRewards, setShowRewards] = useState(() => false)

   const [privateDifficulty, setPrivateDifficulty] = useState(() => 0)
   const [publicDifficulty, setPublicDifficulty] = useState(() => 0)

   const handleToggleDifficulty = () => setToggleDifficulty(!toggleDifficulty);
   const [computingInput, setComputingInput] = useState(() => "");

   const [dailyInput, setDailyInput] = useState(() => "0 XDEN");
   const [weeklyInput, setWeeklyInput] = useState(() => "0 XDEN");
   const [monthlyInput, setMonthlyInput] = useState(() => "0 XDEN");

   const getPublicPrivateDifficulty = async () => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
         "toggleDifficulty": toggleDifficulty,
         "size": 1,
         "basmInput": parseInt(activeBasm),
         "hidrInput": +parseInt(activeHidr),
         "minterInput": parseInt(activeMinter),
         "devicesInput": 0
      });

      var requestOptions = {
         method: 'POST',
         headers: myHeaders,
         body: raw,
         redirect: 'follow'
      };
      try {
         const checkReq = await axios.post('https://api.heimdall.ro/calculatorFunction', requestOptions);
         if (checkReq.status === 200) {

            setPrivateDifficulty(checkReq.data.privateDifficulty);
            setPublicDifficulty(checkReq.data.publicDifficulty);

         }
      } catch (err) {
         if (err.response) {
            switch (err.response.status) {
               case 404:
                  console.log("404 Not found.");
                  return;
               default:
                  console.log("Something is temporarily wrong.");
                  return;
            }
         }
      }
   }
   const getPublicPrivateRewards = async (toggleDifficulty) => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = {
         toggleDifficulty: toggleDifficulty,
         size: 1,
         basmInput: parseInt(activeBasm),
         hidrInput: parseInt(activeHidr),
         minterInput: parseInt(activeMinter),
         devicesInput: 0
      };

      var requestOptions = {
         method: 'POST',
         headers: myHeaders,
         body: raw,
         redirect: 'follow'
      };
      try {
         const checkReq = await axios.post('https://api.heimdall.ro/calculatorFunction', raw, requestOptions);
         if (checkReq.status === 200) {
            setDailyInput(checkReq.data.dailyInput)
            setWeeklyInput(checkReq.data.weeklyInput)
            setMonthlyInput(checkReq.data.monthlyInput)
         }
      } catch (err) {
         setPriceAvailable(false)
         if (err.response) {
            switch (err.response.status) {
               case 404:
                  console.log("404 Not found.");
                  return;
               default:
                  console.log("Something is temporarily wrong.");
                  return;
            }
         }
      }
   }
   useEffect(() => {
      getPublicPrivateDifficulty()
   }, [])

   const myCalculatorFunction = async () => {
      await getPublicPrivateRewards(toggleDifficulty)
   }


   const handleDevicesChange = () => {
      let result = 0;

      if (activeBasm) result += 83
      if (activeHidr) result += 54
      if (activeMinter) result += 49

      setComputingInput(result)
   }

   useEffect(() => {
      handleDevicesChange()
   }, [activeBasm, activeHidr, activeMinter])


   const handleComputing = event => setComputingInput(event.target.value);

   const handleDaily = event => setDailyInput(event.target.value);
   const handleWeekly = event => setWeeklyInput(event.target.value);
   const handleMonthly = event => setMonthlyInput(event.target.value);

   return (
      <div className="text-white reward-calculator position-relative">
         <div className="reward-calculator-content">
            <div className="px-xl-5 px-sm-4 px-3 pt-xl-5 pt-sm-4 pt-3 pb-4">
               <h4 className="mb-5 font-size-40 font-pnb calc-heading">Calculate reward</h4>


               <div className="mb-4 w-100 d-flex justify-content-between middle-inputs">
                  <div className="me-2 mi-flex-row d-flex flex-column">
                     <div className="mb-2 mi-flex-row-div justify-content-end d-flex align-items-center">

                        <span className="ms-2 mi-flex-row-div-span font-size-24 font-pnr setting-item"></span>
                        <label htmlFor="router-radio" className="ms-2 mi-flex-row-div-span font-size-24 font-pnr setting-item pointer">
                           <img src={MinterIcon} className="me-1" alt="impulse" />
                           Router
                        </label>
                        <input
                           id="router-radio"
                           type="checkbox"
                           checked={activeMinter}
                           onChange={() => {
                              setActiveMinter(activeMinter === 1 ? 0 : 1)
                           }}
                           className="ms-3 calculator-checkbox"
                        />
                     </div>
                  </div>

                  <div className="me-2 mi-flex-row d-flex flex-column">
                     <div className="mb-2 mi-flex-row-div justify-content-end d-flex align-items-center">
                        <label htmlFor="basm-radio" className="ms-2 mi-flex-row-div-span font-size-24 font-pnr setting-item pointer">
                           <img src={BasmIcon} className="me-1" alt="impulse" />
                           BASM
                        </label>
                        <input
                           id="basm-radio"
                           type="checkbox"
                           checked={activeBasm}
                           onChange={() => {
                              setActiveBasm(activeBasm === 1 ? 0 : 1)
                           }}
                           className="ms-3 calculator-checkbox"
                        />
                     </div>
                  </div>

                  <div className="mi-flex-row d-flex flex-column">
                     <div className="mb-2 mi-flex-row-div justify-content-end d-flex align-items-center">
                        <label htmlFor="hidr-radio" className="ms-2 mi-flex-row-div-span font-size-24 font-pnr setting-item pointer">
                           <img src={ImpulseIcon} className="me-1" alt="impulse" />
                           HidR
                        </label>
                        <input
                           id="hidr-radio"
                           type="checkbox"
                           checked={activeHidr}
                           onChange={() => {
                              setActiveHidr(activeHidr === 1 ? 0 : 1)
                           }}
                           className="ms-3 calculator-checkbox"
                        />
                     </div>
                  </div>
               </div>

               <div className="w-100 d-flex justify-content-between align-items-center toggle-container">
                  <div className="d-flex flex-column align-items-center justify-content-center me-3  private-public-container">
                     <span
                        className={` font-size-24 font-pnr toggle-txt setting-item ${toggleDifficulty ? "darkgrey-color" : "text-white"
                           }`}
                     >
                        Private <br className="d-none"></br> difficulty
                     </span>
                     <input disabled type="text" value={privateDifficulty} className={` calculateInputs font-size-24 font-pnr toggle-txt setting-item private-difficulty ${toggleDifficulty ? "darkgrey-color" : "text-white"}`} />
                  </div>

                  <CustomToggleButton checkState={toggleDifficulty} toggle={handleToggleDifficulty} />

                  <div className="d-flex flex-column align-items-center justify-content-center ms-3 private-public-container">
                     <span
                        className={`font-size-24 font-pnr toggle-txt setting-item public-number ${toggleDifficulty ? "text-white" : "darkgrey-color"
                           }`}
                     >
                        Public <br className="d-none"></br> difficulty
                     </span>
                     <input disabled type="text" value={publicDifficulty} className={` calculateInputs font-size-24 font-pnr toggle-txt setting-item ${toggleDifficulty ? "text-white" : "darkgrey-color"}`} />
                  </div>
               </div>
               <div className="my-sm-5 my-4 w-100 d-flex align-items-center justify-content-between">
                  <span className="font-size-24 font-pnr setting-item">Validating power</span>
                  <input type="text" disabled placeholder="cycles" className="calculateInputs" value={computingInput === 0 ? "cycles" : computingInput} onChange={handleComputing} />
               </div>
               <div className="mt-sm-5 mb-sm-3 mt-4 mb-3 w-100 d-flex align-items-center justify-content-start">
                  <WarningSign className="warning-svg me-2" />
                  <span className="font-size-18 font-pnr setting-item">All calculations are made based on the network difficulty.</span>
               </div>
               <HashLink className="w-100 buttonOutlineBlue custom-button-redirect font-size-24 mb-4 font-pnsb" to={`${routes.rewards}#rewards-calculator-section`}>
                  <CalculatorLogo className="me-2" />
                  Advanced calculator
               </HashLink>
               <button className={`w-100 buttonBlue custom-button font-size-24 font-pnsb ${showRewards ? "d-none" : ""}`}
                  onClick={() => {
                     // if (priceAvailable) {
                     myCalculatorFunction();
                     setShowRewards(true);
                     setRefreshDisable(false)
                     // }
                     // else {
                     //    return toast.error("Service unavailable for the moment!", { toastId: "error-fetching-data" })
                     // }
                  }}>
                  Calculate reward
                  <ArrowRight className="ms-2" />
               </button>
            </div>


            <div className={showRewards ? "" : "d-none"}>
               <div className="calculate-reward-separator"></div>

               <div className="px-xl-5 px-sm-4 px-3 pb-xl-5 pb-sm-4 pb-3 pt-4">

                  <div className="d-flex justify-content-start align-items-center mb-3">
                     <FireIcon className="me-2" />
                     <p className="font-size-40 font-pnb mb-0 total-txt line-height-11">Total reward earned:</p>
                     <Button disabled={refreshDisable} ref={target} className="ms-auto my-refresh-button" onClick={() => { setRefreshDisable(true); setTimeout(() => { setShowRewards(false) }, 300) }} onMouseEnter={() => { setShowTooltip(!showTooltip) }} onMouseLeave={() => setShowTooltip(false)}>
                        <RefreshLogo />
                     </Button>
                     <Overlay placement="bottom" show={showTooltip} target={target.current}>
                        {(props) => (
                           <Tooltip {...props} id="tooltip-disabled" className="d-lg-block d-none">Calculate again</Tooltip>
                        )}
                     </Overlay>
                  </div>

                  <div className="mb-4 w-100 d-flex justify-content-between middle-inputs">
                     <div className="me-2 mi-flex-row d-flex flex-column">
                        <div className="mb-2 d-flex align-items-center">
                           <span className="ms-2 font-size-24 font-pnr setting-item">Daily</span>
                        </div>
                        <input type="text" disabled className="calculateInputs" value={dailyInput + "XDEN"} onChange={handleDaily} />
                     </div>

                     <div className=" me-2 mi-flex-row d-flex flex-column">
                        <div className="mb-2 d-flex align-items-center">
                           <span className="ms-2 font-size-24 font-pnr setting-item">Weekly</span>
                        </div>
                        <input type="text" disabled className="calculateInputs" value={weeklyInput + "XDEN"} onChange={handleWeekly} />
                     </div>

                     <div className="mi-flex-row d-flex flex-column">
                        <div className="mb-2 d-flex align-items-center">
                           <span className="ms-2 font-size-24 font-pnr setting-item">Monthly</span>
                        </div>
                        <input type="text" disabled className="calculateInputs" value={monthlyInput + "XDEN"} onChange={handleMonthly} />
                     </div>
                  </div>
               </div>

            </div>
         </div>

      </div >
   );
};

export default RewardCalculator;
