import React from "react";
import "./XidenPartner.scss";
import XidenPartnerImg from "../../../assets/images/png/meta-realm-page/xiden-partner-img.png";
// import { ReactComponent as ArrowRight } from "../../../assets//images/svg/arrow-right.svg";
// import { toast } from "react-toastify";

const XidenPartner = () => {
  return (
    <div className="text-white page-section meta-xiden-partner">
      <div className="container-main d-flex flex-column align-items-center">
        <div className="w-100 mb-5 d-flex justify-content-center">
          <img src={XidenPartnerImg} alt="xiden partner" className="xiden-partner-img" />
        </div>

        <div className="line-height-11">
          <p className="text-center mb-0 font-size-80 font-pnb">Become Xiden Partner</p>
          <p className="text-center mb-0 font-size-80 font-pnb">
            and own a Realm Metanode<span className="pink-text">.</span>
          </p>
        </div>

        <div className="meta-centered-text ">
          <p className="mb-0 text-center font-pnb lightgrey-color font-size-32 line-height-11">
            Be one of our partners and you can acquire one of the 30 Realm Metanodes distributed across the world.
          </p>
        </div>

        {/* <div className="w-100 d-flex justify-content-center">
               <button
                  className="buttonBlue"
                  onClick={() => toast.info("Coming soon...", { toastId: "derjfnjkfrfrn" })}
               >
                  <span className="me-2">Contact us</span>
                  <ArrowRight />
               </button>
            </div> */}
      </div>
    </div>
  );
};

export default XidenPartner;
