import React, { useContext, useEffect, useState } from "react";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import ReactCardFlip from 'react-card-flip';
import { rotateContext } from "./CardsSection1V2";

const StakingCard = ({ cardData, currentRotate, index }) => {

    const { rotate, setRotate } = useContext(rotateContext)

    const [rotateCopy, setRotateCopy] = useState(rotate)
    const [rerender, setRerender] = useState(false)

    const handleSetRotate = (index) => {
        setRotateCopy(rotate)
        setRerender(!rerender)
        rotateCopy[index] = !rotateCopy[index];
        setRotate(rotateCopy);
    }

    const matchCardBackground = (cardUpperTitle) => {
        switch (cardUpperTitle) {
            case "LOW ENERGY":
                return "card1-bg";

            case "SECURED":
                return "card2-bg";

            case "NO FRAUD":
                return "card3-bg";

            case "QUICK":
                return "card4-bg";

            default:
                return "no-bg"
        }
    };


    return (
        <div
            id={`xiden-card${cardData.id}-box`}
            className={`xiden-card${cardData.id}-box-app-card xiden-card${cardData.id}-box-card`}
        >
            <div className="p-4 position-relative transform-card h-100">
                <div
                    onClick={() => handleSetRotate(index)}
                    className={`h-100 ${rotate[index] ? `rotate-xiden-card${cardData.id}-box-front-side` : ``
                        } d-flex flex-column justify-content-start xiden-card-box-app xiden-card${cardData.id}-box-card-side xiden-card${cardData.id}-box-front-side p-lg-4 p-3
                        ${matchCardBackground(cardData.cardUpperTitle)}
                        `}
                >
                    <Uppertitle title={cardData.cardUpperTitle} />
                    <div className=" d-flex align-items-center justify-content-start">
                        {cardData.img}
                    </div>
                    {cardData.title}
                    <div className={`plus-btn plus-btn-xiden-card${cardData.id}-box d-flex justify-content-center align-items-center position-relative mt-auto`}>
                        {cardData.plusImg}
                    </div>
                </div>
                <div
                    onClick={() => handleSetRotate(index)}
                    className={`h-100 ${rotate[index] ? `rotate-xiden-card${cardData.id}-box-back-side` : ""
                        } d-flex flex-column justify-content-start xiden-card-box-app xiden-card${cardData.id}-box-card-side xiden-card${cardData.id}-box-back-side p-lg-4 p-3
                        ${matchCardBackground(cardData.cardUpperTitle)}
                        `}
                >
                    <Uppertitle title={cardData.cardUpperTitle} />
                    {cardData.backsideTitle}
                    <div className={`plus-btn plus-btn-xiden-card${cardData.id}-box d-flex justify-content-center align-items-center position-relative mt-auto plus-btn-rotate`}>
                        {cardData.plusImg}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StakingCard;
