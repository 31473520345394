import React from "react";
import "./Calculator.scss";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import RewardCalculatorLong from "../../../components/RewardCalculator/RewardCalculatorLong";
import RewardCalculatorImg from "../../../assets/images/png/Rewards-reward-calculator.png";

const Calculator = () => {
  return (
    <div className="page-section calculator-section" id="rewards-calculator-section">
      <div className="container-main reward-container">
        <div className="info-col">
          <img
            src={RewardCalculatorImg}
            loading="lazy"
            alt="Reward Calculator"
            className="img-fluid calculator-img mb-lg-5 mb-3"
          />
          <div>
            <Uppertitle title={`POWER`} />
            <div className="line-height-1">
              <p className="mb-0 font-size-80 font-pnb line-height">Reward</p>
              <p className="font-size-80 font-pnb">
                Calculator
                <span className="pink-text">.</span>
              </p>
            </div>
            <div className="description description-991 pb-4 paragraph-container">
              <p className="font-family-pnr font-size-24 lightgrey-color line-height-11">
                Calculate your reward rates according to your staking, type of devices, and your preferred mining difficulty.
              </p>
            </div>
          </div>
          {/* <img src={RewardCalculatorImg} alt="Reward Calculator" className="img-fluid d-none img-mobile mb-lg-5 mb-3" /> */}
        </div>

        <div className="calculator-col">
          <RewardCalculatorLong />
        </div>
      </div>
    </div>
  );
};

export default Calculator;
