import React from "react";
import HeroSection from "./HeroSection/HeroSection";
import SupportSteps from "./SupportKraterSteps/SupportSteps";
import Announcement from "../SupportStake/Announcement/Announcement"
//NOW IT IS SUPPORT MINTER GUARDIAN
export default function SupportKraterApp() {
  return (
    <>
      <HeroSection />
      <SupportSteps />
      {/* <Announcement /> */}
    </>
  );
}
