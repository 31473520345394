import React from "react";
import "./XidenFeatures.scss";
import HeroSection from "./HeroSection/HeroSection";
import XidenAttributes from "./XidenAttributes";
import Features from "./Features/Features";
import HowTo from "./HowTo/HowTo";
import { Helmet } from "react-helmet";
import SchemaTemplate from "../../SEO/SchemaTemplate.json";
import FeaturesMobileSection from "./FeaturesMobile/FeaturesMobileSection";

const XidenFeatures = () => {
  return (
    <div className="xidenfeatures-page">
      {/* seo */}
      <Helmet>
        <title>Xiden | Digital Transferable Asset</title>
        <meta name="title" content="Xiden | Digital Transferable Asset" />
        <meta
          name="description"
          content="The asset that defines the value of the apps, resources and services from the blockchain, featuring low gas fees, automatic validation and top-of-the-line security."
        />
        <link rel="canonical" href="" />
        {/* Open Graph Facebook */}
        <meta property="og:title" content="Xiden | Digital Transferable Asset" />
        <meta
          property="og:description"
          content="The asset that defines the value of the apps, resources and services from the blockchain, featuring low gas fees, automatic validation and top-of-the-line security."
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        {/* Open Graph Twitter  */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="" />
        <meta property="twitter:title" content="Xiden | Digital Transferable Asset" />
        <meta
          property="twitter:description"
          content="The asset that defines the value of the apps, resources and services from the blockchain, featuring low gas fees, automatic validation and top-of-the-line security."
        />
        <meta property="twitter:image" content="" />
        {/* Structured Data */}
        <script>{JSON.stringify(SchemaTemplate)}</script>
      </Helmet>

      {/* sections */}
      <HeroSection />
      <Features />
      <FeaturesMobileSection />

      <HowTo />
      <XidenAttributes />
    </div>
  );
};

export default XidenFeatures;
