import Step1 from "../SupportKraterSteps/assets-steps/basm/step1.jpeg";
import Step2 from "../SupportKraterSteps/assets-steps/basm/step2.jpeg";
import Step3 from "../SupportKraterSteps/assets-steps/basm/step3.jpeg";
import Step4 from "../SupportKraterSteps/assets-steps/basm/step4.jpeg";
import Step5 from "../SupportKraterSteps/assets-steps/basm/step5.jpeg";
import Step6 from "../SupportKraterSteps/assets-steps/basm/step6.jpeg";
import SudoSleep from "../SupportKraterSteps/assets-steps/sudo-sleep.png"

export const accessMinterSteps = [
    {
        uppertitle: "STEP 01",
        title: "Open the Application Menu",
        punctuation: ".",
        content: <>
            <p className="font-pnr font-size-20 lightgrey-color line-height-11 text-start">
                Click on the "Show Applications" icon on the bottom menu. The button is the last one on the right, positioned at the bottom of your screen.
            </p>

        </>,
        image: Step1,
        modal: true,
    },
    {
        uppertitle: "STEP 02",
        title: "Open a Terminal window",
        punctuation: ".",
        content: <>
            <p className="font-pnr font-size-20 lightgrey-color line-height-11 text-start">
                Once the menu is open, type in the search box: "Terminal". Click on the icon that you can see on your screen.
            </p>

        </>,
        image: Step2,
        modal: true,
    },
    {
        uppertitle: "STEP 03",
        title: "Install Krater Validator App",
        punctuation: ".",
        content: <>
            <p className="font-pnr font-size-20 lightgrey-color line-height-11 text-start">
                {`Type in the terminal window the following command:`}
            </p>
            <br />
            <p className="font-pnr font-size-15 lightgrey-color line-height-11 text-start text-break">
                {`source <(curl -s https://cryptodata.com/basm-updates/mining/krater-mining.txt)`}
            </p>
            <br />
            <ul className="mt-0 pt-0 line-height-11">
                <li>○ <span className="lightgrey-color font-pnr font-size-20">Please make sure that you are connected to a WiFi network.</span></li>
                <li>○ <span className="lightgrey-color font-pnr font-size-20">{`Pay attention to the command above. There is no space between < and (`}.</span></li>
                <li>○ <span className="lightgrey-color font-pnr font-size-20">{`Pay attention to the command above. There is a space between curl and -s and https`}.</span></li>
            </ul>

        </>,
        image: Step3,
        modal: true,
    },
    {
        uppertitle: "STEP 04",
        title: "The application is now installing",
        punctuation: ".",
        content: <>
            <p className="font-pnr font-size-20 lightgrey-color line-height-11 text-start">
                Press "enter" and wait for approx 30-45 seconds. If successful, the installation process will end with a message "Installation complete, please close this terminal and search for Krater Mining".
            </p>
        </>,
        image: Step4,
        modal: true,
    },
    {
        uppertitle: "STEP 05",
        title: "Optional step",
        punctuation: ".",
        content: <>
            <p className="font-pnr font-size-20 lightgrey-color line-height-11 text-start">
                {`To keep the BASM laptop in a non-sleep mode type in the terminal window the following command:`}
            </p>
            <br />
            <p className="font-pnr font-size-15 lightgrey-color line-height-11 text-start text-break">
                {`sudo systemctl mask sleep.target suspend.target hibernate.target hybrid-sleep.target`}
            </p>
            <br />
            {/* <ul className="mt-0 pt-0 line-height-11">
                <li>○ <span className="lightgrey-color font-pnr font-size-20">Please make sure that you are connected to a WiFi network.</span></li>
                <li>○ <span className="lightgrey-color font-pnr font-size-20">{`Pay attention to the command above. There is no space between < and (`}.</span></li>
                <li>○ <span className="lightgrey-color font-pnr font-size-20">{`Pay attention to the command above. There is a space between curl and -s and https`}.</span></li>
            </ul> */}

        </>,
        image: SudoSleep,
        modal: true,
    },
    {
        uppertitle: "STEP 06",
        title: "Open the app and start configuring your Miner",
        punctuation: ".",
        content: <>
            <p className="font-pnr font-size-20 lightgrey-color line-height-11 text-start">
                Close the terminal and open again the menu with all applications. You should be able to see the application "Krater Mining".
            </p>
        </>,
        image: Step5,
        modal: true,
    },
    {
        uppertitle: "STEP 07",
        title: "Your BASM is ready for mining",
        punctuation: "!",
        content: <>
            <p className="font-pnr font-size-20 lightgrey-color line-height-11 text-start"> To add your BASM into your pool as a Miner, please follow this guide: <a className="blue-color tutorial-link" href="https://xiden.com/support-validator" target="_blank">How to setup a validator </a></p>
            <br />
            <ul className="mt-0 pt-0 text-center text-md-start line-height-11">
                <li>○ <span className="lightgrey-color font-pnr font-size-20">Please make sure that you have a CryptoData Encryption Key inserted into the USB-C port, otherwise the application will not work.</span></li>
            </ul>

        </>,
        image: Step6,
        modal: true,
    }
];