import "./HeroSection.scss";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import { Link as ScrollLink } from "react-scroll";
import { ReactComponent as ArrowRight } from "../../../assets/images/svg/arrow-right.svg";
import loop1 from "../../../assets/videos/private-network-page/spectralis-video.mp4";
import React from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../Utils/routes";

//TODO: Link spre set up network

const HeroSection = () => {
  const navigate = useNavigate();
  return (
    <section id="private-network-hero" className="page-section position-relative">
      <div className="container-main hero-section-container d-flex  align-items-center flex-column">
        <div className="main-col d-flex align-items-center justify-content-center text-center">
          <Uppertitle title={`PRIVATE NETWORK`} />
          <h2 className="font-size-80 white-color font-pnb first-h2 line-height-11 mb-0">Spectralis</h2>
          <h2 className="font-size-80 white-color font-pnb second-h2 line-height-11">
            Private Network
            <span className="purple-color">.</span>
          </h2>
          <div className="paragraph-container">
            <p className="font-family-pnr font-size-24 lightgrey-color line-height-11">
              The Spectralis Private Network is a private network that combines elements of technologies such as TOR, VPN, and
              VOBP to secure connections, traffic, communication sessions, data transfers, and devices.
            </p>
          </div>
          <div className="hero-btn-container w-100 d-flex align-items-center justify-content-center text-center mt-3">
            <button
              className="buttonBlue"
              onClick={() => {
                window.open("https://xiden.com/whitepaper/xiden-layers/decentralized/");
              }}
            >
              <span className="me-2">Whitepaper</span>
              <ArrowRight />
            </button>
            <button className="buttonOutlineBlue" onClick={() => navigate(routes.supportKraterPool)}>
              <span className="me-2">How to connect</span>
              <ArrowRight />
            </button>
          </div>
        </div>
        <video src={loop1} autoPlay playsInline muted loop={true} className="hero-video img-fluid " />
        {/* d-none d-lg-block */}
      </div>
    </section>
  );
};

export default HeroSection;
