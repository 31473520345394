import { ReactComponent as FreeInternetIcon } from "../../../assets/images/svg/small-cards/wifi-icon.svg";
import { ReactComponent as ComputingIcon } from "../../../assets/images/svg/small-cards/devices-icon.svg";
import { ReactComponent as ConnectionIcon } from "../../../assets/images/svg/small-cards/connection-icon.svg";
import { ReactComponent as PrivacyIcon } from "../../../assets/images/svg/small-cards/lock-icon.svg";
import { ReactComponent as StorageIcon } from "../../../assets/images/svg/small-cards/storage-icon.svg";
import { ReactComponent as SecurityIcon } from "../../../assets/images/svg/small-cards/shield-icon.svg";
import { ReactComponent as TransactionIcon } from "../../../assets/images/svg/small-cards/speed-icon.svg";
import { ReactComponent as PriceIcon } from "../../../assets/images/svg/small-cards/price-icon.svg";

export const cardsData = [
   {
      id: 1,
      icon: <FreeInternetIcon />,
      upperTitle: "FREE INTERNET",
      title: "Free access & connectivity to the internet",
   },
   {
      id: 2,
      icon: <ComputingIcon />,
      upperTitle: "COMPUTING POWER",
      title: "Computing power from your devices",
   },
   {
      id: 3,
      icon: <ConnectionIcon />,
      upperTitle: "P2P CONNECTION",
      title: "Easy file sharing with peer-to-peer connection",
   },
   {
      id: 4,
      icon: <PrivacyIcon />,
      upperTitle: "PRIVACY",
      title: "Privacy is one of our core values",
   },
   {
      id: 5,
      icon: <StorageIcon />,
      upperTitle: "STORAGE",
      title: "Fully decentralized storage",
   },
   {
      id: 6,
      icon: <SecurityIcon />,
      upperTitle: "SECURITY",
      title: "Your funds are as secure as possible",
   },
   {
      id: 7,
      icon: <TransactionIcon />,
      upperTitle: "QUICK TRANSACTIONS",
      title: "Best time execution on the market",
   },
   {
      id: 8,
      icon: <PriceIcon />,
      upperTitle: "AFFORDABLE FEES",
      title: "Super cheap transactions",
   },
];
