import React from "react";
import "./GuardianNode.scss";
import HeroSection from "./HeroSection/HeroSection";
// import NetworkRole from "./NetworkRole/NetworkRole";
import Router from "./Router/Router";
// import RewardsBooster from "./RewardsBooster/RewardsBooster";
// import RouterApps from "./RouterApps/RouterApps";
import RouterFunctions from "./RouterFunctions/RouterFunctions";
import GuardianSteps from "./GuardianSteps/GuardianSteps";
import ExtraStep from "./ExtraStep/ExtraStep";
import GuardianFeatures from "./GuardianFeatures/GuardianFeatures";
import { Helmet } from "react-helmet";
import SchemaTemplate from "../../SEO/SchemaTemplate.json";
import FullPower from "../Homepage/FullPower/FullPower";
import FullPowerCardOnly from "../Homepage/FullPower/FullPowerCardOnly";

const GuardianNode = () => {
   return (
      <div className="guardian-node-page">
         {/* seo */}
         <Helmet>
            <title>Xiden | Guardian Node</title>
            <meta name="title" content="Xiden | Guardian Node" />
            <meta name="description" content="A light validation node that verifies the conformity of both data and the system’s integrated devices, contributing to the network’s operation and providing resources for decentralized computing and storage power." />
            <link rel="canonical" href="" />
            {/* Open Graph Facebook */}
            <meta property="og:title" content="Xiden | Guardian Node" />
            <meta property="og:description" content="A light validation node that verifies the conformity of both data and the system’s integrated devices, contributing to the network’s operation and providing resources for decentralized computing and storage power." />
            <meta property="og:type" content="website" />
            <meta property="og:image" content="" />
            <meta property="og:url" content="" />
            {/* Open Graph Twitter  */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="" />
            <meta property="twitter:title" content="Xiden | Guardian Node" />
            <meta property="twitter:description" content="A light validation node that verifies the conformity of both data and the system’s integrated devices, contributing to the network’s operation and providing resources for decentralized computing and storage power." />
            <meta property="twitter:image" content="" />
            {/* Structured Data */}
            <script>{JSON.stringify(SchemaTemplate)}</script>
         </Helmet>

         {/* sections */}
         <HeroSection />
 

         <Router />
         <FullPowerCardOnly/>
         <RouterFunctions />
         <GuardianFeatures />
         <GuardianSteps />
         <ExtraStep />
         {/* <NetworkRole /> */}
         {/* <RewardsBooster /> */}
         {/* <RouterApps /> */}
      </div>
   );
};

export default GuardianNode;
