import "./Card.scss";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import PlusIcon from "./assets/plus-icon.png";
import React, { useState } from "react";
import Secured from "./assets/secured.png";
import Pointer from "./assets/pointer-control.png";
import EcoFriendly from "./assets/eco-friendly-img.png";

import Cube from "./assets/cube-img.png";

import CustomUpperTitle from "../CustomUpperTitle/CustomUpperTitle";

const Card = () => {
  const [rotateCard1, setRotateCard1] = useState(() => false);
  const [rotateCard2, setRotateCard2] = useState(() => false);
  const [rotateCard3, setRotateCard3] = useState(() => false);
  const [rotateCard4, setRotateCard4] = useState(() => false);
  const [rotateCard5, setRotateCard5] = useState(() => false);
  const [rotateCard6, setRotateCard6] = useState(() => false);

  const handleRotate = (card) => {
    switch (card) {
      case "card1":
        setRotateCard1(!rotateCard1);
        break;
      case "card2":
        setRotateCard2(!rotateCard2);
        break;
      case "card3":
        setRotateCard3(!rotateCard3);
        break;
      case "card4":
        setRotateCard4(!rotateCard4);
        break;
      case "card5":
        setRotateCard5(!rotateCard5);
        break;
      case "card6":
        setRotateCard6(!rotateCard6);
        break;
    }
  };

  return (
    <section id="sdr-card" className="page-section">
      <div className="container-main">
        <div className="text-center sdr-text-container d-flex flex-column align-items-center justify-content-center">
          <h2 className="line-height-1 font-size-80 font-pnb line-height-1 mb-0 smart-devices-title-1">
            designed by <span className="developers-gradient">Developers</span>
          </h2>
          <h2 className="line-height-1 font-size-80 font-pnb line-height-1 integrated smart-devices-title-2">
            built by <span className="users-gradient">Users</span>
          </h2>
          <p className="text-center font-pnr font-size-24 line-height-1 smart-devices-description pb-5 pt-3 darkgrey-color">
            Each user makes available its devices’ resources for the Xiden network and by doing this aids <br></br> the creation
            of a decentralized community that includes both users and developers.
          </p>
        </div>
        <div className="row om-bg pt-5 h-100">
          <div className="col-lg-10 lg-mb-4 md-mb-0">
            <div id="xiden-card6-box" className="xiden-card6-box-app-card xiden-card6-box-card">
              <div className="p-4 position-relative transform-card h-100">
                <div
                  onClick={() => handleRotate("card6")}
                  className={`${
                    rotateCard6 ? "rotate-xiden-card6-box-front-side" : ""
                  } d-flex flex-column justify-content-between xiden-card-box-app xiden-card6-box-card-side xiden-card6-box-front-side p-lg-5 p-4`}
                >
                  <CustomUpperTitle title={"SCALABILITY"} />

                  <div className="align-self-center">
                    <img src={Cube} alt="" className="img-fluid" />
                  </div>
                  <h4 className="line-height-1 white-color py-0 font-size-80 font-pnb ms-0 text-center">
                    Earn XDEN in exchange for your resources
                    <br /> and online activity.
                  </h4>
                  <div className="plus-btn plus-btn-xiden-card6-box d-flex justify-content-center align-items-center">
                    <img className="plus-icons plus-btn-xiden-card6-box-img" src={PlusIcon} alt="plus" />
                  </div>
                </div>
                <div
                  onClick={() => handleRotate("card6")}
                  className={`${
                    rotateCard6 ? "rotate-xiden-card6-box-back-side" : ""
                  } d-flex flex-column justify-content-between xiden-card-box-app xiden-card6-box-card-side xiden-card6-box-back-side p-lg-5 p-4`}
                >
                  <CustomUpperTitle title={"SCALABILITY"} />

                  {/* <div className="align-self-center">
                    <img src={Cube} alt="" className="img-fluid" />
                  </div> */}
                  <h4 className="line-height-1 white-color py-lg-0 py-0 font-size-56 font-pnb ms-0 text-center line-height-1">
                    You are rewarded with Xiden as long as you keep the devices connected to the system and provide to the Xiden
                    network necessary resources.
                  </h4>

                  <div className="plus-btn plus-btn-xiden-card6-box d-flex justify-content-center align-items-center">
                    <img
                      className={`${rotateCard6 ? "close-btn" : ""} plus-icons plus-btn-xiden-card6-box-img`}
                      src={PlusIcon}
                      alt="plus"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div  className="col-lg-1">
                    <img src={UserSilhouette} alt="" />
                    </div> */}
        </div>
        <div className="row h-100">
          <div className="col-12 col-lg-4 mt-4">
            <div id="xiden-card1-box" className="xiden-card1-box-app-card xiden-card1-box-card">
              <div className="p-4 position-relative transform-card h-100">
                <div
                  onClick={() => handleRotate("card1")}
                  className={`${
                    rotateCard1 ? "rotate-xiden-card1-box-front-side" : ""
                  } d-flex flex-column justify-content-between xiden-card-box-app xiden-card1-box-card-side xiden-card1-box-front-side p-lg-5 p-4`}
                >
                  <CustomUpperTitle title={"ECO FRIENDLY"} />
                  <div className="">
                    <img src={EcoFriendly} alt="" className="img-fluid" />
                  </div>
                  <h4 className="line-height-1 medium-green-text py-lg-0 py-0 font-size-40 font-pnb ms-0">
                    Integrate your
                    <br /> old unused devices.
                  </h4>
                  <div className="plus-btn plus-btn-xiden-card1-box d-flex justify-content-center align-items-center">
                    <img className="plus-icons plus-btn-xiden-card1-box-img" src={PlusIcon} alt="plus" />
                  </div>
                </div>
                <div
                  onClick={() => handleRotate("card1")}
                  className={`${
                    rotateCard1 ? "rotate-xiden-card1-box-back-side" : ""
                  } d-flex flex-column justify-content-between xiden-card-box-app xiden-card1-box-card-side xiden-card1-box-back-side p-lg-5 p-4`}
                >
                  <CustomUpperTitle title={"ECO FRIENDLY"} />
                  {/* <div className="">
                    <img src={EcoFriendly} alt="" className="img-fluid" />
                  </div> */}
                  <h4 className="line-height-1 medium-green-text py-lg-0 py-0 font-size-28 font-pnb ms-0">
                    Get rewards for putting your old devices to use by sharing their resources with the network.
                  </h4>

                  <div className="plus-btn plus-btn-xiden-card1-box d-flex justify-content-center align-items-center">
                    <img
                      className={`${rotateCard1 ? "close-btn" : ""} plus-icons plus-btn-xiden-card1-box-img`}
                      src={PlusIcon}
                      alt="plus"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-8 mt-4">
            <div id="xiden-card2-box" className="xiden-card2-box-app-card xiden-card2-box-card position-relative">
              <div className="transform-card h-100 position-relative">
                <div
                  onClick={() => handleRotate("card2")}
                  className={`${
                    rotateCard2 ? "rotate-xiden-card2-box-front-side" : ""
                  } xiden-card-box-app xiden-card2-box-card-side xiden-card2-box-front-side p-lg-5 p-4 overflow-hidden`}
                >
                  <div className="d-flex flex-column justify-content-between h-100">
                    <CustomUpperTitle title={"BENEFITS"} />
                    <h4 className="line-height-1 white-color py-lg-0 py-0 font-size-48 font-pnb ms-0">
                      Performant
                      <br /> Resources
                      <br />
                      Management.
                    </h4>
                    <div className="plus-btn-xiden-card2-box d-flex justify-content-center align-items-center plus-btn">
                      <img src={PlusIcon} className="plus-icons img-fluid plus-btn-xiden-card2-box-img" />
                    </div>
                  </div>
                </div>
                <div
                  onClick={() => handleRotate("card2")}
                  className={`${
                    rotateCard2 ? "rotate-xiden-card2-box-back-side" : ""
                  } xiden-card-box-app xiden-card2-box-card-side xiden-card2-box-back-side d-flex justify-content-between flex-column p-lg-5 p-4 overflow-hidden`}
                >
                  <div className="d-flex flex-column justify-content-between h-100">
                    <div className="mb-4 mb-sm-4 w-100 d-flex justify-content-start">
                      {/* <div className="d-flex py-1 px-2">
                        <span className="ml-2 font-size-18 text-nowrap">
                          BENEFITS
                        </span>
                      </div> */}
                      <CustomUpperTitle title={"BENEFITS"} />
                    </div>
                    <div className="d-flex flex-column">
                      <h4 className="line-height-1 white-color py-lg-0 py-0 font-size-28 font-pnb ms-0">
                        The resources management is performed <br></br> automatically to optimize the network’s <br></br>{" "}
                        operation and to offer benefits <br></br> to the community.
                      </h4>
                    </div>
                    <div className="plus-btn plus-btn-xiden-card2-box d-flex justify-content-center align-items-center">
                      <img
                        src={PlusIcon}
                        className={`${rotateCard2 ? "close-btn" : ""} plus-icons plus-btn-xiden-card2-box-img`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-12 mt-4">
            <div id="xiden-card3-box" className="xiden-card3-box-app-card xiden-card3-box-card">
              <div className="p-4 position-relative transform-card h-100">
                <div
                  onClick={() => handleRotate("card3")}
                  className={`${
                    rotateCard3 ? "rotate-xiden-card3-box-front-side" : ""
                  } d-flex flex-column justify-content-between xiden-card-box-app xiden-card3-box-card-side xiden-card3-box-front-side p-lg-5 p-4`}
                >
                  <CustomUpperTitle title={"SECURED"} />
                  <div className="">
                    <img src={Secured} alt="" className="img-fluid" />
                  </div>
                  <h4 className="line-height-1 white-color py-lg-0 py-0 font-size-40 font-pnb ms-0">
                    Everything
                    <br /> is secure.
                  </h4>
                  <div className="plus-btn plus-btn-xiden-card3-box d-flex justify-content-center align-items-center">
                    <img className="plus-icons plus-btn-xiden-card3-box-img" src={PlusIcon} alt="plus" />
                  </div>
                </div>
                <div
                  onClick={() => handleRotate("card3")}
                  className={`${
                    rotateCard3 ? "rotate-xiden-card3-box-back-side" : ""
                  } d-flex flex-column justify-content-between xiden-card-box-app xiden-card3-box-card-side xiden-card3-box-back-side p-lg-5 p-4`}
                >
                  <CustomUpperTitle title={"SECURED"} />
                  {/* <div className="">
                    <img src={EcoFriendly} alt="" className="img-fluid" />
                  </div> */}
                  <h4 className="line-height-1 white-color py-lg-0 py-0 font-size-28 font-pnb ms-0">
                    Nobody can directly access your device. Everything is secured by the Xiden system’s security mechanism.
                  </h4>

                  <div className="plus-btn plus-btn-xiden-card3-box d-flex justify-content-center align-items-center">
                    <img
                      className={`${rotateCard3 ? "close-btn" : ""} plus-icons plus-btn-xiden-card3-box-img`}
                      src={PlusIcon}
                      alt="plus"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-12 mt-4">
            <div id="xiden-card4-box" className="xiden-card4-box-app-card xiden-card4-box-card">
              <div className="p-4 position-relative transform-card h-100">
                <div
                  onClick={() => handleRotate("card4")}
                  className={`${
                    rotateCard4 ? "rotate-xiden-card4-box-front-side" : ""
                  } d-flex flex-column justify-content-between xiden-card-box-app xiden-card4-box-card-side xiden-card4-box-front-side p-lg-5 p-4`}
                >
                  <CustomUpperTitle title={"TOTAL CONTROL"} />
                  <div className="">
                    <img src={Pointer} alt="" className="img-fluid" />
                  </div>
                  <h4 className="line-height-1 white-color py-lg-0 py-0 font-size-40 font-pnb ms-0">
                    You're in control
                    <br /> With just one tap.
                  </h4>
                  <div className="plus-btn plus-btn-xiden-card4-box d-flex justify-content-center align-items-center">
                    <img className="plus-icons plus-btn-xiden-card4-box-img" src={PlusIcon} alt="plus" />
                  </div>
                </div>
                <div
                  onClick={() => handleRotate("card4")}
                  className={`${
                    rotateCard4 ? "rotate-xiden-card4-box-back-side" : ""
                  } d-flex flex-column justify-content-between xiden-card-box-app xiden-card4-box-card-side xiden-card4-box-back-side p-lg-5 p-4`}
                >
                  <CustomUpperTitle title={"TOTAL CONTROL"} />
                  {/* <div className="">
                    <img src={Pointer} alt="" className="img-fluid" />
                  </div> */}
                  <h4 className="line-height-1 white-color py-lg-0 py-0 font-size-28 font-pnb ms-0">
                    You decide when your devices’ resources are distributed in the network.
                  </h4>

                  <div className="plus-btn plus-btn-xiden-card4-box d-flex justify-content-center align-items-center">
                    <img
                      className={`${rotateCard4 ? "close-btn" : ""} plus-icons plus-btn-xiden-card4-box-img`}
                      src={PlusIcon}
                      alt="plus"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-12 mt-4">
            <div id="xiden-card5-box" className="xiden-card5-box-app-card xiden-card5-box-card">
              <div className="p-4 position-relative transform-card h-100">
                <div
                  onClick={() => handleRotate("card5")}
                  className={`${
                    rotateCard5 ? "rotate-xiden-card5-box-front-side" : ""
                  } d-flex flex-column justify-content-between xiden-card-box-app xiden-card5-box-card-side xiden-card5-box-front-side p-lg-5 p-4`}
                >
                  <CustomUpperTitle title={"PROTECTS YOUR IDENTITY"} />

                  <div className="">
                    <img src={Secured} alt="" className="img-fluid" />
                  </div>
                  <h4 className="line-height-1 white-color py-lg-0 py-0 font-size-40 font-pnb ms-0">
                    Xiden blockchain
                    <br /> manages all jobs.
                  </h4>
                  <div className="plus-btn plus-btn-xiden-card5-box d-flex justify-content-center align-items-center">
                    <img className="plus-icons plus-btn-xiden-card5-box-img" src={PlusIcon} alt="plus" />
                  </div>
                </div>
                <div
                  onClick={() => handleRotate("card5")}
                  className={`${
                    rotateCard5 ? "rotate-xiden-card5-box-back-side" : ""
                  } d-flex flex-column justify-content-between xiden-card-box-app xiden-card5-box-card-side xiden-card5-box-back-side p-lg-5 p-4`}
                >
                  <CustomUpperTitle title={"PROTECTS YOUR IDENTITY"} />
                  {/* <div className="">
                    <img src={Secured} alt="" className="img-fluid" />
                  </div> */}
                  <h4 className="line-height-1 white-color py-lg-0 py-0 font-size-28 font-pnb ms-0">
                    Your identity and your device’s identity are always protected. Xiden’s job management is performed by the
                    blockchain, not by the users.
                  </h4>

                  <div className="plus-btn plus-btn-xiden-card5-box d-flex justify-content-center align-items-center">
                    <img
                      className={`${rotateCard5 ? "close-btn" : ""} plus-icons plus-btn-xiden-card5-box-img`}
                      src={PlusIcon}
                      alt="plus"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Card;
