import { TiWarning as WarningSign } from "react-icons/ti";
import EthImg from "../SupportKraterSteps/assets-steps/eth-cables.png";
import MinterPicture from "../SupportKraterSteps/assets-steps/minter-picture.jpeg";
import IncognitoScreen from "../SupportKraterSteps/assets-steps/incognito-screen.PNG";
import Incognito from "../SupportKraterSteps/assets-steps/incog.png";
import Step1 from "../SupportKraterSteps/assets-steps/step1.png";

export const accessMinterSteps = [
    {
        uppertitle: "STEP 01",
        title: "Cable connections",
        punctuation: ".",
        content: <>
            <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                Connect your device (laptop/pc/tablet) to Minter by:
            </p>
            <p className="font-pnb font-size-20 purple-color mt-3 mb-0 pb-0">via LAN</p>
            <ul className="mt-0 pt-0">
                <li>○ <span className="lightgrey-color font-pnr fonr-size-20">connect the cable into the Minter LAN port.</span></li>
                <li>○ <span className="lightgrey-color font-pnr fonr-size-20">connect the cable into your device.</span></li>
            </ul>
            <p className="font-pnb font-size-20 purple-color mt-3 mb-0 pb-0">via WiFi</p>
            <ul className="mt-0 pt-0">
                <li>○ <span className="lightgrey-color font-pnr fonr-size-20">connect your wireless device to the Minter WiFi.</span></li>
                <li>○ <span className="lightgrey-color font-pnr fonr-size-20">choose from the networks 2.4 Ghz or 5 Ghz.</span></li>
            </ul>
        </>,
        image: EthImg
    },
    {
        uppertitle: "STEP 02",
        title: "Check MinteR status",
        punctuation: ".",
        content: <>
            <p className="font-pnr font-size-20 lightgrey-color line-height-11 text-start">
                Wait until the status led becomes <span className="cyan-color font-pnb">cyan</span>.
            </p>
            <ul className="mt-3 pt-0">
                <li>○ <span className="red-color font-pnb">Red led</span> indicator: the router is in Boot up sequence;</li>
                <li>○ <span className="green-color font-pnb">Green led</span> indicator: the router has no internet connection;</li>
                <li>○ <span className="cyan-color font-pnb">Cyan led</span> indicator: the router has internet connection and is ready for the set-up.</li>
            </ul>
            <p className="font-pnr font-size-18 lightgrey-color line-height-11">
                <WarningSign className="warning-svg me-2" /> The router could enter in boot sequence phase when updates are available. This phase can last longer mostly when it is at the first configuration. Boot sequence state can be activated randomly when MinteR detects an update.
            </p>
            <p className="font-pnr font-size-18 lightgrey-color line-height-11">

                <WarningSign className="warning-svg me-2" /> If this state persists, please contact <a className="blue-color tutorial-link" href="https://cryptodata.com/cryptodata-support" target="_blank">support</a>.
            </p>
        </>,
        image: MinterPicture
    },
    {
        uppertitle: "STEP 03",
        title: "Open browser in incognito mode",
        punctuation: ".",
        content: <>
            <p className="font-pnr font-size-20 lightgrey-color line-height-11 text-start">
                Open your preferred browser in incognito mode. You can see the tutorial for each browser below:
            </p>
            <ul className="mt-3 pt-0 ">
                <li>○ <span className="lightgrey-color font-pnr fonr-size-20 ">for Chrome <a className="blue-color tutorial-link " href="https://support.google.com/chrome/answer/95464" target="_blank">check the tutorial</a></span></li>
                <li>○ <span className="lightgrey-color font-pnr fonr-size-20 ">for Firefox <a className="blue-color tutorial-link " href="https://support.mozilla.org/en-US/kb/private-browsing-use-firefox-without-history" target="_blank">check the tutorial</a></span></li>
                <li>○ <span className="lightgrey-color font-pnr fonr-size-20 ">for Safari <a className="blue-color tutorial-link " href="https://support.apple.com/guide/safari/browse-privately-ibrw1069/mac" target="_blank">check the tutorial</a></span></li>
            </ul>
        </>,
        image: Incognito
    },
    {
        uppertitle: "STEP 04",
        title: "Access Minter control panel",
        punctuation: ".",
        content: <>
            <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                Type in browser address field: <span className="purple-color">192.168.1.1</span>
            </p>
            <p className="font-pnr lightgrey-color font-size-20 mt-3 mb-0 pb-0">The default management IP address of the Minter is:</p>
            <ul className="mt-0 pt-0">
                <li>○ <span className="lightgrey-color font-pnr fonr-size-20">192.168.1.1</span></li>
                <li>○ <span className="lightgrey-color font-pnr fonr-size-20">depending on the software version type: 192.168.5.1</span></li>
                <li>○ <span className="lightgrey-color font-pnr fonr-size-20">your custom IP if you changed the minter default IP</span></li>
            </ul>
        </>,
        image: IncognitoScreen
    },
    {
        uppertitle: "STEP 05",
        title: "Login into the Minter",
        punctuation: ".",
        content: <>
            <p className="font-pnr font-size-20 lightgrey-color line-height-11 text-start">
                To access the configuration page, you'll be prompted to enter an username and password. <br /> <br />
                Username: <span className="font-pnb purple-color">root</span> <br />
                Password: <span className="font-pnb purple-color">CryptoData2022</span>
            </p>
        </>,
        image: Step1
    }];