import React, { useState, createContext, useEffect } from "react";
import "./SliderSection.scss";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import ServicesSlider from "./ServicesSlider";
// import Realm from "../../../assets/images/png/meta-realm-page/slider-img.png";
import Realm1 from "../../../assets/images/png/meta-realm-page/slider-img-1.png";
import Realm2 from "../../../assets/images/png/meta-realm-page/slider-img-2.png";
import Realm3 from "../../../assets/images/png/meta-realm-page/slider-img-3.png";
import Realm4 from "../../../assets/images/png/meta-realm-page/slider-img-4.png";

// import { useNavigate } from "react-router-dom";

// import MyCountdown from "react-countdown";
// import CountdownService from "../../Homepage/Countdown/CountdownService";

const contentContext = createContext();

// const renderer = ({ days, hours, minutes, seconds, completed }) => {
//     if (completed) {
//         return <CountdownService countdownData={{ days: 0, hours: 0, minutes: 0, seconds: 0 }} />
//     } else {
//         return <CountdownService countdownData={{ days: days, hours: hours, minutes: minutes, seconds: seconds }} />
//     }
// };

const SliderSection = () => {
  const sliderData = [
    {
      id: 1,
      image: <img src={Realm2} alt="Realm" className="slide-image realm-img" />,
      title: "RM Node 1",
    },
    {
      id: 2,
      image: <img src={Realm3} alt="Realm" className="slide-image realm-img" />,
      title: "RM Node 2",
    },
    {
      id: 3,
      image: <img src={Realm1} alt="Realm" className="slide-image realm-img" />,
      title: "RM Node 3",
    },
    {
      id: 4,
      image: <img src={Realm4} alt="Realm" className="slide-image realm-img" />,
      title: "RM Node 4",
    },
  ];

  const contentData = [
    {
      uperTitle: "META REALMS",
      title: "RM Node 1",
      punctuation: ".",
      paragraph: `A validation node that registers the blocks into the blockchain network.
As geographical locations, the Realm nodes are distributed around the globe in order to facilitate the registering process of the Guardian node, which will validate data according to user preferences and/or requirements.`,
    },
    {
      uperTitle: "META REALMS",
      title: "RM Node 2",
      punctuation: ".",
      paragraph: `A validation node that registers the blocks into the blockchain network.
As geographical locations, the Realm nodes are distributed around the globe in order to facilitate the registering process of the Guardian node, which will validate data according to user preferences and/or requirements.`,
    },
    {
      uperTitle: "META REALMS",
      title: "RM Node 3",
      punctuation: ".",
      paragraph: `A validation node that registers the blocks into the blockchain network.
As geographical locations, the Realm nodes are distributed around the globe in order to facilitate the registering process of the Guardian node, which will validate data according to user preferences and/or requirements.`,
    },
    {
      uperTitle: "META REALMS",
      title: "RM Node 4",
      punctuation: ".",
      paragraph: `A validation node that registers the blocks into the blockchain network.
As geographical locations, the Realm nodes are distributed around the globe in order to facilitate the registering process of the Guardian node, which will validate data according to user preferences and/or requirements.`,
    },
  ];

  // const navigate = useNavigate();

  const [contentIndex, setContentIndex] = useState(0);

  const [animate, setAnimate] = useState(false);

  const value = { contentIndex, setContentIndex }; //pentru context provider ca sa pot schimba indexul din slider

  const [displayData, setDisplayData] = useState(contentData[contentIndex]);

  useEffect(() => {
    setDisplayData(contentData[contentIndex]);
    setAnimate(true);
    setTimeout(() => setAnimate(false), 500);
  }, [contentIndex]); //aici fac rerender in parent pe baza modificarii indexului din child

  return (
    <contentContext.Provider value={value}>
      <section id="meta-realm-slider">
        <div className="page-section meta-realm-slider">
          <div className="container-main what-contianer">
            <div className="row">
              <div className="col-lg-6 col-12 order-lg-1 order-2 d-flex flex-column justify-content-center align-items-start">
                <Uppertitle title={displayData.uperTitle} />
                <h2 className={`mb-3 font-size-80 white-color font-pnb ${animate && "apply-effect"}`}>
                  {displayData.title}
                  <span className="purple-color">{displayData.punctuation}</span>
                </h2>
                <div className="description description-991">
                  <p className="font-family-pnr font-size-24 lightgrey-color line-height-11">{displayData.paragraph}</p>
                </div>
              </div>

              {/* <ServicesSlider data={sliderData} /> */}
              <div className="col-lg-6 col-12 order-lg-2 order-1  pb-lg-0 pb-5 position-relative slider-col">
                <div className="pos-absolute-slider">
                  <ServicesSlider data={sliderData} />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="banner countdown-banner">
                        <div className="banner-overlay font-size-48"><MyCountdown date={new Date(2022, 2, 30, 0, 0, 0)} renderer={renderer} /></div>
                        <div className="banner-column d-flex justify-content-between w-50">
                            <div className="banner-item">
                                <div className="d-flex flex-column font-size-20 font-pnl">
                                    <span>XDEN</span>
                                    <span>price</span>
                                </div>

                                <span className="font-pnb number-span blur-me">35$</span>
                            </div>

                            <div className="banner-item">
                                <div className="d-flex flex-column font-size-20 font-pnl">
                                    <span>Staking</span>
                                    <span>supply</span>
                                </div>

                                <span className="font-pnb number-span blur-me">123.485</span>
                            </div>
                        </div>

                        <div className="banner-column d-flex justify-content-between w-50">
                            <div className="banner-item">
                                <div className="d-flex flex-column font-size-20 font-pnl">
                                    <span>Circulating</span>
                                    <span>supply</span>
                                </div>

                                <span className="font-pnb number-span blur-me">23.523.485</span>
                            </div>

                            <div className="banner-item">
                                <div className="d-flex flex-column font-size-20 font-pnl">
                                    <span>Total</span>
                                    <span>supply</span>
                                </div>

                                <span className="font-pnb number-span blur-me">420.523.485</span>
                            </div>
                        </div>
                    </div> */}
        </div>
      </section>
    </contentContext.Provider>
  );
};

export { contentContext };
export default SliderSection;
