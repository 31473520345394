import React from "react";
import HeroSection from "./HeroSection/HeroSection";
import SupportGuideSteps from "./SupportKraterInstallationSteps/SupportGuideSteps";
import "./SupportKraterGuide.scss";

export default function SupportKraterGuide() {
  return (
    <>
      <HeroSection />
      <SupportGuideSteps />
    </>
  );
}
