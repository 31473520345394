import React from 'react';
import './Hero.scss'
import HeroImg from "../assets/mining-competition-hero.png";
import { Col, Row } from 'react-bootstrap';
import Uppertitle from '../../../components/Uppertitle/Uppertitle';
import { AiOutlineArrowRight as ArrowRight } from "react-icons/ai";
import { FiArrowUpRight as ArrowUpRight } from "react-icons/fi";
import { Link as ScrollLink } from "react-scroll";

const Hero = () => {
   return (
      <section id="mining-competition-hero" className="page-section ">
         <div className="container-main hero-section-container pt-5">
            <Row className="flex-lg-row flex-column-reverse m-auto">
               <Col lg={5}>
                  <div className="h-100 d-flex flex-column justify-content-center align-items-lg-start align-items-center">
                     <Uppertitle title={`XIDEN`} />
                     <div className="node-title-container text-center text-lg-start">
                        <h2 className="mb-0 font-size-80 white-color mb-0 font-pnb line-height-11 text-center-991">
                           Mining
                        </h2>
                        <h2 className="mb-0 font-size-80 white-color mb-0 font-pnb line-height-11 text-center-991">
                           Competition
                           <span className="purple-color">.</span>
                        </h2>
                     </div>
                     <div className="pt-3 pb-lg-5 pb-4 paragraph-container">
                        <p className="font-family-pnr font-size-24 lightgrey-color text-center-991 line-height-11 text-center text-lg-start">
                           Minter vs Crypto Winter
                        </p>
                     </div>
                     <div className="buttons-flex-direction w-100 d-flex justify-content-lg-start justify-content-center align-items-center">

                        <ScrollLink
                           className="buttonBlue"
                           to="prizes-register-section"
                           offset={-40}
                           spy={true}
                        >
                           <span className="font-size-20 font-pnb me-2"> Register </span>
                           <ArrowUpRight />
                        </ScrollLink>
                        <ScrollLink
                           className="buttonOutlineBlue ms-3"
                           to="prizes-pool-section"
                           offset={-90}
                           spy={true}
                        >
                           <span className="font-size-20 font-pnb me-2"> Get started </span>
                           <ArrowRight />
                        </ScrollLink>
                     </div>
                  </div>
               </Col>
               <Col lg={7} className="d-flex justify-content-center align-items-center">
                  {/* <img src={PerfGuardianImage} alt="xid coin" className="img-fluid staking-hero-img pb-lg-0 pb-4" /> */}
                  <img src={HeroImg} loading="lazy" alt="xid coin" className="img-fluid staking-hero-img pb-lg-0 pb-4" />
               </Col>
            </Row>
         </div>
      </section>
   )
}

export default Hero