import React from 'react';
import "./ImageModal.scss";
import { Modal } from "react-bootstrap";
import { ReactComponent as CloseButton } from "./assets-steps/close-btn.svg";

const ImageModal = ({ show, onHide, title, image }) => {
  return (
    <div>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="modal-steps"
        centered
      >
        <Modal.Header className='position-relative'>
          <Modal.Title id="contained-modal-title-vcenter">
            {title}
            <div className="close-btn pointer" onClick={onHide}>
              <CloseButton />
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={image} alt="step" className="img-fluid w-100" />
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ImageModal