import React, { useRef } from 'react';
import './Timeline.scss';
import DashRight from "../assets/dash-right.png";
import DashLeft from "../assets/dash-left.png";
import Uppertitle from '../../../components/Uppertitle/Uppertitle';
import TimelineImg from "../assets/timeline.png";
import TimelineImgMobile from "../assets/timeline-mobile.png";

const Timeline = () => {

    const sliderRef = useRef(null);
    let isDown = false;
    let startX;
    let scrollLeft;

    const mouseLeaveFunction = () => {
        isDown = false;
    };

    const mouseDownFunction = (e) => {
        isDown = true;
        startX = e.pageX - sliderRef.current.offsetLeft;
        scrollLeft = sliderRef.current.scrollLeft;
    };

    const mouseUpFunction = () => {
        isDown = false;
    };

    const touchFunction = () => {
        isDown = false;
    };
    
    const mouseMoveFunction = (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - sliderRef.current.offsetLeft;
        const walk = (x - startX) * 1;
        sliderRef.current.scrollLeft = scrollLeft - walk;
    };



    return (
        <section id="prizes-timeline-section" className="page-section ">
            <div className="container-main">
                <div className="title-container d-flex flex-column align-items-center mb-5">
                    <img src={DashLeft} alt="dash" className='me-auto img-fluid' />
                    <h2 className="mt-4 font-size-80 white-color font-pnb line-height-1 text-center my-0">Campaign</h2>
                    <h2 className="mb-4 font-size-80 white-color font-pnb line-height-1 text-center my-0">Timeline<span className="purple-color">.</span></h2>

                    <img src={DashRight} alt="dash" className='ms-auto img-fluid' />
                </div>


                <Uppertitle title={"TIMELINE"} />

                <div className="timeline-container mt-4 d-flex align-items-center justify-content-start h-100">
                    <div className='me-4'>
                        <img src={TimelineImg} alt="competition timeline" className=' ms-4 img-fluid' />
                    </div>
                    <div className="ms-4 info-container d-flex align-items-start justify-content-between flex-column">
                        <div className="top-part">
                            <div className="corners text-corner font-pnb font-size-32">Start Date</div>
                            <div className="corners text-corner font-pnb font-size-32">Registration open</div>
                            <div className="corners text-corner font-pnb font-size-32">Twitter voting open</div>
                        </div>
                        <div className="bottom-part">
                            <div className="corners text-corner font-pnb font-size-32">Registration closed </div>
                            <div className="corners text-corner font-pnb font-size-32 mt-custom">Twitter voting ended</div>
                            <div className="corners text-corner font-pnb font-size-32 purple-color">WINNER SELECTION AND ANNOUNCEMENT</div>
                        </div>
                    </div>
                </div>

                <div className='timeline-mobile-container d-none'>
                    <div
                        id="road-slider"
                        className="d-flex d-lg-none justify-content-between align-items-start slider-div position-relative"
                        ref={sliderRef}
                        onMouseDown={e => mouseDownFunction(e)}
                        onMouseLeave={() => mouseLeaveFunction()}
                        onMouseUp={() => mouseUpFunction()}
                        onMouseMove={e => mouseMoveFunction(e)}
                        onTouchStart={() => touchFunction()}
                    >
                        <img src={TimelineImgMobile} alt="timeline" className='slide-element mt-5' />
                        <div className='start-part position-absolute'>
                            <div className="corners text-corner font-pnb font-size-32 text-nowrap">Start Date</div>
                            <div className="corners text-corner font-pnb font-size-32 text-nowrap">Registration open</div>
                            <div className="corners text-corner font-pnb font-size-32 text-nowrap">Twitter voting open</div>
                        </div>
                        <div className="middle-part position-absolute">
                            <div className="corners text-corner font-pnb font-size-32 text-nowrap">Registration closed </div>
                        </div>
                        <div className="end-part position-absolute">
                            <div className="corners text-corner font-pnb font-size-32 text-nowrap ms-auto">Twitter voting ended</div>
                            <div className="corners text-corner font-pnb font-size-32 purple-color ms-auto text-center text-nowrap">WINNER SELECTION <br /> AND ANNOUNCEMENT</div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

    )
}

export default Timeline