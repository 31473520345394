import React, { useRef } from "react";
import "./Slider.scss";

const Slider = (props) => {
  //  Refs
  const sliderRef = useRef(null);

  // functions
  //  // slider function start
  let isDown = false;
  let startX;
  let scrollLeft;

  const mouseLeaveFunction = () => {
    isDown = false;
  };
  const mouseDownFunction = (e) => {
    isDown = true;
    startX = e.pageX - sliderRef.current.offsetLeft;
    scrollLeft = sliderRef.current.scrollLeft;
  };
  const mouseUpFunction = () => {
    isDown = false;
  };
  const touchFunction = () => {
    isDown = false;
  };
  const mouseMoveFunction = (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - sliderRef.current.offsetLeft;
    const walk = (x - startX) * 1; //scroll-fast
    sliderRef.current.scrollLeft = scrollLeft - walk;
  };
  // sl
  return (
    <div className="position-relative slider-height">
      <div
        ref={sliderRef}
        className="descriptions-container slider-div grab"
        onMouseDown={(e) => mouseDownFunction(e)}
        onMouseLeave={() => mouseLeaveFunction()}
        onMouseUp={() => mouseUpFunction()}
        onMouseMove={(e) => mouseMoveFunction(e)}
        onTouchStart={() => touchFunction()}
      >
        {props.children}
      </div>
    </div>
  );
};

export default Slider;
