import React, { useRef } from "react";
import Government from "../../../assets/images/png/xiden-page/government-img.png";
import Business from "../../../assets/images/png/xiden-page/business-img.png";
import Corporate from "../../../assets/images/png/xiden-page/corporate-img.png";
import Education from "../../../assets/images/png/xiden-page/education-img.png";
import Financial from "../../../assets/images/png/xiden-page/financial-img.png";
import SmartCity from "../../../assets/images/png/xiden-page/smart-city-img.png";

import SolutionsCard from "./SolutionsCard";
import "./Sector.scss";

const SectorMobileSlider = () => {
  //  Refs
  const sliderRef = useRef(null);

  // functions
  //  // slider function start
  let isDown = false;
  let startX;
  let scrollLeft;

  const mouseLeaveFunction = () => {
    isDown = false;
  };
  const mouseDownFunction = (e) => {
    isDown = true;
    startX = e.pageX - sliderRef.current.offsetLeft;
    scrollLeft = sliderRef.current.scrollLeft;
  };
  const mouseUpFunction = () => {
    isDown = false;
  };
  const touchFunction = () => {
    isDown = false;
  };
  const mouseMoveFunction = (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - sliderRef.current.offsetLeft;
    const walk = (x - startX) * 1; //scroll-fast
    sliderRef.current.scrollLeft = scrollLeft - walk;
  };
  // slider function end

  return (
    <section id="sector-section-mobile" className="d-lg-none">
      <div className="position-relative slider-height">
        <div
          ref={sliderRef}
          className="descriptions-container slider-div grab"
          onMouseDown={(e) => mouseDownFunction(e)}
          onMouseLeave={() => mouseLeaveFunction()}
          onMouseUp={() => mouseUpFunction()}
          onMouseMove={(e) => mouseMoveFunction(e)}
          onTouchStart={() => touchFunction()}
        >
          <SolutionsCard letter="G" title="Government" imageUrl={Government} />
          <SolutionsCard letter="B" title="Business" imageUrl={Business} />
          <SolutionsCard letter="C" title="Corporate" imageUrl={Corporate} />
          <SolutionsCard letter="S" title="Smart City" imageUrl={SmartCity} />
          <SolutionsCard letter="F" title="Financial" imageUrl={Financial} />
          <SolutionsCard letter="E" title="Education" imageUrl={Education} />
        </div>
      </div>
    </section>
  );
};

export default SectorMobileSlider;
