import React from "react";
import "./CustomToggleButton.scss";

const CustomToggleButton = ({ checkState, toggle }) => {
   return (
      <label className="toggleWrapper">
         <input type="checkbox" checked={checkState} className="toggleInput" onChange={toggle} />
         <span className="toggleSlider"></span>
      </label>
   );
};

export default CustomToggleButton;
