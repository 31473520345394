import React from "react";
import { Col, Row, Button } from "react-bootstrap";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";

import { ReactComponent as ArrowRight } from "../../../assets//images/svg/arrow-right.svg";

import Impulse from "../../../assets/images/png/Impulse.png";
import Hidr from "../../../assets/images/png/Hidr.png";
import PC from "../../../assets/images/png/PC.png";
import Basm from "../../../assets/images/png/Basm.png";

import './HelpNetwork.scss';

export default function HelpNetwork() {
    return (
        <section id="help-network" className="page-section">
            <div className="container-main">
                <Uppertitle title={"DEVICES"} />
                <h2 className="font-size-80 pb-lg-5 pb-4 pt-lg-3 pt-2  white-color font-pnb">
                    Help the network
                    <span className="purple-color">.</span>
                </h2>
                <p className="description description-991 pb-lg-5 pb-4 font-size-24 lightgrey-color">
                    Built as normal devices to be used on daily basis for normal
                    tasks but in the same time, you can share their power with
                    the network.
                </p>

                <Row>
                    <Col lg={3} sm={6}>
                        <div className="wrapper-coloana">
                            <div className="container-img d-flex justify-content-center">
                                <img src={Basm} loading="lazy" alt="Basm laptop" className="img-fluid basm-img" />
                            </div>
                            <h4 className="font-pnb pb-lg-0 pb-5 text-center font-size-40 white-color basm-title">BASM</h4>
                        </div>
                    </Col>
                    <Col lg={3} sm={6}>
                        <div className="wrapper-coloana">
                            <div className="container-img d-flex justify-content-center">
                                <img src={Impulse} loading="lazy" alt="Impulse smartphone" className="img-fluid"  />
                            </div>
                            <h4 className="font-pnb pb-lg-0 pb-5 text-center font-size-40 white-color">IMPulse</h4>
                        </div>
                    </Col>
                    <Col lg={3} sm={6}>
                        <div className="wrapper-coloana">
                            <div className="container-img d-flex justify-content-center">
                                <img src={Hidr} loading="lazy" alt="Hidr smartphone" className="img-fluid"  />
                            </div>
                            <h4 className="font-pnb pb-lg-0 pb-5 text-center font-size-40 white-color">HidR</h4>
                        </div>
                    </Col>
                    <Col lg={3} sm={6}>
                        <div className="wrapper-coloana">
                            <div className="container-img d-flex justify-content-center">
                                <img src={PC} loading="lazy" alt="PC" className="img-fluid pc-img"  />
                            </div>
                            <h4 className="font-pnb pb-lg-0 pb-5 text-center font-size-40 white-color">PC</h4>
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    );
}
