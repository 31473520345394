import React from "react";
import "./HeroSection.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import RewardsImage from "../../../assets/images/png/rewardsPage/xid-rewards-hero.jpg";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import { AiOutlineArrowRight as ArrowRight } from "react-icons/ai";
import { FiArrowUpRight as ArrowRightUp } from "react-icons/fi";

const HeroSection = () => {
  return (
    <div className="page-section rewards-hero">
      <div className="container-main hero-section-container">
        <div className="main-col rewards-hero-col-1">
          <Uppertitle title={`REWARDS`} />
          <h2 className="font-size-80 white-color font-pnb">
            XDEN Rewards
            <span className="pink-text">.</span>
          </h2>
          <div className="my-4 paragraph-container">
            <p className="font-family-pnr font-size-24 lightgrey-color line-height-11">
              Every active validator node is rewarded by the Xiden Network based on jobs collected and validated in order to
              ensure the availability and the functionality of the system.
            </p>
          </div>
          <div className="hero-btn-container rewards-hero-btn-container">
            <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-disabled">Coming soon!</Tooltip>}>
              <button className="buttonBlue rewards-hero-buttons disabled">
                <span className="me-2">Check rewards</span>
                <ArrowRight />
              </button>
            </OverlayTrigger>
            <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-disabled">Coming soon!</Tooltip>}>
              <button className="buttonOutlineBlue rewards-hero-buttons disabled">
                <span className="me-2">Get rewards</span>
                <ArrowRightUp />
              </button>
            </OverlayTrigger>
          </div>
        </div>

        <div className="section-image-col rewards-hero-col-2">
          <img src={RewardsImage} loading="lazy" alt="xid coin" className="section-image" />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
