import React from "react";
import "./CustomUpperTitle.scss";

const CustomUpperTitle = ({ title, blueColor, lineBlueColor }) => {
  return (
    <div className="xideen-upper-title d-flex ">
      <h6 className={`font-size-18 ${blueColor ? "uppertitle-blue" : "uppertitle-green"} font-pnsb mb-0 d-flex `}>
        <div className={`my-auto me-2 ${lineBlueColor ? "line-upper-title-blue " : "line-upper-title-green"}`}></div>
        {title}
      </h6>
    </div>
  );
};

export default CustomUpperTitle;
