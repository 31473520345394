import React from "react";
import "./ComputingPower.scss";
import { Col, Row } from "react-bootstrap";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import Dapps from "../../../assets/images/png/d-apps-validator.png";
import Stack from "../../../assets/images/png/validator-page/validator-image.png";

const ComputingPower = () => {
  return (
    <section id="computingpower-validator" className="page-section">
      <div className="container-main">
        <Uppertitle title={"REWARDS"} />

        <div className="title">
          <h2 className="font-size-80 white-color font-pnb">How to</h2>
          <h2 className="font-size-80 white-color font-pnb mb-10">
            get rewards
            <span className="purple-color">.</span>
          </h2>
        </div>
        <div className="corners p-lg-5 p-4 ">
          <Row className="mobile-row d-flex align-items-center align-content-center justify-content-between h-100">
            <Col lg={6}>
              <div className="card-content">
                <h4 className="font-size-40 pb-lg-4 pb-3 white-color font-pnb">
                  Validate
                  <span className="purple-color">.</span>
                </h4>
                <p className="font-size-20 font-pnr lightgrey-color line-height-11">
                  By validating devices, data, and transactions within the system the users will receive validation earnings.
                </p>
              </div>
            </Col>
            <Col lg={6}>
              <div className="card-img">
                <img src={Stack} loading="lazy" alt="validate" className="img-fluid stack-img" />
              </div>
            </Col>
          </Row>
        </div>
        <div className="corners p-lg-5 p-4 ">
          <Row className="row2 d-flex align-items-center align-content-center justify-content-between h-100">
            <Col lg={6}>
              <div className="card-img">
                <img src={Dapps} loading="lazy" alt="Dapps" className="img-fluid dapps-img" />
              </div>
            </Col>
            <Col lg={6}>
              <div className="card-content">
                <h4 className="font-size-40 pb-lg-4 pb-3 white-color font-pnb">
                  Resources for DApps
                  <span className="purple-color">.</span>
                </h4>
                <p className="font-size-20 font-pnr lightgrey-color line-height-11">
                  We incentivize the developers that create DApps and contribute with value to the underlying network.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
};

export default ComputingPower;
