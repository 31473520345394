import React from 'react';
import "./UserAgreements.scss";
import { Helmet } from 'react-helmet';
import SchemaTemplate from "../../SEO/SchemaTemplate.json";

const UserAgreements = () => {
    return (
        <section id="user-agreements-section" className="page-section">
            {/* seo */}
            <Helmet>
                <title>Xiden - User Agreements</title>
                <meta name="title" content="User Agreements" />
                <meta name="description" content="" />
                <link rel="canonical" href="" />
                {/* Open Graph Facebook */}
                <meta property="og:title" content="User Agreements" />
                <meta property="og:description" content="" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="" />
                <meta property="og:url" content="" />
                {/* Open Graph Twitter  */}
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="" />
                <meta property="twitter:title" content="User Agreements" />
                <meta property="twitter:description" content="" />
                <meta property="twitter:image" content="" />
                {/* Structured Data */}
                <script>{JSON.stringify(SchemaTemplate)}</script>
            </Helmet>
            <div className="container-main">
                <h2 className="text-center font-pnb font-size-40 m-custom">User Agreements</h2>

                <h4 className="font-pnsb font-size-24 mb-3"><span className="purple-color">•</span> Lorem ipsum dolor sit amet consectetur, adipisicing elit. Exercitationem, impedit?</h4>
                <p className="font-pnr font-size-16 lightgrey-color line-height-11">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut est enim, 
                    varius sed ligula in, dictum porta justo. Nunc egestas fermentum velit, 
                    ac vulputate eros vestibulum sed. Nullam tristique felis sed blandit mattis. 
                    Vivamus eleifend, magna sit amet fermentum tempor, nunc neque suscipit purus, 
                    sed consequat turpis massa vel nisi. Ut justo ipsum, mollis quis urna quis, 
                    suscipit ultrices nulla. Curabitur eleifend fermentum enim, fringilla scelerisque 
                    eros iaculis in. Phasellus ac ex in urna pellentesque consectetur in ornare urna.
                </p>
                <h4 className="font-pnsb font-size-24 mb-3"><span className="purple-color">•</span> Lorem ipsum dolor sit amet consectetur, adipisicing elit. Exercitationem, impedit?</h4>
                <p className="font-pnr font-size-16 lightgrey-color line-height-11">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut est enim, 
                    varius sed ligula in, dictum porta justo. Nunc egestas fermentum velit, 
                    ac vulputate eros vestibulum sed. Nullam tristique felis sed blandit mattis. 
                    Vivamus eleifend, magna sit amet fermentum tempor, nunc neque suscipit purus, 
                    sed consequat turpis massa vel nisi. Ut justo ipsum, mollis quis urna quis, 
                    suscipit ultrices nulla. Curabitur eleifend fermentum enim, fringilla scelerisque 
                    eros iaculis in. Phasellus ac ex in urna pellentesque consectetur in ornare urna.
                </p>
                <h4 className="font-pnsb font-size-24 mb-3"><span className="purple-color">•</span> Lorem ipsum dolor sit amet consectetur, adipisicing elit. Exercitationem, impedit?</h4>
                <p className="font-pnr font-size-16 lightgrey-color line-height-11">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut est enim, 
                    varius sed ligula in, dictum porta justo. Nunc egestas fermentum velit, 
                    ac vulputate eros vestibulum sed. Nullam tristique felis sed blandit mattis. 
                    Vivamus eleifend, magna sit amet fermentum tempor, nunc neque suscipit purus, 
                    sed consequat turpis massa vel nisi. Ut justo ipsum, mollis quis urna quis, 
                    suscipit ultrices nulla. Curabitur eleifend fermentum enim, fringilla scelerisque 
                    eros iaculis in. Phasellus ac ex in urna pellentesque consectetur in ornare urna.
                </p>
                <h4 className="font-pnsb font-size-24 mb-3"><span className="purple-color">•</span> Lorem ipsum dolor sit amet consectetur, adipisicing elit. Exercitationem, impedit?</h4>
                <p className="font-pnr font-size-16 lightgrey-color line-height-11">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut est enim, 
                    varius sed ligula in, dictum porta justo. Nunc egestas fermentum velit, 
                    ac vulputate eros vestibulum sed. Nullam tristique felis sed blandit mattis. 
                    Vivamus eleifend, magna sit amet fermentum tempor, nunc neque suscipit purus, 
                    sed consequat turpis massa vel nisi. Ut justo ipsum, mollis quis urna quis, 
                    suscipit ultrices nulla. Curabitur eleifend fermentum enim, fringilla scelerisque 
                    eros iaculis in. Phasellus ac ex in urna pellentesque consectetur in ornare urna.
                </p>
                <h4 className="font-pnsb font-size-24 mb-3"><span className="purple-color">•</span> Lorem ipsum dolor sit amet consectetur, adipisicing elit. Exercitationem, impedit?</h4>
                <p className="font-pnr font-size-16 lightgrey-color line-height-11">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut est enim, 
                    varius sed ligula in, dictum porta justo. Nunc egestas fermentum velit, 
                    ac vulputate eros vestibulum sed. Nullam tristique felis sed blandit mattis. 
                    Vivamus eleifend, magna sit amet fermentum tempor, nunc neque suscipit purus, 
                    sed consequat turpis massa vel nisi. Ut justo ipsum, mollis quis urna quis, 
                    suscipit ultrices nulla. Curabitur eleifend fermentum enim, fringilla scelerisque 
                    eros iaculis in. Phasellus ac ex in urna pellentesque consectetur in ornare urna.
                </p>
                <h4 className="font-pnsb font-size-24 mb-3"><span className="purple-color">•</span> Lorem ipsum dolor sit amet consectetur, adipisicing elit. Exercitationem, impedit?</h4>
                <p className="font-pnr font-size-16 lightgrey-color line-height-11">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut est enim, 
                    varius sed ligula in, dictum porta justo. Nunc egestas fermentum velit, 
                    ac vulputate eros vestibulum sed. Nullam tristique felis sed blandit mattis. 
                    Vivamus eleifend, magna sit amet fermentum tempor, nunc neque suscipit purus, 
                    sed consequat turpis massa vel nisi. Ut justo ipsum, mollis quis urna quis, 
                    suscipit ultrices nulla. Curabitur eleifend fermentum enim, fringilla scelerisque 
                    eros iaculis in. Phasellus ac ex in urna pellentesque consectetur in ornare urna.
                </p>
                <h4 className="font-pnsb font-size-24 mb-3"><span className="purple-color">•</span> Lorem ipsum dolor sit amet consectetur, adipisicing elit. Exercitationem, impedit?</h4>
                <p className="font-pnr font-size-16 lightgrey-color line-height-11">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut est enim, 
                    varius sed ligula in, dictum porta justo. Nunc egestas fermentum velit, 
                    ac vulputate eros vestibulum sed. Nullam tristique felis sed blandit mattis. 
                    Vivamus eleifend, magna sit amet fermentum tempor, nunc neque suscipit purus, 
                    sed consequat turpis massa vel nisi. Ut justo ipsum, mollis quis urna quis, 
                    suscipit ultrices nulla. Curabitur eleifend fermentum enim, fringilla scelerisque 
                    eros iaculis in. Phasellus ac ex in urna pellentesque consectetur in ornare urna.
                </p>
                <h4 className="font-pnsb font-size-24 mb-3"><span className="purple-color">•</span> Lorem ipsum dolor sit amet consectetur, adipisicing elit. Exercitationem, impedit?</h4>
                <p className="font-pnr font-size-16 lightgrey-color line-height-11">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut est enim, 
                    varius sed ligula in, dictum porta justo. Nunc egestas fermentum velit, 
                    ac vulputate eros vestibulum sed. Nullam tristique felis sed blandit mattis. 
                    Vivamus eleifend, magna sit amet fermentum tempor, nunc neque suscipit purus, 
                    sed consequat turpis massa vel nisi. Ut justo ipsum, mollis quis urna quis, 
                    suscipit ultrices nulla. Curabitur eleifend fermentum enim, fringilla scelerisque 
                    eros iaculis in. Phasellus ac ex in urna pellentesque consectetur in ornare urna.
                </p>
                <h4 className="font-pnsb font-size-24 mb-3"><span className="purple-color">•</span> Lorem ipsum dolor sit amet consectetur, adipisicing elit. Exercitationem, impedit?</h4>
                <p className="font-pnr font-size-16 lightgrey-color line-height-11">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut est enim, 
                    varius sed ligula in, dictum porta justo. Nunc egestas fermentum velit, 
                    ac vulputate eros vestibulum sed. Nullam tristique felis sed blandit mattis. 
                    Vivamus eleifend, magna sit amet fermentum tempor, nunc neque suscipit purus, 
                    sed consequat turpis massa vel nisi. Ut justo ipsum, mollis quis urna quis, 
                    suscipit ultrices nulla. Curabitur eleifend fermentum enim, fringilla scelerisque 
                    eros iaculis in. Phasellus ac ex in urna pellentesque consectetur in ornare urna.
                </p>
                <h4 className="font-pnsb font-size-24 mb-3"><span className="purple-color">•</span> Lorem ipsum dolor sit amet consectetur, adipisicing elit. Exercitationem, impedit?</h4>
                <p className="font-pnr font-size-16 lightgrey-color line-height-11">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut est enim, 
                    varius sed ligula in, dictum porta justo. Nunc egestas fermentum velit, 
                    ac vulputate eros vestibulum sed. Nullam tristique felis sed blandit mattis. 
                    Vivamus eleifend, magna sit amet fermentum tempor, nunc neque suscipit purus, 
                    sed consequat turpis massa vel nisi. Ut justo ipsum, mollis quis urna quis, 
                    suscipit ultrices nulla. Curabitur eleifend fermentum enim, fringilla scelerisque 
                    eros iaculis in. Phasellus ac ex in urna pellentesque consectetur in ornare urna.
                </p>
                <h4 className="font-pnsb font-size-24 mb-3"><span className="purple-color">•</span> Lorem ipsum dolor sit amet consectetur, adipisicing elit. Exercitationem, impedit?</h4>
                <p className="font-pnr font-size-16 lightgrey-color line-height-11">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut est enim, 
                    varius sed ligula in, dictum porta justo. Nunc egestas fermentum velit, 
                    ac vulputate eros vestibulum sed. Nullam tristique felis sed blandit mattis. 
                    Vivamus eleifend, magna sit amet fermentum tempor, nunc neque suscipit purus, 
                    sed consequat turpis massa vel nisi. Ut justo ipsum, mollis quis urna quis, 
                    suscipit ultrices nulla. Curabitur eleifend fermentum enim, fringilla scelerisque 
                    eros iaculis in. Phasellus ac ex in urna pellentesque consectetur in ornare urna.
                </p>
                <h4 className="font-pnsb font-size-24 mb-3"><span className="purple-color">•</span> Lorem ipsum dolor sit amet consectetur, adipisicing elit. Exercitationem, impedit?</h4>
                <p className="font-pnr font-size-16 lightgrey-color line-height-11">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut est enim, 
                    varius sed ligula in, dictum porta justo. Nunc egestas fermentum velit, 
                    ac vulputate eros vestibulum sed. Nullam tristique felis sed blandit mattis. 
                    Vivamus eleifend, magna sit amet fermentum tempor, nunc neque suscipit purus, 
                    sed consequat turpis massa vel nisi. Ut justo ipsum, mollis quis urna quis, 
                    suscipit ultrices nulla. Curabitur eleifend fermentum enim, fringilla scelerisque 
                    eros iaculis in. Phasellus ac ex in urna pellentesque consectetur in ornare urna.
                </p>
                <h4 className="font-pnsb font-size-24 mb-3"><span className="purple-color">•</span> Lorem ipsum dolor sit amet consectetur, adipisicing elit. Exercitationem, impedit?</h4>
                <p className="font-pnr font-size-16 lightgrey-color line-height-11">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut est enim, 
                    varius sed ligula in, dictum porta justo. Nunc egestas fermentum velit, 
                    ac vulputate eros vestibulum sed. Nullam tristique felis sed blandit mattis. 
                    Vivamus eleifend, magna sit amet fermentum tempor, nunc neque suscipit purus, 
                    sed consequat turpis massa vel nisi. Ut justo ipsum, mollis quis urna quis, 
                    suscipit ultrices nulla. Curabitur eleifend fermentum enim, fringilla scelerisque 
                    eros iaculis in. Phasellus ac ex in urna pellentesque consectetur in ornare urna.
                </p>
                <h4 className="font-pnsb font-size-24 mb-3"><span className="purple-color">•</span> Lorem ipsum dolor sit amet consectetur, adipisicing elit. Exercitationem, impedit?</h4>
                <p className="font-pnr font-size-16 lightgrey-color line-height-11">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut est enim, 
                    varius sed ligula in, dictum porta justo. Nunc egestas fermentum velit, 
                    ac vulputate eros vestibulum sed. Nullam tristique felis sed blandit mattis. 
                    Vivamus eleifend, magna sit amet fermentum tempor, nunc neque suscipit purus, 
                    sed consequat turpis massa vel nisi. Ut justo ipsum, mollis quis urna quis, 
                    suscipit ultrices nulla. Curabitur eleifend fermentum enim, fringilla scelerisque 
                    eros iaculis in. Phasellus ac ex in urna pellentesque consectetur in ornare urna.
                </p>
            </div>

        </section>
    )
}

export default UserAgreements