import React from 'react';
import { Col, Row } from 'react-bootstrap';
import "./ANetworkBuilt.scss";
import NetworkBuiltImg from "../../../assets/images/png/private-network-page/a-network-built.png";
import { ReactComponent as NetworkIco } from "../../../assets/images/svg/SpectralisPrivateNetworkPage/network-svg.svg";
import { ReactComponent as CpuIco } from "../../../assets/images/svg/SpectralisPrivateNetworkPage/cpu-svg.svg";
import { ReactComponent as BitcoinIco } from "../../../assets/images/svg/SpectralisPrivateNetworkPage/bitcoin-svg.svg";
import { ReactComponent as Anonim } from "../../../assets/images/svg/EcosystemPage/benefits-padlock.svg";
import { ReactComponent as Security } from "../../../assets/images/svg/EcosystemPage/benefits-shield.svg";
import { ReactComponent as Communications } from "../../../assets/images/svg/EcosystemPage/benefits-atom.svg";

export const ANetworkBuilt = () => {
    return (
        <section id="a-network-built-section" className="page-section">
            <div className="container-main">
                <div className='first-part'>
                    <Row className="align-items-center">
                        <Col lg={6} className="d-flex align-items-center">
                            <img src={NetworkBuiltImg} alt="network built for users" className="img-fluid pe-0 pe-lg-3 mb-4 mb-lg-0 m-auto m-lg-0" />
                        </Col>
                        <Col lg={6}>
                            <p className="font-pnr font-size-28 darkgrey-color line-height-11 mb-0 custom-text">
                                SPN is a public network built to facilitate the integration of user-owned devices, therefore, one of its main benefits is that all the nodes are owned by the community. The users that own an SPN Hotspot offer the chance to other people to benefit from the network’s features. Through this, the community shares resources in the network and is an active part in the long sustainable development of the system.
                            </p>
                        </Col>
                    </Row>
                </div>
                <div className='second-part'>
                    <Row className="mb-3 mb-lg-5">
                        <Col lg={4} sm={6}>
                            <div className="column-container d-flex flex-column align-items-start justify-content-between mb-4 mb-sm-5">
                                <div className="ico-container d-flex align-items-center justify-content-center mb-4">
                                    <NetworkIco />
                                </div>

                                <span className="font-pnb font-size-40 mb-3">
                                    Free internet
                                    <span className="purple-color">.</span>
                                </span>

                                <p className="font-size-24 font-pnr line-height-11 lightgrey-color">
                                    Users with registered devices can automatically connect to any Hotspot whenever they are in range, without being prompted for a username or password. 
                                </p>
                            </div>
                        </Col>

                        <Col lg={4} sm={6}>
                            <div className="column-container d-flex flex-column align-items-start justify-content-between mb-4 mb-sm-5">
                                <div className="ico-container d-flex align-items-center justify-content-center mb-4">
                                    <CpuIco />
                                </div>

                                <span className="font-pnb font-size-40 mb-3">
                                    Protected identity
                                    <span className="purple-color">.</span>
                                </span>

                                <p className="font-size-24 font-pnr line-height-11 lightgrey-color">
                                    A SPN’s key attribute is the provision of privacy for all network users through the Matrix Id protocol and it protects the identity of the connection provider and the users who are part of the connection.
                                </p>
                            </div>
                        </Col>

                        <Col lg={4} sm={6}>
                            <div className="column-container d-flex flex-column align-items-start justify-content-between mb-4 mb-sm-5">
                                <div className="ico-container d-flex align-items-center justify-content-center mb-4">
                                    <BitcoinIco />
                                </div>

                                <span className="font-pnb font-size-40 mb-3">
                                    Encryption algorithm
                                    <span className="purple-color">.</span>
                                </span>

                                <p className="font-size-24 font-pnr line-height-11 lightgrey-color">
                                    The SPN’s implemented protocol allows the opening of a connection only after an exchange of P2P encryption keys has been successfully completed between switches.
                                </p>
                            </div>
                        </Col>

                        <Col lg={4} sm={6}>
                            <div className="column-container d-flex flex-column align-items-start justify-content-between mb-4 mb-lg-0">
                                <div className="ico-container d-flex align-items-center justify-content-center mb-4 old-svg">
                                    <Anonim />
                                </div>

                                <span className="font-pnb font-size-40 mb-3">
                                    Routes anonymizer
                                    <span className="purple-color">.</span>
                                </span>

                                <p className="font-size-24 font-pnr line-height-11 lightgrey-color">
                                    Combines elements of TOR and VPN architectures to anonymize and protect the sources, traffic, and entities that are used as relay Nodes within the network.
                                </p>
                            </div>
                        </Col>

                        <Col lg={4} sm={6}>
                            <div className="column-container d-flex flex-column align-items-start justify-content-between mb-4 mb-sm-0">
                                <div className="ico-container d-flex align-items-center justify-content-center mb-4 old-svg">
                                    <Security />
                                </div>

                                <span className="font-pnb font-size-40 mb-3">
                                    Network Security
                                    <span className="purple-color">.</span>
                                </span>

                                <p className="font-size-24 font-pnr line-height-11 lightgrey-color">
                                    It uses the VOBP architecture and cryptographic algorithms to encrypt connections between devices and the Hotspot.
                                </p>
                            </div>
                        </Col>

                        <Col lg={4} sm={6}>
                            <div className="column-container d-flex flex-column align-items-start justify-content-between">
                                <div className="ico-container d-flex align-items-center justify-content-center mb-4 old-svg">
                                    <Communications />
                                </div>

                                <span className="font-pnb font-size-40 mb-3">
                                    P2P Communications
                                    <span className="purple-color">.</span>
                                </span>

                                <p className="font-size-24 font-pnr line-height-11 lightgrey-color">
                                    Opens a direct communication session between the initiator device and every network relay, including between all the network relays. Traffic and sessions encryption keys are generated by the initiator device.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </section>
    )
}

export default ANetworkBuilt