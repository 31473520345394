import './SupportSteps.scss'
import Uppertitle from '../../../../components/Uppertitle/Uppertitle'
import { useNavigate } from 'react-router-dom'

// steps
import step1 from './assets/pasul 1 select pool tab.png'
import step2 from './assets/pasul 2 select pool .png'
import step3 from './assets/pasul 3 connect metamask.png'
import step4 from './assets/pasul 4 conectare metamask cu wallet de stake.png'
import step5 from './assets/pasul 5 click unstake.png'
import step6 from './assets/pasul 6 confirm transaction after metamask pop out.png'
import step7 from './assets/pasul 7 unstake confirmed.png'

import { Row, Col } from 'react-bootstrap'

const SupportStaketSteps = () => {
  const navigate = useNavigate()

  return (
    <section
      id='guardian-steps-section'
      className='stake-support-steps-section'
    >
      <div className='page-section text-white'>
        <div className='container-main'>
          <div className='mt-5'>
            <div className='corners hts-card p-lg-5 p-4'>
              <div className='rotate-uppertitle'>
                <Uppertitle title={'STEP 01'} />
              </div>
              <div className='description-col marginRight hts-card-col reverse-description'>
                <div className='hts-p-container'>
                  <p className='font-family-pnr font-size-20 lightgrey-color line-height-11 text-start'>
                    Select the "Pools" tab from your back-office.
                    <span
                      onClick={() =>
                        window.open('https://xiden.com/support-minter')
                      }
                      className='pools-link-support white-color'
                    >
                      {' '}
                      Check here
                    </span>{' '}
                    how to configure MinteR Guardian Router.
                  </p>
                </div>
              </div>
              <div className='d-flex justify-content-center align-items-center hts-card-col reverse-img-col'>
                <img
                  src={step1}
                  loading='lazy'
                  alt='router'
                  className='img-fluid pb-md-0 pb-sm-5 pb-4'
                />
              </div>
            </div>

            {/* kreater pool */}
            <div className='corners hts-card p-lg-5 p-4'>
              <div className='rotate-uppertitle right-0'>
                <Uppertitle title={'STEP 02'} />
              </div>
              <div className='d-flex justify-content-center align-items-center marginRight hts-card-col'>
                <img
                  src={step2}
                  loading='lazy'
                  alt='transactions'
                  className='hts-image img-fluid mb-md-0 mb-5 '
                />
              </div>
              <div className='description-col hts-card-col'>
                {/* <p className='mb-4 font-size-40 font-pnb line-height-11'>
                  Login into your Metamask with your wallet into the Pool
                  <span className='purple-color'>.</span>
                </p> */}
                <div className='hts-p-container'>
                  <p className='font-family-pnr font-size-20 lightgrey-color line-height-11 text-start'>
                    {/* Login your wallet into the Pool. You can{' '}
                    <a
                      href='https://xiden.com/support-metamask'
                      target='_blank'
                    >
                      check here{' '}
                    </a>{' '}
                    how to configure your wallet. */}
                    Scroll down to the "My Pools" list and click on the pool
                    from which you wish to process the withdrawal of your
                    private mining revenue.
                  </p>
                </div>
              </div>
            </div>

            <div className='corners hts-card p-lg-5 p-4'>
              <div className='rotate-uppertitle'>
                <Uppertitle title={'STEP 03'} />
              </div>
              <div className='description-col marginRight hts-card-col reverse-description'>
                <div className='hts-p-container'>
                  <p className='pb-2 font-family-pnr font-size-20 lightgrey-color line-height-11 text-start'>
                    Open your MetaMask browser extension and log in.
                  </p>
                </div>
              </div>
              <div className='d-flex justify-content-center align-items-center hts-card-col reverse-img-col'>
                <img
                  src={step3}
                  loading='lazy'
                  alt='transactions'
                  className='hts-image img-fluid pb-5 pb-md-0'
                />
              </div>
            </div>

            <div className='corners hts-card p-lg-5 p-4'>
              <div className='rotate-uppertitle right-0'>
                <Uppertitle title={'STEP 04'} />
              </div>
              <div className='d-flex justify-content-center align-items-center marginRight hts-card-col'>
                <img
                  src={step4}
                  loading='lazy'
                  alt='transactions'
                  className='hts-image img-fluid mb-md-0 mb-4'
                />
              </div>
              <div className='description-col hts-card-col'>
                <div className='hts-p-container'>
                  <p className='font-family-pnr font-size-20 lightgrey-color line-height-11 text-start'>
                    Once you log into MetaMask make sure you are selecting the
                    wallet you used for staking the XDEN of that specific pool.
                    (You can verify this by looking next to your pool's name and
                    making sure the Staking wallet address is the same as the
                    one displayed under "MetaMask connected".)
                  </p>
                </div>
              </div>
            </div>

            <div className='corners hts-card p-lg-5 p-4'>
              <div className='rotate-uppertitle'>
                <Uppertitle title={'STEP 05'} />
              </div>
              <div className='description-col marginRight hts-card-col reverse-description'>
                <div className='hts-p-container'>
                  <p className='font-family-pnr font-size-20 lightgrey-color line-height-11 text-start'>
                    Scroll down on your pool's page and click the " Unstake"
                    button. Carefully read the notification that pops up and
                    then press "Unstake".
                  </p>
                </div>
              </div>
              <div className='step-5 d-flex justify-content-center align-items-center hts-card-col reverse-img-col'>
                <img
                  src={step5}
                  loading='lazy'
                  alt='transactions'
                  className='img-fluid pb-5 pb-md-0'
                />
              </div>
            </div>

            <div className='corners hts-card p-lg-5 p-4'>
              <div className='rotate-uppertitle right-0'>
                <Uppertitle title={'STEP 06'} />
              </div>
              <div className='d-flex justify-content-center align-items-center marginRight hts-card-col'>
                <img
                  src={step6}
                  loading='lazy'
                  alt='transactions'
                  className='hts-image img-fluid mb-md-0 mb-4'
                />
              </div>
              <div className='description-col hts-card-col'>
                <div className='hts-p-container'>
                  <p className='font-family-pnr font-size-20 lightgrey-color line-height-11 text-start'>
                    Once the Unstake from the previous step is confirmed, the
                    MetaMask browser extension will pop up with your
                    transaction. Simply click on the "Confirm" button.
                  </p>
                </div>
              </div>
            </div>

            <div className='corners hts-card p-lg-5 p-4'>
              <div className='rotate-uppertitle'>
                <Uppertitle title={'STEP 07'} />
              </div>
              <div className='description-col marginRight hts-card-col reverse-description'>
                <div className='hts-p-container'>
                  <p className='font-family-pnr font-size-20 lightgrey-color line-height-11 text-start'>
                    After 30 seconds, your transaction will be completed and the
                    withdrawal of your XDEN transferred to your MetaMask wallet.
                  </p>
                </div>
              </div>
              <div className='d-flex justify-content-center align-items-center hts-card-col reverse-img-col'>
                <img
                  src={step7}
                  loading='lazy'
                  alt='transactions'
                  className='hts-image  img-fluid mb-md-0 mb-4 p-lg-5 p-0'
                />
              </div>
            </div>
            {/* <hr></hr>
            <h4 className='mb-4 font-size-80 font-pnb line-height-11 mt-5'>
              How to Unstake<span className='purple-color'>?</span>
            </h4>

            <div className='corners hts-card p-lg-5 p-4 mb-5'>
              <div className='rotate-uppertitle'>
                <Uppertitle title={'STEP 08'} />
              </div>
              <div className='description-col marginRight hts-card-col reverse-description'>
                <p className='mb-4 font-size-40 font-pnb line-height-11'>
                  Access your wallet and press unstake
                  <span className='purple-color'>.</span>
                </p>
                <div className='hts-p-container'>
                  <p className='font-family-pnr font-size-20 lightgrey-color line-height-11 text-start'>
                    Access your wallet and press the ''Unstake'' button to end
                    your staking period. If you choose to Unstake you will not
                    receive staking rewards anymore.{' '}
                  </p>
                </div>
              </div>
              <div className='d-flex justify-content-center align-items-center hts-card-col reverse-img-col'>
                <img
                  src={Four}
                  loading='lazy'
                  alt='transactions'
                  className='hts-image img-fluid mb-md-0 mb-5'
                />
              </div>
            </div>
            <hr></hr> */}
          </div>
        </div>
      </div>
    </section>
  )
}

export default SupportStaketSteps
