import React from "react";
import "./Howitworks.scss";
import { Col, Row } from "react-bootstrap";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import Rewards2 from "../../../assets/images/png/validator-how.png";
import BeOnline from "../../../assets/images/png/be-online-validator.png";
import ConfigureValidator from "../../../assets/images/png/validator-page/validator-configure-validator-step.png";
import KraterPool from "../../../assets/images/png/validator-page/validator-connect-to-krater-pool-step.png";
import Rewards from "../../../assets/images/png/validator-page/validator-get-rewards-step.png";
import Validate from "../../../assets/images/png/validator-page/validator-validate-step.png";
import { ReactComponent as ArrowRight } from "../../../assets//images/svg/arrow-right.svg";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../Utils/routes";
import { toast } from "react-toastify";
import { HashLink } from "react-router-hash-link";

const Howitworks = () => {
  const navigate = useNavigate();
  const descriptions = {
    description1:
      "Integrate your device into the system, share your available resources to help validate and maintain the integrity of network data.",
    description2: "Open your Krater Pool and connect all your available smart devices in order to unlock your XDEN amount.",
    description3: "Make sure your smart devices are always connected and alive, contributing to the network’s operation.",
    description4: "Ensure conformity to the system for data, transactions, and integrated smart electronic devices.",
    description5: "The more successful processed validations, the more rewards you will get.",
  };
  return (
    <section id="howitworks-validator" className="page-section">
      <div className="container-main">
        <Uppertitle title={"STEPS"} />

        <div className="title">
          <h2 className="font-size-80 white-color font-pnb">
            How it works
            <span className="purple-color">.</span>
          </h2>
          <p className="title-paragraph font-pnr font-size-24 pt-lg-5 pt-4 lightgrey-color description-991 line-height-11">
            Discover how to configure your smart devices to work for you even when you’re not there. By following the steps you
            will be able to be part of the Xiden decentralized network.
          </p>
        </div>
        <Row className="row-content mb-md-5">
          <Col
            lg={5}
            md={4}
            className="d-flex align-items-center align-content-center justify-content-start my-md-0 my-lg-0 my-5"
          >
            <img src={ConfigureValidator} loading="lazy" alt="Configure the validator" className="img-fluid how-it-works-img" />
          </Col>
          <Col
            lg={7}
            md={8}
            className="column-identifier d-flex align-items-center align-content-center justify-content-start ps-3 ps-md-5"
          >
            <div className="column-wrapper step1 d-flex align-items-center align-content-center justify-content-start">
              <div className="uppertitle-container">
                <div className="rotate-uppertitle">
                  <Uppertitle title={"STEP 01"} />
                </div>
              </div>
              <div className="content">
                <h4 className="font-size-40 white-color font-pnb">
                  Configure your validator
                  <span className="purple-color">.</span>
                </h4>
                <p className="font-pnr font-size-20 lightgrey-color line-height-11">{descriptions.description1}</p>
                <button
                  className="btn-link font-size-20 font-pnsb"
                  onClick={() => toast.info("Coming soon...", { toastId: "2dkerjfuhrtg" })}
                >
                  Read more
                  <ArrowRight />
                </button>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="row-content mb-md-5">
          <Col
            lg={5}
            md={4}
            className="d-flex align-items-center align-content-center justify-content-start my-md-0 my-lg-0 my-5"
          >
            <img src={KraterPool} loading="lazy" alt="Krater pool connection" className="img-fluid how-it-works-img" />
          </Col>
          <Col
            lg={7}
            md={8}
            className="column-identifier d-flex align-items-center align-content-center justify-content-start ps-3 ps-md-5"
          >
            <div className="column-wrapper step1 d-flex align-items-center align-content-center justify-content-start">
              <div className="uppertitle-container">
                <div className="rotate-uppertitle">
                  <Uppertitle title={"STEP 02"} />
                </div>
              </div>
              <div className="content">
                <h4 className="font-size-40 white-color font-pnb">
                  Connect to KrateR Pool
                  <span className="purple-color">.</span>
                </h4>
                <p className="font-pnr font-size-20 lightgrey-color line-height-11">{descriptions.description2}</p>
                <button className="btn-link font-size-20 font-pnsb" onClick={() => navigate(routes.guardianNode)}>
                  Read more
                  <ArrowRight />
                </button>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="row-content mb-md-5">
          <Col
            lg={5}
            md={4}
            className="d-flex align-items-center align-content-center justify-content-start  my-md-0 my-lg-0 my-5"
          >
            <img src={BeOnline} loading="lazy" alt="Be online" className="img-fluid how-it-works-img" />
          </Col>
          <Col
            lg={7}
            md={8}
            className="column-identifier d-flex align-items-center align-content-center justify-content-start ps-3 ps-md-5"
          >
            <div className="column-wrapper step1 d-flex align-items-center align-content-center justify-content-start">
              <div className="uppertitle-container">
                <div className="rotate-uppertitle">
                  <Uppertitle title={"STEP 03"} />
                </div>
              </div>
              <div className="content">
                <h4 className="font-size-40 white-color font-pnb">
                  Be online
                  <span className="purple-color">.</span>
                </h4>
                <p className="font-pnr font-size-20 lightgrey-color line-height-11">{descriptions.description3}</p>
                <button className="btn-link font-size-20 font-pnsb" onClick={() => navigate(routes.xiden)}>
                  Read more
                  <ArrowRight />
                </button>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="row-content mb-md-5">
          <Col
            lg={5}
            md={4}
            className="d-flex align-items-center align-content-center justify-content-start my-md-0 my-lg-0 my-5"
          >
            <img src={Validate} loading="lazy" alt="Validate to ensure conformity" className="img-fluid how-it-works-img" />
          </Col>
          <Col
            lg={7}
            md={8}
            className="column-identifier d-flex align-items-center align-content-center justify-content-start ps-3 ps-md-5"
          >
            <div className="column-wrapper step1 d-flex align-items-center align-content-center justify-content-start">
              <div className="uppertitle-container">
                <div className="rotate-uppertitle">
                  <Uppertitle title={"STEP 04"} />
                </div>
              </div>
              <div className="content">
                <h4 className="font-size-40 white-color font-pnb">
                  Validate
                  <span className="purple-color">.</span>
                </h4>
                <p className="font-pnr font-size-20 lightgrey-color line-height-11">{descriptions.description4}</p>
                <button
                  className="btn-link font-size-20 font-pnsb"
                  onClick={() => toast.info("Coming soon...", { toastId: "3dkerjfuhrtg" })}
                >
                  Read more
                  <ArrowRight />
                </button>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="row-content">
          <Col
            lg={5}
            md={4}
            className="d-flex align-items-center align-content-center justify-content-start my-md-0 my-lg-0 my-5"
          >
            <img src={Rewards} loading="lazy" alt="Get rewards" className="img-fluid how-it-works-coin-img" />
          </Col>
          <Col
            lg={7}
            md={8}
            className="column-identifier d-flex align-items-center align-content-center justify-content-start ps-3 ps-md-5"
          >
            <div className="column-wrapper step1 d-flex align-items-center align-content-center justify-content-start">
              <div className="uppertitle-container">
                <div className="rotate-uppertitle">
                  <Uppertitle title={"STEP 05"} />
                </div>
              </div>
              <div className="content">
                <h4 className="font-size-40 white-color font-pnb">
                  Get rewards
                  <span className="purple-color">.</span>
                </h4>
                <p className="font-pnr font-size-20 lightgrey-color line-height-11">{descriptions.description5}</p>
                {/* <button className="btn-link font-size-20 font-pnsb"
                                    onClick={() => navigate(routes.rewards)}>
                                    
                                    <ArrowRight />
                                </button> */}
                <HashLink className="btn-link font-size-20 font-pnsb" to="/rewards/#rewards-get-rewards">
                  Read more
                  <ArrowRight />
                </HashLink>
              </div>
            </div>
          </Col>
        </Row>
        {/* </Col> */}
        {/* </Row> */}
      </div>
    </section>
  );
};

export default Howitworks;
