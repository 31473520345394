import React, { useState } from "react";
import "./Boosters.scss";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import DeviceCard from "../../../components/DeviceCard/DeviceCard";
import { ReactComponent as ArrowDown } from "../../../assets/images/svg/arrow-down.svg";
import { basmMainCardData, basmCardsData } from "./cardsData/basmCardsData";
import { routerMainCardData, routerCardsData } from "./cardsData/routerCardsData";
import { hidrMainCardData, hidrCardsData } from "./cardsData/hidrCardsData";
import ImpulseCardsList from "./CardsLists/ImpulseCardsList";
import BasmCardsList from "./CardsLists/BasmCardsList";
import HidrCardsList from "./CardsLists/HidrCardsList";

const Boosters = () => {
  const [activeDevice, setActiveDevice] = useState(() => {
    return {
      impulse: false,
      basm: true,
      hidr: false,
    };
  });
  const [selectedDevice, setSelectedDevice] = useState(() => "basm");

  const handleActiveDevice = (deviceName) => {
    switch (deviceName) {
      case "impulse":
        setActiveDevice({ impulse: true, basm: false, hidr: false });
        break;

      case "basm":
        setActiveDevice({ impulse: false, basm: true, hidr: false });
        break;

      case "hidr":
        setActiveDevice({ impulse: false, basm: false, hidr: true });
        break;

      default:
        setActiveDevice({ impulse: false, basm: true, hidr: false });
    }
    setSelectedDevice(deviceName);
  };

  let cardsList;
  switch (selectedDevice) {
    case "impulse":
      cardsList = <ImpulseCardsList cardsData={routerCardsData} />;
      break;

    case "basm":
      cardsList = <BasmCardsList cardsData={basmCardsData} />;
      break;

    case "hidr":
      cardsList = <HidrCardsList />;
      break;

    default:
      cardsList = <BasmCardsList cardsData={basmCardsData} />;
  }
  return (
    <section id="boosters-section" className="page-section">
      <div className="container-main boosters-section-container">
        {/* heading */}
        <div className="w-100">
          <Uppertitle title={`DEVICES`} />
          <h2 className="font-size-80 font-pnb">
            Validators<span className="pink-text">.</span>
          </h2>
          <div className="rewards-p-container-lg">
            <p className="font-family-pnr font-size-24 lightgrey-color line-height-11">
              You can connect up to 20 devices to your Hotspot. By making their resources available for the network you will
              benefit from an up to 20% mining booster.
            </p>
          </div>
        </div>

        {/* cards */}
        <div className="w-100 mt-5 ">
          {/* controls */}
          <div className="mb-4 w-100 d-flex justify-content-center">
            <p className="mb-0 font-size-18 font-pnr pointer">
              <span>SELECT A DEVICE BELOW</span>
              <ArrowDown className="ms-3" />
            </p>
          </div>
          <div className="cards-control-container">
            <DeviceCard
              cardData={routerMainCardData}
              color="dark"
              pointer
              devicesCards
              deviceType="impulse"
              activeDevice={activeDevice.impulse}
              toggleDevice={handleActiveDevice}
            />

            <DeviceCard
              cardData={basmMainCardData}
              color="blue"
              pointer
              marginX
              devicesCards
              deviceType="basm"
              activeDevice={activeDevice.basm}
              toggleDevice={handleActiveDevice}
            />

            <DeviceCard
              cardData={hidrMainCardData}
              color="dark"
              pointer
              devicesCards
              deviceType="hidr"
              activeDevice={activeDevice.hidr}
              toggleDevice={handleActiveDevice}
            />
          </div>
          {/* !!!! div below is temporary. DELETE IT WHEN NO LONGER NEEDING COMING SOON CONTAINER */}
          <div className="">{cardsList}</div>
        </div>
      </div>
    </section>
  );
};

export default Boosters;
