import React from "react";
import "./MainView.scss";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
// import MyImg from "../../../assets/images/png/performer-guardian.png";
import MyImg from "../../../assets/images/png/xiden-page/xiden-hero-img.png";
import { ReactComponent as ArrowRight } from "../../../assets/images/svg/arrow-right.svg";
import { ReactComponent as ArrowRightUp } from "../../../assets/images/svg/arrow-right-up.svg";
import { toast } from "react-toastify";
import { Link as ScrollLink } from "react-scroll";
// import LightPaper from "../../../assets/documents/WhitePaper-XIDEN BlockchainV1.1.pdf";
import loop1 from "../../../assets/videos/ecosystem-page/animatie_realm.mp4";

var myVids = [`${loop1}`, `${loop1}`];

const Hero = () => {
  return (
    <section id="homepage-xiden" className="page-section">
      <div className="container-main hero-section-container">
        <div className="main-col">
          <div className="ecosystem-uppertitle-container">
            <Uppertitle title={"[ k ʃ ɪ d (ə) n ]"} />
          </div>
          <h2 className="mb-0 font-size-80 white-color font-pnb">
            X<span className="darkgrey-color"> | </span>
            Anonymous Internet of Decentralized Networks
            <span className="purple-color">.</span>
          </h2>
          <div className="my-4 paragraph-container">
            <p className="font-family-pnr font-size-24 lightgrey-color">
              The Xiden network is built to facilitate the integration of all smart devices in order to provide a free connection
              and internet access in a secure, anonymous, and unrestricted manner.
            </p>
          </div>
          <div className="hero-btn-container">
            <a className="buttonBlue hero-custom-btn" href={"https://xiden.com/whitepaper/"} target="_blank">
              <span className="me-2">Whitepaper</span>
              <ArrowRightUp />
            </a>

            <ScrollLink className="buttonOutlineBlue hero-custom-btn" to="ecosystem-blockchain" spy={true}>
              <span className="me-2">Get started</span>
              <ArrowRight />
            </ScrollLink>
          </div>
        </div>

        <div className="section-image-col">
          {/* <img src={MyImg} loading="lazy" alt="xid coin" className="section-image ecosystem-hero-image" /> */}
          <video src={loop1} autoPlay playsInline muted loop={true} className="w-100 section-image ecosystem-hero-image" />
          {/* am lasat clasele cu denumirea image - ele sunt aplicabile si pentru video */}
        </div>
      </div>
    </section>
  );
};

export default Hero;
