import React from "react";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import { ReactComponent as ArrowRight } from "../../../assets/images/svg/arrow-right.svg";

import "./FeaturesMobileSection.scss";

const FeaturesCardXden = (props) => {
  return (
    <div className={`${props.bg} column-wrapper p-xl-5 p-4 coloana-r2 d-flex flex-column`}>
      <Uppertitle title={props.title} />
      <div className="d-flex align-items-start align-content-start justify-content-start flex-column-reverse img-and-title">
        <div className="content-card d-flex flex-column justify-content-between">
          <h4
            className={`${props.title === "Owner" && "custom-margin"} ${
              props.title === "Open" && "custom-margin"
            }  font-pnb font-size-56 white-color`}
          >
            {props.description}
            <span className="purple-color">.</span>
          </h4>
        </div>

        {props.imageUrl && (
          <img src={props.imageUrl} alt="coin" className={`img-fluid ${props.customClass ? "crate-img" : "img-title "}`} />
        )}
      </div>
      <div>
        <button className="link-btn font-pnsb font-size-20" onClick={props.onNavigation}>
          Get started
          <ArrowRight />
        </button>
      </div>
    </div>
  );
};

export default FeaturesCardXden;
