import React from "react";
import { Col, Row, Button } from "react-bootstrap";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import "./CardsSection2.scss";
import ValdidatorsCooldown from "../../../assets/images/png/staking/validators-cooldown.png";
import { ReactComponent as GraphicPools } from "../../../assets/images/svg/staking-cards-section2-img1.svg";
import { ReactComponent as GraphicExecution } from "../../../assets/images/svg/staking-cards-section2-img2.svg";

const CardsSection2 = () => {
  return (
    <section id="cards-section2" className="page-section">
      <div className="container-main">
        <div className="network-reward-container d-flex justify-content-end align-items-center">
          <div className="column-wrapper p-4 p-lg-5 bg1 d-flex flex-column justify-content-between w-100 me-4">
            <Uppertitle title={"HELP NETWORK"} />
            <GraphicPools className="img-fluid my-4 column-wrapper-img" />

            <p className="mb-0 validator-text white-color font-size-56 mb-0 font-pnb line-height-11">
              Validators <br></br> must be <br></br> kept online <br></br> <span className="darkgrey-color">and up to date</span>{" "}
              <br></br> to help the network
              <span className="purple-color">.</span>
            </p>
          </div>

          <div className="column-wrapper p-4 p-lg-5 bg2 d-flex flex-column justify-content-between w-100">
            <Uppertitle title={"TAKE REWARD"} />
            <GraphicExecution className="img-fluid my-4 column-wrapper-img" />

            <p className="mb-0 validator-text white-color font-size-56 mb-0 font-pnb line-height-11">
              Validators <br></br> must be <br></br> kept online <br></br> <span className="darkgrey-color">and up to date</span>{" "}
              <br></br> to earn rewards
              <span className="purple-color">.</span>
            </p>
          </div>
        </div>

        <div className="column-wrapper p-4 p-lg-5 bg3 d-flex justify-content-between w-100 section-end-col flex-row">
          <div className="card-content d-flex flex-column justify-content-start align-items-start">
            <Uppertitle title={"WITHDRAW"} />
            <h4 className="white-color font-size-56 my-auto pt-lg-0 pt-4 font-pnb withdraw-text">
              Once unstaked, <br></br>
              <span className="darkgrey-color">
                validators enter a <br></br>
              </span>
              cooldown period <br></br>
              <span className="darkgrey-color">
                for approximately<br></br>
              </span>{" "}
              6 months<span className="purple-color">.</span>
            </h4>
          </div>

          <img src={ValdidatorsCooldown} loading="lazy" alt="connected devices" className="img-fluid validators-cooldown-img" />
        </div>
      </div>
    </section>
  );
};

export default CardsSection2;
