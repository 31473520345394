import React from "react";
import "./Features.scss";
import { Col, Row } from "react-bootstrap";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import { ReactComponent as ArrowRight } from "../../../assets/images/svg/arrow-right.svg";
import Coins from "../../../assets/images/png/xiden-features-coins-bg.png";
import Splash from "../../../assets/images/png/xiden-features-card-img.png";
import Execution from "../../../assets/images/png/xid-page-card-check-img.png";
import Cube from "../../../assets/images/png/XID-features-page/cryptographic-cube.png";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../Utils/routes";
import { toast } from "react-toastify";
import { Element } from "react-scroll";

const Features = () => {
  const navigate = useNavigate();

  return (
    <Element name="xid-page-features">
      <section id="features-xiden-section" className="page-section">
        <div className="container-main ">
          <h2 className="title font-size-80 white-color font-pnb">
            Features
            <span className="purple-color">.</span>
          </h2>

          <Row>
            <Col lg={4}>
              <div className="column-wrapper p-xl-5 p-4 bg1 coloana-r2 d-flex flex-column">
                <div className="image-title d-flex flex-column justify-content-between">
                  <Uppertitle title={"FREE EXCHANGE"} />
                  <div className="d-flex align-items-start align-content-start justify-content-start flex-column-reverse img-and-title">
                    <div className="content-card d-flex flex-column justify-content-between">
                      <h4 className="font-pnb font-size-56 white-color">
                        Free for <br /> exchange
                        <span className="purple-color">.</span>
                      </h4>
                    </div>
                    <img src={Coins} alt="coin" className="img-fluid img-title" />
                  </div>
                </div>
                <div>
                  <button
                    className="link-btn font-pnsb font-size-20"
                    onClick={() => toast.info("Coming soon...", { toastId: "13dkerjfuhrtg" })}
                  >
                    Get started
                    <ArrowRight />
                  </button>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <Row>
                <Col md={6}>
                  <div className="column-wrapper p-xl-5 p-4 bg2 coloana-r2-c2-c1 d-flex flex-column">
                    <Uppertitle title={"OWNER"} />
                    <h4 className="font-pnb font-size-56 white-color">
                      You are <br />
                      sole owner
                      <span className="purple-color">.</span>
                    </h4>
                    <div>
                      <button
                        className="link-btn font-pnsb font-size-20"
                        onClick={() => toast.info("Coming soon...", { toastId: "14dkerjfuhrtg" })}
                      >
                        Read more
                        <ArrowRight />
                      </button>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="column-wrapper p-xl-5 p-4 bg3 coloana-r2-c2-c2 blue-color d-flex flex-column">
                    <Uppertitle title={"OPEN"} />
                    <h4 className="font-pnb font-size-56 white-color">
                      Open to <br /> everyone
                      <span className="purple-color">.</span>
                    </h4>
                    <div>
                      <button
                        className="link-btn font-pnsb font-size-20"
                        onClick={() => toast.info("Coming soon...", { toastId: "15dkerjfuhrtg" })}
                      >
                        Get started
                        <ArrowRight />
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="column-wrapper p-xl-5 p-4 coloana-r3 bg5 d-flex align-items-center justify-content-between">
                    <div className="content green-color-title">
                      <Uppertitle title={"SECURED"} />
                      <h4 className="font-pnb  py-lg-0 py-4  font-size-56 white-color">
                        Cryptographic <br /> secured
                        <span className="green-color">.</span>
                      </h4>
                      <div id="xid-cryptographic-card">
                        <button
                          className="link-btn font-pnsb font-size-20 text-shadowed cryptographic-link"
                          onClick={() => navigate(routes.privateNetwork)}
                        >
                          Get started
                          <ArrowRight />
                        </button>
                      </div>
                    </div>
                    <div className="content-img d-flex align-items-center">
                      <img src={Cube} alt="validator" className="img-fluid cube-img" />
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col lg={8}>
              <div className="column-wrapper p-xl-5 p-4 bg4 coloana-r3 d-flex flex-row align-items-center justify-content-between">
                <div className="content d-flex flex-column justify-content-between">
                  <Uppertitle title={"VALIDATION"} />
                  <h4 className="font-pnb  py-lg-0 py-4  font-size-56 white-color">
                    Automatically <br /> validated by <br /> the network
                    <span className="purple-color">.</span>
                  </h4>
                  <button className="link-btn font-pnsb font-size-20" onClick={() => navigate(routes.validator)}>
                    Get started
                    <ArrowRight />
                  </button>
                </div>
                <div className="content-img">
                  <img src={Execution} alt="computing" className="validation-img" />
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="column-wrapper p-xl-5 p-4 bg6 coloana-r3 blue-color">
                <Row className="normal-col h-100 ">
                  <Col md={5} lg className="d-flex flex-column justify-content-between link-col">
                    <Uppertitle title={"DECENTRALIZED"} />
                    <h4 className="decentralized font-pnb py-lg-0 py-4  font-size-56 white-color">
                      Decentralized
                      <span className="purple-color">.</span>
                    </h4>

                    <div>
                      <button
                        className="link-btn font-pnsb font-size-20"
                        onClick={() => toast.info("Coming soon...", { toastId: "16dkerjfuhrtg" })}
                      >
                        Get started
                        <ArrowRight />
                      </button>
                    </div>
                  </Col>
                  <Col md={7} lg className="d-flex justify-content-center align-items-center">
                    <img src={Splash} alt="computing" className="decentralized-img img-fluid d-none" />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </Element>
  );
};

export default Features;
