import React from "react";
import "./BuildApps.scss";
import { Col, Row } from "react-bootstrap";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import { Element } from "react-scroll";
import { ReactComponent as ArrowRight } from "../../../assets//images/svg/arrow-right.svg";

const BuildApps = () => {
  return (
    <Element name="builds-apps-section">
      <section id="build-apps" className="page-section">
        <div className="container-main">
          <Uppertitle title={"DEVELOPER"} />
          <h2 className="font-size-80  pb-lg-5 pb-4  white-color font-pnb">
            Build apps that use resources available within the network
            <span className="purple-color">.</span>
          </h2>

          <Row>
            <Col md={6}>
              <div className="column-wrapper small-col bg1 p-4 p-xl-5 d-flex flex-column align-items-start justify-content-between">
                <Uppertitle title={"FRAMEWORKS"} />
                <div className="content-card d-flex align-items-end">
                  <h4 className="font-pnb font-size-56 white-color">
                    For every <br></br>
                    programmer <br></br>
                    out there
                    <ArrowRight className="purple-color" />
                  </h4>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="column-wrapper small-col bg2 p-4 p-xl-5 d-flex flex-column align-items-start justify-content-between last-col">
                <Uppertitle title={"EASY"} />
                <div className="content-card d-flex align-items-end">
                  <h4 className="font-pnb font-size-56 white-color">
                    As easy as <br></br>
                    Plug & Play
                    <ArrowRight className="purple-color" />
                  </h4>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </Element>
  );
};

export default BuildApps;
