import React from "react";
import "./Reward.scss";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import RewardCalculator from "../../../components/RewardCalculator/RewardCalculator";
import CalculatorImage from "../../../assets/images/png/homePage/calculator.png";

const Reward = () => {
  return (
    <section className="reward-section page-section">
      <div className="container-main reward-container">
        <div className="info-col">
          <img src={CalculatorImage} loading="lazy" alt="Reward Calculator" className="img-fluid calculator-img mb-lg-5 mb-3" />
          <div>
            <Uppertitle title={`REWARDS`} />
            <div className="line-height-1">
              <p className="mb-0 font-size-80 font-pnb line-height-11">Reward</p>
              <p className="font-size-80 font-pnb line-height-11">
                Calculator
                <span className="pink-text">.</span>
              </p>
            </div>
            <div className="description pb-4 paragraph-container">
              <p className="font-family-pnr font-size-24 lightgrey-color line-height-11">
                Calculate your reward rates according to your staking, type of devices, and your preferred mining difficulty.
              </p>
            </div>
          </div>
        </div>

        <div className="calculator-col">
          <RewardCalculator />
        </div>
      </div>
    </section>
  );
};

export default Reward;
