import React, { useState } from "react";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import ImageModal from "../SupportKraterInstallationSteps/ImageModal";
import "./LeftSide.scss";

const LeftSide = (props) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="corners step-1-card hts-card p-lg-5 p-4">
      <div className="rotate-uppertitle">
        <Uppertitle title={props.uppertitle} />
      </div>
      <div className="description-col marginRight hts-card-col reverse-description">
        <p className="pb-4 font-size-40 font-pnb text-center text-md-start line-height-11 mb-0">
          {props.title}
          <span className="purple-color">{props.punctuation}</span>
        </p>
        <div className="hts-p-container text-center text-md-start krater-mobile-center">{props.content}</div>
      </div>
      <div className="d-flex justify-content-center align-items-center hts-card-col reverse-img-col">
        {props.modal ? (
          <img
            src={props.image}
            loading="lazy"
            alt="router"
            className="img-fluid mb-md-0 mb-4 pointer"
            onClick={() => setShowModal(true)}
          />
        ) : (
          <img src={props.image} loading="lazy" alt="router" className="img-fluid mb-md-0 mb-4 pointer" />
        )}

        <ImageModal show={showModal} onHide={() => setShowModal(false)} title={props.uppertitle} image={props.image} />
      </div>
    </div>
  );
};

export default LeftSide;
