import React, { useState } from 'react';
import "./FAQ.scss";
import DashRight from "../assets/dash-right.png";
import DashLeft from "../assets/dash-left.png";
import Uppertitle from '../../../components/Uppertitle/Uppertitle';
import { Accordion } from 'react-bootstrap';
import { ReactComponent as Plus } from "../../../components/Assets/images/png/test-plus.svg";
import { AiOutlineArrowDown as ArrowDown } from "react-icons/ai";
import { AiOutlineArrowUp as ArrowUp } from "react-icons/ai";

const FAQ = () => {

    const [maxItems, setMaxItems] = useState(4);
    const [toggle, setToggle] = useState(false)

    const FAQData = [
        {
            question: "Are there any special requirements for the picture submitted on Twitter?",
            answer: `The picture must be clear and unaltered.`
        },
        {
            question: "Can I participate if I don’t own a Minter router?",
            answer: `No. All participants are required to at least own a Minter in order to participate, even if they did not set up their pools yet.`
        },
        {
            question: "Can I participate on any other social media platform?",
            answer: `No. The competition is held on the website and the submissions for the design category is held exclusively on Twitter.`
        },
        {
            question: "I have a Minter router. How do I set up my mining pool?",
            answer: `There are step-by-step guides on how to configure your Minter router and how to set up your Pool on the Xiden website.`
        },
        {
            question: "How do I add miners to my pool?",
            answer: `There are step-by-step guides on how to add each type of mining device to your Pool on the Xiden website.`
        },
        {
            question: "How do I increase the power of my pool?",
            answer: `Each miner added to the pool increases the mining power by a specific amount. On top of that, up to 20 additional devices can be added to the pool as boosters, simply by connecting them to your Minter WiFi.`
        },
        {
            question: "What are the boosters offered as prizes?",
            answer: `The booster prize is a special NFT which grants the owner increased mining power of their Pool.`
        },
    ]

    return (
        <section id="prizes-faq-section" className="page-section ">
            <div className="container-main">
                <div className="title-container d-flex flex-column align-items-center mb-5">
                    <img src={DashLeft} alt="dash" className='me-auto img-fluid' />
                    <h2 className="my-4 font-size-80 white-color font-pnb line-height-1 text-center my-0">FAQ<span className="purple-color">.</span></h2>

                    <img src={DashRight} alt="dash" className='ms-auto img-fluid' />
                </div>

                <Uppertitle title={"FAQ"} />

                <Accordion className="dark-grey-color mt-4" defaultActiveKey="0">
                    {
                        FAQData.map((row, index) => {
                            if (index < maxItems)
                                return (
                                    <Accordion.Item eventKey={`${index}`} key={index}>
                                        <Accordion.Header className="white-color font-pnsb mb-10px ">
                                            <div className="white-color font-size-18 font-pnsb mb-10px ">{row.question}</div>{" "}
                                            <Plus className="button-transition" />
                                        </Accordion.Header>

                                        <Accordion.Body>
                                            <p className="font-pnr font-size-18 line-height-11 darkgrey-color">
                                                {row.answer}
                                            </p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )
                        })
                    }
                </Accordion>

                <div className="d-flex justify-content-center align-items-center mt-4 color-blue">
                    {
                        toggle ?
                            <span className='load-more-btn font-size-16 font-pnr text-center' onClick={() => { setMaxItems(4); setToggle(!toggle) }}> See less <ArrowUp /></span>
                            :
                            <span className='load-more-btn font-size-16 font-pnr text-center' onClick={() => { setMaxItems(FAQData.length); setToggle(!toggle) }}> View all questions <ArrowDown /></span>
                    }
                </div>
            </div>
        </section>
    )
}

export default FAQ