import { ReactComponent as FreeInternetIcon } from "../../../assets/images/svg/small-cards/wifi-icon.svg";
import { ReactComponent as ConnectionIcon } from "../../../assets/images/svg/small-cards/connection-icon.svg";
import { ReactComponent as SecurityIcon } from "../../../assets/images/svg/small-cards/shield-icon.svg";
import { ReactComponent as PriceIcon } from "../../../assets/images/svg/small-cards/price-icon.svg";
// import { ReactComponent as ComputingIcon } from "../../../assets/images/svg/small-cards/devices-icon.svg";
// import { ReactComponent as PrivacyIcon } from "../../../assets/images/svg/small-cards/lock-icon.svg";
// import { ReactComponent as StorageIcon } from "../../../assets/images/svg/small-cards/storage-icon.svg";
// import { ReactComponent as TransactionIcon } from "../../../assets/images/svg/small-cards/speed-icon.svg";

import ComputingPower from "../../../assets/images/png/computing-power.png";

import Privacy from "../../../assets/images/png/privacy-icon.png";
import Storage from "../../../assets/images/png/storage-icon.png";
import QuickTransactions from "../../../assets/images/png/quick-transactions-icon.png";

export const cardsData = [
  {
    id: 1,
    icon: <FreeInternetIcon />,
    upperTitle: "FREE INTERNET",
    title: (
      <>
        Free Internet <br />
        Access & <br /> Connectivity
      </>
    ),
  },
  {
    id: 2,
    icon: <img src={ComputingPower} alt="icon"></img>,
    upperTitle: "COMPUTING POWER",
    title: (
      <>
        Computing <br /> power from <br /> your devices
      </>
    ),
  },
  {
    id: 3,
    icon: <ConnectionIcon />,
    upperTitle: "P2P CONNECTION",
    title: (
      <>
        Easy file <br /> sharing <br /> with P2P
      </>
    ),
  },
  {
    id: 4,
    icon: <img src={Privacy} alt="icon"></img>,
    upperTitle: "PRIVACY",
    title: (
      <>
        Privacy <br /> is one of our <br /> core values
      </>
    ),
  },
  {
    id: 5,
    icon: <img src={ComputingPower} alt="icon"></img>,
    upperTitle: "STORAGE",
    title: (
      <>
        Fully <br /> decentralized <br /> storage
      </>
    ),
  },
  {
    id: 6,
    icon: <SecurityIcon />,
    upperTitle: "SECURITY",
    title: (
      <>
        Your funds <br /> are as secure <br />
        as possible
      </>
    ),
  },
  {
    id: 7,
    icon: <img src={QuickTransactions} alt="icon"></img>,
    upperTitle: "QUICK TRANSACTIONS",
    title: (
      <>
        Best execution <br /> time available <br /> on the market
      </>
    ),
  },
  {
    id: 8,
    icon: <PriceIcon />,
    upperTitle: "AFFORDABLE FEES",
    title: (
      <>
        Super <br /> cheap <br /> transactions
      </>
    ),
  },
];
