import React from "react";
import "./Frameworks.scss";
import { Col, Row } from "react-bootstrap";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import BigImg from "../../../assets/images/png/Mask Group 6.png";
import { ReactComponent as Ethers } from "../../../assets/images/svg/ethers-logo.svg";
import { ReactComponent as Truffle } from "../../../assets/images/svg/truffle-logo.svg";
import { ReactComponent as Web3js } from "../../../assets/images/svg/web3js.svg";
import { ReactComponent as Hardhat } from "../../../assets/images/svg/hardhat-logo.svg";
import { ReactComponent as Metamask } from "../../../assets/images/svg/mm-logo.svg";
import { ReactComponent as Solidity } from "../../../assets/images/svg/solidity.svg";
import { ReactComponent as EVM } from "../../../assets/images/svg/evm-logo.svg";
import { ReactComponent as Remix } from "../../../assets/images/svg/remix-logo.svg";

const Frameworks = () => {
  return (
    <section id="framweorks" className="page-section">
      <div className="container-main">
        <Uppertitle title={"FRAMEWORKS"} />
        <h2 className="font-size-80  pb-lg-5 pb-4 white-color font-pnb">
          Frameworks
          <span className="purple-color">.</span>
        </h2>
        <p className="description description-991 font-size-24 lightgrey-color line-height-11">
          Discover a wide variety of frameworks, blockchains, wallets and explorers, built in the Xiden ecosystem by developers
          and contributors from across the globe.
        </p>
        <Row className="">
          <Col className="d-flex flex-column" lg={9}>
            <Row>
              <Col sm={6} className="coloana">
                <div className="wrapper-coloana d-flex flex-column">
                  <div className="content-photo mb-3 d-flex align-items-center align-content-center justify-content-center">
                    <Truffle className="framework-svg" />
                  </div>
                  <h4 className="font-pnb pb-2 font-size-32 white-color">
                    Truffle
                    <span className="purple-color">.</span>
                  </h4>
                  <p className="lightgrey-color description-991-custom custom-font-size-fw font-pnr line-height-11">
                    A personal blockchain for development you can use to deploy contracts, develop your applications, and run
                    tests.
                  </p>
                </div>
              </Col>
              <Col sm={6} className="coloana">
                <div className="wrapper-coloana d-flex flex-column">
                  <div className="content-photo mb-3 d-flex align-items-center align-content-center justify-content-center">
                    <Ethers className="framework-svg" />
                  </div>
                  <h4 className="font-pnb pb-2 font-size-32 white-color">
                    Ethers
                    <span className="purple-color">.</span>
                  </h4>
                  <p className="lightgrey-color description-991-custom custom-font-size-fw font-pnr line-height-11">
                    Ethers is a Wallet and dApp Browser for your Web Browser. It keeps your private keys safe while making dApps
                    easy to use.
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={6} className="coloana">
                <div className="wrapper-coloana d-flex flex-column">
                  <div className="content-photo mb-3 d-flex align-items-center align-content-center justify-content-center">
                    <Hardhat className="framework-svg" />
                  </div>
                  <h4 className="font-pnb pb-2 font-size-32 white-color">
                    Hardhat
                    <span className="purple-color">.</span>
                  </h4>
                  <p className="lightgrey-color description-991-custom custom-font-size-fw font-pnr line-height-11">
                    Hardhat is an Ethereum development environment. Compile your contracts and run them on a development network
                  </p>
                </div>
              </Col>
              <Col sm={6} className="coloana">
                <div className="wrapper-coloana d-flex flex-column">
                  <div className="content-photo mb-3 d-flex align-items-center align-content-center justify-content-center">
                    <Web3js className="framework-svg" />
                  </div>
                  <h4 className="font-pnb pb-2 font-size-32 white-color">
                    Web3js
                    <span className="purple-color">.</span>
                  </h4>
                  <p className="lightgrey-color description-991-custom custom-font-size-fw font-pnr line-height-11">
                    A collection of libraries that allow you to interact with a local or remote ethereum node using HTTP, IPC or
                    WebSocket.
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={6} className="coloana">
                <div className="wrapper-coloana d-flex flex-column">
                  <div className="content-photo mb-3 d-flex align-items-center align-content-center justify-content-center">
                    <Solidity className="solidity-img" />
                  </div>
                  <h4 className="font-pnb pb-2 font-size-32 white-color">
                    Solidity
                    <span className="purple-color">.</span>
                  </h4>
                  <p className="lightgrey-color description-991-custom custom-font-size-fw font-pnr line-height-11">
                    Solidity is an object-oriented, high-level language for implementing smart contracts which govern the
                    behaviour of accounts.
                  </p>
                </div>
              </Col>
              <Col sm={6} className="coloana">
                <div className="wrapper-coloana d-flex flex-column">
                  <div className="content-photo mb-3 d-flex align-items-center align-content-center justify-content-center">
                    <Metamask className="framework-svg" />
                  </div>
                  <h4 className="font-pnb pb-2 font-size-32 white-color">
                    Metamask
                    <span className="purple-color">.</span>
                  </h4>
                  <p className="lightgrey-color description-991-custom custom-font-size-fw font-pnr line-height-11">
                    MetaMask equips you with a key vault, secure login, token wallet, and token exchange - basically everything
                    you need.
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={6} className="coloana mb-0">
                <div className="wrapper-coloana d-flex flex-column">
                  <div className="content-photo mb-3 d-flex align-items-center align-content-center justify-content-center">
                    <Remix className="remix-img" />
                  </div>
                  <h4 className="font-pnb pb-2 font-size-32 white-color">
                    RemixIDE
                    <span className="purple-color">.</span>
                  </h4>
                  <p className="lightgrey-color description-991-custom custom-font-size-fw font-pnr line-height-11 mb-0">
                    An open source web and desktop application. It fosters a fast development cycle and has a rich set of plugins.
                  </p>
                </div>
              </Col>
              <Col sm={6} className="coloana mb-0">
                <div className="wrapper-coloana d-flex flex-column">
                  <div className="content-photo mb-3 d-flex align-items-center align-content-center justify-content-center">
                    <EVM className="evm-img" />
                  </div>
                  <h4 className="font-pnb pb-2 font-size-32 white-color">
                    EVM
                    <span className="purple-color">.</span>
                  </h4>
                  <p className="lightgrey-color description-991-custom custom-font-size-fw font-pnr line-height-11 mb-0">
                    EVM does exist as one single entity maintained by thousands of connected computers running an Ethereum client.
                  </p>
                </div>
              </Col>
            </Row>
            {/* <Row className="rand-buton">
              <Col lg={12} className="coloana-btn">
                <div className="wrapper-coloana">
                  <button
                    className="buttonBlue d-flex black-color align-items-center align-content-center justify-content-center developer-button"
                    onClick={() => navigate(routes.home)}
                  >
                    <div className="font-pnb font-size-20">Developer portal</div>
                    <ArrowRight />
                  </button>
                </div>
              </Col>
            </Row> */}
          </Col>
          <Col lg={3} className="coloana-img">
            <img src={BigImg} loading="lazy" alt="" className="img-fluid d-none" />
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Frameworks;
