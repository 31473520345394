import './App.scss'
import './template/main.scss'
import { routes } from './Utils/routes'
import { Routes, Route, Link } from 'react-router-dom'
import NavBar from './components/Navbar/NavBar'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import HomePage from './Views/Homepage/Homepage'
import Ecosystem from './Views/Ecosystem/Ecosystem'
import XidenFeatures from './Views/XidenFeatures/XidenFeatures'
import Apps from './Views/Apps/Apps'
// import Minter from "./Views/Minter/Minter";
import PerformerGuardian from './Views/PerformerGuardian/PerformerGuardian'
import PrivateNetwork from './Views/PrivateNetwork/PrivateNetwork'
import Rewards from './Views/Rewards/Rewards'
import Staking from './Views/Staking/Staking'
import Validator from './Views/Validator/Validator'
import GuardianNode from './Views/GuardianNode/GuardianNode'
// import OLD_Ecosystem from "./Views/OLD_Ecosystem/OLD_Ecosystem";
import Footer from './components/Footer/Footer'
import Newsletter from './components/Newsletter/Newsletter'
import ScrollToTop from './Utils/ScrollToTop'
import ErrorPage from './Views/ErrorPage/ErrorPage'
import TermsOfUse from './Views/TermsOfUse/TermsOfUse'
import ChangeLog from './Views/ChangeLog/ChangeLog'
import DataPrivacy from './Views/DataPrivacy/DataPrivacy'
import UserAgreements from './Views/UserAgreements/UserAgreements'
import MetaRealm from './Views/MetaRealm/MetaRealm'
import SupportMetamask from './Views/SupportMetamask/SupportMetamask'
import SupportHotspot from './Views/SupportHotspot/SupportHotspot'
import SupportStake from './Views/SupportStake/SupportStake'
import SupportKraterPool from './Views/SupportKraterPool/SupportKraterPool'
import SupportReward from './Views/SupportReward/SupportReward'
import SupportValidator from './Views/SupportValidator/SupportValidator'
import SupportUpdate from './Views/SupportUpdate/SupportUpdate'
import SupportTroubleshoot from './Views/SupportTroubleshoot/SupportTroubleshoot'
import SupportKraterApp from './Views/SupportKraterApp/SupportKraterPool'
import SupportKraterGuide from './Views/SupportKraterInstallation/SupportKraterGuide'
import MiningCompetition from './Views/MiningCompetition/MiningCompetition'
import { ReactComponent as MotoGPLogo } from './assets/images/png/homePage/news/motogp-logo.svg'
import { ReactComponent as CoinDeskLogo } from './assets/images/png/homePage/news/coindesk.svg'
import { ReactComponent as BitcoinLogo } from './assets/images/png/homePage/news/bitcoin.svg'
import { ReactComponent as FinboldLogo } from './assets/images/png/homePage/news/finbold-logo.svg'
import { ReactComponent as CoinMarketCapLogo } from './assets/images/png/homePage/news/cmc-logo.svg'
import { ReactComponent as CoinBaseLogo } from './assets/images/png/homePage/news/coinbase-logo.svg'

import SDRLayer from './Views/SDRLayer/SDRLayer'
import SupportUnstake from './Views/SupportUnstake/SupportUnstake'

const App = () => {
  return (
    <div className='App'>
      <ToastContainer
        position='top-center'
        autoClose={5000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        closeButton={true}
      />

      <ScrollToTop />
      <NavBar />

      <Routes>
        <Route path={routes.home} element={<HomePage />} />
        <Route path={routes.xiden} element={<Ecosystem />} />
        <Route path={routes.xidenFeatures} element={<XidenFeatures />} />
        <Route path={routes.apps} element={<Apps />} />
        <Route path={routes.minter} element={<MetaRealm />} />
        <Route
          path={routes.performerGuardian}
          element={<PerformerGuardian />}
        />
        <Route path={routes.privateNetwork} element={<PrivateNetwork />} />
        <Route path={routes.rewards} element={<Rewards />} />
        <Route path={routes.staking} element={<Staking />} />
        <Route path={routes.validator} element={<Validator />} />
        <Route path={routes.guardianNode} element={<GuardianNode />} />
        {/* <Route path={routes.ecosystem} element={<OLD_Ecosystem />} /> */}
        <Route path={routes.termsOfUse} element={<TermsOfUse />} />
        <Route path={routes.dataPrivacy} element={<DataPrivacy />} />
        <Route path={routes.userAgreements} element={<UserAgreements />} />
        <Route path={routes.changeLog} element={<ChangeLog />} />
        <Route path={routes.errorPage} element={<ErrorPage />} />
        <Route path={routes.supportMetamask} element={<SupportMetamask />} />
        {/* <Route path={routes.supportHotspot} element={<SupportHotspot />} /> */}
        {/* <Route path={routes.supportStake} element={<SupportStake />} /> */}
        <Route path={routes.supportUnstake} element={<SupportUnstake />} />
        <Route
          path={routes.supportKraterPool}
          element={<SupportKraterPool />}
        />
        <Route path={routes.supportKraterApp} element={<SupportKraterApp />} />

        {/* <Route path={routes.supportReward} element={<SupportReward />} /> */}

        <Route path={routes.supportValidator} element={<SupportValidator />} />
        <Route path={routes.supportUpdate} element={<SupportUpdate />} />
        <Route path={routes.supportGuide} element={<SupportKraterGuide />} />
        {/* <Route path={routes.supportTroubleshoot} element={<SupportTroubleshoot />} /> */}

        <Route path={routes.sdrLayer} element={<SDRLayer />} />
        <Route
          path={routes.miningCompetition}
          element={<MiningCompetition />}
        />
        <Route path={routes.changeLog} element={<ChangeLog />} />
      </Routes>

      <Newsletter />

      <Footer />
    </div>
  )
}

export default App
