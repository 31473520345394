import React from "react";
import { Col, Row } from "react-bootstrap";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import "./XidenFeatures.scss";
// import atom from "../../../assets/images/png/xiden-atom.png";
// import idea from "../../../assets/images/svg/eco-green.svg";
// import blockchain from "../../../assets/images/png/xiden-blockchain.png";
// import scissors from "../../../assets/images/png/xiden-scissors.png";
// import shield from "../../../assets/images/png/xiden-shield.png";
// import bitcoin from "../../../assets/images/png/xiden-bitcoin.png";

import { ReactComponent as Atom } from "../../../assets/images/svg/EcosystemPage/atom.svg";
import { ReactComponent as Idea } from "../../../assets/images/svg/EcosystemPage/idea.svg";
import { ReactComponent as Scissors } from "../../../assets/images/svg/EcosystemPage/scissors.svg";
import { ReactComponent as Shield } from "../../../assets/images/svg/EcosystemPage/shield.svg";
import { ReactComponent as Bitcoin } from "../../../assets/images/svg/EcosystemPage/bitcoin.svg";
import { ReactComponent as Blockchain } from "../../../assets/images/svg/EcosystemPage/blockchain.svg";
import { useState } from "react";

const XidenFeatures = () => {
  const [hovered, setHovered] = useState([false, false, false, false, false, false]);
  // console.log("rerender");

  const mouseOn = (a) => {
    let myNewArray = [...hovered];
    for (let i = 0; i < 6; i++) {
      if (i === a) myNewArray[i] = true;
    }
    setHovered(myNewArray);
    //console.log(`hovered ${myNewArray}`)
  };
  const mouseOut = () => {
    let myNewArray = [...hovered];
    for (let i = 0; i < 6; i++) {
      if (myNewArray[i] === true) myNewArray[i] = false;
    }
    setHovered(myNewArray);
    //console.log(`unhovered ${myNewArray}`)
  };

  return (
    <section id="xiden-features" className="page-section">
      <div className="container-main d-flex flex-column">
        <Uppertitle title={"FEATURES"} />

        <div className="title">
          <h2 className="font-size-80 white-color font-pnb line-height-11 description-991 mb-0">
            Xiden Features
            <span className="purple-color">.</span>
          </h2>
        </div>
        <div className="content">
          <p className="font-pnr font-size-24 lightgrey-color line-height-11 description-991 ecosystem-heading-section">
            Discover the features of a new decentralized network that provides free, restrictionless, and permanent internet
            connection regardless of user location or device.
          </p>
        </div>

        <Row className="rand d-flex justify-content-end align-items-start">
          <Col
            lg={6}
            onMouseEnter={() => mouseOn(0)}
            onMouseLeave={() => mouseOut()}
            className={hovered[0] ? "coloana hovered" : "coloana unhovered"}
          >
            <div className="d-flex">
              <div className="img-container text-center">
                {/* <img src={blockchain} alt="blockchain" className="img-fluid" /> */}
                <Blockchain className="ico-svg" />
              </div>
              <div className="content-card">
                <h4 className="font-size-40 white-color font-pnb line-height-11">
                  Scalability<span className="purple-color">.</span>
                </h4>
                <p className="font-pnr font-size-20 lightgrey-color line-height-11 xiden-features-p">
                  Easy scalable and customizable consensus algorithms as the decentralized network’s architecture brings together
                  multiple resources distributed across the globe.
                </p>
              </div>
            </div>
          </Col>
          <Col
            lg={6}
            onMouseEnter={() => mouseOn(1)}
            onMouseLeave={() => mouseOut()}
            className={hovered[1] ? "coloana dreapta hovered" : "coloana dreapta unhovered"}
          >
            <div className="d-flex">
              <div className="img-container text-center">
                {/* <img src={atom} alt="blockchain" className="img-fluid" /> */}
                <Atom className="ico-svg" />
              </div>
              <div className="content-card">
                <h4 className="font-size-40 white-color font-pnb line-height-11">
                  Interoperability<span className="purple-color">.</span>
                </h4>
                <p className="font-pnr font-size-20 lightgrey-color line-height-11 xiden-features-p">
                  Adaptable technology that develops secure bridges with other blockchains in order to meet the needs of users.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="rand d-flex justify-content-end align-items-start">
          <Col
            lg={6}
            onMouseEnter={() => mouseOn(2)}
            onMouseLeave={() => mouseOut()}
            className={hovered[2] ? "coloana hovered" : "coloana unhovered"}
          >
            <div className="d-flex">
              <div className="img-container text-center">
                {/* <img src={shield} alt="blockchain" className="img-fluid" /> */}
                <Shield className="ico-svg" />
              </div>
              <div className="content-card">
                <h4 className="font-size-40 white-color font-pnb line-height-11">
                  Security<span className="purple-color">.</span>
                </h4>
                <p className="font-pnr font-size-20 lightgrey-color line-height-11 xiden-features-p">
                  Modular ''security as a service'' provided by a pool of professional validators. The network’s architecture uses
                  Voice Over Blockchain Protocol (VOBP) and cryptographic algorithms to secure the connections between devices,
                  routers, and peers.
                </p>
              </div>
            </div>
          </Col>
          <Col
            lg={6}
            onMouseEnter={() => mouseOn(3)}
            onMouseLeave={() => mouseOut()}
            className={hovered[3] ? "coloana dreapta hovered" : "coloana dreapta unhovered"}
          >
            <div className="d-flex">
              <div className="img-container text-center">
                {/* <img src={idea} alt="blockchain" className="img-fluid" /> */}
                <Idea className="ico-svg" />
              </div>
              <div className="content-card">
                <h4 className="font-size-40 font-pnb line-height-11 medium-green-text">
                  ECO friendly<span className="yellow-text">.</span>
                </h4>
                <p className="font-pnr font-size-20 medium-green-text line-height-11 xiden-features-p">
                  Energy-efficient network as it functions with reduced energy consumption from the Guardian Nodes.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="rand d-flex justify-content-end align-items-start">
          <Col
            lg={6}
            onMouseEnter={() => mouseOn(4)}
            onMouseLeave={() => mouseOut()}
            className={hovered[4] ? "coloana hovered" : "coloana unhovered"}
          >
            <div className="d-flex">
              <div className="img-container text-center">
                {/* <img src={bitcoin} alt="blockchain" className="img-fluid" /> */}
                <Bitcoin className="ico-svg" />
              </div>
              <div className="content-card">
                <h4 className="font-size-40 white-color font-pnb line-height-11">
                  Lightning fast<span className="purple-color">.</span>
                </h4>
                <p className="font-pnr font-size-20 lightgrey-color line-height-11 xiden-features-p">
                  Highly reduced validation time as the system uses a hybrid between PoS and Poe consensus algorithms.
                </p>
              </div>
            </div>
          </Col>
          <Col
            lg={6}
            onMouseEnter={() => mouseOn(5)}
            onMouseLeave={() => mouseOut()}
            className={hovered[5] ? "coloana dreapta hovered" : "coloana dreapta unhovered"}
          >
            <div className="d-flex">
              <div className="img-container text-center">
                {/* <img src={scissors} alt="blockchain" className="img-fluid" /> */}
                <Scissors className="ico-svg" />
              </div>
              <div className="content-card">
                <h4 className="font-size-40 white-color font-pnb line-height-11">
                  Affordable fees<span className="purple-color">.</span>
                </h4>
                <p className="font-pnr font-size-20 lightgrey-color line-height-11 xiden-features-p">
                  The network ensures low gas fees for each transaction as it uses undirected resources of the devices integrated
                  into the system in order to ensure computing power and storage capacity.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default XidenFeatures;
