import "./HeroSection.scss";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import { Link as ScrollLink } from "react-scroll";
import { ReactComponent as ArrowRight } from "../../../assets/images/svg/arrow-right.svg";
import React from "react";
import SdrLayer from "../../../assets/images/png/sdr-layer/sdr-hero.png";
import SDRVideo from "../../../assets/videos/SDRLayer/sdr-hero-video.mp4";

const HeroSection = () => {
  return (
    <section id="sdr-hero" className="page-section d-flex flex-column-reverse">
      <div className="container-main hero-section-container">
        <div className="main-col d-flex align-items-center justify-content-center text-center me-0 mb-lg-0 mb-5">
          <Uppertitle title={`SDR LAYER`} />
          <h2 className="font-size-80 white-color font-pnb first-h2 line-height-11 mb-0">Smart Distributed</h2>
          <h2 className="font-size-80 white-color font-pnb second-h2 line-height-11">
            Resources
            <span className="purple-color">.</span>
          </h2>
          <div className="paragraph-container py-4">
            <p className="font-family-pnr font-size-24 lightgrey-color line-height-11">
              SDR is a utility layer of the Xiden network that manages all the resources from the connected devices in the Xiden
              blockchain.
            </p>
          </div>
          <div className="d-flex justify-content-center align-items-center button-container hero-btn-container">
            <ScrollLink to="connected-devices" className="get-started-btn" spy={true}>
              <button className="buttonBlue me-0">
                <span className="me-2">Get started</span>
                <ArrowRight />
              </button>
            </ScrollLink>

            <a
              className="buttonOutlineBlue whitepaper-btn"
              href={"https://xiden.com/whitepaper/xiden-layers/smart/"}
              target="_blank"
            >
              <span className="me-2">Whitepaper</span>
              <ArrowRight />
            </a>
          </div>
        </div>
      </div>
      <div></div>
      <div className="d-flex align-items-center justify-content-end sdr-hero-video">
        {/* <img src={SdrLayer} alt="layer" className="img-fluid"></img> */}
        <video src={SDRVideo} muted className="img-fluid hero-video d-block d-md-none" />
        <video src={SDRVideo} autoPlay playsInline muted loop={true} className="img-fluid hero-video d-none d-md-block" />
      </div>
    </section>
  );
};

export default HeroSection;
