import "./ErrorPage.scss"
import ErrorImg from "../../assets/images/png/404-error.png"
import { ReactComponent as ArrowRight } from "../../assets/images/svg/arrow-right.svg"
import { routes } from "../../Utils/routes"
import { useNavigate } from "react-router-dom";

export default function ErrorPage() {

    const navigate = useNavigate();
    return (
        <section id="error-page" className="page-section">
            <div className="container-main">
                <div className="d-flex align-items-center justify-content-center flex-column ">
                    <img src={ErrorImg} alt="error image" className="img-fluid error-img"></img>
                    <h2 className="font-pnb white-color font-size-80 error-page-title">Oops<span className="purple-color">!</span> Page not found<span className="purple-color">.</span></h2>
                    <div className="error-button-container d-flex align-items-center justify-content-center">
                        <button onClick={() => navigate(routes.home)} className="buttonBlue black-color font-pnsb font-size-20">Go back home
                            <ArrowRight className="arrow-right-svg ms-2" /></button>

                    </div>
                </div>
            </div>
        </section>
    )
}