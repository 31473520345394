import React from "react";
import "./Uppertitle.scss";

const Uppertitle = ({ title, cards }) => {
   return (
      <div className="xideen-upper-title d-flex ">
         <h6 className={`font-size-18 darkgrey-color font-pnsb mb-0 d-flex ${cards && "cards-smaller-text"}`}>
            {" "}
            <div className="line-upper-title my-auto me-2"></div>
            {title}
         </h6>
      </div>
   );
};

export default Uppertitle;
