import React from "react";
import "./Proprietary.scss";
import { Col, Row } from "react-bootstrap";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import { ReactComponent as Checked } from "../../../assets/images/svg/proprietary-checked.svg";
import { ReactComponent as DistributeCube } from "../../../assets/images/svg/proprietary-cube-prop.svg";
import { ReactComponent as Tap } from "../../../assets/images/svg/proprietary-tap.svg";
import { ReactComponent as Idea } from "../../../assets/images/svg/proprietary-business-and-finance.svg";
import CubePurple from "../../../assets/images/png/prop-cube.png";
import ProprietaryMobile from "./ProprietaryMobile/ProprietaryMobile";

const Proprietary = () => {
  return (
    <section id="proprietary" className="page-section">
      <div className="container-main">
        <Uppertitle title={"ECONOMICS"} />
        <h2 className="font-size-80 pb-xl-5 pb-4 white-color font-pnb">
          Proprietary control
          <span className="purple-color">.</span>
        </h2>

        <div className="d-none d-lg-block">
          <Row>
            <Col>
              <div className="column-wrapper contract-card p-4 p-xl-5 d-flex align-items-center justify-content-between">
                <div className="prop-feature-info h-100">
                  <Uppertitle title={"DECENTRALIZED"} />
                  <h4 className="font-pnb py-lg-0 py-4 font-size-56 white-color proprietary-title-main-card">
                    Every developed app is decentralized and managed by smart contracts
                    <span className="purple-color">.</span>
                  </h4>
                  <div className="">
                    {/* <button
                    className="font-pnsb link-btn font-size-20 blue-color d-flex justify-content-start align-items-baseline align-content-center"
                    onClick={() => navigate(routes.home)}
                  >
                    Get started
                    <ArrowRight />
                  </button> */}
                  </div>
                </div>
                <div className="prop-image-col">
                  <img src={CubePurple} loading="lazy" alt="purple cube" className="prop-cube-img" />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={3} md={6}>
              <div className="column-wrapper small-col bg1 p-4 p-xl-5 d-flex flex-column align-items-start justify-content-between">
                <Uppertitle title={"USABILITY"} />
                <Idea />
                <h4 className="font-pnb font-size-40 white-color prop-control-title">
                  Setup Usability Method
                  <span className="purple-color">.</span>
                </h4>
                <div></div>
                {/* <button
                className="font-pnsb link-btn font-size-20 blue-color d-flex justify-content-start align-items-baseline align-content-center"
                onClick={() => navigate(routes.home)}
              >
                Get started
                <ArrowRight />
              </button> */}
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className="column-wrapper small-col p-4 p-xl-5 d-flex flex-column align-items-start justify-content-between">
                <Uppertitle title={"COSTS"} />
                <Checked />
                <h4 className="font-pnb font-size-40 white-color prop-control-title">
                  Hassle <br /> Free <br /> Setup
                  <span className="purple-color">.</span>
                </h4>
                <div></div>
                {/* <button
                className="font-pnsb link-btn font-size-20 blue-color d-flex justify-content-start align-items-baseline align-content-center"
                onClick={() => navigate(routes.home)}
              >
                Get started
                <ArrowRight />
              </button> */}
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className="column-wrapper small-col p-4 p-xl-5 d-flex flex-column align-items-start justify-content-between setup-col">
                <Uppertitle title={"DISTRIBUTE"} />
                <DistributeCube />
                <h4 className="font-pnb font-size-40 white-color prop-control-title">
                  Simple Distribution Setup
                  <span className="purple-color">.</span>
                </h4>
                <div></div>
                {/* <button
                className="font-pnsb link-btn font-size-20 blue-color d-flex justify-content-start align-items-baseline align-content-center"
                onClick={() => navigate(routes.home)}
              >
                Get started
                <ArrowRight />
              </button> */}
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className="column-wrapper small-col bg2 p-4 p-xl-5 d-flex flex-column align-items-start justify-content-between setup-last-col">
                <Uppertitle title={"REWARDS"} />
                <Tap />
                <h4 className="font-pnb font-size-40 white-color prop-control-title">
                  Set up reward in one click
                  <span className="purple-color">.</span>
                </h4>
                <div></div>
                {/* <button
                className="font-pnsb link-btn font-size-20 blue-color d-flex justify-content-start align-items-baseline align-content-center"
                onClick={() => navigate(routes.rewards)}
              >
                Get started
                <ArrowRight />
              </button> */}
              </div>
            </Col>
          </Row>
        </div>
        <ProprietaryMobile />
      </div>
    </section>
  );
};

export default Proprietary;
