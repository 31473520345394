import React, { useEffect } from "react";
import "./Blockchain.scss";
// import FeatureCard from "../../../components/FeatureCard/FeatureCard";
import { cardsData } from "./cardsData";
// import { Row, Col } from "react-bootstrap";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import { Element } from "react-scroll";

const Blockchain = () => {
  useEffect(() => {
    const slider = document.getElementById("blockchain-slider");
    let isDown = false;
    let startX;
    let scrollLeft;

    const mouseLeaveFunction = () => {
      // document.getElementById("roadmap-event").style.cursor = 'grabbing';

      isDown = false;
      slider.classList.remove("active");
    };
    const mouseDownFunction = (e) => {
      // document.getElementById("roadmap-event").style.cursor = 'grabbing';
      isDown = true;
      slider.classList.add("active");
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    };
    const mouseUpFunction = () => {
      // document.getElementById("roadmap-event").style.cursor = 'grabbing';
      isDown = false;
      slider.classList.remove("active");
    };
    const touchFunction = (e) => {
      // document.getElementById("roadmap-event").style.cursor = 'grabbing';
      isDown = false;
      slider.classList.remove("active");
      //console.log('sal')
    };
    const mouseMoveFunction = (e) => {
      // document.getElementsByClassName("roadmap-event").style.cursor = 'grabbing';
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 1; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
      //console.log(walk);
    };

    if (slider) {
      slider.addEventListener("mousedown", mouseDownFunction);
      slider.addEventListener("mouseleave", mouseLeaveFunction);
      slider.addEventListener("mouseup", mouseUpFunction);
      slider.addEventListener("mousemove", mouseMoveFunction);
      slider.addEventListener("touchstart", touchFunction);
    }

    return () => {
      slider.removeEventListener("mousedown", mouseDownFunction);
      slider.removeEventListener("mouseleave", mouseLeaveFunction);
      slider.removeEventListener("mouseup", mouseUpFunction);
      slider.removeEventListener("mousemove", mouseMoveFunction);
      slider.removeEventListener("mousemove", touchFunction);
    };
  }, []);

  const matchCardBackground = (cardId) => {
    switch (cardId) {
      case 1:
        return "internet-card-bg";

      case 3:
        return "connection-card-bg";

      case 6:
        return "security-card-bg";

      case 8:
        return "price-card-bg";
    }
  };

  return (
    <Element name="ecosystem-blockchain">
      <section id="blockchain-section" className="page-section">
        <div className="container-main">
          <div className="w-100 heading-container">
            <h2 className="mb-0 font-size-80 white-color font-pnb">People Powered</h2>
            <h2 className="font-size-80 white-color font-pnb">
              Blockchain<span className="purple-color">.</span>
            </h2>
          </div>

          <div id="blockchain-slider" className="d-flex justify-content-between align-items-start blockchain-slider">
            {cardsData.map((card) => (
              <div key={card.title} className="cardSlider">
                {/* <FeatureCard cardData={card} /> */}
                <div className={`text-white blockchain-card ${matchCardBackground(card.id)}`}>
                  <Uppertitle title={card.upperTitle} />

                  <div className="card-content">
                    <div className="mb-3">{card.icon}</div>

                    <h4 className="font-pnb blockchain-card-title">
                      {card.title}
                      <span className="purple-color">.</span>
                    </h4>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* <Row xs={1} sm={2} lg={4} className="cards-row">
          {cardsData.map((card) => (
            <Col key={card.title}>
              <FeatureCard cardData={card} />
            </Col>
          ))}
        </Row> */}
        </div>
      </section>
    </Element>
  );
};

export default Blockchain;
