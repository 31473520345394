import React, { useRef, useState, useContext } from "react";
import "./SliderSection.scss";
import Slider from "react-slick";
import LeftArrow from "../../../assets/images/png/Left slider.png";
import RightArrow from "../../../assets/images/png/Right slider.png";
import { contentContext } from "./SliderSection";

const ServicesSlider = ({ data }) => {
    const [slideIndex, setSlideIndex] = useState(() => 0);

    const { contentIndex, setContentIndex } = useContext(contentContext);

    const sliderRef = useRef();

    const sliderEnd = data.length - 1;

    const updateIndex = (newIndex, nextBtn) => {
        if (newIndex < 0) {
            newIndex = sliderEnd;
        } else if (newIndex > sliderEnd) {
            newIndex = 0;
        }

        if (sliderRef.current) {
            // sliderRef.current.slickGoTo(newIndex);
            if (nextBtn === true) sliderRef.current.slickNext();
            else sliderRef.current.slickPrev();
        }

        setContentIndex(newIndex);
        setSlideIndex(newIndex);
    };

    const settings = {
        className: "center",
        arrows: false,
        autoplaySpeed: 1500,
        draggable: true,
        variableWidth: true,
        speed: 500,
        centerMode: true,
        infinite: true,
        beforeChange: (current, next) => updateIndex(next),
        // afterChange: next => { updateIndex(next); console.log(next) },
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <>
            <Slider {...settings} ref={sliderRef}>
                {data.map(boxItem => (
                    <div key={boxItem.id} className="p-3">
                        <div key={boxItem.id} className="text-white slider-box">
                            {boxItem.image}
                            <h4 className="text-center active-box-title">
                                {boxItem.title}
                                {/* <span className="title-dot">.</span> */}
                            </h4>
                        </div>
                    </div>
                ))}
            </Slider>

            <div className="w-100 d-flex align-items-center justify-content-center bottom-0">
                <img
                    src={LeftArrow}
                    alt="arrow"
                    className="me-4 pointer"
                    onClick={() => {
                        updateIndex(slideIndex - 1, false);
                    }}
                />

                <img
                    src={RightArrow}
                    alt="arrow"
                    className="pointer"
                    onClick={() => {
                        updateIndex(slideIndex + 1, true);
                    }}
                />
            </div>
        </>
    );
};

export default ServicesSlider;
