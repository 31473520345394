import React from "react";
import "./HowTo.scss";
import { Col, Row } from "react-bootstrap";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import Resources from "../../../assets/images/png/xiden-features-resources.png";
import Reward from "../../../assets/images/png/reward-new-xden-page.png";
import Oracle2 from "../../../assets/images/png/XID-features-page/digger.jpg";
import Transactions from "../../../assets/images/png/XID-features-page/how-to-exchange.jpg";
import { ReactComponent as ArrowRight } from "../../../assets//images/svg/arrow-right.svg";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../Utils/routes";
import { toast } from "react-toastify";

const HowTo = () => {
  const navigate = useNavigate();

  return (
    <section id="howto-xiden" className="page-section">
      <div className="container-main d-flex flex-column">
        <Row className="title align-items-center">
          <div className="my-auto">
            <Uppertitle title={"XDEN"} />
            <h2 className="title font-size-80 pb-2 white-color font-pnb">
              How to <br />
              get XDEN<span className="purple-color">.</span>
            </h2>
          </div>
        </Row>
        <div className="corners p-lg-5 p-4 right">
          <Row className="rand d-flex justify-content-between align-items-center">
            <Col className="d-flex flex-column" lg={6}>
              <h2 className="title font-size-40 pb-3 white-color font-pnb">
                Validating
                <span className="purple-color">.</span>
              </h2>
              <p className="font-pnr font-size-20 pb-2 lightgrey-color description-991 line-height-11">
                By using the resources of your integrated smart electronic devices you can start mining the XDEN token either on
                Public difficulty or Private difficulty.
              </p>

              <div
                className="link-container d-flex align-items-center ustify-content-start"
                onClick={() => toast.info("Coming soon...", { toastId: "21dkerjfuhrtg" })}
              >
                <p className="font-pnsb font-size-20 blue-color">Start validating</p>
                <ArrowRight />
              </div>
            </Col>
            <Col lg={6} className="d-flex justify-content-end align-items-center">
              <img src={Oracle2} loading="lazy" alt="mining" className="img-fluid" />
            </Col>
          </Row>
        </div>

        <div className="corners p-lg-5 p-4 left ">
          <Row className="rand d-flex flex-row-reverse justify-content-between align-items-center">
            <Col className="d-flex flex-column" lg={6}>
              <h2 className="title font-size-40 pb-3 white-color font-pnb">
                Resources
                <span className="purple-color">.</span>
              </h2>
              <p className="font-pnr font-size-20 pb-2 lightgrey-color description-991 line-height-11">
                The users that will make their smart electronic devices’ resources available for the mining process required
                within the network will receive rewards.
              </p>

              <div
                className="link-container d-flex align-items-center align-contents-center justify-content-start"
                onClick={() => navigate(routes.rewards)}
              >
                <p className="font-pnsb font-size-20 blue-color">Get boosters</p>
                <ArrowRight />
              </div>
            </Col>
            <Col lg={6} className="d-flex justify-content-end align-items-center">
              <img src={Resources} loading="lazy" alt="resources" className="img-fluid" />
            </Col>
          </Row>
        </div>

        <div className="corners p-lg-5 p-4 right ">
          <Row className="rand d-flex justify-content-between align-items-center">
            <Col className="d-flex flex-column" lg={6}>
              <h2 className="title font-size-40 pb-3 white-color font-pnb">
                Market<span className="purple-color">.</span>
              </h2>
              <p className="font-pnr font-size-20 pb-2 lightgrey-color description-991 line-height-11">
                Users can get XDEN tokens from public exchanges or can transfer the digital asset between them.
              </p>

              <div
                className="link-container d-flex align-items-center align-contents-center justify-content-start"
                onClick={() => toast.info("Coming soon...", { toastId: "22dkerjfuhrtg" })}
              >
                <p className="font-pnsb font-size-20 blue-color">Exchange XDEN</p>
                <ArrowRight />
              </div>
            </Col>
            <Col lg={6} className="d-flex justify-content-end align-items-center">
              <img src={Transactions} loading="lazy" alt="transactions" className="img-fluid market-img" />
            </Col>
          </Row>
        </div>

        <div className="corners p-lg-5 p-4 left last-card">
          <Row className="rand d-flex flex-row-reverse justify-content-between align-items-center">
            <Col className="d-flex flex-column" lg={6}>
              <h2 className="title font-size-40 pb-3 white-color font-pnb">
                Rewards from DApps<span className="purple-color">.</span>
              </h2>
              <p className="font-pnr font-size-20 pb-2 lightgrey-color description-991 line-height-11">
                The developers that will build DApps on the Xiden network will receive rewards for their work. The more popular
                your DApp will become, the more rewards you will get.
              </p>

              <div
                className="link-container d-flex align-items-center align-contents-center justify-content-start"
                onClick={() => navigate(routes.apps)}
              >
                <p className="font-pnsb font-size-20 blue-color">Build DApps</p>
                <ArrowRight />
              </div>
            </Col>
            <Col lg={6} className="d-flex justify-content-end align-items-center">
              <img src={Reward} loading="lazy" alt="rewards" className="img-fluid reward-img" />
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
};

export default HowTo;
