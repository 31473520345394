import React from "react";
import "./Apps.scss";
import HeroSection from "./HeroSection/HeroSection";
import Frameworks from "./Frameworks/Frameworks";
import Properties from "./Properties/Properties";
import Types from "./Types/Types";
import AppsServices from "./AppsServices/AppsServices";
import Proprietary from "./Proprietary/Proprietary";
import BuildApps from "./BuildApps/BuildApps";
import { Helmet } from "react-helmet";
import SchemaTemplate from "../../SEO/SchemaTemplate.json";
import MobileProperties from "./Properties/MobileProperties";

const Apps = () => {
  return (
    <div className="dapps-page">
      {/* seo */}
      <Helmet>
        <title>Xiden | Decentralized Applications</title>
        <meta name="title" content="Xiden | Decentralized Applications" />
        <meta
          name="description"
          content="The Xiden ecosystem is integrated with popular frameworks in order to support the development of DApps running on the network infrastructure in the form of smart contracts."
        />
        <link rel="canonical" href="" />
        {/* Open Graph Facebook */}
        <meta property="og:title" content="Xiden | Decentralized Applications" />
        <meta
          property="og:description"
          content="The Xiden ecosystem is integrated with popular frameworks in order to support the development of DApps running on the network infrastructure in the form of smart contracts."
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        {/* Open Graph Twitter  */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="" />
        <meta property="twitter:title" content="Xiden | Decentralized Applications" />
        <meta
          property="twitter:description"
          content="The Xiden ecosystem is integrated with popular frameworks in order to support the development of DApps running on the network infrastructure in the form of smart contracts."
        />
        <meta property="twitter:image" content="" />
        {/* Structured Data */}
        <script>{JSON.stringify(SchemaTemplate)}</script>
      </Helmet>

      {/* sections */}
      <HeroSection />
      <Properties />
      <MobileProperties />
      <Types />
      <Frameworks />
      {/* <AppsServices /> */}
      <Proprietary />
      <BuildApps />
    </div>
  );
};

export default Apps;
