import React, { createContext, useEffect, useState } from "react";
import "./Miners.scss";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import { FiArrowUpRight as ArrowRightUp } from "react-icons/fi";
import { FiArrowRight as ArrowRight } from "react-icons/fi";
import ServicesSlider from "./ServicesSlider";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../Utils/routes";
import Basm from "../../../assets/images/png/validator-basm.png";
import Impulse from "../../../assets/images/png/validator-impulse.png";
import RouterImg from "../../../assets/images/png/xiden-page/router-icon.png";
import Hidr from "../../../assets/images/png/xiden-page/hidr-icon.png";

const contentContext = createContext();

const Miners = () => {
  const sliderData = [
    {
      id: 1,
      image: (
        <img
          src={Basm}
          loading="lazy"
          alt="Basm laptop"
          className="slider-image basm-icon"
        />
      ),
      title: "Basm",
    },
    {
      id: 2,
      image: (
        <img
          src={RouterImg}
          loading="lazy"
          alt="Router"
          className="slider-image router-icon"
        />
      ),
      title: "MinteR",
    },
    {
      id: 3,
      image: (
        <img
          src={Hidr}
          loading="lazy"
          alt="HidR"
          className="slider-image hidr-icon"
        />
      ),
      title: "HidR",
    },
    {
      id: 4,
      image: (
        <img
          src={Impulse}
          loading="lazy"
          alt="Impulse"
          className="slider-image hidr-icon"
        />
      ),
      title: "IMPulse",
    },
  ];
  const contentData = [
    {
      uperTitle: "VALIDATORS",
      title: "Basm",
      punctuation: ".",
      paragraph: `BASM is the first encrypted laptop that offers integral protection in 
                     accessing and transmitting user data by using Voice Over Blockchain 
                     Protocol (VOBP) technology.
                     `,
      readMore: (
        <button className="buttonBlue" onClick={() => window.open("https://cryptodata.com/basm")}>
          <span className="me-2">Read more</span>
          <ArrowRight />
        </button>
      ),
      buyNow: (
        <button className="buttonOutlineBlue" onClick={() => window.open("https://cryptodata.com/product/basm")}>
          <span className="me-2">Buy now</span>
          <ArrowRightUp />
        </button>
      ),
      storage: "512GB",
      computing: "83 Cycles",
      consumption: "max. 65 W (+/-10%)",
    },
    {
      uperTitle: "VALIDATORS",
      title: "MinteR",
      punctuation: ".",
      paragraph: `A redefined smart device that will unlock the full potential of blockchain 
                     technology by creating new and innovative business opportunities. It functions 
                     as a WiFi point unlocked by a Router that can open a pool through which smart 
                     devices can connect freely in order to access an anonymous internet network.
                     `,
      readMore: (
        <button className="buttonBlue" onClick={() => navigate(routes.guardianNode)}>
          <span className="me-2">Read more</span>
          <ArrowRight />
        </button>
      ),
      buyNow: (
        <button className="buttonOutlineBlue" onClick={() => window.open("https://cryptodata.com/product/minter")}>
          <span className="me-2">Buy now</span>
          <ArrowRightUp />
        </button>
      ),
      storage: "128MB",
      computing: "49 cycles",
      consumption: "max. 12 W (+/-10%)",
    },
    {
      uperTitle: "VALIDATORS",
      title: "HidR",
      punctuation: ".",
      paragraph: `A dual environment device integrated into a secure ecosystem that provides users 
                     two different platforms for regular daily activities and encrypted and 
                     privacy-oriented data and assets transfer.
                     `,
      readMore: (
        <button className="buttonBlue" onClick={() => window.open("https://cryptodata.com/hidr")}>
          <span className="me-2">Read more</span>
          <ArrowRight />
        </button>
      ),
      buyNow: (
        <button className="buttonOutlineBlue" onClick={() => window.open("https://cryptodata.com/shop")}>
          <span className="me-2">Buy now</span>
          <ArrowRightUp />
        </button>
      ),
      storage: "128GB",
      computing: "54 cycles",
      consumption: "max 10 W (+/-10%)",
    },
    {
      uperTitle: "VALIDATORS",
      title: "IMPulse",
      punctuation: ".",
      paragraph: `A smartphone designed to provide user privacy and ensure its data security. Built-in security-optimized features that facilitate connection between all our encryption mobile devices.`,
      readMore: (
        <button
          className="buttonBlue"
          onClick={() => window.open("https://cryptodata.com/impulse")}
        >
          <span className="me-2">Read more</span>
          <ArrowRight />
        </button>
      ),
      buyNow: (
        <button
          className="buttonOutlineBlue"
          onClick={() => window.open("https://cryptodata.com/product/impulse-k1")}
        >
          <span className="me-2">Buy now</span>
          <ArrowRightUp />
        </button>
      ),
      storage: "64GB",
      computing: "39 cycles",
      consumption: "max 10 W (+/-10%)",
    },
  ];

  const navigate = useNavigate();

  const [contentIndex, setContentIndex] = useState(0);

  const [animate, setAnimate] = useState(false);

  const value = { contentIndex, setContentIndex }; //pentru context provider ca sa pot schimba indexul din slider

  const [displayData, setDisplayData] = useState(contentData[contentIndex]);

  useEffect(() => {
    setDisplayData(contentData[contentIndex]);
    setAnimate(true);
    setTimeout(() => setAnimate(false), 500);
  }, [contentIndex]); //aici fac rerender in parent pe baza modificarii indexului din child
  return (
    <contentContext.Provider value={value}>
      <section className="page-section ecosystem-slider-section">
        <div className="container-main what-contianer">
          <div className="row">
            <div
              className={
                animate
                  ? "col-lg-6 col-12 order-lg-1 order-2  apply-effect d-flex flex-column justify-content-center align-items-start"
                  : "col-lg-6 col-12 order-lg-1 order-2  d-flex flex-column justify-content-center align-items-start"
              }
            >
              <Uppertitle title={displayData.uperTitle} />
              <h2 className="mb-3 font-size-80 white-color font-pnb">
                {displayData.title}
                <span className="purple-color">{displayData.punctuation}</span>
              </h2>
              <div className="description description-991 ecosystem-heading-section">
                <p className="font-family-pnr font-size-24 lightgrey-color line-height-11">{displayData.paragraph}</p>
              </div>

              <div className="buttons-flex-direction d-flex justify-content-start align-items-start w-100 pt-lg-5 pt-4">
                {displayData.readMore}
                {displayData.buyNow}
              </div>
            </div>

            {/* <ServicesSlider data={sliderData} /> */}
            <div className="col-lg-6 col-12 order-lg-2 order-1 pb-lg-0 pb-5 position-relative slider-col-height">
              <div className="pos-absolute-slider">
                <ServicesSlider data={sliderData} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </contentContext.Provider>
  );
};

export { contentContext };

export default Miners;
