import Step2 from "../SupportKraterSteps/assets-steps/step2.png"
import Step3 from "../SupportKraterSteps/assets-steps/step3.png"
import Step5 from "../SupportKraterSteps/assets-steps/step5.png"
import Step6 from "../SupportKraterSteps/assets-steps/step6.png"
import Step7 from "../SupportKraterSteps/assets-steps/step7.png"
import Step8 from "../SupportKraterSteps/assets-steps/step8.png"
import Step9 from "../SupportKraterSteps/assets-steps/step9.png"
import Step10 from "../SupportKraterSteps/assets-steps/step10.png"
import Step11 from "../SupportKraterSteps/assets-steps/step11.png"
import Step12 from "../SupportKraterSteps/assets-steps/step12.png"
import Step13 from "../SupportKraterSteps/assets-steps/step13.png"
import PoolSuccess from "../SupportKraterSteps/assets-steps/pool-success.png";
import { TiWarning as WarningSign } from "react-icons/ti";

export const linkMinterSteps = [
    {
        uppertitle: "STEP 01",
        title: "Access Miner Config",
        punctuation: ".",
        content: <>
            <div className="w-100 text-center text-md-start font-family-pnr font-size-20 lightgrey-color line-height-11 mt-2 mb-2">
                From the left hand side menu press <span className="purple-color">Miner Config</span> and insert your personalized name. This will initialize the Minter configuration process.
            </div>
        </>,
        image: Step2
    },
    {
        uppertitle: "STEP 02",
        title: "Connect the MinteR to a new pool",
        punctuation: ".",
        content: <>
            <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                To create a new pool, click on <span className="purple-color">New Pool</span> button.
            </p>
        </>,
        image: Step3
    },
    {
        uppertitle: "STEP 03",
        title: "Create account on Xiden Pools",
        punctuation: ".",
        content: <>
            <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 text-start pb-3">
                Access the Pools page:
            </p>
            <a href="https://pools.xiden.com/" className="font-pnb my-3 font-size-20 purple-color tutorial-link-pools">
                https://pools.xiden.com/
            </a>
            <p className="font-family-pnr font-size-20 lightgrey-color line-height-11  pt-3">
                Insert your credentials and create your Xiden Pool account.
            </p>
        </>,
        image: Step5
    },
    {
        uppertitle: "STEP 04",
        title: "Sign in on Xiden Pools",
        punctuation: ".",
        content: <>
            <p className="font-family-pnr font-size-20 lightgrey-color line-height-11">
                Enter your email and password to successfully sign in.
            </p>
        </>,
        image: Step6
    },
    {
        uppertitle: "STEP 05",
        title: "CDPool",
        punctuation: ".",
        content: <>
            <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                Click on “Start now” button and follow the instructions.
            </p>
        </>,
        image: Step7
    },
    {
        uppertitle: "STEP 06",
        title: "Create your pool",
        punctuation: ".",
        content: <>
            <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                In the “Overview” section, click on “+ Create Pool”.
            </p>
        </>,
        image: Step8
    },
    {
        uppertitle: "STEP 07",
        title: "Copy MinteR ID",
        punctuation: ".",
        content: <>
            <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                Copy your Minter Guardian Router ID from router interface to pair with the pool.
            </p>
        </>,
        image: Step9
    },
    {
        uppertitle: "STEP 08",
        title: "Paste MinteR ID",
        punctuation: ".",
        content: <>
            <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                Insert the MinteR ID into this field and type a Pool Name in the field below. Press "Next" to generate the 12 mnemonic words.
            </p>
        </>,
        image: Step10
    },
    {
        uppertitle: "STEP 09",
        title: "Pairing Pool with MinteR",
        punctuation: ".",
        content: <>
            <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                Based on the previous step there were generated 12 mnemonic words that are displayed on that tab. You must insert these words in the MinteR configuration step.
            </p>
            <p className="font-family-pnr font-size-18 lightgrey-color line-height-11 text-start">
                <WarningSign className="warning-svg me-2" />Those words are available only 10 minutes. In this time you need to insert them in your MinteR configuration window. If the time expired you must return to previous step and generate another 12 mnemonic words.
            </p>
        </>,
        image: Step11
    },
    {
        uppertitle: "STEP 10",
        title: "12 words configuration",
        punctuation: ".",
        content: <>
            <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                To successfully configure your Minter router, insert the 12 mnemonic words in the sequence you can see on the Pair Minter Guardian page.
                Each word must to be inserted individually and in the order generated from the Pool.
            </p>
            <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                After you inserted the 12 mnemonic words in the correct order press "Send Phrase".
            </p>
        </>,
        image: Step12
    },
    {
        uppertitle: "STEP 11",
        title: "Success",
        punctuation: ".",
        content: <>
            <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                You have successfully configured your MinteR!
            </p>
        </>,
        image: Step13
    },
    {
        uppertitle: "STEP 12",
        title: "Xiden Pool Success motification",
        punctuation: ".",
        content: <>
            <p className="font-family-pnr font-size-20 lightgrey-color line-height-11 text-start">
                Now you should be prompted with the success message in <span className="purple-color">Xiden Pools</span> also.
            </p>
        </>,
        image: PoolSuccess
    }];