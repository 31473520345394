import React from 'react'
import './Homepage.scss'
import Hero from './Hero/Hero'
// import WhatIsXiden from "./WhatIs/WhatIsXiden";
import FullPower from './FullPower/FullPower'
import Developers from './Developers/Developers'
// import DownloadApp from "../PrivateNetwork/DownloadApp/DownloadApp";
// import NetworkFeatures from "../PrivateNetwork/NetworkFeatures/NetworkFeatures";
// import NavBar from "../../components/Navbar/NavBar";
// import XidenAttributes from "../XidenFeatures/XidenAttributes";
import BePart from './BePart/BePart'
import WhatIs from './WhatIs/WhatIsXiden'
import Statistics from './Statistics/Statistics'
import Consensus from './Consensus/Consensus'
import Reward from './Reward/Reward'
// import Newsletter from "../../components/Newsletter/Newsletter";
import { Helmet } from 'react-helmet'
import SchemaTemplate from '../../SEO/SchemaTemplate.json'
import OGImage from '../../assets/images/metaTags/xiden-og-image.jpg'
import Countdown from './Countdown/Countdown'
import Audits from './Audits/Audits'
import AvailableExchanges from './AvailableExchanges/AvailableExchanges'
import News from './News/News'
import Changelog from './Changelog/Changelog'
import PrivateWithdraw from './PrivateWithdraw/PrivateWithdraw'

const Homepage = () => {
  // TODO better spacing between each section :)
  return (
    <div className='homepage'>
      {/* seo */}
      <Helmet>
        <title>Xiden | Private Internet of Decentralized Networks</title>
        <meta
          name='title'
          content='Xiden | Private Internet of Decentralized Network'
        />
        <meta
          name='description'
          content=' Xiden is a dual-layered decentralized network built to facilitate the integration of all smart devices in order to provide a free connection and internet access in a secure, anonymous, and unrestricted environment.'
        />
        <link rel='canonical' href='' />
        {/* Open Graph Facebook */}
        <meta
          property='og:title'
          content='Xiden | Private Internet of Decentralized Networks'
        />
        <meta
          property='og:description'
          content='Xiden is a dual-layered decentralized network built to facilitate the integration of all smart devices in order to provide a free connection and internet access in a secure, anonymous, and unrestricted environment.'
        />
        <meta property='og:type' content='website' />
        <meta property='og:image' content={OGImage} />
        <meta property='og:url' content='' />
        {/* Open Graph Twitter  */}
        <meta property='twitter:card' content='summary_large_image' />
        <meta property='twitter:url' content='' />
        <meta
          property='twitter:title'
          content='Xiden | Private Internet of Decentralized Networks'
        />
        <meta
          property='twitter:description'
          content='Xiden is a dual-layered decentralized network built to facilitate the integration of all smart devices in order to provide a free connection and internet access in a secure, anonymous, and unrestricted environment.'
        />
        <meta property='twitter:image' content={OGImage} />
        {/* Structured Data */}
        <script>{JSON.stringify(SchemaTemplate)}</script>
      </Helmet>

      {/* sections */}
      <Hero />
      <PrivateWithdraw />
      <Changelog />
      {/* <Statistics /> */}
      {/* <FullPower />
        <Developers />
        <DownloadApp />
        <NetworkFeatures /> 
        <XidenAttributes /> */}
      {/* <Countdown /> */}
      <WhatIs />
      <News />
      <Audits />
      <AvailableExchanges />
      <BePart />

      <FullPower />
      <Consensus />
      <Reward />
      <Developers />

      {/* <NetworkFeatures />  */}
    </div>
  )
}

export default Homepage
