import React from "react";
import "./SupportMetamask.scss";

//sections
// import SidebarNavigation from "../../components/sidebarNavigation/SidebarNavigation";

// SEO
import { Helmet } from "react-helmet";
import SchemaTemplate from "../../SEO/SchemaTemplate.json";

// import Subscribe from "../../components/subscribe/Subscribe";
import HeroSection from "./HeroSection/HeroSection";
import Steps from "./Steps/Steps";
// import TellUs from "./TellUs/TellUs";
// const links = [
//     "press-releases-section",
// ];

const SupportMetamask = () => {
    return (
        <div className="metamask-support-page">
            <Helmet>
                <title>Xiden | Support Metamask</title>
                <meta name="title" content="Support Metamask" />
                <meta name="description" content="" />
                <link rel="canonical" href="" />
                {/* Open Graph Facebook */}
                <meta property="og:title" content=" Support Metamask" />
                <meta property="og:description" content="" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="" />
                <meta property="og:url" content="" />
                {/* Open Graph Twitter  */}
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="" />
                <meta property="twitter:title" content=" Support Metamask" />
                <meta property="twitter:description" content="" />
                <meta property="twitter:image" content="" />
                {/* Structured Data */}
                <script>{JSON.stringify(SchemaTemplate)}</script>
            </Helmet>
            {/* <SidebarNavigation links={links} /> */}

            {/* <HeroSection /> */}
            <HeroSection />
            <Steps />
            
        </div>
    );
};

export default SupportMetamask;
