import React from "react";
import Uppertitle from "../../../components/Uppertitle/Uppertitle";
import { ReactComponent as MotoGPLogo } from "../../../assets/images/png/homePage/news/motogp-logo.svg";
import { ReactComponent as CoinDeskLogo } from "../../../assets/images/png/homePage/news/coindesk.svg";
import { ReactComponent as BitcoinLogo } from "../../../assets/images/png/homePage/news/bitcoin.svg";
import { ReactComponent as FinboldLogo } from "../../../assets/images/png/homePage/news/finbold-logo.svg";
import { ReactComponent as CoinMarketCapLogo } from "../../../assets/images/png/homePage/news/cmc-logo.svg";
import { ReactComponent as CoinBaseLogo } from "../../../assets/images/png/homePage/news/coinbase-logo.svg";
import { ReactComponent as BloombergIco } from "../../../assets/images/png/homePage/news/New_Bloomberg_Logo.svg";
import { ReactComponent as PressLogo } from "../../../assets/images/png/homePage/news/Associated_Press_logo.svg";
import "./News.scss";

const News = () => {
  return (
    <section
      id="news-section"
      className="page-section d-flex align-items-center justify-content-center flex-column w-100"
    >
      <div className="d-flex container-main overflow-hidden w-100 flex-column align-items-start justify-content-start">
        <div className=" d-flex flex-column align-items-start justify-content-start">
          <Uppertitle title={"News"} />
          <h2 className="font-size-80 font-pnb mb-4">
            As seen on<span className="purple-color">:</span>
          </h2>
        </div>
      </div>
      <div className="top-border mb-3"></div>
      <div className="d-flex container-main overflow-hidden w-100 flex-column align-items-start justify-content-start">
        <div className="marquee d-flex">
          <div className="set-1 w-100 d-flex gap-4 px-3 align-items-center justify-content-around">
            <button
              className="z-index-10 p-1"
              onClick={() =>
                window.open(
                  "https://www.motogp.com/en/news/2022/07/25/cryptodata-tech-to-title-sponsor-austrian-gp/429509/"
                )
              }
            >
              <MotoGPLogo className="img-fluid small-svg" />
            </button>

            <button
              className="z-index-10 p-1"
              onClick={() =>
                window.open(
                  "https://www.coindesk.com/sponsored-content/cryptodata-tech-sponsors-motogp-austrian-grand-prix/"
                )
              }
            >
              <CoinDeskLogo className="img-fluid large-svg" />
            </button>
            <button
              className="z-index-10 p-1"
              onClick={() =>
                window.open(
                  "https://news.bitcoin.com/xiden-blockchain-developer-cryptodata-tech-announced-as-official-title-sponsor-of-the-motogp-austrian-gp/"
                )
              }
            >
              <BitcoinLogo className="img-fluid small-svg" />
            </button>

            <button
              className="z-index-10 p-1"
              onClick={() =>
                window.open(
                  "https://finbold.com/motogp-signs-multi-year-sponsorship-with-romanian-crypto-firm/"
                )
              }
            >
              <FinboldLogo className="img-fluid large-svg" />
            </button>

            <button
              className="z-index-10 p-1"
              onClick={() =>
                window.open("https://coinmarketcap.com/currencies/xiden/")
              }
            >
              <CoinMarketCapLogo className="img-fluid small-svg" />
            </button>

            <button
              className="z-index-10 p-1"
              onClick={() =>
                window.open("https://www.coinbase.com/price/xiden")
              }
            >
              <CoinBaseLogo className="img-fluid large-svg" />
            </button>
            <button
              className="z-index-10 bloomberg-ico p-1"
              onClick={() =>
                window.open(
                  "https://www.bloomberg.com/press-releases/2022-11-08/cryptodata-tech-becomes-rnf-motogp-team-majority-and-strategic-shareholder"
                )
              }
            >
              <BloombergIco className="img-fluid small-svg" />
            </button>
            <button
              className="z-index-10 press-ico p-1"
              onClick={() =>
                window.open(
                  "https://apnews.com/press-release/globe-newswire/technology-sports-europe-business-blockchain-4d3c2672dad5f32ea2a846610de6e63a"
                )
              }
            >
              <PressLogo className="img-fluid large-svg" />
            </button>
          </div>
          <div className="set-1 w-100 d-flex gap-4 px-3 align-items-center justify-content-around">
            <button
              className="z-index-10 p-1"
              onClick={() =>
                window.open(
                  "https://www.motogp.com/en/news/2022/07/25/cryptodata-tech-to-title-sponsor-austrian-gp/429509/"
                )
              }
            >
              <MotoGPLogo className="img-fluid small-svg" />
            </button>

            <button
              className="z-index-10 p-1"
              onClick={() =>
                window.open(
                  "https://www.coindesk.com/sponsored-content/cryptodata-tech-sponsors-motogp-austrian-grand-prix/"
                )
              }
            >
              <CoinDeskLogo className="img-fluid large-svg" />
            </button>
            <button
              className="z-index-10 p-1"
              onClick={() =>
                window.open(
                  "https://news.bitcoin.com/xiden-blockchain-developer-cryptodata-tech-announced-as-official-title-sponsor-of-the-motogp-austrian-gp/"
                )
              }
            >
              <BitcoinLogo className="img-fluid small-svg" />
            </button>

            <button
              className="z-index-10 p-1"
              onClick={() =>
                window.open(
                  "https://finbold.com/motogp-signs-multi-year-sponsorship-with-romanian-crypto-firm/"
                )
              }
            >
              <FinboldLogo className="img-fluid large-svg" />
            </button>

            <button
              className="z-index-10 p-1"
              onClick={() =>
                window.open("https://coinmarketcap.com/currencies/xiden/")
              }
            >
              <CoinMarketCapLogo className="img-fluid small-svg" />
            </button>

            <button
              className="z-index-10 p-1"
              onClick={() =>
                window.open("https://www.coinbase.com/price/xiden")
              }
            >
              <CoinBaseLogo className="img-fluid large-svg" />
            </button>

            <button
              className="z-index-10 bloomberg-ico p-1"
              onClick={() =>
                window.open(
                  "https://www.bloomberg.com/press-releases/2022-11-08/cryptodata-tech-becomes-rnf-motogp-team-majority-and-strategic-shareholder"
                )
              }
            >
              <BloombergIco className="img-fluid small-svg" />
            </button>
            <button
              className="z-index-10 press-ico p-1"
              onClick={() =>
                window.open(
                  "https://apnews.com/press-release/globe-newswire/technology-sports-europe-business-blockchain-4d3c2672dad5f32ea2a846610de6e63a"
                )
              }
            >
              <PressLogo className="img-fluid large-svg" />
            </button>
          </div>
        </div>
      </div>
      <div className="bottom-border mt-3"></div>
    </section>
  );
};

export default News;
